import gql from 'graphql-tag'

export default {
  MENUS: gql`
    query MENUS($congress_id: ID) {
      menus(congress_id: $congress_id) {
        id
        title
        order
        is_sidebar
        items {
          id
          title
          order
          icon
        }
      }
    }
  `,
  DRAWER_MENU: gql`
    query DRAWER_MENU($id: ID!) {
      menu(id: $id) {
        id
        title
        order
        is_sidebar
        items {
          id
          title
          order
          icon
        }
      }
    }
  `,
  CONGRESSES: gql`
    query CONGRESSES {
      congresses {
        id
        name
      }
    }
  `,
}
