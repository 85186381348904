import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'
import { Formik, FastField } from 'formik'
import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'

import { ADVERTISEMENT_LOCATIONS } from '../../../config'
import { removeNullProps } from '../../../utils/functions'

// Utils
import FormikTextField from '../../utils/FormikTextField'

// MUI
import {
  Grid,
  Divider,
  Typography,
  FormControlLabel,
  Switch,
  TextField,
  MenuItem,
  InputAdornment,
} from '@material-ui/core'

// Layout
import FormContainer from '../elements/layout/FormContainer'
import FormFooter from '../elements/layout/FormFooter'

// Components
import ImageCarousel from '../elements/inputs/ImageCarousel'

// Cosmetics
import FullscreenLoading from '../../cosmetics/loadings/FullscreenLoading'
import SelectLoading from '../../cosmetics/loadings/SelectLoading'

// Dialogs
import ConfirmationDialog from '../../dialogs/ConfirmationDialog'

// Queries & Mutations
import { query, mutation } from '../../../lib/apollo-client'

const schema = Yup.object().shape({
  congress_id: Yup.string().nullable(true),
  name: Yup.string().required().max(500),
  location: Yup.string().required().max(100),
  image_file_names: Yup.string().nullable(true).required(),
  random: Yup.boolean().required(),
  timing: Yup.number().integer().moreThan(-1),
})

const initialValues = {
  congress_id: null,
  name: '',
  location: 'SPLASH_SCREEN',
  image_file_names: null,
  random: false,
  timing: 500,
}

const AdForm = ({ advertisement }) => {
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const isNewAd = !advertisement

  const [congresses, setCongresses] = React.useState([])

  const [deleteConfirmOpen, setDeleteConfirmOpen] = React.useState(false)
  const handleConfirmationOpen = () => setDeleteConfirmOpen(true)
  const handleConfirmationClose = () => setDeleteConfirmOpen(false)

  // Data
  const [addAdvertisement, addAdvertisementMutation] = useMutation(
    mutation.ads.ADD_ADVERTISEMENT,
    {
      update(cache, { data: { addAdvertisement } }) {
        const adsData = cache.readQuery({
          query: query.ads.ADVERTISEMENTS,
        })

        if (adsData && Array.isArray(adsData.advertisements)) {
          cache.writeQuery({
            query: query.ads.ADVERTISEMENTS,
            data: {
              advertisements: adsData.advertisements.concat([addAdvertisement]),
            },
          })
        }
      },
    }
  )
  const [updateAdvertisement, updateAdvertisementMutation] = useMutation(
    mutation.ads.UPDATE_ADVERTISEMENT
  )
  const [deleteAdvertisement, deleteAdvertisementMutation] = useMutation(
    mutation.ads.DELETE_ADVERTISEMENT,
    {
      update(cache, { data: { deleteAdvertisement } }) {
        const adsData = cache.readQuery({
          query: query.ads.ADVERTISEMENTS,
        })

        if (adsData && Array.isArray(adsData.advertisements)) {
          cache.writeQuery({
            query: query.ads.ADVERTISEMENTS,
            data: {
              advertisements: adsData.advertisements.filter(ad => {
                return ad.id !== deleteAdvertisement.id
              }),
            },
          })
        }
      },
    }
  )

  const congressQuery = useQuery(query.polls.CONG_LIST)
  React.useEffect(() => {
    const { data, error, loading } = congressQuery

    if (loading) return

    if (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
      captureException(error)
    }

    if (data && Array.isArray(data.congresses)) {
      setCongresses(data.congresses)
    }
  }, [congressQuery, enqueueSnackbar])

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    values.timing = Number(values.timing)

    try {
      if (isNewAd) {
        const adId = uuidv4()

        await addAdvertisement({
          variables: {
            id: adId,
            ...values,
          },
        })

        history.push(`/ad/${adId}`)
      } else {
        await updateAdvertisement({
          variables: values,
        })

        resetForm(values)
        setSubmitting(false)
      }
    } catch (error) {
      enqueueSnackbar(
        error.message ? error.message : 'Error: error during creating ad!',
        {
          variant: 'error',
        }
      )
      captureException(error)

      setSubmitting(false)
    }
  }

  const handleDelete = async () => {
    if (!advertisement) {
      enqueueSnackbar('Could not delete non-existent advertisement!', {
        variant: 'error',
      })
      captureException('Could not delete non-existent advertisement!')
      return
    }

    try {
      await deleteAdvertisement({
        variables: { id: advertisement.id },
      })

      history.push('/ads')
    } catch (error) {
      enqueueSnackbar(
        error.message
          ? error.message
          : 'Error: error during deleting advertisement!',
        { variant: 'error' }
      )
      captureException(error)
    }
  }

  removeNullProps(advertisement)
  const formData = {
    ...initialValues,
    ...advertisement,
  }

  return (
    <>
      {(addAdvertisementMutation.loading ||
        updateAdvertisementMutation.loading ||
        deleteAdvertisementMutation.loading) && (
        <FullscreenLoading variant="white" transparent />
      )}

      <Formik
        enableReinitialize
        initialValues={formData}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, dirty, values, setFieldValue, errors }) => (
          <>
            <FormContainer>
              <Grid container spacing={4}>
                {/* NAME */}
                <Grid item xs={12} xl={6}>
                  <Typography variant="h6" gutterBottom>
                    {'Advertisement title'}
                  </Typography>

                  <FastField
                    name="name"
                    type="text"
                    placeholder="Title"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                {/* CONGRESS ID */}
                <Grid item xs={12} sm={6} xl={3}>
                  <Typography variant="h6" gutterBottom>
                    {'Advertisement is for...'}
                  </Typography>

                  {congressQuery.loading ? (
                    <SelectLoading />
                  ) : (
                    <TextField
                      name="congress_id"
                      type="text"
                      variant="outlined"
                      fullWidth
                      select
                      value={values.congress_id || 'unselected'}
                      onChange={e => {
                        if (e.target.value === 'unselected') {
                          setFieldValue('congress_id', null)
                        } else {
                          setFieldValue('congress_id', e.target.value)
                        }
                      }}
                    >
                      <MenuItem value={'unselected'}>{'Society App'}</MenuItem>
                      {Array.isArray(congresses) &&
                        congresses.map((congress, index) => (
                          <MenuItem key={index} value={congress.id}>
                            {congress.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                </Grid>

                {/* LOCATION */}
                <Grid item xs={12} sm={6} xl={3}>
                  <Typography variant="h6" gutterBottom>
                    {'Advertisement location'}
                  </Typography>

                  <FastField
                    name="location"
                    type="text"
                    placeholder="Location"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    select
                  >
                    {Array.isArray(ADVERTISEMENT_LOCATIONS) &&
                      ADVERTISEMENT_LOCATIONS.map((item, index) => (
                        <MenuItem key={index} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                  </FastField>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                {/* TIMING */}
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" gutterBottom>
                    {'Image timing'}
                  </Typography>

                  <FastField
                    name="timing"
                    type="text"
                    placeholder="Image timing"
                    component={FormikTextField}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">ms</InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                </Grid>

                {/* RANDOM */}
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" gutterBottom>
                    {'Random order'}
                  </Typography>

                  <FormControlLabel
                    control={
                      <Switch
                        name="random"
                        checked={values.random}
                        onChange={e => {
                          setFieldValue('random', e.target.checked)
                        }}
                        color="primary"
                      />
                    }
                    label="Play images in a random order"
                  />
                </Grid>

                {/* IMAGES */}
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    {'Advertisement images'}
                  </Typography>

                  <ImageCarousel
                    enableOrdering={!values.random}
                    requiredImages={1}
                    value={values.image_file_names}
                    onChange={newImageFileNames => {
                      if (
                        typeof newImageFileNames === 'string' &&
                        newImageFileNames.length > 0
                      ) {
                        setFieldValue('image_file_names', newImageFileNames)
                      } else {
                        setFieldValue('image_file_names', null)
                      }
                    }}
                  />
                </Grid>
              </Grid>

              <FormFooter
                isSubmitting={isSubmitting}
                dirty={dirty}
                save={!isNewAd}
                onDelete={!isNewAd ? handleConfirmationOpen : null}
              />
            </FormContainer>

            <ConfirmationDialog
              title="Delete advertisement"
              description="Are you sure you would like to delete this advertisement? This action is irreversible."
              returnLabel="Cancel"
              confirmLabel="Delete"
              onReturn={handleConfirmationClose}
              onConfirm={() => {
                handleConfirmationClose()
                handleDelete()
              }}
              open={deleteConfirmOpen}
            />
          </>
        )}
      </Formik>
    </>
  )
}

AdForm.propTypes = {
  advertisement: PropTypes.object,
}

export default AdForm
