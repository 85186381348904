import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

// MUI
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import HomeIcon from '@material-ui/icons/Home'
import SocietyAppIcon from '@material-ui/icons/Group'
import CongressesIcon from '@material-ui/icons/DateRange'
import PollsIcon from '@material-ui/icons/Poll'
import AdIcon from '@material-ui/icons/PermDeviceInformation'
import AppSettingsIcon from '@material-ui/icons/PhonelinkSetup'

const MainDrawer = props => {
  const classes = styles()
  const { closeDrawer } = props

  // Local state
  const [items] = useState([
    {
      name: 'Dashboard',
      url: '/',
      icon: <HomeIcon />,
      closeDrawer: true,
      enabled: true,
    },
    {
      name: 'Society App',
      url: '/societyapp',
      icon: <SocietyAppIcon />,
      closeDrawer: false,
      enabled: true,
    },
    {
      name: 'Congresses',
      url: '/congresses',
      icon: <CongressesIcon />,
      closeDrawer: false,
      enabled: true,
    },
    {
      name: 'Polls',
      url: '/polls',
      icon: <PollsIcon />,
      closeDrawer: false,
      enabled: true,
    },
    {
      name: 'Advertisements',
      url: '/ads',
      icon: <AdIcon />,
      closeDrawer: false,
      enabled: true,
    },
    {
      name: 'App Settings',
      url: '/settings',
      icon: <AppSettingsIcon />,
      closeDrawer: true,
      enabled: true,
    },
  ])

  return (
    <>
      <List>
        {items &&
          items.map(item => (
            <ListItem
              button
              key={item.name}
              to={item.url}
              component={Link}
              onClick={item.closeDrawer ? closeDrawer : undefined}
              disabled={!item.enabled}
            >
              <ListItemIcon className={classes.drawerItemLogo}>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
      </List>
    </>
  )
}

MainDrawer.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
}

const styles = makeStyles(theme => ({
  drawerItemLogo: {
    color: theme.palette.primary.main,
    minWidth: '40px',
  },
}))

export default MainDrawer
