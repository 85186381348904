import gql from 'graphql-tag'

export default {
  ADD_POLL: gql`
    mutation ADD_POLL(
      $id: ID!
      $congress_id: ID
      $section_id: ID
      $abstract_id: ID
      $title: String!
      $type: String
      $are_results_public: Boolean!
      $from: Int
      $to: Int
    ) {
      addPoll(
        id: $id
        congress_id: $congress_id
        section_id: $section_id
        abstract_id: $abstract_id
        title: $title
        type: $type
        are_results_public: $are_results_public
        from: $from
        to: $to
      ) {
        id
        title
        created_at
        __typename
      }
    }
  `,
  ADD_POLL_QUESTION: gql`
    mutation ADD_POLL_QUESTION(
      $id: ID!
      $poll_id: ID!
      $order: Int!
      $title: String!
      $type: String!
      $allow_multiple: Boolean!
      $required: Boolean!
      $exam_answer_values: String
      $right_option_id: ID
      $additional_info: String
    ) {
      addPollQuestion(
        id: $id
        poll_id: $poll_id
        order: $order
        title: $title
        type: $type
        allow_multiple: $allow_multiple
        required: $required
        exam_answer_values: $exam_answer_values
        right_option_id: $right_option_id
        additional_info: $additional_info
      ) {
        id
        __typename
      }
    }
  `,
  ADD_POLL_QUESTION_OPTION: gql`
    mutation APP_POLL_QUESTION_OPTION(
      $id: ID!
      $poll_question_id: ID!
      $order: Int!
      $title: String!
    ) {
      addPollQuestionOption(
        id: $id
        poll_question_id: $poll_question_id
        order: $order
        title: $title
      ) {
        id
        __typename
      }
    }
  `,

  UPDATE_POLL: gql`
    mutation UPDATE_POLL(
      $id: ID!
      $congress_id: ID
      $section_id: ID
      $abstract_id: ID
      $title: String
      $type: String
      $are_results_public: Boolean
      $from: Int
      $to: Int
    ) {
      updatePoll(
        id: $id
        congress_id: $congress_id
        section_id: $section_id
        abstract_id: $abstract_id
        title: $title
        type: $type
        are_results_public: $are_results_public
        from: $from
        to: $to
      ) {
        id
        congress_id
        section_id
        abstract_id
        title
        type
        are_results_public
        from
        to
        __typename
      }
    }
  `,
  UPDATE_POLL_QUESTION: gql`
    mutation UPDATE_POLL_QUESTION(
      $id: ID!
      $order: Int
      $title: String
      $type: String
      $allow_multiple: Boolean
      $required: Boolean
      $exam_answer_values: String
      $right_option_id: ID
      $additional_info: String
    ) {
      updatePollQuestion(
        id: $id
        order: $order
        title: $title
        type: $type
        allow_multiple: $allow_multiple
        required: $required
        exam_answer_values: $exam_answer_values
        right_option_id: $right_option_id
        additional_info: $additional_info
      ) {
        id
        order
        title
        type
        allow_multiple
        required
        exam_answer_values
        right_option_id
        additional_info
        __typename
      }
    }
  `,
  UPDATE_POLL_QUESTION_OPTION: gql`
    mutation UPDATE_POLL_QUESTION_OPTION(
      $id: ID!
      $order: Int
      $title: String
    ) {
      updatePollQuestionOption(id: $id, order: $order, title: $title) {
        id
        order
        title
        __typename
      }
    }
  `,

  DELETE_POLL: gql`
    mutation DELETE_POLL($id: ID!) {
      deletePoll(id: $id) {
        id
        __typename
      }
    }
  `,
  DELETE_POLL_QUESTION: gql`
    mutation DELETE_POLL_QUESTION($id: ID!) {
      deletePollQuestion(id: $id) {
        id
        __typename
      }
    }
  `,
  DELETE_POLL_QUESTION_OPTION: gql`
    mutation DELETE_POLL_QUESTION_OPTION($id: ID!) {
      deletePollQuestionOption(id: $id) {
        id
        poll_question_id
        __typename
      }
    }
  `,
}
