import { UseMutationOptions, useMutation } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { queryClient } from '../lib/react-query'
import { File, FileType, FileUploadService } from '../services/openapi'
import { HttpError } from '../types/error'

function useFileUpload(
  type: FileType,
  mutationOptions?: UseMutationOptions<File, HttpError, { file: Blob }>
) {
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    data =>
      FileUploadService.fileUploadControllerUploadFile({
        type,
        file: data.file,
      }),
    {
      ...mutationOptions,
      onError: error => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        })
      },
      onSuccess: newFile => {
        queryClient.setQueryData(['files', type], (files: File[] = []) => {
          return [newFile, ...files]
        })
      },
    }
  )
}

export { useFileUpload }
