import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'

// MUI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from '@material-ui/core'

type SelectionDialogProps = {
  title: string
  open: boolean
  onClose: () => void
  options: string[]
  onSubmit: (selected: string) => void
  defaultValue?: string
}

const SelectionDialog = (props: SelectionDialogProps) => {
  const { title, open, onClose, options, onSubmit, defaultValue } = props
  const classes = styles()

  const [selected, setSelected] = useState(
    defaultValue ? defaultValue : 'unselected'
  )

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelected(e.target.value)
  }

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.dialog }}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <TextField
          name="selected"
          type="text"
          variant="outlined"
          fullWidth
          select
          value={selected}
          onChange={handleChange}
        >
          <MenuItem value={'unselected'}>{'Select...'}</MenuItem>
          {options.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button onClick={onClose} variant="outlined" color="secondary">
          {'Cancel'}
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            onSubmit(selected)
            onClose()
          }}
          disabled={selected === 'unselected'}
        >
          {'Select'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const styles = makeStyles(theme => ({
  dialog: {
    [theme.breakpoints.up('xs')]: {
      width: 425,
    },
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
}))

export default SelectionDialog
