import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'
import { Formik, FastField } from 'formik'
import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'

import { removeNullProps } from '../../../utils/functions'

// Utils
import FormikTextField from '../../utils/FormikTextField'

// MUI
import { useTheme, Grid, Typography } from '@material-ui/core'

// Layout
import FormContainer from '../elements/layout/FormContainer'
import FormFooter from '../elements/layout/FormFooter'

// Elements
import DrawerTitle from '../../drawers/elements/DrawerTitle'
import TimestampPicker from '../elements/inputs/TimestampPicker'

// Cosmetics
import FullscreenLoading from '../../cosmetics/loadings/FullscreenLoading'
import InformationBlock from '../../cosmetics/UI/InformationBlock'

// Dialogs
import ConfirmationDialog from '../../dialogs/ConfirmationDialog'

// Queries & Mutations
import { query, mutation } from '../../../lib/apollo-client'

const FORM_DISABLED = false

const initialValues = {
  name: '',
  from: Math.floor(Date.now() / 1000),
  to: Math.floor(Date.now() / 1000),
}

const schema = Yup.object().shape({
  name: Yup.string().required().max(250),
  from: Yup.number()
    .integer()
    .required()
    .lessThan(
      Yup.ref('to'),
      'break start date should be earlier than end date'
    ),
  to: Yup.number()
    .integer()
    .required()
    .moreThan(
      Yup.ref('from'),
      'break end date should be later than start date'
    ),
})

const BreakForm = props => {
  const { breakItem, congressId } = props
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()
  const history = useHistory()

  const isNewBreak = !breakItem

  const [deleteConfirmOpen, setDeleteConfirmOpen] = React.useState(false)
  const handleConfirmationOpen = () => setDeleteConfirmOpen(true)
  const handleConfirmationClose = () => setDeleteConfirmOpen(false)

  // Data
  const [updateBreak, updateBreakMutation] = useMutation(
    mutation.form.break.UPDATE_BREAK
  )
  const [addBreak, addBreakMutation] = useMutation(
    mutation.form.break.ADD_BREAK,
    {
      update(cache, { data: { addBreak } }) {
        const breaksData = cache.readQuery({
          query: query.table.BREAKS,
          variables: { congress_id: congressId },
        })

        if (breaksData && Array.isArray(breaksData.breaks)) {
          cache.writeQuery({
            query: query.table.BREAKS,
            variables: { congress_id: congressId },
            data: { breaks: breaksData.breaks.concat([addBreak]) },
          })
        }
      },
    }
  )
  const [deleteBreak, deleteBreakMutation] = useMutation(
    mutation.form.break.DELETE_BREAK,
    {
      update(cache, { data: { deleteBreak } }) {
        const breaksData = cache.readQuery({
          query: query.table.BREAKS,
          variables: { congress_id: congressId },
        })

        if (breaksData && Array.isArray(breaksData.breaks)) {
          cache.writeQuery({
            query: query.table.BREAKS,
            variables: { congress_id: congressId },
            data: {
              breaks: breaksData.breaks.filter(breakItem => {
                return breakItem.id !== deleteBreak.id
              }),
            },
          })
        }
      },
    }
  )

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      if (isNewBreak) {
        const breakId = uuidv4()

        await addBreak({
          variables: {
            id: breakId,
            congress_id: congressId,
            ...values,
          },
        })

        history.push(`/congress/${congressId}/break/${breakId}`)
      } else {
        await updateBreak({
          variables: {
            ...values,
          },
        })

        resetForm(values)
        setSubmitting(false)
      }
    } catch (error) {
      enqueueSnackbar(
        error.message ? error.message : 'Error: error during saving break!',
        { variant: 'error' }
      )
      captureException(error)

      setSubmitting(false)
    }
  }

  const handleDelete = async () => {
    if (!breakItem) {
      enqueueSnackbar('Could not delete non-existent break!', {
        variant: 'error',
      })
      captureException('Could not delete non-existent break!')
      return
    }

    try {
      await deleteBreak({
        variables: { id: breakItem.id },
      })

      history.push(`/congress/${congressId}`)
    } catch (error) {
      enqueueSnackbar(
        error.message ? error.message : 'Error: error during deleting break!',
        { variant: 'error' }
      )
      captureException(error)
    }
  }

  removeNullProps(breakItem)
  const formData = {
    ...initialValues,
    ...breakItem,
  }

  return (
    <>
      {(addBreakMutation.loading ||
        updateBreakMutation.loading ||
        deleteBreakMutation.loading) && (
        <FullscreenLoading variant="white" transparent />
      )}

      <Formik
        enableReinitialize
        initialValues={formData}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, errors, isSubmitting, dirty }) => (
          <>
            <FormContainer elevation={0}>
              <DrawerTitle
                title={isNewBreak ? 'Create new break' : 'Edit break'}
              />

              <Grid container spacing={2}>
                {/* NAME */}
                <Grid item xs={12}>
                  <FastField
                    name="name"
                    type="text"
                    label="Name"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                {/* FROM */}
                <Grid item xs={12} md={6}>
                  <TimestampPicker
                    label="Starts from"
                    labelVariant="body1"
                    time
                    timestamp={values.from}
                    onChange={timestamp => setFieldValue('from', timestamp)}
                    error={Boolean(errors.from)}
                    errorText={Boolean(errors.from) ? errors.from : undefined}
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                {/* TO */}
                <Grid item xs={12} md={6}>
                  <TimestampPicker
                    label="Ends at"
                    labelVariant="body1"
                    time
                    timestamp={values.to}
                    onChange={timestamp => setFieldValue('to', timestamp)}
                    error={Boolean(errors.to)}
                    errorText={Boolean(errors.to) ? errors.to : undefined}
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                <Grid item xs={12}>
                  <InformationBlock variant="warning" size="small">
                    <Typography
                      style={{
                        fontWeight: theme.typography.fontWeightBold,
                      }}
                    >
                      {`All dates are displayed in your browser's current timezone!`}
                    </Typography>
                  </InformationBlock>
                </Grid>
              </Grid>

              <FormFooter
                isSubmitting={isSubmitting}
                dirty={dirty}
                save={!isNewBreak}
                onDelete={!isNewBreak ? handleConfirmationOpen : null}
              />
            </FormContainer>

            <ConfirmationDialog
              title="Delete break"
              description="Are you sure you would like to delete this break? This action is irreversible."
              returnLabel="Cancel"
              confirmLabel="Delete"
              onReturn={handleConfirmationClose}
              onConfirm={() => {
                handleConfirmationClose()
                handleDelete()
              }}
              open={deleteConfirmOpen}
            />
          </>
        )}
      </Formik>
    </>
  )
}

BreakForm.propTypes = {
  breakItem: PropTypes.object,
  congressId: PropTypes.string.isRequired,
}

export default BreakForm
