import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core/styles'

// MUI
import {
  useMediaQuery,
  Button,
  IconButton,
  Typography,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/DeleteForeverOutlined'
import UnsavedChangesIcon from '@material-ui/icons/FiberManualRecord'
import SaveIcon from '@material-ui/icons/Save'
import SavedIcon from '@material-ui/icons/Check'
import AddIcon from '@material-ui/icons/Add'
import { grey } from '@material-ui/core/colors'

const FormFooter = props => {
  const { isSubmitting, dirty, save, onDelete } = props
  const classes = styles(props)
  const theme = useTheme()
  const smAndUp = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <div className={classes.formFooter}>
      {onDelete && (
        <IconButton onClick={onDelete} className={classes.error} size="medium">
          <DeleteIcon />
        </IconButton>
      )}

      {dirty && smAndUp && (
        <div className={classes.unsavedChanges}>
          <UnsavedChangesIcon className={classes.unsavedChangesIcon} />

          <Typography variant="body1">{'Unsaved changes'}</Typography>
        </div>
      )}

      <Button
        type="submit"
        variant="contained"
        color="primary"
        size="large"
        disabled={isSubmitting || !dirty}
        className={classes.submitButton}
      >
        {save ? `Save${dirty ? '' : 'd'}` : 'Add'}

        {save ? (
          dirty ? (
            <SaveIcon className={classes.marginLeft} />
          ) : (
            <SavedIcon className={classes.marginLeft} />
          )
        ) : (
          <AddIcon className={classes.marginLeft} />
        )}
      </Button>
    </div>
  )
}

FormFooter.propTypes = {
  dirty: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  save: PropTypes.bool.isRequired,
  onDelete: PropTypes.func,
}

const styles = makeStyles(theme => ({
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  error: {
    color: theme.palette.error.main,
  },
  formFooter: props => ({
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: props.onDelete ? 'space-between' : 'flex-end',
    borderTop: `1px solid ${grey[400]}`,
  }),
  unsavedChanges: props => ({
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: props.onDelete ? 'center' : 'flex-start',
    color: theme.palette.error.main,
  }),
  unsavedChangesIcon: {
    height: '15px',
  },
  submitButton: {
    width: '125px',
  },
}))

export default FormFooter
