import React from 'react'
import PropTypes from 'prop-types'
import { Formik, FastField } from 'formik'
import * as Yup from 'yup'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'

// config
import { SECTION_PROPERTIES, ABSTRACT_PROPERTIES } from '../../../config'

// Utils
import FormikTextField from '../../utils/FormikTextField'

// MUI
import { Grid, Typography, Divider } from '@material-ui/core'

// Layout
import FormContainer from '../elements/layout/FormContainer'
import FormFooter from '../elements/layout/FormFooter'

// Cosmetics
import FullscreenLoading from '../../cosmetics/loadings/FullscreenLoading'
import InformationBlock from '../../cosmetics/UI/InformationBlock'

// Queries & Mutations
import { mutation } from '../../../lib/apollo-client'

const schema = Yup.object().shape({
  section_template: Yup.object()
    .shape({
      left_column: Yup.string().nullable(true),
      right_column: Yup.string().nullable(true),
    })
    .nullable(true),
  abstract_template: Yup.object()
    .shape({
      left_column: Yup.string().nullable(true),
      right_column: Yup.string().nullable(true),
    })
    .nullable(true),
})

const initialValues = {
  section_template: {
    left_column: '',
    right_column: '',
  },
  abstract_template: {
    left_column: '',
    right_column: '',
  },
}

const CongressMiscellaneousForm = props => {
  const { congress } = props
  const { enqueueSnackbar } = useSnackbar()

  // Data
  const [updateCongressSettings, updateCongressSettingsMutation] = useMutation(
    mutation.form.congress.UPDATE_CONGRESS_SETTINGS
  )

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await updateCongressSettings({
        variables: {
          id: congress.id,
          settings: JSON.stringify(values),
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateCongressSettings: {
            id: congress.id,
            settings: JSON.stringify(values),
            __typename: 'Congress',
          },
        },
      })

      resetForm(values)
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      })
      captureException(error)
    }

    setSubmitting(false)
  }

  const settings = congress.settings ? JSON.parse(congress.settings) : {}
  const formData = {
    ...initialValues,
    ...settings,
  }

  return (
    <>
      {updateCongressSettingsMutation.loading && (
        <FullscreenLoading variant="white" transparent />
      )}

      <Formik
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={schema}
        initialValues={formData}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, dirty }) => (
          <FormContainer>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>
                  {'Congress miscellaneous settings'}
                </Typography>
                <Divider />
              </Grid>

              {/* SECTION */}
              <Grid item xs={12}>
                <Typography variant="h6">{'Section display format'}</Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <InformationBlock variant="info">
                  <Typography gutterBottom>
                    {
                      'To display any of the variables, please wrap the variable name inside % marks!'
                    }
                  </Typography>
                  <Typography>{'(For example: %name%)'}</Typography>
                </InformationBlock>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography gutterBottom>
                  {'List of available variables'}
                </Typography>
                {SECTION_PROPERTIES.map(property => (
                  <Typography
                    key={property.name}
                    variant="caption"
                    component="p"
                  >
                    <b>{property.name}</b> ({property.description})
                  </Typography>
                ))}
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {/* SECTION - LEFT */}
                  <Grid item xs={12} md={6}>
                    <FastField
                      name="section_template.left_column"
                      type="text"
                      label="Left column template"
                      component={FormikTextField}
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                    />
                  </Grid>

                  {/* SECTION - RIGHT */}
                  <Grid item xs={12} md={6}>
                    <FastField
                      name="section_template.right_column"
                      type="text"
                      label="Right column template"
                      component={FormikTextField}
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              {/* ABSTRACT */}
              <Grid item xs={12}>
                <Typography variant="h6">
                  {'Abstract display format'}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <InformationBlock variant="info">
                  <Typography gutterBottom>
                    {
                      'To display any of the variables, please wrap the variable name inside % marks!'
                    }
                  </Typography>
                  <Typography>{'(For example: %name%)'}</Typography>
                </InformationBlock>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography gutterBottom>
                  {'List of available variables'}
                </Typography>
                {ABSTRACT_PROPERTIES.map(property => (
                  <Typography
                    key={property.name}
                    variant="caption"
                    component="p"
                  >
                    <b>{property.name}</b> ({property.description})
                  </Typography>
                ))}
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {/* ABSTRACT - LEFT */}
                  <Grid item xs={12} md={6}>
                    <FastField
                      name="abstract_template.left_column"
                      type="text"
                      label="Left column template"
                      component={FormikTextField}
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                    />
                  </Grid>

                  {/* ABSTRACT - RIGHT */}
                  <Grid item xs={12} md={6}>
                    <FastField
                      name="abstract_template.right_column"
                      type="text"
                      label="Right column template"
                      component={FormikTextField}
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <FormFooter isSubmitting={isSubmitting} dirty={dirty} save />
          </FormContainer>
        )}
      </Formik>
    </>
  )
}

CongressMiscellaneousForm.propTypes = {
  congress: PropTypes.object.isRequired,
}

export default CongressMiscellaneousForm
