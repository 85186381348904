import React, { useState } from 'react'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'

// MUI
import { Button } from '@material-ui/core'
import PublishIcon from '@material-ui/icons/Publish'

// Cosmetics
import FullscreenLoading from '../../cosmetics/loadings/FullscreenLoading'

// Dialogs
import ConfirmationDialog from '../../dialogs/ConfirmationDialog'

// Queries & Mutations
import { mutation } from '../../../lib/apollo-client'

const PublishButton = props => {
  const { match } = props
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  // Data
  const [publishCongress, publishCongressMutation] = useMutation(
    mutation.form.congress.PUBLISH_CONGRESS,
    {
      variables: { id: match.params.congressId },
    }
  )

  // State
  const [publishConfirmOpen, setPublishConfirmOpen] = useState(false)
  const handleConfirmationOpen = () => setPublishConfirmOpen(true)
  const handleConfirmationClose = () => setPublishConfirmOpen(false)

  const handlePublish = async () => {
    try {
      await publishCongress()
    } catch (error) {
      enqueueSnackbar('Error: error during publishing changes!', {
        variant: 'error',
      })
      captureException(error)
    }
  }

  return (
    <>
      {publishCongressMutation.loading && (
        <FullscreenLoading
          variant="white"
          transparent
          label="Publishing changes..."
        />
      )}

      <Button
        variant="contained"
        color="secondary"
        size={isMobile ? 'medium' : 'large'}
        onClick={handleConfirmationOpen}
        disabled={publishCongressMutation.loading}
      >
        {'Publish'}

        {!isMobile && (
          <PublishIcon
            style={{
              marginLeft: '6px',
            }}
          />
        )}
      </Button>

      <ConfirmationDialog
        title="Publish congress changes"
        open={publishConfirmOpen}
        description="Are you sure you would like to publish the changes? This will publish all changes made to the congress since the last publish!"
        returnLabel="Cancel"
        confirmLabel="Publish changes"
        onReturn={handleConfirmationClose}
        onConfirm={() => {
          handleConfirmationClose()
          handlePublish()
        }}
        variant="general"
      />
    </>
  )
}

export default PublishButton
