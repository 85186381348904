import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery, useTheme, Typography } from '@material-ui/core'

// MUI
import { Paper } from '@material-ui/core'

const GuideBlock = props => {
  const { icon, title } = props
  const classes = styles()
  const theme = useTheme()
  const smAndUp = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <Paper className={classes.paper}>
      <div className={classes.iconContainer}>{icon}</div>

      <div>
        <Typography
          variant="h5"
          style={{
            marginBottom: theme.spacing(2.5),
          }}
          align={smAndUp ? 'left' : 'center'}
        >
          {title}
        </Typography>

        {props.children}
      </div>
    </Paper>
  )
}

GuideBlock.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string.isRequired,
}

const styles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2.5),
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
      marginRight: theme.spacing(4),
    },
  },
}))

export default GuideBlock
