import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useAuth0 } from '../lib/auth0'

// Components
import Header from '../components/Header'

const Layout = props => {
  const classes = styles()
  const { isAuthenticated } = useAuth0()

  return (
    <div className={classes.layout}>
      {isAuthenticated && <Header />}

      <main className={classes.main}>
        {isAuthenticated && <div className={classes.toolbar} />}

        <div className={classes.container}>{props.children}</div>
      </main>
    </div>
  )
}

const styles = makeStyles(theme => ({
  layout: {
    display: 'flex',
  },
  main: {
    flexGrow: 1,
    marginBottom: theme.spacing(8),
    maxWidth: '100%',
    overflow: 'hidden',
  },
  toolbar: theme.mixins.toolbar,
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
}))

export default Layout
