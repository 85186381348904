import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

// MUI
import { Tab } from '@material-ui/core'

const ResponsiveTab = props => {
  const { label, icon, disabled, ...rest } = props
  const classes = styles()
  const theme = useTheme()
  const displayTabLabels = useMediaQuery(theme.breakpoints.up(700))

  return (
    <Tab
      label={displayTabLabels ? label : undefined}
      icon={icon}
      disabled={disabled}
      className={classes.tab}
      {...rest}
    />
  )
}

ResponsiveTab.propTypes = {
  label: PropTypes.node.isRequired,
  icon: PropTypes.element.isRequired,
  disabled: PropTypes.bool,
}

const styles = makeStyles(theme => ({
  tab: {
    minWidth: 0,
    fontSize: '0.75rem',
  },
}))

export default ResponsiveTab
