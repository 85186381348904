import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useAssetStore } from '../../../../AssetStore'

// MUI
import { Grid, Typography, Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

// Components
import ImageCarouselItem from './ImageCarouselItem'

const ImageCarousel = ({ value, onChange, enableOrdering, requiredImages }) => {
  const classes = styles()
  const { handleAssetStoreOpen } = useAssetStore()

  const fileNamesArray = React.useMemo(() => {
    if (value && typeof value === 'string') {
      return value.split(';')
    } else {
      return []
    }
  }, [value])

  const addImage = () => {
    handleAssetStoreOpen('IMG', selected => {
      if (!selected) {
        return
      }

      const newArray = fileNamesArray.concat([selected.file_name])
      onChange(newArray.join(';'))
    })
  }

  const removeImage = removeableIndex => {
    const newArray = Array.from(fileNamesArray)
    newArray.splice(removeableIndex, 1)
    onChange(newArray.join(';'))
  }

  const onDragEnd = result => {
    const { source, destination } = result
    if (!destination) {
      return
    }
    if (source.index === destination.index) {
      return
    }

    const newArray = Array.from(fileNamesArray)
    const [moved] = newArray.splice(source.index, 1)
    newArray.splice(destination.index, 0, moved)
    onChange(newArray.join(';'))
  }

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="imageCarouselDroppable"
          isDropDisabled={!enableOrdering}
        >
          {provided => (
            <>
              <Grid
                innerRef={provided.innerRef}
                {...provided.droppableProps}
                container
                spacing={2}
              >
                {fileNamesArray.length > 0 ? (
                  fileNamesArray.map((fileName, index) => (
                    <ImageCarouselItem
                      key={index}
                      index={index}
                      fileName={fileName}
                      removeImage={removeImage}
                      dragDisabled={!enableOrdering}
                    />
                  ))
                ) : (
                  <Grid item xs={12} className={classes.noImages}>
                    <Typography variant="caption" gutterBottom>
                      {'No images added yet.'}
                    </Typography>
                    {fileNamesArray.length < requiredImages && (
                      <Typography variant="caption" color="error">
                        {`At least ${requiredImages} image is required!`}
                      </Typography>
                    )}
                  </Grid>
                )}
              </Grid>

              {provided.placeholder}
            </>
          )}
        </Droppable>
      </DragDropContext>

      <div className={classes.carouselFooter}>
        <Button
          onClick={addImage}
          variant="contained"
          color="primary"
          size="small"
          startIcon={<AddIcon />}
        >
          {'Add image'}
        </Button>
      </div>
    </>
  )
}

ImageCarousel.defaultProps = {
  enableOrdering: false,
  requiredImages: 0,
}

ImageCarousel.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  enableOrdering: PropTypes.bool,
  requiredImages: PropTypes.number,
}

const styles = makeStyles(theme => ({
  noImages: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  carouselFooter: {
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
}))

export default ImageCarousel
