import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { TwitterPicker } from 'react-color'

// MUI
import { Typography, Button } from '@material-ui/core'

const ColorPicker = props => {
  const { label, color, onRemove, onChange, error, errorText } = props
  const classes = styles()

  return (
    <>
      {label && (
        <Typography variant="h6" gutterBottom>
          {label}
        </Typography>
      )}

      <div className={classes.center}>
        <TwitterPicker
          color={color ? color : '#808080'}
          onChangeComplete={color => onChange(color.hex)}
          onChange={color => onChange(color.hex)}
        />

        {onRemove && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => onRemove()}
            disabled={!color}
            className={classes.marginTop}
          >
            {'Unset'}
          </Button>
        )}

        {error && (
          <Typography
            variant="caption"
            color="error"
            paragraph
            className={classes.marginTop}
          >
            {errorText}
          </Typography>
        )}
      </div>
    </>
  )
}

ColorPicker.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  onRemove: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  errorText: PropTypes.string,
}

const styles = makeStyles(theme => ({
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
}))

export default ColorPicker
