import React from 'react'
import Helmet from 'react-helmet'

// MUI
import { OpenAPI } from '../services/openapi'

const SocietyApp = () => {
  return (
    <>
      <Helmet>
        <title>{'Society App'}</title>
      </Helmet>

      <iframe
        title="Society Edit"
        src={`${process.env.REACT_APP_NEW_FRONTEND}/society-navigation?accessToken=${OpenAPI.TOKEN}`}
        style={{ height: '100vh', border: 0 }}
      />
    </>
  )
}

export default SocietyApp
