import { makeStyles } from '@material-ui/core/styles'
import { captureException } from '@sentry/browser'
import clsx from 'clsx'
import { FastField, FieldArray } from 'formik'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { v4 as uuidv4 } from 'uuid'

// config
import { QUESTION_TYPES, s3config } from '../../../../config'

// MUI
import {
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Switch,
  Typography,
  useTheme,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/DeleteForeverOutlined'

// Utils
import FormikTextField from '../../../utils/FormikTextField'

// Elements
import QuillEditor from '../../elements/inputs/QuillEditor'
import NewPollOptionItem from './NewPollOptionItem'

// Queries & Mutations
import { useFileUpload } from '../../../../hooks/file-upload.mutation'
import { FileType } from '../../../../services/openapi'

const ONE_MEGABYTE_IN_BYTES = 1048576

const initialOptionValues = {
  title: '',
}

const NewPollQuestionItem = (props: any) => {
  const { pollType, question, index, setFieldValue, removeQuestion } = props
  const { enqueueSnackbar } = useSnackbar()
  const classes = styles()
  const theme = useTheme()

  // FOR QUILL IMAGE UPLOAD
  const { mutateAsync } = useFileUpload(FileType.QUILL_IMAGE)

  const uploadImage = useCallback(
    async file => {
      if (file.size > ONE_MEGABYTE_IN_BYTES) {
        enqueueSnackbar('Error: the selected image exceeds the 1MB limit!', {
          variant: 'error',
        })
        return new Error('Selected image exceeds the 1MB limit!')
      }

      try {
        const uploadedFile = await mutateAsync({
          file: file,
        })

        if (uploadedFile) {
          const fileName = uploadedFile.file_name

          return `https://${s3config.bucket}.s3.${s3config.region}.amazonaws.com/${fileName}`
        } else {
          captureException(
            new Error('Could not retrieve filename from response!')
          )
          enqueueSnackbar(
            'Error: could not retrieve image URL from response!',
            {
              variant: 'error',
            }
          )
        }
      } catch (error) {
        captureException(error)
      }
    },
    [enqueueSnackbar, mutateAsync]
  )

  const questionNumber = (
    <Typography
      variant="h6"
      style={{
        marginLeft: theme.spacing(0.5),
        marginBottom: theme.spacing(1),
      }}
    >{`${index + 1}.`}</Typography>
  )

  return (
    <Paper variant="outlined" className={classes.paddingAll}>
      {/* NO. */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {questionNumber}
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        {/* TITLE */}
        <Grid item xs={12}>
          <FastField
            name={`questions[${index}].title`}
            type="text"
            placeholder="Your question..."
            component={FormikTextField}
            variant="outlined"
            fullWidth
          />
        </Grid>

        {/* TYPE */}
        <Grid item xs={12}>
          <FastField
            name={`questions[${index}].type`}
            type="text"
            label="Type"
            component={FormikTextField}
            variant="outlined"
            fullWidth
            select
          >
            {QUESTION_TYPES &&
              QUESTION_TYPES.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
          </FastField>
        </Grid>

        {/* EXAM VALUES */}
        {pollType === 'EXAM' && question.type === 'OPTIONS' && (
          <>
            <Grid item xs={12} sm={4}>
              <FastField
                name={`questions[${index}].exam_answer_values.right`}
                type="text"
                label="Right answer value"
                component={FormikTextField}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FastField
                name={`questions[${index}].exam_answer_values.wrong`}
                type="text"
                label="Wrong answer value"
                component={FormikTextField}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FastField
                name={`questions[${index}].exam_answer_values.empty`}
                type="text"
                label="Empty answer value"
                component={FormikTextField}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </>
        )}

        {/* ADDITIONAL_INFO */}
        {pollType === 'EXAM' && (
          <Grid item xs={12}>
            <Typography
              variant="overline"
              gutterBottom
              style={{ fontWeight: 'bold' }}
            >
              {'Additional info (e.g.: references...)'}
            </Typography>

            <QuillEditor
              value={question.additional_info}
              onChange={(newContent: string) => {
                setFieldValue(`questions[${index}].additional_info`, newContent)
              }}
              uploadHandler={uploadImage}
            />
          </Grid>
        )}

        {/* OPTIONS */}
        {question.type === 'OPTIONS' && (
          <Grid item xs={12}>
            <FieldArray
              name={`questions[${index}].options`}
              render={arrayHelpers => (
                <div className={classes.paddingAll}>
                  {Array.isArray(question.options) &&
                  question.options.length > 0 ? (
                    question.options.map((option: any, optionIndex: number) => (
                      <NewPollOptionItem
                        key={optionIndex}
                        option={option}
                        questionIndex={index}
                        pollType={pollType}
                        rightAnswerId={question.right_option_id}
                        setFieldValue={setFieldValue}
                        optionIndex={optionIndex}
                        removeOption={() => {
                          // Normalize other options' order
                          for (
                            let i = optionIndex + 1;
                            i < question.options.length;
                            i++
                          ) {
                            setFieldValue(
                              `questions[${index}].options[${i}].order`,
                              question.options[i].order - 1
                            )
                          }

                          arrayHelpers.remove(optionIndex)
                        }}
                      />
                    ))
                  ) : (
                    <>
                      <Typography align="center" noWrap>
                        {'Add options to your question!'}
                      </Typography>
                      <Typography
                        align="center"
                        component="p"
                        variant="caption"
                        color="error"
                        noWrap
                      >
                        {'(At least 1 option is required.)'}
                      </Typography>
                    </>
                  )}

                  <div className={clsx(classes.center, classes.marginTopSm)}>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={() => {
                        arrayHelpers.push({
                          id: uuidv4(),
                          order: question.options.length,
                          ...initialOptionValues,
                        })
                      }}
                    >
                      {'New option'}
                    </Button>
                  </div>
                </div>
              )}
            />
          </Grid>
        )}

        {/* ALLOW MULTIPLE */}
        {pollType !== 'EXAM' && question.type === 'OPTIONS' && (
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Switch
                  name={`questions[${index}].allow_multiple`}
                  checked={question.allow_multiple}
                  onChange={e => {
                    setFieldValue(
                      `questions[${index}].allow_multiple`,
                      e.target.checked
                    )
                  }}
                  color="primary"
                />
              }
              label="Allow multiple choices?"
            />
          </Grid>
        )}
        {/* REQUIRED */}
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Switch
                name={`questions[${index}].required`}
                checked={question.required}
                onChange={e => {
                  setFieldValue(
                    `questions[${index}].required`,
                    e.target.checked
                  )
                }}
                color="primary"
              />
            }
            label="Required?"
          />
        </Grid>
      </Grid>

      <div className={classes.toRight}>
        <IconButton onClick={removeQuestion} className={classes.error}>
          <DeleteIcon />
        </IconButton>
      </div>
    </Paper>
  )
}

NewPollQuestionItem.propTypes = {
  pollType: PropTypes.string.isRequired,
  question: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  removeQuestion: PropTypes.func.isRequired,
}

const styles = makeStyles(theme => ({
  paddingAll: {
    padding: theme.spacing(2),
  },
  toRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  marginTopSm: {
    marginTop: theme.spacing(2),
  },
  error: {
    color: theme.palette.error.main,
  },
}))

export default NewPollQuestionItem
