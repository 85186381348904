import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { useDebounce } from 'use-debounce'

import PerfectScrollbar from 'react-perfect-scrollbar'
import { useTable, usePagination } from 'react-table'
import {
  Box,
  Card,
  CardHeader,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Typography,
  TextField,
  MenuItem,
  InputAdornment,
} from '@material-ui/core'
import ArrowPrev from '@material-ui/icons/ChevronLeft'
import ArrowNext from '@material-ui/icons/ChevronRight'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'

import ContentLoading from '../../cosmetics/loadings/ContentLoading'

const ServerSideTable = ({
  title,
  columns,
  data,
  loading,
  onRowClick,
  fetchData,
  pageCount: controlledPageCount,
  limit,
  defaultOrderBy,
  orderByOptions,
  defaultOrder,
  className,
  ...rest
}) => {
  const classes = styles()

  const [searchTerm, setSearchTerm] = React.useState('')
  const [order, setOrder] = React.useState(null)
  const [orderBy, setOrderBy] = React.useState(null)

  const [debouncedSearchTerm] = useDebounce(searchTerm, 500)

  React.useEffect(() => {
    setOrder(defaultOrder)
    setOrderBy(defaultOrderBy)
    // eslint-disable-next-line
  }, [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    canPreviousPage,
    canNextPage,
    previousPage,
    nextPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    usePagination
  )

  React.useEffect(() => {
    fetchData({
      variables: {
        offset: pageIndex * limit,
        limit,
        order: typeof order === 'string' ? order : undefined,
        orderBy: typeof orderBy === 'string' ? orderBy : undefined,
        searchTerm:
          debouncedSearchTerm.length > 0 ? debouncedSearchTerm : undefined,
      },
    })
  }, [fetchData, pageIndex, limit, order, orderBy, debouncedSearchTerm])

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        title={title}
        action={
          <>
            <TextField
              name="searchTerm"
              type="text"
              label="Search"
              variant="outlined"
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {searchTerm.length > 0 ? (
                      <IconButton
                        size="small"
                        onClick={() => setSearchTerm('')}
                      >
                        <ClearIcon />
                      </IconButton>
                    ) : (
                      <SearchIcon />
                    )}
                  </InputAdornment>
                ),
              }}
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              className={classes.searchTerm}
            />

            <TextField
              name="orderBy"
              type="text"
              label="Order by"
              variant="outlined"
              select
              size="small"
              value={orderBy || ''}
              onChange={e => setOrderBy(e.target.value)}
              className={classes.orderBy}
            >
              {Array.isArray(orderByOptions) &&
                orderByOptions.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
            </TextField>

            <TextField
              name="order"
              type="text"
              label="Order"
              variant="outlined"
              select
              size="small"
              value={order || ''}
              onChange={e => setOrder(e.target.value)}
            >
              <MenuItem value="ASC">{'Ascending'}</MenuItem>
              <MenuItem value="DESC">{'Descending'}</MenuItem>
            </TextField>
          </>
        }
        className={classes.cardHeader}
      />

      {loading ? (
        <ContentLoading />
      ) : (
        <PerfectScrollbar>
          <Box minWidth={500} height={500}>
            <Table stickyHeader {...getTableProps()}>
              <TableHead>
                {headerGroups.map(headerGroup => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <TableCell {...column.getHeaderProps()}>
                        {column.render('Header')}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>

              <TableBody {...getTableBodyProps()}>
                {page.map(row => {
                  prepareRow(row)

                  return (
                    <TableRow
                      onClick={() => {
                        if (onRowClick) onRowClick(row)
                      }}
                      {...row.getRowProps()}
                    >
                      {row.cells.map(cell => (
                        <TableCell {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </TableCell>
                      ))}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      )}

      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <Typography variant="caption" className={classes.pageInfo}>
          {`Page ${pageIndex + 1} of ${pageCount}`}
        </Typography>

        <IconButton onClick={() => previousPage()} disabled={!canPreviousPage}>
          <ArrowPrev color="inherit" />
        </IconButton>

        <IconButton onClick={() => nextPage()} disabled={!canNextPage}>
          <ArrowNext color="inherit" />
        </IconButton>
      </Box>
    </Card>
  )
}

ServerSideTable.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  onRowClick: PropTypes.func,
  fetchData: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  limit: PropTypes.number,
  defaultOrder: PropTypes.string.isRequired,
  orderByOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultOrderBy: PropTypes.string.isRequired,
}

ServerSideTable.defaultProps = {
  loading: false,
  limit: 10,
}

const styles = makeStyles(theme => ({
  root: {},
  cardHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  searchTerm: {
    marginRight: theme.spacing(4),
    width: 150,
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
    [theme.breakpoints.up('lg')]: {
      width: 250,
    },
  },
  orderBy: {
    marginRight: theme.spacing(2),
  },
  pageInfo: {
    marginRight: theme.spacing(2),
  },
}))

export default ServerSideTable
