import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

// MUI
import CircularProgress from '@material-ui/core/CircularProgress'

const FullscreenLoading = () => {
  const classes = styles()

  return (
    <div className={classes.wrapper}>
      <CircularProgress size={35} thickness={5} />
    </div>
  )
}

const styles = makeStyles(() => ({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export default FullscreenLoading
