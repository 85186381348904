import React from 'react'
import PropTypes from 'prop-types'

// MUI
import { useTheme, Button } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'

// Components
import EditNodeDialog from './EditNodeDialog'

// Dialogs
import ContentDialog from '../../../dialogs/ContentDialog'

const EditNodeButton = props => {
  const { congressId, dataItemId, dataItemType } = props
  const theme = useTheme()

  const [contentDialogOpen, setContentDialogOpen] = React.useState(false)
  const handleContentDialogOpen = () => setContentDialogOpen(true)
  const handleContentDialogClose = () => setContentDialogOpen(false)

  return (
    <>
      <Button
        onClick={handleContentDialogOpen}
        variant="outlined"
        color="primary"
        size="large"
      >
        {'Edit contents'}

        <EditIcon fontSize="small" style={{ marginLeft: theme.spacing(1.5) }} />
      </Button>

      <ContentDialog
        title="Edit contents"
        open={contentDialogOpen}
        onClose={handleContentDialogClose}
      >
        <EditNodeDialog
          congressId={congressId}
          dataItemId={dataItemId}
          dataItemType={dataItemType}
          closeContentDialog={handleContentDialogClose}
        />
      </ContentDialog>
    </>
  )
}

EditNodeButton.propTypes = {
  congressId: PropTypes.string,
  dataItemId: PropTypes.string.isRequired,
  dataItemType: PropTypes.string.isRequired,
}

export default EditNodeButton
