import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { useDebounce } from 'use-debounce'

// List of icons
import * as mdi from '../../lib/@mdi'

// MUI
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Button,
  TextField,
  Typography,
  Divider,
} from '@material-ui/core'
import SadFace from '@material-ui/icons/SentimentDissatisfied'

// Cosmetics
import MaterialIcon from '../cosmetics/UI/MaterialIcon'

const INITIAL_LIST = Array.isArray(mdi.icons) ? mdi.icons : []

const MaterialIconPicker = props => {
  const { open, onClose, onSelect } = props
  const classes = styles()

  const [icons, setIcons] = useState(INITIAL_LIST)
  const [displayedIcons, setDisplayedIcons] = useState([])
  const [displaySize, setDisplaySize] = useState(50)

  const [searchValue, setSearchValue] = useState('')
  const [debouncedSearchValue] = useDebounce(searchValue, 500)
  const handleSearchValueChange = e => setSearchValue(e.target.value)

  useEffect(() => {
    setDisplayedIcons(icons.slice(0, displaySize))
  }, [icons, displaySize])

  useEffect(() => {
    resetDisplaySize()

    if (!debouncedSearchValue) {
      setIcons(INITIAL_LIST)
    } else {
      const results = getSearchResults(debouncedSearchValue)

      setIcons(results)
    }
  }, [debouncedSearchValue])

  const increaseDisplaySize = () => {
    setDisplaySize(displaySize + 50)
  }

  const resetDisplaySize = () => {
    setDisplaySize(50)
  }

  const getSearchResults = keyword => {
    return INITIAL_LIST.filter(icon => {
      if (icon.name.includes(keyword)) {
        return true
      }

      return icon.aliases.some(alias => alias.includes(keyword))
    })
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        resetDisplaySize()
        onClose()
      }}
      fullWidth
      maxWidth="sm"
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {'Pick an icon...'}
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Search..."
              name="search"
              type="text"
              variant="outlined"
              fullWidth
              value={searchValue}
              onChange={handleSearchValueChange}
            />
          </Grid>
        </Grid>
      </DialogTitle>

      <Divider />

      <DialogContent>
        <Grid container spacing={4}>
          {displayedIcons.length > 0 ? (
            displayedIcons.map((icon, index) => (
              <Grid key={index} item xs={4} sm={3}>
                <span
                  onClick={() => onSelect(icon.name)}
                  className={clsx(classes.center, classes.iconContainer)}
                >
                  <MaterialIcon icon={icon.name} size="medium" />
                </span>
              </Grid>
            ))
          ) : (
            <Grid
              item
              xs={12}
              className={clsx(classes.flexColumn, classes.center)}
            >
              <SadFace className={classes.sadFace} />

              <Typography variant="h6" align="center">
                {'No results matched your search.'}
              </Typography>
            </Grid>
          )}

          {icons.length > displaySize && (
            <Grid item xs={12} className={classes.center}>
              <Button
                variant="outlined"
                color="primary"
                onClick={increaseDisplaySize}
                className={classes.marginBottom}
              >
                {'Load more...'}
              </Button>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

MaterialIconPicker.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
}

const styles = makeStyles(theme => ({
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  dialogPaper: {
    height: '80vh',
  },
  iconContainer: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.grey[100],
    },
  },
  sadFace: {
    height: '50px',
  },
}))

export default MaterialIconPicker
