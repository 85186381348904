import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { v4 as uuidv4 } from 'uuid'
import { captureException } from '@sentry/browser'

// MUI
import {
  Typography,
  IconButton,
  Paper,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Button,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined'

// Elements
import NewsItem from './NewsItem'

// Dialogs
import NamingDialog from '../../../dialogs/NamingDialog'

// Cosmetics
import FullscreenLoading from '../../../cosmetics/loadings/FullscreenLoading'
import ContentLoading from '../../../cosmetics/loadings/ContentLoading'

// Queries & Mutations
import { query, mutation } from '../../../../lib/apollo-client'

const INITIAL_DISPLAY_SIZE = 5

const News = props => {
  const { congressId, dataItem } = props
  const classes = styles()
  const { enqueueSnackbar } = useSnackbar()

  const [namingDialogOpen, setNamingDialogOpen] = React.useState(false)
  const handleNamingDialogOpen = () => setNamingDialogOpen(true)
  const handleNamingDialogClose = () => setNamingDialogOpen(false)

  const [news, setNews] = React.useState([])

  const [displayedNews, setDisplayedNews] = React.useState([])
  const [displaySize, setDisplaySize] = React.useState(INITIAL_DISPLAY_SIZE)
  React.useEffect(() => {
    if (Array.isArray(news)) {
      setDisplayedNews(news.slice(0, displaySize))
    }
  }, [news, displaySize])
  React.useEffect(() => {
    return () => {
      resetDisplaySize()
    }
  }, [])

  const isThereMore = React.useMemo(() => {
    if (
      Array.isArray(news) &&
      Array.isArray(displayedNews) &&
      news.length === displayedNews.length
    ) {
      return false
    } else {
      return true
    }
  }, [news, displayedNews])

  const increaseDisplaySize = () => {
    setDisplaySize(displaySize + INITIAL_DISPLAY_SIZE)
  }
  const resetDisplaySize = () => {
    setDisplaySize(INITIAL_DISPLAY_SIZE)
  }

  // Data
  const [addDataNode, addDataNodeMutation] = useMutation(
    mutation.form.menu.ADD_DATA_NODE,
    {
      update(cache, { data: { addDataNode } }) {
        const { dataNodes } = cache.readQuery({
          query: query.form.menu.DATA_NODES,
          variables: { data_item_id: dataItem.id },
        })

        cache.writeQuery({
          query: query.form.menu.DATA_NODES,
          variables: { data_item_id: dataItem.id },
          data: { dataNodes: dataNodes.concat([addDataNode]) },
        })
      },
      refetchQueries: [
        {
          query: query.oneSignal.ACTIVE_DATA_NODES,
          variables: { congress_id: congressId, type: 'NEWS' },
        },
      ],
    }
  )
  const { data, error, loading } = useQuery(query.form.menu.DATA_NODES, {
    variables: { data_item_id: dataItem.id },
  })

  React.useEffect(() => {
    if (loading) return

    if (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      })
      captureException(error)
    }

    if (data && Array.isArray(data.dataNodes)) {
      // sort nodes by created_at
      data.dataNodes.sort((a, b) => {
        if (Number(a.created_at) < Number(b.created_at)) {
          return 1
        } else if (Number(a.created_at) > Number(b.created_at)) {
          return -1
        } else {
          return 0
        }
      })

      setNews(data.dataNodes)
    }
  }, [data, error, loading, enqueueSnackbar])

  /**
   * Adds new data node.
   */
  const addNewDataNode = async name => {
    const values = {
      id: uuidv4(),
      data_item_id: dataItem.id,
      data: JSON.stringify({
        title: name,
      }),
    }

    try {
      await addDataNode({
        variables: values,
        optimisticResponse: {
          __typename: 'Mutation',
          addDataNode: {
            ...values,
            type: dataItem.type,
            created_at: `${Date.now()}`,
            __typename: 'DataNode',
          },
        },
      })
    } catch (error) {
      enqueueSnackbar('Error: error during adding data node!', {
        variant: 'error',
      })
      captureException(error)
    }
  }

  return (
    <>
      {addDataNodeMutation.loading && (
        <FullscreenLoading variant="white" transparent />
      )}

      <div className={classes.title}>
        <Typography variant="h6" component="span">
          {dataItem.type}
        </Typography>

        <Tooltip placement="top" title="Create a piece of news.">
          <span>
            <IconButton
              color="primary"
              size="small"
              className={classes.marginLeft}
              onClick={handleNamingDialogOpen}
              disabled={loading}
            >
              <AddIcon />
            </IconButton>
          </span>
        </Tooltip>
      </div>

      <NamingDialog
        subject="item"
        open={namingDialogOpen}
        onClose={handleNamingDialogClose}
        onSubmit={name => addNewDataNode(name)}
      />

      {loading ? (
        <ContentLoading />
      ) : (
        <Paper variant="outlined">
          <List disablePadding>
            {Array.isArray(displayedNews) && displayedNews.length > 0 ? (
              displayedNews.map((node, index) => (
                <NewsItem key={index} congressId={congressId} node={node} />
              ))
            ) : (
              <ListItem>
                <ListItemText primary="No news added." />
              </ListItem>
            )}
          </List>

          {isThereMore && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                padding: '12px',
              }}
            >
              <Button
                onClick={increaseDisplaySize}
                variant="outlined"
                color="primary"
              >
                {'Load more...'}
              </Button>
            </div>
          )}
        </Paper>
      )}
    </>
  )
}

News.propTypes = {
  congressId: PropTypes.string,
  dataItem: PropTypes.object.isRequired,
}

const styles = makeStyles(theme => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  error: {
    color: theme.palette.error.main,
  },
}))

export default News
