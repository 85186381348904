import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useAuth0 } from '../../lib/auth0'

// MUI
import { Avatar, Box, Divider, Toolbar, Typography } from '@material-ui/core'

// Components
import { siteConfig } from '../../config'
import AdsDrawer from './drawers/AdsDrawer'
import CongressDrawer from './drawers/CongressDrawer'
import CongressesDrawer from './drawers/CongressesDrawer'
import MainDrawer from './drawers/MainDrawer'
import PollsDrawer from './drawers/PollsDrawer'
import SocietyAppDrawer from './drawers/SocietyAppDrawer'

const DrawerRouter = props => {
  const { closeDrawer } = props
  const classes = styles()
  const { user } = useAuth0()

  const userLetter = useMemo(() => {
    if (!user) return null

    const { nickname, email } = user
    if (nickname && typeof nickname === 'string') {
      return nickname.charAt(0)
    } else if (email && typeof email === 'string') {
      return email.charAt(0)
    } else {
      return null
    }
  }, [user])

  const greeting = useMemo(() => {
    const date = new Date()
    const hours = date.getHours()
    if (hours < 12) {
      return 'Good morning,'
    } else if (hours >= 12 && hours < 20) {
      return 'Good afternoon,'
    } else {
      return 'Good night,'
    }
  }, [])

  return (
    <>
      <Toolbar>
        <img
          src={siteConfig.logo}
          className={classes.logo}
          alt="Company Logo"
        />
        <Typography
          component="h1"
          variant="h5"
          className={classes.appTitle}
          noWrap
        >
          {siteConfig.name}
        </Typography>
      </Toolbar>
      <Divider />

      {user ? (
        <>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt={4}
            mb={4}
          >
            {user.picture ? (
              <Avatar
                alt="User avatar"
                src={user.picture}
                className={classes.userPicture}
              />
            ) : (
              <Avatar alt="User avatar" className={classes.userPicture}>
                {userLetter}
              </Avatar>
            )}

            {user.email ? (
              <Box mt={1} textAlign="center">
                <Typography variant="h6" className={classes.greeting}>
                  {greeting}
                </Typography>
                <Typography variant="subtitle2" className={classes.email}>
                  {user.email}
                </Typography>
              </Box>
            ) : null}
          </Box>

          <Divider />
        </>
      ) : null}

      <Switch>
        <Route
          exact
          path="/"
          render={props => <MainDrawer {...props} closeDrawer={closeDrawer} />}
        />

        <Route
          path="/societyapp"
          render={props => (
            <SocietyAppDrawer {...props} closeDrawer={closeDrawer} />
          )}
        />

        <Route
          path="/congresses"
          render={props => (
            <CongressesDrawer {...props} closeDrawer={closeDrawer} />
          )}
        />
        <Route
          path="/congress/:congressId"
          render={props => (
            <CongressDrawer {...props} closeDrawer={closeDrawer} />
          )}
        />

        <Route
          path="/polls"
          render={props => <PollsDrawer {...props} closeDrawer={closeDrawer} />}
        />
        <Route
          path="/poll/:pollId"
          render={props => <PollsDrawer {...props} closeDrawer={closeDrawer} />}
        />

        <Route
          path="/ads"
          render={props => <AdsDrawer {...props} closeDrawer={closeDrawer} />}
        />
        <Route
          path="/ad/:adId"
          render={props => <AdsDrawer {...props} closeDrawer={closeDrawer} />}
        />

        <Route
          exact
          path="*"
          render={props => <MainDrawer {...props} closeDrawer={closeDrawer} />}
        />
      </Switch>
    </>
  )
}

DrawerRouter.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
}

const styles = makeStyles(theme => ({
  logo: {
    height: '40px',
    marginRight: theme.spacing(2),
  },
  appTitle: {
    fontWeight: 500,
  },
  userPicture: {
    height: theme.spacing(6),
    width: theme.spacing(6),
  },
  greeting: {
    fontWeight: theme.typography.fontWeightLight,
  },
  email: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

export default DrawerRouter
