import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Draggable } from 'react-beautiful-dnd'
import Image from 'react-image'

import { s3config } from '../../../../../config'

// MUI
import { Grid, CircularProgress, Tooltip, Fab } from '@material-ui/core'
import RemoveIcon from '@material-ui/icons/Close'

const ImageCarouselItem = ({ index, fileName, removeImage, dragDisabled }) => {
  const classes = styles()

  return (
    <Draggable
      draggableId={`${fileName}_${index}`}
      index={index}
      isDragDisabled={dragDisabled}
    >
      {provided => (
        <Grid
          innerRef={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          item
          xs={12}
          className={classes.imageContainer}
        >
          <div className={classes.image}>
            <Image
              src={`https://${s3config.bucket}.s3.${s3config.region}.amazonaws.com/${fileName}`}
              loader={<CircularProgress size={35} thickness={5} />}
              style={{
                maxWidth: '100%',
                maxHeight: 150,
              }}
            />

            <Tooltip title="Remove" placement="right">
              <Fab
                onClick={() => removeImage(index)}
                color="primary"
                size="small"
                className={classes.removeButton}
              >
                <RemoveIcon fontSize="small" />
              </Fab>
            </Tooltip>
          </div>
        </Grid>
      )}
    </Draggable>
  )
}

ImageCarouselItem.defaultProps = {
  dragDisabled: true,
}

ImageCarouselItem.propTypes = {
  index: PropTypes.number.isRequired,
  fileName: PropTypes.string.isRequired,
  removeImage: PropTypes.func.isRequired,
  dragDisabled: PropTypes.bool,
}

const styles = makeStyles(theme => ({
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    maxWidth: '100%',
    maxHeight: 150,
    overflow: 'hidden',
    position: 'relative',
  },
  removeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),
  },
}))

export default ImageCarouselItem
