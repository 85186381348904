import React from 'react'
import Helmet from 'react-helmet'

// MUI
import { useTheme, Grid, Typography } from '@material-ui/core'
import CongressesIcon from '@material-ui/icons/DateRange'

// Layout
import { ContentWrapper } from '../layout'

// Components
import InformationBlock from '../components/cosmetics/UI/InformationBlock'

// Widgets
import GuideBlock from '../components/widgets/GuideBlock'

const Congresses = () => {
  const theme = useTheme()

  return (
    <>
      <Helmet>
        <title>{'Congresses'}</title>
      </Helmet>

      <ContentWrapper>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <GuideBlock
              icon={
                <CongressesIcon
                  style={{
                    fontSize: '5em',
                  }}
                />
              }
              title="Congresses"
            >
              <Typography>
                {
                  'You can customize and interact with your Congresses here. That includes:'
                }
              </Typography>

              <ul
                style={{
                  marginBottom: theme.spacing(4),
                }}
              >
                <li>{'Creating new congresses'}</li>
                <li>{'Editing your existing congresses'}</li>
              </ul>

              <InformationBlock variant="info" size="small">
                <Typography>
                  {
                    'Tip: use the drawer on the left to create your first congress!'
                  }
                </Typography>
              </InformationBlock>
            </GuideBlock>
          </Grid>
        </Grid>
      </ContentWrapper>
    </>
  )
}

export default Congresses
