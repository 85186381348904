import React from 'react'
import Helmet from 'react-helmet'

// MUI
import { Grid } from '@material-ui/core'

// Layout
import { ContentWrapper } from '../layout'

// Widgets
import Welcome from '../components/widgets/Welcome'
import QuickActions from '../components/widgets/QuickActions'
import CongressList from '../components/widgets/CongressList'

const Dashboard = () => {
  return (
    <>
      <Helmet>
        <title>{'Dashboard'}</title>
      </Helmet>

      <ContentWrapper>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Welcome />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CongressList />
          </Grid>

          <Grid item xs={12} sm={6}>
            <QuickActions />
          </Grid>
        </Grid>
      </ContentWrapper>
    </>
  )
}

export default Dashboard
