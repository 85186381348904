import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { v4 as uuidv4 } from 'uuid'
import { Draggable } from 'react-beautiful-dnd'
import { captureException } from '@sentry/browser'

// Utils
import { sortItemsByOrder, indicesToOrders } from '../../../../utils/functions'

// MUI
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  IconButton,
  ListItemSecondaryAction,
  List,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/FormatLineSpacing'
import SidebarIcon from '@material-ui/icons/Menu'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined'

// Elements
import MenuItems from './MenuItems'

// Dialogs
import NamingDialog from '../../../dialogs/NamingDialog'

// Cosmetics
import FullscreenLoading from '../../../cosmetics/loadings/FullscreenLoading'

// Queries & Mutations
import { query, mutation } from '../../../../lib/apollo-client'

const Menu = props => {
  const { index, menu, isAdding, congressId, closeDrawer } = props
  const classes = styles()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  // Local state
  const [isOpen, setIsOpen] = useState(false)
  const toggleIsOpen = () => {
    setIsOpen(!isOpen)
  }
  const [menuItems, setMenuItems] = useState([])

  // Naming dialog
  const [namingDialogOpen, setNamingDialogOpen] = useState(false)
  const handleNamingDialogOpen = () => setNamingDialogOpen(true)
  const handleNamingDialogClose = () => setNamingDialogOpen(false)

  useEffect(() => {
    sortItemsByOrder(menu.items)
    indicesToOrders(menu.items)
    setMenuItems(menu.items)
  }, [menu.items])

  const [updateMenuItemOrder] = useMutation(
    mutation.drawer.UPDATE_MENU_ITEM_ORDER
  )
  const [addMenuItem, addMenuItemMutationData] = useMutation(
    mutation.drawer.ADD_MENU_ITEM,
    {
      update(cache, { data: { addMenuItem } }) {
        cache.writeQuery({
          query: query.drawer.DRAWER_MENU,
          variables: {
            id: menu.id,
          },
          data: {
            menu: {
              ...menu,
              items: menu.items.concat([addMenuItem]),
            },
          },
        })
      },
    }
  )

  /**
   * Sync order of menu items with DB.
   */
  useEffect(() => {
    ;(async () => {
      await Promise.all(
        menuItems.map(async menuItem => {
          try {
            await updateMenuItemOrder({
              variables: { id: menuItem.id, order: menuItem.order },
            })
          } catch (error) {
            enqueueSnackbar('Error: error during updating menu item order!', {
              variant: 'error',
            })
            captureException(error)
          }
        })
      )
    })()
  }, [menuItems, updateMenuItemOrder, enqueueSnackbar])

  /**
   * Adds new menu item.
   */
  const addNewMenuItem = async name => {
    const values = {
      id: uuidv4(),
      menu_id: menu.id,
      title: name,
      order: Array.isArray(menuItems) ? menuItems.length : 0,
    }

    try {
      await addMenuItem({
        variables: values,
        optimisticResponse: {
          __typename: 'Mutation',
          addMenuItem: {
            icon: null,
            ...values,
            __typename: 'MenuItem',
          },
        },
      })
    } catch (error) {
      enqueueSnackbar('Error: error during adding menu item!', {
        variant: 'error',
      })
      captureException(error)
    }
  }

  if (!menu.is_sidebar) {
    return (
      <>
        {addMenuItemMutationData.loading && (
          <FullscreenLoading variant="white" transparent />
        )}

        <Draggable draggableId={menu.id} index={index}>
          {(provided, snapshot) => (
            <>
              <ListItem
                button
                divider={isOpen}
                onClick={() => {
                  if (!isAdding) {
                    if (congressId) {
                      history.push(`/congress/${congressId}/menu/${menu.id}`)
                    } else {
                      history.push(`/societyapp/menu/${menu.id}`)
                    }

                    closeDrawer()
                  }
                }}
                innerRef={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <ListItemIcon className={classes.drawerItemLogo}>
                  <MenuIcon />
                </ListItemIcon>
                <ListItemText primary={menu.title} />

                {!snapshot.isDragging && (
                  <ListItemSecondaryAction>
                    <IconButton edge="end" size="small" onClick={toggleIsOpen}>
                      {!isOpen && <ExpandMoreIcon />}
                      {isOpen && <ExpandLessIcon />}
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            </>
          )}
        </Draggable>

        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <List>
            <ListItem button onClick={handleNamingDialogOpen}>
              <AddIcon className={classes.addIcon} />
              {'New menu item'}
            </ListItem>
          </List>

          <NamingDialog
            subject="menu item"
            open={namingDialogOpen}
            onClose={handleNamingDialogClose}
            onSubmit={name => addNewMenuItem(name)}
            maxLength={100}
          />

          <MenuItems
            items={menuItems}
            setItems={setMenuItems}
            closeDrawer={closeDrawer}
            congressId={congressId}
            isAdding={addMenuItemMutationData.loading}
          />
        </Collapse>
      </>
    )
  } else {
    return (
      <>
        {addMenuItemMutationData.loading && (
          <FullscreenLoading variant="white" transparent />
        )}

        <ListItem
          button
          divider={isOpen}
          onClick={() => {
            if (!isAdding) {
              if (congressId) {
                history.push(`/congress/${congressId}/menu/${menu.id}`)
              } else {
                history.push(`/societyapp/menu/${menu.id}`)
              }

              closeDrawer()
            }
          }}
        >
          <ListItemIcon className={classes.drawerItemLogo}>
            <SidebarIcon />
          </ListItemIcon>
          <ListItemText primary={menu.title} />

          <ListItemSecondaryAction>
            <IconButton edge="end" size="small" onClick={toggleIsOpen}>
              {!isOpen && <ExpandMoreIcon />}
              {isOpen && <ExpandLessIcon />}
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <List>
            <ListItem button onClick={handleNamingDialogOpen}>
              <AddIcon className={classes.addIcon} />
              {'New menu item'}
            </ListItem>
          </List>

          <NamingDialog
            subject="menu item"
            open={namingDialogOpen}
            onClose={handleNamingDialogClose}
            onSubmit={name => addNewMenuItem(name)}
            maxLength={100}
          />

          <MenuItems
            items={menuItems}
            setItems={setMenuItems}
            closeDrawer={closeDrawer}
            congressId={congressId}
            isAdding={addMenuItemMutationData.loading}
          />
        </Collapse>
      </>
    )
  }
}

Menu.propTypes = {
  index: PropTypes.number.isRequired,
  menu: PropTypes.object.isRequired,
  isAdding: PropTypes.bool.isRequired,
  congressId: PropTypes.string,
  closeDrawer: PropTypes.func.isRequired,
}

const styles = makeStyles(theme => ({
  drawerItemLogo: {
    color: theme.palette.primary.main,
    minWidth: '40px',
  },
  addIcon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
}))

export default Menu
