import React from 'react'
import Helmet from 'react-helmet'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'
import { v4 as uuidv4 } from 'uuid'

// MUI
import { Stepper, Step, StepLabel } from '@material-ui/core'

// Layout
import { ContentWrapper } from '../layout'

// Components
import NewPollSettings from '../components/forms/NewPoll/NewPollSettings'
import NewPollQuestions from '../components/forms/NewPoll/NewPollQuestions'

// Cosmetics
import FullscreenLoading from '../components/cosmetics/loadings/FullscreenLoading'

// Queries & Mutations
import { query, mutation } from '../lib/apollo-client'

// Step labels
const STEPS = ['General settings', 'Questions']
const MAX_STEP = STEPS.length - 1

const initialValues = {
  congress_id: null,
  section_id: null,
  abstract_id: null,
  title: '',
  type: 'VOTING',
  are_results_public: false,
  from: Math.floor(Date.now() / 1000),
  to: Math.floor(Date.now() / 1000) + 3600,
  questions: [],
}

const NewPoll = () => {
  const classes = styles()
  const theme = useTheme()
  const displayStepLabels = useMediaQuery(theme.breakpoints.up('sm'))
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const [poll, setPoll] = React.useState(initialValues)

  const [currentStep, setCurrentStep] = React.useState(0)
  const [isLoading, setIsLoading] = React.useState(false)

  // Data
  const [addPoll] = useMutation(mutation.polls.ADD_POLL, {
    update(cache, { data: { addPoll } }) {
      const pollsData = cache.readQuery({
        query: query.polls.POLLS,
      })

      if (pollsData && Array.isArray(pollsData.polls)) {
        cache.writeQuery({
          query: query.polls.POLLS,
          data: { polls: pollsData.polls.concat([addPoll]) },
        })
      }
    },
  })
  const [addPollQuestion] = useMutation(mutation.polls.ADD_POLL_QUESTION)
  const [addPollQuestionOption] = useMutation(
    mutation.polls.ADD_POLL_QUESTION_OPTION
  )

  const handleBack = () => {
    setCurrentStep(currentStep => currentStep - 1)
  }
  const handleNext = () => {
    setCurrentStep(currentStep => currentStep + 1)
  }
  const handleSaveProgress = values => {
    setPoll(values)
  }

  const handlePollCreation = async poll => {
    setIsLoading(true)

    try {
      const pollId = uuidv4()
      const { questions, ...rest } = poll

      await addPoll({
        variables: {
          id: pollId,
          ...rest,
        },
      })

      if (Array.isArray(questions) && questions.length > 0) {
        await Promise.all(
          questions.map(async question => {
            const { options, exam_answer_values, ...rest } = question

            // Serialize exam answer values
            const examAnswerValues =
              exam_answer_values && typeof exam_answer_values === 'object'
                ? JSON.stringify({
                    right: Number(exam_answer_values.right),
                    wrong: Number(exam_answer_values.wrong),
                    empty: Number(exam_answer_values.empty),
                  })
                : null

            await addPollQuestion({
              variables: {
                poll_id: pollId,
                exam_answer_values: examAnswerValues,
                ...rest,
              },
            })

            if (
              question.type === 'OPTIONS' &&
              Array.isArray(question.options) &&
              question.options.length > 0
            ) {
              await Promise.all(
                question.options.map(async option => {
                  await addPollQuestionOption({
                    variables: {
                      poll_question_id: question.id,
                      ...option,
                    },
                  })
                })
              )
            }
          })
        )
      }

      setIsLoading(false)
      history.push(`/poll/${pollId}`)
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      })
      captureException(error)
      setIsLoading(false)
    }
  }

  if (isLoading) {
    return <FullscreenLoading variant="white" transparent />
  }

  return (
    <>
      <Helmet>
        <title>{'Create new poll'}</title>
      </Helmet>

      <Stepper activeStep={currentStep} className={classes.stepper}>
        {STEPS.map((label, index) => (
          <Step key={index}>
            <StepLabel>{displayStepLabels ? label : undefined}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <ContentWrapper>
        {currentStep === 0 && (
          <NewPollSettings
            poll={poll}
            setPoll={handleSaveProgress}
            currentStep={currentStep}
            maxStep={MAX_STEP}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        )}

        {currentStep === 1 && (
          <NewPollQuestions
            poll={poll}
            setPoll={handleSaveProgress}
            currentStep={currentStep}
            maxStep={MAX_STEP}
            handleBack={handleBack}
            handleNext={handlePollCreation}
          />
        )}
      </ContentWrapper>
    </>
  )
}

const styles = makeStyles(() => ({
  stepper: {
    backgroundColor: 'inherit',
  },
}))

export default NewPoll
