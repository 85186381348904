import { QueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import { Helmet } from 'react-helmet'
import { Route, Switch } from 'react-router-dom'
import { useAuth0 } from './lib/auth0'
import { queryClient } from './lib/react-query'
import { OpenAPI } from './services/openapi'

// Utils
import PrivateRoute from './components/utils/PrivateRoute'

// Layouts
import Layout from './layout'

// Views
import Advertisements from './views/Advertisements'
import AppSettings from './views/AppSettings'
import Congress from './views/Congress'
import Abstract from './views/Congress/drawers/Abstract'
import Break from './views/Congress/drawers/Break'
import Floorplan from './views/Congress/drawers/Floorplan'
import NewAbstract from './views/Congress/drawers/NewAbstract'
import NewBreak from './views/Congress/drawers/NewBreak'
import NewSection from './views/Congress/drawers/NewSection'
import Section from './views/Congress/drawers/Section'
import Sponsor from './views/Congress/drawers/Sponsor'
import Topic from './views/Congress/drawers/Topic'
import Congresses from './views/Congresses'
import Dashboard from './views/Dashboard'
import EditAd from './views/EditAd'
import Login from './views/Login'
import Menu from './views/Menu'
import MenuItem from './views/MenuItem'
import NewAd from './views/NewAd'
import NewCongress from './views/NewCongress'
import NewPoll from './views/NewPoll'
import NotFound from './views/NotFound'
import PollSettings from './views/PollSettings'
import Polls from './views/Polls'
import SocietyApp from './views/SocietyApp'

// Components
import AssetStore from './components/AssetStore'

// Cosmetics
import FullscreenLoading from './components/cosmetics/loadings/FullscreenLoading'
import { siteConfig } from './config'

const App = () => {
  const { loading, isAuthenticated } = useAuth0()
  OpenAPI.BASE = process.env.REACT_APP_API

  return (
    <>
      <Helmet
        titleTemplate={`%s | ${siteConfig.name} Admin`}
        defaultTitle={`${siteConfig.name} Admin`}
      />

      {loading ? (
        <FullscreenLoading variant="primary" />
      ) : (
        <QueryClientProvider client={queryClient}>
          {isAuthenticated && <AssetStore />}

          <Layout>
            <Switch>
              <Route exact path="/login" component={Login} />

              <PrivateRoute exact path="/" component={Dashboard} />

              <PrivateRoute path="/societyapp" component={SocietyApp} />

              <PrivateRoute exact path="/congresses" component={Congresses} />
              <PrivateRoute
                exact
                path="/congresses/new"
                component={NewCongress}
              />
              <PrivateRoute path="/congress/:congressId" component={Congress} />

              <PrivateRoute exact path="/polls" component={Polls} />
              <PrivateRoute exact path="/polls/new" component={NewPoll} />
              <PrivateRoute
                exact
                path="/poll/:pollId"
                component={PollSettings}
              />

              <PrivateRoute exact path="/ads" component={Advertisements} />
              <PrivateRoute exact path="/ads/new" component={NewAd} />
              <PrivateRoute exact path="/ad/:adId" component={EditAd} />

              <PrivateRoute exact path="/settings" component={AppSettings} />

              <PrivateRoute exact path="*" component={NotFound} />
            </Switch>

            {/* DRAWERS */}
            <Switch>
              {/* FOR SOCIETY APP */}
              <PrivateRoute
                exact
                path="/societyapp/menu/:menuId"
                component={Menu}
              />
              <PrivateRoute
                exact
                path="/societyapp/menuItem/:menuItemId"
                component={MenuItem}
              />

              {/* FOR CONGRESS */}
              <PrivateRoute
                exact
                path="/congress/:congressId/menu/:menuId"
                component={Menu}
              />
              <PrivateRoute
                exact
                path="/congress/:congressId/menuItem/:menuItemId"
                component={MenuItem}
              />
              <PrivateRoute
                exact
                path="/congress/:congressId/section/new"
                component={NewSection}
              />
              <PrivateRoute
                exact
                path="/congress/:congressId/section/:sectionId"
                component={Section}
              />
              <PrivateRoute
                exact
                path="/congress/:congressId/abstract/new"
                component={NewAbstract}
              />
              <PrivateRoute
                exact
                path="/congress/:congressId/abstract/:abstractId"
                component={Abstract}
              />
              <PrivateRoute
                exact
                path="/congress/:congressId/topic/:topicId"
                component={Topic}
              />
              <PrivateRoute
                exact
                path="/congress/:congressId/break/new"
                component={NewBreak}
              />
              <PrivateRoute
                exact
                path="/congress/:congressId/break/:breakId"
                component={Break}
              />
              <PrivateRoute
                exact
                path="/congress/:congressId/floorplan/:floorplanId"
                component={Floorplan}
              />
              <PrivateRoute
                exact
                path="/congress/:congressId/sponsor/:sponsorId"
                component={Sponsor}
              />
            </Switch>
          </Layout>
        </QueryClientProvider>
      )}
    </>
  )
}

export default App
