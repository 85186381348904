import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'
import { Formik, FastField } from 'formik'
import * as Yup from 'yup'

import { POLL_TYPES } from '../../../config'

// Utils
import FormikTextField from '../../utils/FormikTextField'

// MUI
import {
  useTheme,
  Typography,
  Grid,
  FormControlLabel,
  Switch,
  TextField,
  MenuItem,
} from '@material-ui/core'

// Layout
import FormContainer from '../elements/layout/FormContainer'
import FormFooter from '../elements/layout/FormFooter'

// Elements
import TimestampPicker from '../elements/inputs/TimestampPicker'

// Cosmetics
import FullscreenLoading from '../../cosmetics/loadings/FullscreenLoading'
import SelectLoading from '../../cosmetics/loadings/SelectLoading'
import InformationBlock from '../../cosmetics/UI/InformationBlock'

// Dialogs
import ConfirmationDialog from '../../dialogs/ConfirmationDialog'

// Queries & Mutations
import { query, mutation } from '../../../lib/apollo-client'

const schema = Yup.object().shape({
  congress_id: Yup.string().nullable(true),
  section_id: Yup.string().nullable(true),
  abstract_id: Yup.string().nullable(true),
  title: Yup.string().required().max(200),
  type: Yup.string().required().max(100),
  are_results_public: Yup.boolean().required(),
  from: Yup.number()
    .integer()
    .required()
    .lessThan(Yup.ref('to'), 'poll start date should be earlier than end date'),
  to: Yup.number()
    .integer()
    .required()
    .moreThan(Yup.ref('from'), 'poll end date should be later than start date'),
})

const PollSettingsGeneralForm = props => {
  const { poll } = props
  const classes = styles()
  const theme = useTheme()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const [congresses, setCongresses] = React.useState([])
  const [sections, setSections] = React.useState([])
  const [abstracts, setAbstracts] = React.useState([])

  const [deleteConfirmOpen, setDeleteConfirmOpen] = React.useState(false)
  const handleConfirmationOpen = () => setDeleteConfirmOpen(true)
  const handleConfirmationClose = () => setDeleteConfirmOpen(false)

  // Data
  const [updatePoll, updatePollMutation] = useMutation(
    mutation.polls.UPDATE_POLL
  )
  const [deletePoll, deletePollMutation] = useMutation(
    mutation.polls.DELETE_POLL,
    {
      update(cache, { data: { deletePoll } }) {
        const pollsData = cache.readQuery({
          query: query.polls.POLLS,
        })

        if (pollsData && Array.isArray(pollsData.polls)) {
          cache.writeQuery({
            query: query.polls.POLLS,
            data: {
              polls: pollsData.polls.filter(poll => {
                return poll.id !== deletePoll.id
              }),
            },
          })
        }
      },
    }
  )

  // CONGRESSES
  const congressQuery = useQuery(query.polls.CONG_LIST)
  React.useEffect(() => {
    if (congressQuery.loading) {
      return
    }

    if (congressQuery.error) {
      enqueueSnackbar(congressQuery.error.message, { variant: 'error' })
      captureException(congressQuery.error)
    }

    if (congressQuery.data && Array.isArray(congressQuery.data.congresses)) {
      setCongresses(congressQuery.data.congresses)
    }
  }, [congressQuery, enqueueSnackbar])
  // SECTIONS
  const [fetchSections, sectionQuery] = useLazyQuery(query.polls.SECT_LIST)
  React.useEffect(() => {
    if (sectionQuery.loading) {
      return
    }

    if (sectionQuery.error) {
      enqueueSnackbar(sectionQuery.error.message, { variant: 'error' })
      captureException(sectionQuery.error)
    }

    if (sectionQuery.data && Array.isArray(sectionQuery.data.sections)) {
      setSections(sectionQuery.data.sections)
    }
  }, [sectionQuery, enqueueSnackbar])
  // ABSTRACTS
  const [fetchAbstracts, abstractQuery] = useLazyQuery(query.polls.ABS_LIST)
  React.useEffect(() => {
    if (abstractQuery.loading) {
      return
    }

    if (abstractQuery.error) {
      enqueueSnackbar(abstractQuery.error.message, { variant: 'error' })
      captureException(abstractQuery.error)
    }

    if (
      abstractQuery.data &&
      Array.isArray(abstractQuery.data.sectionAbstracts)
    ) {
      setAbstracts(abstractQuery.data.sectionAbstracts)
    }
  }, [abstractQuery, enqueueSnackbar])

  // Prefetch
  React.useEffect(() => {
    if (poll.congress_id) {
      fetchSections({
        variables: { congress_id: poll.congress_id },
      })
    }

    if (poll.section_id) {
      fetchAbstracts({
        variables: { section_id: poll.section_id },
      })
    }
  }, [poll, fetchSections, fetchAbstracts])

  /**
   * Handles poll general settings save.
   *
   * @param values
   * @param FormikBag
   */
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const { questions, ...rest } = values

      await updatePoll({
        variables: {
          ...rest,
        },
      })

      resetForm(values)
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
      captureException(error)
    }

    setSubmitting(false)
  }

  /**
   * Handles poll delete.
   */
  const handleDelete = async () => {
    try {
      await deletePoll({
        variables: { id: poll.id },
      })

      history.push('/polls')
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
      captureException(error)
    }
  }

  const formData = {
    ...poll,
  }

  return (
    <>
      {(updatePollMutation.loading || deletePollMutation.loading) && (
        <FullscreenLoading variant="white" transparent />
      )}

      <Formik
        enableReinitialize
        initialValues={formData}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ dirty, isSubmitting, values, setFieldValue, errors }) => (
          <>
            <FormContainer>
              <Grid container spacing={4}>
                {/* TITLE */}
                <Grid item xs={12} lg={6}>
                  <Typography variant="h6" gutterBottom>
                    {'Poll title'}
                  </Typography>

                  <FastField
                    name="title"
                    type="text"
                    placeholder="Poll title"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                {/* FROM */}
                <Grid item xs={12} sm={6} lg={3}>
                  <TimestampPicker
                    label="Poll starts at"
                    time
                    timestamp={values.from}
                    onChange={timestamp => {
                      setFieldValue('from', timestamp)
                      setFieldValue('to', timestamp + 3600)
                    }}
                    error={Boolean(errors.from)}
                    errorText={Boolean(errors.from) ? errors.from : undefined}
                    fullWidth
                  />
                </Grid>

                {/* TO */}
                <Grid item xs={12} sm={6} lg={3}>
                  <TimestampPicker
                    label="Poll ends at"
                    time
                    timestamp={values.to}
                    onChange={timestamp => setFieldValue('to', timestamp)}
                    error={Boolean(errors.to)}
                    errorText={Boolean(errors.to) ? errors.to : undefined}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <InformationBlock variant="warning" size="small">
                    <Typography
                      style={{
                        fontWeight: theme.typography.fontWeightBold,
                      }}
                    >
                      {`All dates are displayed in your browser's current timezone!`}
                    </Typography>
                  </InformationBlock>
                </Grid>

                {/* TYPE */}
                <Grid item xs={12} md={6}>
                  <TextField
                    name="type"
                    type="text"
                    label="Poll type"
                    value={values.type}
                    onChange={e => {
                      setFieldValue('type', e.target.value)
                      if (e.target.value === 'EVALUATION') {
                        setFieldValue('are_results_public', false)
                      }
                    }}
                    variant="outlined"
                    fullWidth
                    select
                  >
                    {POLL_TYPES.map(type => (
                      <MenuItem key={type.value} value={type.value}>
                        {type.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {/* ARE RESULTS PUBLIC? */}
                <Grid item xs={12} md={6}>
                  <FormControlLabel
                    disabled={values.type === 'EVALUATION'}
                    control={
                      <Switch
                        checked={values.are_results_public}
                        name="are_results_public"
                        onChange={e => {
                          setFieldValue('are_results_public', e.target.checked)
                        }}
                        color="primary"
                      />
                    }
                    label="Are results public?"
                  />
                </Grid>
              </Grid>

              <Typography
                variant="h6"
                gutterBottom
                className={classes.marginTopLg}
              >
                {'The poll is for...'}
              </Typography>

              <Grid container spacing={2}>
                {/* CONGRESS ID */}
                <Grid item xs={12}>
                  {congressQuery.loading ? (
                    <SelectLoading />
                  ) : Array.isArray(congresses) && congresses.length > 0 ? (
                    <TextField
                      name="congress_id"
                      type="text"
                      variant="outlined"
                      fullWidth
                      select
                      disabled={congressQuery.loading}
                      value={values.congress_id || 'unselected'}
                      onChange={e => {
                        if (e.target.value === 'unselected') {
                          setFieldValue('congress_id', null)
                          setSections([])
                          setAbstracts([])
                        } else {
                          setFieldValue('congress_id', e.target.value)
                          fetchSections({
                            variables: { congress_id: e.target.value },
                          })
                        }

                        // Reset fields below
                        setFieldValue('section_id', null)
                        setFieldValue('abstract_id', null)
                      }}
                    >
                      <MenuItem value={'unselected'}>
                        {'Select congress...'}
                      </MenuItem>
                      {congresses.map((congress, index) => (
                        <MenuItem key={index} value={congress.id}>
                          {congress.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <Typography>{'No available congresses.'}</Typography>
                  )}
                </Grid>

                {/* SECTION ID */}
                <Grid item xs={12}>
                  {congressQuery.loading || sectionQuery.loading ? (
                    <SelectLoading />
                  ) : Array.isArray(sections) && sections.length > 0 ? (
                    <TextField
                      name="section_id"
                      type="text"
                      variant="outlined"
                      fullWidth
                      select
                      disabled={!values.congress_id || sectionQuery.loading}
                      value={values.section_id || 'unselected'}
                      onChange={e => {
                        if (e.target.value === 'unselected') {
                          setFieldValue('section_id', null)
                          setAbstracts([])
                        } else {
                          setFieldValue('section_id', e.target.value)
                          fetchAbstracts({
                            variables: { section_id: e.target.value },
                          })
                        }

                        // Reset fields below
                        setFieldValue('abstract_id', null)
                      }}
                    >
                      <MenuItem value={'unselected'}>
                        {'Select section...'}
                      </MenuItem>
                      {sections.map((section, index) => (
                        <MenuItem key={index} value={section.id}>
                          {section.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <Typography>{'No available sections.'}</Typography>
                  )}
                </Grid>

                {/* ABSTRACT ID */}
                <Grid item xs={12}>
                  {congressQuery.loading ||
                  sectionQuery.loading ||
                  abstractQuery.loading ? (
                    <SelectLoading />
                  ) : Array.isArray(abstracts) && abstracts.length > 0 ? (
                    <TextField
                      name="abstract_id"
                      type="text"
                      variant="outlined"
                      fullWidth
                      select
                      disabled={!values.section_id || abstractQuery.loading}
                      value={values.abstract_id || 'unselected'}
                      onChange={e => {
                        if (e.target.value === 'unselected') {
                          setFieldValue('abstract_id', null)
                        } else {
                          setFieldValue('abstract_id', e.target.value)
                        }
                      }}
                    >
                      <MenuItem value={'unselected'}>
                        {'Select abstract...'}
                      </MenuItem>
                      {abstracts.map((abstract, index) => (
                        <MenuItem key={index} value={abstract.id}>
                          {abstract.title}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <Typography>{'No available abstracts.'}</Typography>
                  )}
                </Grid>
              </Grid>

              <FormFooter
                isSubmitting={isSubmitting}
                dirty={dirty}
                save
                onDelete={handleConfirmationOpen}
              />
            </FormContainer>

            <ConfirmationDialog
              title="Delete poll"
              open={deleteConfirmOpen}
              description="Are you sure you would like to delete this poll? This action is irreversible."
              returnLabel="Cancel"
              confirmLabel="Delete"
              onReturn={handleConfirmationClose}
              onConfirm={() => {
                handleConfirmationClose()
                handleDelete()
              }}
            />
          </>
        )}
      </Formik>
    </>
  )
}

PollSettingsGeneralForm.propTypes = {
  poll: PropTypes.object.isRequired,
}

const styles = makeStyles(theme => ({
  marginTop: {
    marginTop: theme.spacing(4),
  },
  marginTopLg: {
    marginTop: theme.spacing(6),
  },
}))

export default PollSettingsGeneralForm
