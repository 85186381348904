import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Image from 'react-image'

// config
import { s3config } from '../../../../config'

// MUI
import {
  CircularProgress,
  Typography,
  Button,
  Tooltip,
  Fab,
  Paper,
} from '@material-ui/core'
import RemoveIcon from '@material-ui/icons/Close'

const IconPicker = props => {
  const { label, icon, onRemove, onChange, error, errorText } = props
  const classes = styles()

  return (
    <>
      {label && (
        <Typography variant="h6" gutterBottom>
          {label}
        </Typography>
      )}

      <div className={clsx(classes.marginTop, classes.center)}>
        {icon && (
          <Paper variant="outlined" className={classes.icon}>
            <Image
              src={`https://${s3config.bucket}.s3.${s3config.region}.amazonaws.com/${icon.file_name}`}
              style={{
                maxHeight: '95%',
                maxWidth: '95%',
                borderRadius: '4px',
              }}
              loader={<CircularProgress size={35} thickness={5} />}
            />

            {onRemove && (
              <Tooltip title="Remove" placement="right">
                <Fab
                  size="small"
                  color="primary"
                  className={classes.removeButton}
                  onClick={() => onRemove()}
                >
                  <RemoveIcon fontSize="small" />
                </Fab>
              </Tooltip>
            )}
          </Paper>
        )}

        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => onChange()}
          className={classes.marginTop}
        >
          {icon ? 'Change' : 'Add'}
        </Button>
      </div>

      {error && (
        <Typography
          variant="caption"
          color="error"
          paragraph
          className={classes.marginTop}
        >
          {errorText}
        </Typography>
      )}
    </>
  )
}

IconPicker.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.object,
  onRemove: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  errorText: PropTypes.string,
}

const styles = makeStyles(theme => ({
  icon: {
    height: 80,
    width: 125,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  removeButton: {
    position: 'absolute',
    top: 0,
    left: 0,
    transform: 'translate(-35%, -35%)',
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

export default IconPicker
