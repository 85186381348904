import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

// Elements
import BackButton from '../elements/BackButton'
import SendNotificationButton from '../elements/SendNotificationButton'
import Menus from './elements/Menus'

const CongressDrawer = props => {
  const { match, closeDrawer } = props
  const history = useHistory()

  return (
    <>
      <BackButton
        handleBack={() => {
          history.push('/congresses')
        }}
      />

      <SendNotificationButton congressId={match.params.congressId} />

      <Menus congressId={match.params.congressId} closeDrawer={closeDrawer} />
    </>
  )
}

CongressDrawer.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
}

export default CongressDrawer
