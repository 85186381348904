import gql from 'graphql-tag'

export default {
  SECTIONS: gql`
    query SECTIONS($congress_id: ID!) {
      sections(congress_id: $congress_id) {
        id
        congress_id
        name
        short_name
        from
        to
        chair_free
        room
        ratingCount
        ratingAvg
      }
    }
  `,
  SECTIONS_CONNECTION: gql`
    query SECTIONS_CONNECTION(
      $filter: SectionFilter!
      $offset: Int
      $limit: Int
      $orderBy: String
      $order: String
      $searchTerm: String
    ) {
      sectionsConnection(
        filter: $filter
        offset: $offset
        limit: $limit
        orderBy: $orderBy
        order: $order
        searchTerm: $searchTerm
      ) {
        edges {
          id
          congress_id
          name
          short_name
          from
          to
          chair_free
          room
          ratingCount
          ratingAvg
        }
        offset
        limit
        orderBy
        order
        count
      }
    }
  `,
  ABSTRACTS: gql`
    query ABSTRACTS($congress_id: ID!) {
      abstracts(congress_id: $congress_id) {
        id
        congress_id
        section_id
        title
        from
        to
        presenter
        topic {
          id
          name
        }
        ratingCount
        ratingAvg
      }
    }
  `,
  ABSTRACTS_CONNECTION: gql`
    query ABSTRACTS_CONNECTION(
      $filter: AbstractFilter!
      $offset: Int
      $limit: Int
      $orderBy: String
      $order: String
      $searchTerm: String
    ) {
      abstractsConnection(
        filter: $filter
        offset: $offset
        limit: $limit
        orderBy: $orderBy
        order: $order
        searchTerm: $searchTerm
      ) {
        edges {
          id
          congress_id
          section_id
          title
          from
          to
          presenter
          topic {
            id
            name
          }
          ratingCount
          ratingAvg
        }
        offset
        limit
        orderBy
        order
        count
      }
    }
  `,
  TOPICS: gql`
    query TOPICS($congress_id: ID!) {
      topics(congress_id: $congress_id) {
        id
        congress_id
        name
      }
    }
  `,
  BREAKS: gql`
    query BREAKS($congress_id: ID!) {
      breaks(congress_id: $congress_id) {
        id
        congress_id
        name
        from
        to
      }
    }
  `,
  FLOORPLANS: gql`
    query FLOORPLANS($congress_id: ID!) {
      floorplans(congress_id: $congress_id) {
        id
        congress_id
        name
      }
    }
  `,
  SPONSOR_CATEGORIES: gql`
    query SPONSOR_CATEGORIES($congress_id: ID!) {
      sponsorCategories(congress_id: $congress_id) {
        id
        congress_id
        name
        order
      }
    }
  `,
  SPONSOR_CATEGORY: gql`
    query SPONSOR_CATEGORY($id: ID!) {
      sponsorCategory(id: $id) {
        id
        sponsors {
          id
          name
          order
        }
      }
    }
  `,
}
