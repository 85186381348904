import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

// MUI
import { Container } from '@material-ui/core'

const ContentWrapper = props => {
  const classes = styles()

  return <Container className={classes.container}>{props.children}</Container>
}

const styles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}))

export default ContentWrapper
