import { alpha, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

// MUI
import { CircularProgress, Typography } from '@material-ui/core'

const FullscreenLoading = props => {
  const classes = styles(props)

  return (
    <div className={clsx(classes.fixed, classes.wrapper)}>
      <div className={classes.content}>
        <CircularProgress size={35} thickness={5} className={classes.spinner} />
        {props.label && (
          <Typography variant="h6" className={classes.label}>
            {props.label}
          </Typography>
        )}
      </div>
    </div>
  )
}

FullscreenLoading.propTypes = {
  variant: PropTypes.oneOf(['primary', 'white']),
  transparent: PropTypes.bool,
  label: PropTypes.string,
}

const styles = makeStyles(theme => ({
  fixed: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 9999,
  },
  wrapper: props => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor:
      props.variant === 'primary'
        ? props.transparent
          ? alpha(theme.palette.primary.main, 0.5)
          : theme.palette.primary.main
        : props.transparent
        ? alpha(theme.palette.common.white, 0.5)
        : theme.palette.common.white,
  }),
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  spinner: props => ({
    color:
      props.variant === 'primary'
        ? theme.palette.common.white
        : theme.palette.primary.main,
  }),
  label: {
    marginTop: theme.spacing(2),
    color: theme.palette.common.black,
  },
}))

export default FullscreenLoading
