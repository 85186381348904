import React from 'react'
import ContentLoader from 'react-content-loader'

const DrawerLoading = () => {
  return (
    <ContentLoader height={180} width={240} speed={1}>
      <rect x="15" y="10" rx="10" ry="10" width="210" height="40" />
      <rect x="15" y="60" rx="10" ry="10" width="210" height="40" />
      <rect x="15" y="110" rx="10" ry="10" width="210" height="40" />
    </ContentLoader>
  )
}

export default DrawerLoading
