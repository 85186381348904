import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'

// MUI
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListSubheader,
} from '@material-ui/core'
import CongressIcon from '@material-ui/icons/EventNote'
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined'

// Elements
import BackButton from '../elements/BackButton'

// Cosmetics
import DrawerLoading from '../../cosmetics/loadings/DrawerLoading'

// Queries & Mutations
import { query } from '../../../lib/apollo-client'

const CongressesDrawer = props => {
  const { closeDrawer } = props
  const classes = styles()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  // Data
  const { data, error, loading } = useQuery(query.drawer.CONGRESSES)

  useEffect(() => {
    if (loading) {
      return
    }

    if (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      })
      captureException(error)
    }
  }, [data, error, loading, enqueueSnackbar])

  return (
    <>
      <BackButton
        handleBack={() => {
          history.push('/')
        }}
      />

      {loading ? (
        <DrawerLoading />
      ) : (
        <>
          <List subheader={<ListSubheader>{'Congresses'}</ListSubheader>}>
            <ListItem
              button
              onClick={() => {
                history.push('/congresses/new')
                closeDrawer()
              }}
            >
              <ListItemIcon className={classes.drawerItemLogo}>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary={'New congress'} />
            </ListItem>
          </List>

          {data && data.congresses && data.congresses.length > 0 && (
            <List>
              {data.congresses.map((congress, index) => (
                <ListItem
                  key={index}
                  button
                  onClick={() => {
                    history.push(`/congress/${congress.id}`)
                    closeDrawer()
                  }}
                >
                  <ListItemIcon className={classes.drawerItemLogo}>
                    <CongressIcon />
                  </ListItemIcon>

                  <ListItemText primary={congress.name} />
                </ListItem>
              ))}
            </List>
          )}
        </>
      )}
    </>
  )
}

CongressesDrawer.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
}

const styles = makeStyles(theme => ({
  drawerItemLogo: {
    color: theme.palette.primary.main,
    minWidth: '40px',
  },
}))

export default CongressesDrawer
