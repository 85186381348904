import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'

// MUI
import {
  useTheme,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Divider,
  Tooltip,
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import FlagIcon from '@material-ui/icons/Flag'
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined'

const Questions = ({ questions, toggleFlagged, toggleAnswered }) => {
  const classes = styles()
  const theme = useTheme()

  const sortedQuestions = React.useMemo(() => {
    if (Array.isArray(questions)) {
      return questions.sort((a, b) => {
        if (Number(a.created_at) < Number(b.created_at)) return 1
        if (Number(a.created_at) > Number(b.created_at)) return -1

        return 0
      })
    } else {
      return []
    }
  }, [questions])

  const flaggedQuestions = React.useMemo(() => {
    return sortedQuestions.filter(question => {
      return question.flagged
    })
  }, [sortedQuestions])

  const otherQuestions = React.useMemo(() => {
    return sortedQuestions.filter(question => {
      return !question.flagged
    })
  }, [sortedQuestions])

  return (
    <div>
      <List
        dense
        subheader={
          <ListSubheader disableSticky>{'Flagged questions'}</ListSubheader>
        }
      >
        {flaggedQuestions.length > 0 ? (
          flaggedQuestions.map((question, index) => (
            <ListItem key={index} className={classes.question}>
              <ListItemText
                primary={
                  <Typography
                    style={{
                      textDecoration: question.answered
                        ? 'line-through'
                        : 'none',
                      color: question.answered
                        ? theme.palette.grey[400]
                        : theme.palette.common.black,
                    }}
                  >
                    <span className={classes.bold}>
                      {`(${moment
                        .unix(Number(question.created_at) / 1000)
                        .format('HH:mm')}) `}
                    </span>
                    <span className={classes.bold}>{`${
                      question.author || 'Anonymous'
                    }: `}</span>
                    <span>{question.question}</span>
                  </Typography>
                }
              />

              <ListItemSecondaryAction>
                <Tooltip title="Toggle answered state" placement="top">
                  <IconButton
                    onClick={() =>
                      toggleAnswered(
                        question.id,
                        question.flagged,
                        question.answered
                      )
                    }
                    size="small"
                    style={{
                      marginRight: theme.spacing(1),
                    }}
                    className={classes.tickMark}
                  >
                    {question.answered ? <ClearIcon /> : <CheckIcon />}
                  </IconButton>
                </Tooltip>

                <Tooltip title="Toggle flagged status" placement="top">
                  <IconButton
                    onClick={() =>
                      toggleFlagged(
                        question.id,
                        question.flagged,
                        question.answered
                      )
                    }
                    size="small"
                    edge="end"
                    className={classes.flagMark}
                  >
                    {question.flagged ? <FlagOutlinedIcon /> : <FlagIcon />}
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          ))
        ) : (
          <Typography
            variant="caption"
            component="p"
            align="center"
            gutterBottom
          >
            {'No questions flagged.'}
          </Typography>
        )}
      </List>

      <Divider />

      <div className={classes.allQuestionsContainer}>
        <List
          dense
          subheader={
            <ListSubheader disableSticky>{'Other questions'}</ListSubheader>
          }
        >
          {otherQuestions.length > 0 ? (
            otherQuestions.map((question, index) => (
              <ListItem key={index} className={classes.question}>
                <ListItemText
                  primary={
                    <Typography
                      style={{
                        textDecoration: question.answered
                          ? 'line-through'
                          : 'none',
                        color: question.answered
                          ? theme.palette.grey[400]
                          : theme.palette.common.black,
                      }}
                    >
                      <span className={classes.bold}>
                        {`(${moment
                          .unix(Number(question.created_at) / 1000)
                          .format('HH:mm')}) `}
                      </span>
                      <span className={classes.bold}>{`${
                        question.author || 'Anonymous'
                      }: `}</span>
                      <span>{question.question}</span>
                    </Typography>
                  }
                />

                <ListItemSecondaryAction>
                  <Tooltip title="Toggle answered state" placement="top">
                    <IconButton
                      onClick={() =>
                        toggleAnswered(
                          question.id,
                          question.flagged,
                          question.answered
                        )
                      }
                      size="small"
                      style={{
                        marginRight: theme.spacing(1),
                      }}
                      className={classes.tickMark}
                    >
                      {question.answered ? <ClearIcon /> : <CheckIcon />}
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Toggle flagged state" placement="top">
                    <IconButton
                      onClick={() =>
                        toggleFlagged(
                          question.id,
                          question.flagged,
                          question.answered
                        )
                      }
                      size="small"
                      edge="end"
                      className={classes.flagMark}
                    >
                      {question.flagged ? <FlagOutlinedIcon /> : <FlagIcon />}
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            ))
          ) : (
            <Typography
              variant="caption"
              component="p"
              align="center"
              gutterBottom
            >
              {'No questions yet.'}
            </Typography>
          )}
        </List>
      </div>
    </div>
  )
}

Questions.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  toggleFlagged: PropTypes.func.isRequired,
  toggleAnswered: PropTypes.func.isRequired,
}

const styles = makeStyles(theme => ({
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  allQuestionsContainer: {
    maxHeight: 300,
    overflowY: 'scroll',
  },
  question: {
    paddingRight: 96,
  },
  tickMark: {
    color: theme.palette.grey[400],
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  flagMark: {
    color: theme.palette.grey[400],
    '&:hover': {
      color: theme.palette.error.dark,
    },
  },
}))

export default Questions
