import gql from 'graphql-tag'

export default {
  congress: {
    CONGRESS: gql`
      query CONGRESS($id: ID!) {
        congress(id: $id) {
          id
          name
          from
          to
          hidden
          status
          cover_id
          color
          text_color
          source
          settings
          cover {
            id
            file_name
          }
        }
      }
    `,
    CONGRESS_SETTINS: gql`
      query CONGRESS_SETTINGS($id: ID!) {
        congress(id: $id) {
          id
          settings
        }
      }
    `,
  },
  section: {
    SECTION: gql`
      query SECTION($id: ID!) {
        section(id: $id) {
          id
          name
          name2
          name3
          short_name
          description
          from
          to
          chair_label
          chair_free
          discussant
          room
          room_rank
          show_break_after
          break_name
          break_time
          show_discussion_after
          discussion_name
          discussion_time
          poster
          section_group
          track
          download_file
          link
          logo
          ratingCount
          ratingAvg
        }
      }
    `,
  },
  abstract: {
    ABSTRACT: gql`
      query ABSTRACT($id: ID!) {
        abstract(id: $id) {
          id
          section_id
          topic_id
          abstract_num
          title
          presenter
          name_head
          inst_head
          description
          content
          keyword
          rank
          from
          to
          lect_name
          lect_number
          lect_time
          discussion_time
          uploaded_file_name
          track
          forum
          info_before
          info_after
          video_id
          room
          ratingCount
          ratingAvg
        }
      }
    `,
  },
  topic: {
    TOPIC: gql`
      query TOPIC($id: ID!) {
        topic(id: $id) {
          id
          name
        }
      }
    `,
  },
  break: {
    BREAK: gql`
      query BREAK($id: ID!) {
        break(id: $id) {
          id
          name
          from
          to
        }
      }
    `,
  },
  floorplan: {
    FLOORPLAN: gql`
      query FLOORPLAN($id: ID!) {
        floorplan(id: $id) {
          id
          name
          file_id
          file {
            id
            file_name
          }
          width
          height
        }
      }
    `,
    MARKERS: gql`
      query markers($floorplan_id: ID!) {
        markers(floorplan_id: $floorplan_id) {
          id
          section_room
          coord_x
          coord_y
        }
      }
    `,
    LOCATIONS: gql`
      query LOCATIONS($congress_id: ID!) {
        locations(congress_id: $congress_id)
      }
    `,
  },
  sponsor: {
    SPONSOR: gql`
      query SPONSOR($id: ID!) {
        sponsor(id: $id) {
          id
          name
          sponsor_category_id
          logo_id
          logo {
            id
            file_name
          }
          url
          content
        }
      }
    `,
  },
  menu: {
    MENU: gql`
      query MENU($id: ID!) {
        menu(id: $id) {
          id
          title
          type
          is_sidebar
        }
      }
    `,
    MENU_ITEM: gql`
      query MENU_ITEM($id: ID!) {
        menuItem(id: $id) {
          id
          menu_id
          title
          icon
          data_item_id
          data {
            id
            title
            type
          }
        }
      }
    `,
    DATA_ITEMS: gql`
      query DATA_ITEMS($congress_id: ID, $type: String) {
        dataItems(congress_id: $congress_id, type: $type) {
          id
          title
          type
        }
      }
    `,
    DATA_ITEM: gql`
      query DATA_ITEM($id: ID!) {
        dataItem(id: $id) {
          id
          title
          type
        }
      }
    `,
    DATA_NODES: gql`
      query DATA_NODES($data_item_id: ID!) {
        dataNodes(data_item_id: $data_item_id) {
          id
          data_item_id
          type
          data
          created_at
        }
      }
    `,
    DATA_NODE: gql`
      query DATA_NODE($id: ID!) {
        dataNode(id: $id) {
          id
          data
        }
      }
    `,
  },
}
