import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

// MUI
import { Typography } from '@material-ui/core'

const ColorDemo = props => {
  const classes = styles(props)

  return (
    <div className={classes.colorDemo}>
      <Typography variant="h6" align="center">
        {'This is how your colors look together.'}
      </Typography>
    </div>
  )
}

ColorDemo.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
}

const styles = makeStyles(theme => ({
  colorDemo: props => ({
    backgroundColor: props.backgroundColor,
    color: props.textColor,
    padding: theme.spacing(2),
    borderRadius: 4,
  }),
}))

export default ColorDemo
