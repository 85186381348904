import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Formik, FieldArray } from 'formik'
import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { DEFAULT_QUESTION_ANSWER_VALUES } from '../../../config'

// MUI
import { Typography, Grid, Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddCircleOutline'

// Layout
import FormContainer from '../elements/layout/FormContainer'
import StepperFooter from '../elements/layout/StepperFooter'

// Elements
import NewPollQuestionItem from './elements/NewPollQuestionItem'

const schema = Yup.object().shape({
  questions: Yup.array()
    .of(
      Yup.object().shape({
        title: Yup.string()
          .required('Question is required')
          .max(200, 'Question title must be at most 200 characters'),
        type: Yup.string().required('Question type is required'),
        allow_multiple: Yup.boolean().required(),
        required: Yup.boolean().required(),
        exam_answer_values: Yup.object()
          .shape({
            right: Yup.number().integer(),
            wrong: Yup.number().integer(),
            empty: Yup.number().integer(),
          })
          .nullable(true),
        additional_info: Yup.string(),
        options: Yup.array()
          .of(
            Yup.object().shape({
              title: Yup.string()
                .required('Option title is required')
                .max(200, 'Option title must be at most 200 characters'),
            })
          )
          .when('type', {
            is: val => val === 'OPTIONS',
            then: field => field.required(),
            otherwise: field => field.nullable(true),
          }),
      })
    )
    .required(),
})

const initialQuestionValues = {
  title: '',
  type: '',
  allow_multiple: false,
  required: true,
  right_option_id: null,
  additional_info: '',
  exam_answer_values: DEFAULT_QUESTION_ANSWER_VALUES,
  options: [],
}

const sortHandler = (a, b) => {
  if (a.order < b.order) {
    return -1
  }
  if (a.order > b.order) {
    return 1
  }
  return 0
}

const NewPollQuestions = props => {
  const { poll, setPoll, currentStep, maxStep, handleBack, handleNext } = props
  const classes = styles()

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    resetForm(values)
    setSubmitting(false)

    handleNext(values)
  }

  const onDragEnd = (result, setFieldValue, questions) => {
    const { source, destination } = result

    if (!destination) {
      return
    }
    if (source.index === destination.index) {
      return
    }

    // sync orders
    if (Array.isArray(questions)) {
      const [moved] = questions.splice(source.index, 1)
      questions.splice(destination.index, 0, moved)

      questions.forEach((_, index) => {
        setFieldValue(`questions[${index}].order`, index)
      })
    }
  }

  const formData = {
    ...poll,
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, submitForm, values, setFieldValue }) => (
        <>
          <FormContainer>
            <div>
              {/* QUESTIONS */}
              <FieldArray
                name="questions"
                render={arrayHelpers => (
                  <DragDropContext
                    onDragEnd={result =>
                      onDragEnd(result, setFieldValue, values.questions)
                    }
                  >
                    <Droppable droppableId="newQuestionsDroppable">
                      {provided => (
                        <Grid
                          innerRef={provided.innerRef}
                          {...provided.droppableProps}
                          container
                          spacing={8}
                        >
                          {Array.isArray(values.questions) &&
                          values.questions.length > 0 ? (
                            <>
                              {values.questions
                                .sort(sortHandler)
                                .map((question, index) => (
                                  <Draggable
                                    draggableId={question.id}
                                    index={index}
                                    key={question.id}
                                  >
                                    {provided => (
                                      <Grid
                                        innerRef={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        item
                                        xs={12}
                                      >
                                        <NewPollQuestionItem
                                          pollType={poll.type}
                                          question={question}
                                          index={index}
                                          setFieldValue={setFieldValue}
                                          removeQuestion={() => {
                                            // Normalize other questions' order
                                            for (
                                              let i = index + 1;
                                              i < values.questions.length;
                                              i++
                                            ) {
                                              setFieldValue(
                                                `questions[${i}].order`,
                                                values.questions[i].order - 1
                                              )
                                            }

                                            arrayHelpers.remove(index)
                                          }}
                                        />
                                      </Grid>
                                    )}
                                  </Draggable>
                                ))}

                              {provided.placeholder}
                            </>
                          ) : (
                            <Grid item xs={12}>
                              <Typography align="center" variant="h6" noWrap>
                                {
                                  'Add questions to the poll by using the button below!'
                                }
                              </Typography>
                              <Typography
                                component="p"
                                align="center"
                                variant="caption"
                                color="error"
                                noWrap
                              >
                                {'(At least 1 question is required.)'}
                              </Typography>
                            </Grid>
                          )}

                          <Grid item xs={12} className={classes.center}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                arrayHelpers.push({
                                  id: uuidv4(),
                                  order: values.questions.length,
                                  ...initialQuestionValues,
                                })
                              }}
                            >
                              {'New question'}

                              <AddIcon className={classes.buttonIcon} />
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                    </Droppable>
                  </DragDropContext>
                )}
              />
            </div>
          </FormContainer>

          <StepperFooter
            currentStep={currentStep}
            maxStep={maxStep}
            handleBack={() => {
              setPoll(values)
              handleBack()
            }}
            handleNext={submitForm}
            loading={isSubmitting}
            canLastStepSubmit
            submitLabel="Create poll"
          />
        </>
      )}
    </Formik>
  )
}

NewPollQuestions.propTypes = {
  poll: PropTypes.object.isRequired,
  setPoll: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  maxStep: PropTypes.number.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
}

const styles = makeStyles(theme => ({
  marginTop: {
    marginTop: theme.spacing(4),
  },
  marginTopLg: {
    marginTop: theme.spacing(6),
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonIcon: {
    marginLeft: theme.spacing(1),
  },
}))

export default NewPollQuestions
