import React from 'react'
import PropTypes from 'prop-types'
import { Formik, FastField } from 'formik'
import * as Yup from 'yup'

// Utils
import FormikTextField from '../../utils/FormikTextField'

// MUI
import { Typography, Grid, FormControlLabel, Switch } from '@material-ui/core'

// Layout
import FormContainer from '../elements/layout/FormContainer'
import StepperFooter from '../elements/layout/StepperFooter'

// Elements
import TimestampPicker from '../elements/inputs/TimestampPicker'

const schema = Yup.object().shape({
  name: Yup.string().required().max(200),
  from: Yup.number()
    .integer()
    .required()
    .lessThan(
      Yup.ref('to'),
      'congress start date should be earlier than end date'
    ),
  to: Yup.number()
    .integer()
    .required()
    .moreThan(
      Yup.ref('from'),
      'congress end date should be later than start date'
    ),
  hidden: Yup.boolean().required(),
  status: Yup.boolean().required(),
})

const GeneralSettings = props => {
  const {
    congress,
    setCongress,
    currentStep,
    maxStep,
    handleBack,
    handleNext,
  } = props

  const saveProgress = values => {
    setCongress(values)
  }

  /**
   * Handles form submit.
   *
   * If there is an available next step:
   * 1. Sets 'NewCongressGuide' congress state.
   * 2. Resets form to new values.
   * 3. Goes to the next step's view.
   */
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    saveProgress(values)

    resetForm(values)
    setSubmitting(false)

    handleNext()
  }

  // generate form data
  const formData = {
    ...congress,
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, submitForm, values, setFieldValue, errors }) => (
        <>
          <FormContainer>
            <Grid container spacing={4}>
              {/* NAME */}
              <Grid item xs={12} lg={6}>
                <Typography variant="h6" gutterBottom>
                  {'Congress name'}
                </Typography>

                <FastField
                  name="name"
                  type="text"
                  placeholder="Congress name"
                  component={FormikTextField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              {/* FROM */}
              <Grid item xs={12} sm={6} lg={3}>
                <TimestampPicker
                  label="Congress starts from"
                  timestamp={values.from}
                  onChange={timestamp => setFieldValue('from', timestamp)}
                  error={Boolean(errors.from)}
                  errorText={Boolean(errors.from) ? errors.from : undefined}
                  fullWidth
                />
              </Grid>

              {/* TO */}
              <Grid item xs={12} sm={6} lg={3}>
                <TimestampPicker
                  label="Congress ends at"
                  timestamp={values.to}
                  onChange={timestamp => setFieldValue('to', timestamp)}
                  error={Boolean(errors.to)}
                  errorText={Boolean(errors.to) ? errors.to : undefined}
                  fullWidth
                />
              </Grid>

              {/* STATUS */}
              <Grid item xs={6} lg={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.status}
                      name="status"
                      onChange={e => {
                        setFieldValue('status', e.target.checked)
                      }}
                      color="primary"
                    />
                  }
                  label="Status"
                />
              </Grid>

              {/* HIDDEN */}
              <Grid item xs={6} lg={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.hidden}
                      name="hidden"
                      onChange={e => {
                        setFieldValue('hidden', e.target.checked)
                      }}
                      color="primary"
                    />
                  }
                  label="Hidden?"
                />
              </Grid>
            </Grid>
          </FormContainer>

          <StepperFooter
            currentStep={currentStep}
            maxStep={maxStep}
            handleBack={() => {
              saveProgress(values)
              handleBack()
            }}
            handleNext={submitForm}
            loading={isSubmitting}
          />
        </>
      )}
    </Formik>
  )
}

GeneralSettings.propTypes = {
  congress: PropTypes.object,
  setCongress: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  maxStep: PropTypes.number.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
}

export default GeneralSettings
