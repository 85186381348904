import gql from 'graphql-tag'

export default {
  POLLS: gql`
    query POLLS($congress_id: ID) {
      polls(congress_id: $congress_id) {
        id
        title
        entry_code
        created_at
      }
    }
  `,
  POLL: gql`
    query POLL($id: ID!) {
      poll(id: $id) {
        id
        congress_id
        section_id
        abstract_id
        title
        type
        are_results_public
        from
        to
        entry_code
        questions {
          id
          order
          title
          type
          allow_multiple
          required
          exam_answer_values
          right_option_id
          additional_info
          options {
            id
            order
            title
          }
        }
      }
    }
  `,
  POLL_RESULTS: gql`
    query POLL_RESULTS($id: ID!) {
      poll(id: $id) {
        id
        title
        questions {
          id
          order
          title
          type
          options {
            id
            title
          }
        }
        submissions {
          id
          poll_id
          device_id
          answers
        }
      }
    }
  `,
  CONG_LIST: gql`
    query CONG_LIST {
      congresses {
        id
        name
        created_at
      }
    }
  `,
  SECT_LIST: gql`
    query SECT_LIST($congress_id: ID!) {
      sections(congress_id: $congress_id) {
        id
        name
        created_at
      }
    }
  `,
  ABS_LIST: gql`
    query ABS_LIST($section_id: ID!) {
      sectionAbstracts(section_id: $section_id) {
        id
        title
        created_at
      }
    }
  `,
}
