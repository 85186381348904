import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'
import { Formik, FastField } from 'formik'
import * as Yup from 'yup'
import MaterialIconPicker from '../../MaterialIconPicker'

// Utils
import FormikTextField from '../../utils/FormikTextField'

// MUI
import { Grid, Button, Avatar } from '@material-ui/core'

// Layout
import FormContainer from '../elements/layout/FormContainer'
import FormFooter from '../elements/layout/FormFooter'

// Elements
import DrawerTitle from '../../drawers/elements/DrawerTitle'
import CurrentDataItem from './CurrentDataItem'
import DataItems from './DataItems'

// Dialogs
import ConfirmationDialog from '../../dialogs/ConfirmationDialog'

// Cosmetics
import MaterialIcon from '../../cosmetics/UI/MaterialIcon'
import FullscreenLoading from '../../cosmetics/loadings/FullscreenLoading'

// Queries & Mutations
import { query, mutation } from '../../../lib/apollo-client'

const schema = Yup.object().shape({
  title: Yup.string().required().max(100),
  icon: Yup.string().nullable(true),
  data_item_id: Yup.string().nullable(true),
})

const initialValues = {
  title: '',
  icon: null,
  data_item_id: null,
  data: null,
}

const MenuItemForm = props => {
  const { menuItem, congressId, setTabValue } = props
  const classes = styles()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  // Data
  const [updateMenuItem, updateMenuItemMutation] = useMutation(
    mutation.form.menu.UPDATE_MENU_ITEM
  )
  const [deleteMenuItem, deleteMenuItemMutation] = useMutation(
    mutation.form.menu.DELETE_MENU_ITEM,
    {
      update(cache, { data: { deleteMenuItem } }) {
        const { menus } = cache.readQuery({
          query: query.drawer.MENUS,
          variables: { congress_id: congressId },
        })
        const parentMenu = Array.isArray(menus)
          ? menus.find(menu => {
              return menu.id === menuItem.menu_id
            })
          : {}

        cache.writeQuery({
          query: query.drawer.DRAWER_MENU,
          variables: { id: menuItem.menu_id },
          data: {
            menu: {
              ...parentMenu,
              items: parentMenu.items.filter(menuItem => {
                return menuItem.id !== deleteMenuItem.id
              }),
            },
          },
        })
      },
    }
  )

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
  const handleConfirmationOpen = () => setDeleteConfirmOpen(true)
  const handleConfirmationClose = () => setDeleteConfirmOpen(false)

  const [iconPickerOpen, setIconPickerOpen] = useState(false)
  const handleIconPickerOpen = () => setIconPickerOpen(true)
  const handleIconPickerClose = () => setIconPickerOpen(false)

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const { data, ...rest } = values
      await updateMenuItem({
        variables: {
          ...rest,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateMenuItem: {
            data: data
              ? {
                  ...data,
                  __typename: 'DataItem',
                }
              : null,
            ...rest,
            __typename: 'MenuItem',
          },
        },
      })

      resetForm(values)
    } catch (error) {
      enqueueSnackbar('Error: error during updating menu item!', {
        variant: 'error',
      })
      captureException(error)
    }

    setSubmitting(false)
  }

  /**
   * Deletes menu item and redirects to Society App.
   */
  const handleDelete = async () => {
    try {
      await deleteMenuItem({
        variables: { id: menuItem.id },
        optimisticResponse: {
          __typename: 'Mutation',
          deleteMenuItem: {
            id: menuItem.id,
            __typename: 'MenuItem',
          },
        },
      })

      if (congressId) {
        history.push(`/congress/${congressId}`)
      } else {
        history.push('/societyapp')
      }
    } catch (error) {
      enqueueSnackbar('Error: error during deleting menu item!', {
        variant: 'error',
      })
      captureException(error)
    }
  }

  // generate form data
  // change nulls to empty strings
  const formData = {
    ...initialValues,
    ...menuItem,
  }

  return (
    <>
      {(updateMenuItemMutation.loading || deleteMenuItemMutation.loading) && (
        <FullscreenLoading variant="white" transparent />
      )}

      <Formik
        enableReinitialize
        initialValues={formData}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ dirty, isSubmitting, setFieldValue, values, submitForm }) => (
          <FormContainer elevation={0}>
            <DrawerTitle title="Edit menu item" />

            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.alignCenter}>
                {values.icon ? (
                  <MaterialIcon
                    icon={values.icon}
                    color="primary"
                    size="large"
                  />
                ) : (
                  <Avatar alt="icon" className={classes.avatar}>
                    {values.title.charAt(0)}
                  </Avatar>
                )}

                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={handleIconPickerOpen}
                  className={classes.changeIconButton}
                >
                  {'Change icon'}
                </Button>
              </Grid>

              <Grid item xs={12}>
                <FastField
                  name="title"
                  type="text"
                  label="Title"
                  component={FormikTextField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} className={classes.marginTop}>
                <CurrentDataItem data={values.data} setTabValue={setTabValue} />
              </Grid>

              <Grid item xs={12} className={classes.marginTop}>
                <DataItems
                  currentDataItemId={values.data_item_id}
                  setFieldValue={setFieldValue}
                  congressId={congressId}
                  saveForm={submitForm}
                />
              </Grid>
            </Grid>

            <FormFooter
              isSubmitting={isSubmitting}
              dirty={dirty}
              save
              onDelete={handleConfirmationOpen}
            />

            <ConfirmationDialog
              title="Delete menu item"
              open={deleteConfirmOpen}
              description="Are you sure you would like to delete this menu item? This action is irreversible."
              returnLabel="Cancel"
              confirmLabel="Delete"
              onReturn={handleConfirmationClose}
              onConfirm={() => {
                handleConfirmationClose()
                handleDelete()
              }}
              variant="delete"
            />

            <MaterialIconPicker
              open={iconPickerOpen}
              onClose={handleIconPickerClose}
              onSelect={selected => {
                setFieldValue('icon', selected)
                handleIconPickerClose()
              }}
            />
          </FormContainer>
        )}
      </Formik>
    </>
  )
}

MenuItemForm.propTypes = {
  menuItem: PropTypes.object.isRequired,
  congressId: PropTypes.string,
  setTabValue: PropTypes.func.isRequired,
}

const styles = makeStyles(theme => ({
  alignCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  marginTop: {
    marginTop: theme.spacing(4),
  },
  avatar: {
    height: theme.spacing(7),
    width: theme.spacing(7),
  },
  changeIconButton: {
    marginLeft: theme.spacing(2),
  },
}))

export default MenuItemForm
