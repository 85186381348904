import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'

// Components
import ContentDrawer from '../components/drawers/ContentDrawer'
import MenuForm from '../components/forms/MenuForm'

// Cosmetics
import FullscreenLoading from '../components/cosmetics/loadings/FullscreenLoading'

// Queries & Mutations
import { query } from '../lib/apollo-client'

const Menu = props => {
  const { match } = props
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  // Data
  const { data, error, loading } = useQuery(query.form.menu.MENU, {
    variables: { id: match.params.menuId },
  })

  const [isFormDrawerOpen, setIsFormDrawerOpen] = useState(false)

  useEffect(() => {
    if (loading) {
      return
    }

    if (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      })
      captureException(error)
    }

    if (data && data.menu) {
      setIsFormDrawerOpen(true)
    }
  }, [data, error, loading, enqueueSnackbar])

  const closeDrawer = () => {
    setIsFormDrawerOpen(false)

    setTimeout(() => {
      if (match.params.congressId) {
        history.push(`/congress/${match.params.congressId}`)
      } else {
        history.push('/societyapp')
      }
    }, 500)
  }

  if (loading) {
    return <FullscreenLoading variant="white" transparent />
  }

  return (
    <>
      <Helmet>
        <title>{'Edit menu'}</title>
      </Helmet>

      <ContentDrawer open={isFormDrawerOpen} onClose={closeDrawer}>
        {data && data.menu && (
          <MenuForm
            menu={data.menu}
            congressId={
              match.params.congressId ? match.params.congressId : null
            }
          />
        )}
      </ContentDrawer>
    </>
  )
}

export default Menu
