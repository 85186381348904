import React, { useState } from 'react'

import { File, FileType } from '../../services/openapi'
import { AssetStoreContext } from './context'

type AssetStoreProviderProps = {
  children: React.ReactNode
}

const AssetStoreProvider = (props: AssetStoreProviderProps) => {
  // asset store state
  const [assetStoreOpen, setAssetStoreOpen] = useState(false)
  const [assetStoreType, setAssetStoreType] = useState<FileType>(FileType.IMG)

  // cb to invoke when submitting
  const [assetStoreCallback, setAssetStoreCallback] = useState(() => () => {})

  /**
   * opens asset store which displays files based on 'type' param
   *
   * you can optionally specify a cb function
   * which gets invoked on asset store submit
   */
  const handleAssetStoreOpen = (type: FileType, cb: (file: File) => any) => {
    if (cb) {
      setAssetStoreCallback(() => cb)
    }

    setAssetStoreType(type)
    setAssetStoreOpen(true)
  }
  const handleAssetStoreClose = () => setAssetStoreOpen(false)

  return (
    <AssetStoreContext.Provider
      value={{
        assetStoreOpen,
        assetStoreType,
        handleAssetStoreOpen,
        handleAssetStoreClose,
        assetStoreCallback,
      }}
    >
      {props.children}
    </AssetStoreContext.Provider>
  )
}

export default AssetStoreProvider
