import { useMutation } from '@apollo/react-hooks'
import { captureException } from '@sentry/browser'
import { Form, Formik, FormikHelpers } from 'formik'
import { useSnackbar } from 'notistack'
import React from 'react'
import * as Yup from 'yup'

// config
import { s3config } from '../../../../../config'

// Layout
import FormFooter from '../../../elements/layout/FormFooter'

// Elements
import QuillEditor from '../../../elements/inputs/QuillEditor'

// Cosmetics
import FullscreenLoading from '../../../../cosmetics/loadings/FullscreenLoading'

// Queries & Mutations
import { useFileUpload } from '../../../../../hooks/file-upload.mutation'
import { mutation } from '../../../../../lib/apollo-client'
import { FileType } from '../../../../../services/openapi'

const ONE_MEGABYTE_IN_BYTES = 1048576

const schema = Yup.object().shape({
  content: Yup.string().required(),
})

const initialValues: { content: string } = {
  content: '',
}

type ContentFormProps = {
  closeContentDialog: () => void
  node: any
}

const ContentForm = (props: ContentFormProps) => {
  const { node, closeContentDialog } = props

  const { enqueueSnackbar } = useSnackbar()

  // Data
  const [updateDataNode, updateDataNodeMutation] = useMutation(
    mutation.form.menu.UPDATE_DATA_NODE
  )

  const { isLoading: isImageUploading, mutateAsync } = useFileUpload(
    FileType.QUILL_IMAGE
  )

  const handleSubmit = async (
    values: { content: string },
    { setSubmitting, resetForm }: FormikHelpers<{ content: string }>
  ) => {
    try {
      await updateDataNode({
        variables: { id: node.id, data: JSON.stringify(values) },
        optimisticResponse: {
          __typename: 'Mutation',
          updateDataNode: {
            id: node.id,
            data: JSON.stringify(values),
            __typename: 'DataNode',
          },
        },
      })

      resetForm()
    } catch (e) {
      const error = e as any
      enqueueSnackbar(error.message, { variant: 'error' })
      captureException(error)
    }

    setSubmitting(false)
    closeContentDialog()
  }

  const uploadImage = async (file: Blob) => {
    if (file.size > ONE_MEGABYTE_IN_BYTES) {
      enqueueSnackbar('Error: the selected image exceeds the 1MB limit!', {
        variant: 'error',
      })
      return new Error('Selected image exceeds the 1MB limit!')
    }

    try {
      const uploadedFile = await mutateAsync({
        file: file,
      })

      if (uploadedFile) {
        const fileName = uploadedFile.file_name

        return `https://${s3config.bucket}.s3.${s3config.region}.amazonaws.com/${fileName}`
      } else {
        captureException(
          new Error('Could not retrieve filename from response!')
        )
        enqueueSnackbar('Error: could not retrieve image URL from response!', {
          variant: 'error',
        })
      }
    } catch (error) {
      captureException(error)
    }
  }

  // Parse returned JSON data to object so we can edit it
  const data = node.data ? JSON.parse(node.data) : null
  // generate form data
  const formData = {
    ...initialValues,
    ...data,
  }

  return (
    <>
      {(updateDataNodeMutation.loading || isImageUploading) && (
        <FullscreenLoading variant="white" transparent />
      )}

      <Formik
        enableReinitialize
        initialValues={formData}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ dirty, isSubmitting, values, setFieldValue }) => (
          <Form noValidate>
            <QuillEditor
              value={values.content}
              onChange={(newContent: string) => {
                setFieldValue('content', newContent)
              }}
              uploadHandler={uploadImage}
            />

            <FormFooter isSubmitting={isSubmitting} dirty={dirty} save />
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ContentForm
