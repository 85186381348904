import { createTheme, responsiveFontSizes } from '@material-ui/core/styles'

const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: '#663399',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#82C3C7',
        contrastText: '#ffffff',
      },
    },
    overrides: {
      MuiCardHeader: {
        action: {
          alignSelf: 'auto',
          marginTop: 0,
          marginRight: 0,
        },
      },
      MuiTableRow: {
        root: {
          cursor: 'pointer',
        },
      },
      MuiTableCell: {
        root: {
          backgroundColor: '#ffffff !important',
        },
      },
      MuiTablePagination: {
        root: {
          padding: '0 !important',
        },
      },
    },
  })
)

export default theme
