export const sortItemsByOrder = items => {
  if (!Array.isArray(items)) {
    return
  }

  items.sort((a, b) => {
    if (a.order < b.order) {
      return -1
    }
    if (a.order > b.order) {
      return 1
    }
    return 0
  })
}

export const indicesToOrders = items => {
  if (!Array.isArray(items)) {
    return
  }

  items.forEach((item, index) => {
    item.order = index
  })
}

/**
 * Returns the dotified (short) version of a string.
 *
 * Is just String.slice() and String.concat('...') under the hood.
 *
 * @param {string} string The string to dotify.
 * @param {number} maxLength The maximum length of the dotified string.
 */
export const dotify = (string, maxLength) => {
  if (!string && !maxLength) {
    return
  }
  if (!maxLength) {
    return string
  }

  const stringified = stringify(string)
  let sub = stringified.slice(0, maxLength)

  if (sub.length < stringified.length) {
    sub = sub.concat('...')
  }

  return sub
}

/**
 * Makes sure the param gets converted to a string,
 * no matter what.
 *
 * @param string
 */
export const stringify = string => {
  return `${string}`
}

/**
 * Removes every property that equals null
 * from any given object.
 *
 * @param {object} obj
 */
export const removeNullProps = obj => {
  if (typeof obj !== 'object') return

  for (let prop in obj) {
    if (obj[prop] === null) delete obj[prop]
  }
}

/**
 * Translates the given array of objects to a single object.
 *
 * Objects in the array must have two properties:
 * - key (string)
 * - value (any)
 *
 * Result object will contain the keys with their corresponding values.
 *
 * @param {Array} arr
 */
export const objectify = arr => {
  if (!Array.isArray(arr)) return

  const obj = {}

  arr.forEach(elem => {
    if (elem.key && typeof elem.key === 'string') {
      obj[elem.key] = elem.value
    }
  })

  return obj
}
