import { FastField, Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import * as Yup from 'yup'

// Utils
import FormikTextField from '../../utils/FormikTextField'

// MUI
import { Grid, Typography } from '@material-ui/core'

// Layout
import { siteConfig } from '../../../config'
import FormContainer from '../elements/layout/FormContainer'
import StepperFooter from '../elements/layout/StepperFooter'

const schema = Yup.object().shape({
  source: Yup.string().nullable(true),
})

const MigrationSettings = props => {
  const {
    congress,
    setCongress,
    currentStep,
    maxStep,
    handleBack,
    handleNext,
  } = props

  const saveProgress = values => {
    const { source, ...rest } = values

    setCongress({
      source: source !== '' ? source : null,
      ...rest,
    })
  }

  /**
   * Handles form submit.
   *
   * If there is an available next step:
   * 1. Sets 'NewCongressGuide' congress state.
   * 2. Resets form to new values.
   * 3. Goes to the next step's view.
   */
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    saveProgress(values)

    resetForm(values)
    setSubmitting(false)

    handleNext()
  }

  // generate form data
  // change nulls to empty strings
  const { source, ...rest } = congress
  const formData = {
    source: source !== null ? source : '',
    ...rest,
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, submitForm, values }) => (
        <>
          <FormContainer>
            <Grid container spacing={4}>
              {/* SOURCE */}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  {`Congress source (${siteConfig.name} API) URL`}
                </Typography>

                <FastField
                  name="source"
                  type="text"
                  placeholder="https://your.congress.source.api.url"
                  component={FormikTextField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
          </FormContainer>

          <StepperFooter
            currentStep={currentStep}
            maxStep={maxStep}
            handleBack={() => {
              saveProgress(values)
              handleBack()
            }}
            handleNext={submitForm}
            loading={isSubmitting}
          />
        </>
      )}
    </Formik>
  )
}

MigrationSettings.propTypes = {
  congress: PropTypes.object,
  setCongress: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  maxStep: PropTypes.number.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
}

export default MigrationSettings
