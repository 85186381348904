import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

// MUI
import { useTheme, Paper, Grid } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'

const InformationBlock = props => {
  const classes = styles(props)
  const theme = useTheme()

  const getPaddingFromProps = () => {
    if (!props.size) {
      return theme.spacing(3)
    } else {
      switch (props.size) {
        case 'small':
          return theme.spacing(1.5)
        case 'large':
          return theme.spacing(6)
        default:
          return theme.spacing(3)
      }
    }
  }

  return (
    <Paper
      variant="outlined"
      className={classes.infoBlock}
      style={{
        padding: getPaddingFromProps(),
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={2} sm={1} className={classes.justify}>
          <InfoIcon />
        </Grid>

        <Grid item xs={10} sm={11} className={classes.content}>
          {props.children}
        </Grid>
      </Grid>
    </Paper>
  )
}

InformationBlock.propTypes = {
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'error',
    'warning',
    'info',
    'success',
  ]).isRequired,
  size: PropTypes.oneOf(['small', 'default', 'large']),
}

const styles = makeStyles(theme => ({
  infoBlock: props => ({
    backgroundColor:
      theme.palette[props.variant ? props.variant : 'info'].light,
    color: theme.palette.common.white,
  }),
  justify: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}))

export default InformationBlock
