import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'

// MUI
import {
  Button,
  IconButton,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'

// Dialogs
import ConfirmationDialog from '../../dialogs/ConfirmationDialog'

// Queries & Mutations
import { query, mutation } from '../../../lib/apollo-client'

const DataItem = props => {
  const {
    dataItem,
    currentDataItemId,
    setFieldValue,
    listingType,
    congressId,
    saveForm,
  } = props
  const classes = styles()
  const { enqueueSnackbar } = useSnackbar()

  // Data
  const [deleteDataItem] = useMutation(mutation.form.menu.DELETE_DATA_ITEM, {
    update(cache, { data: { deleteDataItem } }) {
      const { dataItems } = cache.readQuery({
        query: query.form.menu.DATA_ITEMS,
        variables: { congress_id: congressId, type: listingType },
      })

      cache.writeQuery({
        query: query.form.menu.DATA_ITEMS,
        variables: { congress_id: congressId, type: listingType },
        data: {
          dataItems: dataItems.filter(dataItem => {
            return dataItem.id !== deleteDataItem.id
          }),
        },
      })
    },
  })

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
  const handleConfirmationOpen = () => setDeleteConfirmOpen(true)
  const handleConfirmationClose = () => setDeleteConfirmOpen(false)

  const handleDataItemChange = async dataItem => {
    setFieldValue('data_item_id', dataItem.id)
    setFieldValue('data.id', dataItem.id)
    setFieldValue('data.title', dataItem.title)
    setFieldValue('data.type', dataItem.type)

    await saveForm()
  }

  /**
   * Deletes data item.
   */
  const handleDelete = async () => {
    handleConfirmationClose()

    try {
      await deleteDataItem({
        variables: { id: dataItem.id },
        optimisticResponse: {
          __typename: 'Mutation',
          deleteDataItem: {
            id: dataItem.id,
            __typename: 'DataItem',
          },
        },
      })

      // if we delete the currently used item, make sure to update
      // form values
      if (currentDataItemId === dataItem.id) {
        setFieldValue('data_item_id', null)
        setFieldValue('data', null)
      }

      await saveForm()
    } catch (error) {
      enqueueSnackbar('Error: error during deleting data item!', {
        variant: 'error',
      })
      captureException(error)
    }
  }

  return (
    <>
      <ListItem>
        <ListItemText primary={dataItem.title} secondary={dataItem.type} />

        <ListItemSecondaryAction>
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={currentDataItemId === dataItem.id}
            onClick={() => handleDataItemChange(dataItem)}
          >
            {currentDataItemId === dataItem.id ? 'Displayed' : 'Display this'}

            {currentDataItemId === dataItem.id && (
              <CheckIcon
                style={{
                  marginLeft: '6px',
                }}
              />
            )}
          </Button>

          <IconButton
            onClick={handleConfirmationOpen}
            className={classes.deleteIcon}
            size="small"
            edge="end"
          >
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>

      <ConfirmationDialog
        title="Delete content"
        open={deleteConfirmOpen}
        description="Are you sure you would like to delete this content? This action is irreversible."
        returnLabel="Cancel"
        confirmLabel="Delete"
        onReturn={handleConfirmationClose}
        onConfirm={handleDelete}
        variant="delete"
      />
    </>
  )
}

DataItem.propTypes = {
  dataItem: PropTypes.object.isRequired,
  currentDataItemId: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  listingType: PropTypes.string.isRequired,
  congressId: PropTypes.string,
  saveForm: PropTypes.func.isRequired,
}

const styles = makeStyles(theme => ({
  deleteIcon: {
    marginLeft: theme.spacing(1),
    color: theme.palette.error.main,
  },
}))

export default DataItem
