import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import { QUESTION_TYPES } from '../../config'

import { ResponsiveContainer, PieChart, Pie, Cell, Legend } from 'recharts'

// MUI
import {
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Divider,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import PreviousIcon from '@material-ui/icons/ArrowBackIos'
import NextIcon from '@material-ui/icons/ArrowForwardIos'
import LabelIcon from '@material-ui/icons/Label'

const sortHandler = (a, b) => {
  if (a.order < b.order) {
    return -1
  }
  if (a.order > b.order) {
    return 1
  }
  return 0
}

const COLORS = [
  '#d3d3d3',
  '#2f4f4f',
  '#2e8b57',
  '#808000',
  '#b22222',
  '#800080',
  '#ff0000',
  '#ff8c00',
  '#ffd700',
  '#0000cd',
  '#00ff00',
  '#00fa9a',
  '#e9967a',
  '#00ffff',
  '#00bfff',
  '#ff00ff',
  '#f0e68c',
  '#dda0dd',
  '#ff1493',
  '#7b68ee',
]

const PollResultsDialog = ({ poll, open, onClose, className, ...rest }) => {
  const classes = styles()

  const [questions, setQuestions] = React.useState([])
  const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState(0)

  const handleBack = React.useCallback(
    () => setCurrentQuestionIndex(currentQuestionIndex - 1),
    [currentQuestionIndex]
  )
  const handleNext = React.useCallback(
    () => setCurrentQuestionIndex(currentQuestionIndex + 1),
    [currentQuestionIndex]
  )

  const hasNext = React.useMemo(() => {
    return currentQuestionIndex < questions.length - 1
  }, [currentQuestionIndex, questions])
  const hasPrevious = React.useMemo(() => {
    return currentQuestionIndex > 0
  }, [currentQuestionIndex])

  React.useEffect(() => {
    const { questions, submissions } = poll
    if (!Array.isArray(questions) || !Array.isArray(submissions)) {
      return
    }

    const questionsWithResults = [...questions]
      .sort(sortHandler)
      .map(question => {
        const { options, ...rest } = question
        if (!Array.isArray(options)) {
          return {
            ...question,
          }
        }

        return {
          ...rest,
          options: options.map(option => {
            const optionWithScores = Object.assign(
              {
                score: 0,
              },
              option
            )

            //
            submissions.forEach(submission => {
              if (typeof submission.answers !== 'string') return

              const answers = JSON.parse(submission.answers)
              if (!Array.isArray(answers)) return

              answers.forEach(answerObj => {
                if (answerObj.question_id !== question.id) return

                if (Array.isArray(answerObj.answer)) {
                  // MULTIPLE CHOICE
                  if (
                    answerObj.answer.some(optionId => optionId === option.id)
                  ) {
                    optionWithScores.score++
                  }
                } else {
                  // SINGLE CHOICE
                  if (answerObj.answer === option.id) {
                    optionWithScores.score++
                  }
                }
              })
            })
            //

            return optionWithScores
          }),
        }
      })

    setQuestions(questionsWithResults)
  }, [poll])

  // Keyboard navigation
  const handleKeyboardNavigation = React.useCallback(
    event => {
      const { keyCode } = event

      if (keyCode === 37 && hasPrevious) handleBack()
      if (keyCode === 39 && hasNext) handleNext()
    },
    [hasPrevious, hasNext, handleBack, handleNext]
  )
  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyboardNavigation)

    return () => {
      window.removeEventListener('keydown', handleKeyboardNavigation)
    }
  }, [handleKeyboardNavigation])

  const currentQuestion = React.useMemo(() => {
    if (questions[currentQuestionIndex]) {
      return {
        ...questions[currentQuestionIndex],
        typeLabel: QUESTION_TYPES.find(
          obj => obj.value === questions[currentQuestionIndex].type
        ).label,
      }
    } else {
      return {}
    }
  }, [currentQuestionIndex, questions])

  if (!poll || !Array.isArray(poll.questions) || poll.questions.length < 1) {
    return null
  }

  return (
    <Dialog
      className={clsx(classes.root, className)}
      fullScreen
      TransitionComponent={Transition}
      open={open}
      onClose={onClose}
      {...rest}
    >
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6">
            {poll.title ? `${poll.title} results` : `Poll results`}
          </Typography>

          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <DialogContent>
        <Box p={4}>
          <Typography variant="h6" className={classes.questionPrefix}>
            {`${currentQuestionIndex + 1}. Question`}
          </Typography>
          <Typography
            variant="h3"
            gutterBottom
            className={classes.questionTitle}
          >
            {currentQuestion.title}
          </Typography>
          <Divider />

          <Box p={2}>
            <Box display="flex" alignItems="center">
              <LabelIcon className={classes.labelIcon} />
              <Typography variant="h6">
                {`Question type: ${currentQuestion.typeLabel}`}
              </Typography>
            </Box>
          </Box>

          {currentQuestion.type === 'OPTIONS' && (
            <Box p={2} mt={4} width="100%" height={500}>
              <ResponsiveContainer>
                <PieChart fontSize="1.25rem">
                  <Pie
                    data={currentQuestion.options}
                    nameKey="title"
                    dataKey="score"
                    label={label =>
                      `${label.value} votes, (${(label.percent * 100).toFixed(
                        0
                      )}%)`
                    }
                    outerRadius="60%"
                  >
                    {Array.isArray(currentQuestion.options) &&
                      currentQuestion.options.map((entry, index) => (
                        <Cell
                          key={index}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                  </Pie>

                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </Box>
          )}
        </Box>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button
          variant="outlined"
          color="secondary"
          size="large"
          startIcon={<PreviousIcon />}
          disabled={!hasPrevious}
          onClick={handleBack}
        >
          {'Previous question'}
        </Button>

        <Button
          variant="contained"
          color="primary"
          size="large"
          endIcon={<NextIcon />}
          disabled={!hasNext}
          onClick={handleNext}
        >
          {'Next question'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

PollResultsDialog.propTypes = {
  poll: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
}

const styles = makeStyles(theme => ({
  root: {},
  appBar: {
    position: 'relative',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  closeButton: {
    color: theme.palette.common.white,
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  questionPrefix: {
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.grey[500],
  },
  questionTitle: {
    fontWeight: theme.typography.fontWeightBold,
  },
  labelIcon: {
    color: theme.palette.primary.dark,
    marginRight: theme.spacing(1),
  },
}))

// Slide transition
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default PollResultsDialog
