import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { siteConfig } from '../../../config'

// MUI
import { Paper, Typography, useMediaQuery, useTheme } from '@material-ui/core'

// Components
import InformationBlock from '../../cosmetics/UI/InformationBlock'

const Welcome = () => {
  const classes = styles()
  const theme = useTheme()
  const smAndUp = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <Paper className={classes.paper}>
      <div className={classes.logoContainer}>
        <img
          src={siteConfig.logo}
          alt="Company Logo"
          className={classes.logo}
        />
      </div>

      <div>
        <Typography
          variant="h5"
          align={smAndUp ? 'left' : 'center'}
          gutterBottom
        >
          {'Welcome to the Admin!'}
        </Typography>

        <Typography
          align={smAndUp ? 'left' : 'center'}
          style={{
            marginBottom: theme.spacing(2.5),
          }}
        >
          {'The custom Content Management System for the mobile application.'}
        </Typography>

        <InformationBlock variant="info" size="small">
          <Typography>
            {'Tip: use the drawer on the left for easy navigation!'}
          </Typography>
        </InformationBlock>
      </div>
    </Paper>
  )
}

const styles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    height: 85,
    marginBottom: theme.spacing(2.5),
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
      marginRight: theme.spacing(4),
    },
  },
}))

export default Welcome
