import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'
import { v4 as uuidv4 } from 'uuid'

// MUI
import { Button } from '@material-ui/core'
import MUIDataTable from 'mui-datatables'

// Cosmetics
import FullscreenLoading from '../cosmetics/loadings/FullscreenLoading'

// Dialogs
import NamingDialog from '../dialogs/NamingDialog'

// Queries & Mutations
import { query, mutation } from '../../lib/apollo-client'

// Table head
const columns = [
  {
    name: 'id',
    label: 'ID',
    options: {
      display: 'excluded',
      filter: false,
    },
  },
  {
    name: 'congress_id',
    label: 'Congress ID',
    options: {
      display: 'excluded',
      filter: false,
    },
  },
  {
    name: 'name',
    label: 'Name',
    options: {
      filter: false,
    },
  },
]

const TopicsTable = props => {
  const { topics, congressId } = props
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  // Data
  const [addTopic, addTopicMutation] = useMutation(mutation.table.ADD_TOPIC, {
    update(cache, { data: { addTopic } }) {
      const { topics } = cache.readQuery({
        query: query.table.TOPICS,
        variables: { congress_id: congressId },
      })

      cache.writeQuery({
        query: query.table.TOPICS,
        variables: { congress_id: congressId },
        data: { topics: topics.concat([addTopic]) },
      })
    },
  })

  const [namingDialogOpen, setNamingDialogOpen] = React.useState(false)
  const handleNamingDialogOpen = () => setNamingDialogOpen(true)
  const handleNamingDialogClose = () => setNamingDialogOpen(false)

  const addNewTopic = async name => {
    const values = {
      id: uuidv4(),
      congress_id: congressId,
      name,
    }

    try {
      await addTopic({
        variables: values,
        optimisticResponse: {
          __typename: 'Mutation',
          addTopic: {
            ...values,
            __typename: 'Topic',
          },
        },
      })
    } catch (error) {
      enqueueSnackbar('Error: error during adding topic!', {
        variant: 'error',
      })
      captureException(error)
    }
  }

  const addNewButton = (
    <Button
      variant="contained"
      color="primary"
      onClick={handleNamingDialogOpen}
    >
      {'New topic'}
    </Button>
  )

  return (
    <>
      {addTopicMutation.loading && (
        <FullscreenLoading variant="white" transparent />
      )}

      <MUIDataTable
        title={addNewButton}
        columns={columns}
        data={topics}
        options={{
          responsive: 'scrollMaxHeight',
          rowHover: true,
          selectableRows: 'none',
          print: false,
          download: false,
          elevation: 2,
          onRowClick: row => {
            const topicId = row[0]
            const congressId = row[1]

            history.push(`/congress/${congressId}/topic/${topicId}`)
          },
        }}
      />

      <NamingDialog
        subject="topic"
        open={namingDialogOpen}
        onClose={handleNamingDialogClose}
        onSubmit={name => addNewTopic(name)}
        maxLength={500}
      />
    </>
  )
}

TopicsTable.propTypes = {
  topics: PropTypes.array.isRequired,
  congressId: PropTypes.string.isRequired,
}

export default TopicsTable
