import React from 'react'
import Helmet from 'react-helmet'
import { useHistory } from 'react-router-dom'

// Components
import ContentDrawer from '../../../components/drawers/ContentDrawer'
import BreakForm from '../../../components/forms/Break/BreakForm'

const NewBreak = props => {
  const { match } = props
  const history = useHistory()

  const [isFormDrawerOpen, setIsFormDrawerOpen] = React.useState(false)

  // setting initial state here results in no animation
  // hence using React.useEffect
  React.useEffect(() => {
    setIsFormDrawerOpen(true)
  }, [])

  const closeDrawer = () => {
    setIsFormDrawerOpen(false)

    setTimeout(() => {
      history.push(`/congress/${match.params.congressId}`)
    }, 500)
  }

  return (
    <>
      <Helmet>
        <title>{'Add new break'}</title>
      </Helmet>

      <ContentDrawer open={isFormDrawerOpen} onClose={closeDrawer}>
        <BreakForm congressId={match.params.congressId} />
      </ContentDrawer>
    </>
  )
}

export default NewBreak
