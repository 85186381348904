import gql from 'graphql-tag'

export default {
  FILES: gql`
    query FILES($type: String) {
      files(type: $type) {
        id
        file_name
        type
        created_at
      }
    }
  `,
}
