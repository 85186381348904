import React, { useState } from 'react'
import Helmet from 'react-helmet'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { v4 as uuidv4 } from 'uuid'
import { captureException } from '@sentry/browser'

// MUI
import { Stepper, Step, StepLabel } from '@material-ui/core'

// Layout
import { ContentWrapper } from '../layout'

// Components
import GeneralForm from '../components/forms/NewCongress/GeneralForm'
import CosmeticForm from '../components/forms/NewCongress/CosmeticForm'
import MigrationForm from '../components/forms/NewCongress/MigrationForm'
import FinishForm from '../components/forms/NewCongress/FinishForm'

// Cosmetics
import FullscreenLoading from '../components/cosmetics/loadings/FullscreenLoading'

// Queries & Mutations
import { query, mutation } from '../lib/apollo-client'

// Step labels
const STEPS = [
  'General settings',
  'Cosmetic settings',
  'Migration settings',
  'Finish',
]
const MAX_STEP = STEPS.length - 1

const initialValues = {
  name: '',
  from: Math.floor(Date.now() / 1000), // need seconds, not milliseconds
  to: Math.floor(Date.now() / 1000), // need seconds, not milliseconds
  hidden: false,
  status: false,
  cover_id: null,
  color: null,
  text_color: null,
  source: null,
  cover: null,
}

const NewCongress = () => {
  const classes = styles()
  const theme = useTheme()
  const displayStepLabels = useMediaQuery(theme.breakpoints.up('sm'))
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  // Data
  const [addCongress, addCongressMutation] = useMutation(
    mutation.drawer.ADD_CONGRESS,
    {
      update(cache, { data: { addCongress } }) {
        const { congresses } = cache.readQuery({
          query: query.drawer.CONGRESSES,
        })

        cache.writeQuery({
          query: query.drawer.CONGRESSES,
          data: { congresses: congresses.concat([addCongress]) },
        })
      },
      refetchQueries: [
        {
          query: query.polls.CONG_LIST,
        },
      ],
    }
  )
  const [migrateCongressData, migrateCongressDataMutation] = useMutation(
    mutation.form.congress.MIGRATE_CONGRESS_DATA
  )

  const [congress, setCongress] = useState(initialValues)
  const [currentStep, setCurrentStep] = useState(0)

  const handleBack = () => {
    setCurrentStep(currentStep => currentStep - 1)
  }

  const handleNext = () => {
    setCurrentStep(currentStep => currentStep + 1)
  }

  /**
   * Adds new congress.
   */
  const addNewCongress = async (shouldMigrateData, timezone) => {
    try {
      const congressId = uuidv4()
      const { cover, ...rest } = congress

      await addCongress({
        variables: {
          id: congressId,
          ...rest,
        },
      })

      if (shouldMigrateData) {
        await handleMigration(congressId, timezone)
      }

      history.push(`/congress/${congressId}`)
    } catch (error) {
      enqueueSnackbar('Error: error during adding congress!', {
        variant: 'error',
      })
      captureException(error)
    }
  }

  const handleMigration = async (congressId, timezone) => {
    try {
      await migrateCongressData({
        variables: {
          id: congressId,
          originTimezone: timezone,
        },
      })
    } catch (error) {
      enqueueSnackbar('Error: error during migrating congress data!', {
        variant: 'error',
      })
      captureException(error)
    }
  }

  if (addCongressMutation.loading || migrateCongressDataMutation.loading) {
    return (
      <FullscreenLoading
        variant="white"
        transparent
        label={
          migrateCongressDataMutation.loading
            ? 'Migrating congress data...'
            : undefined
        }
      />
    )
  }

  return (
    <>
      <Helmet>
        <title>{'New congress'}</title>
      </Helmet>

      <Stepper activeStep={currentStep} className={classes.stepper}>
        {STEPS.map((label, index) => (
          <Step key={index}>
            <StepLabel>{displayStepLabels ? label : undefined}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <ContentWrapper>
        {currentStep === 0 && (
          <GeneralForm
            congress={congress}
            setCongress={setCongress}
            currentStep={currentStep}
            maxStep={MAX_STEP}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        )}

        {currentStep === 1 && (
          <CosmeticForm
            congress={congress}
            setCongress={setCongress}
            currentStep={currentStep}
            maxStep={MAX_STEP}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        )}

        {currentStep === 2 && (
          <MigrationForm
            congress={congress}
            setCongress={setCongress}
            currentStep={currentStep}
            maxStep={MAX_STEP}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        )}

        {currentStep === 3 && (
          <FinishForm
            currentStep={currentStep}
            maxStep={MAX_STEP}
            handleBack={handleBack}
            handleNext={handleNext}
            onFinish={(shouldMigrateData, timezone) =>
              addNewCongress(shouldMigrateData, timezone)
            }
            disableMigrate={!congress.source}
          />
        )}
      </ContentWrapper>
    </>
  )
}

const styles = makeStyles(() => ({
  stepper: {
    backgroundColor: 'inherit',
  },
}))

export default NewCongress
