import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'

// Queries & Mutations
import { query, mutation } from '../../lib/apollo-client'

// Components
import Questions from './Questions'

const QA = ({ type, subjectId }) => {
  const classes = styles()
  const { enqueueSnackbar } = useSnackbar()

  const [questions, setQuestions] = React.useState([])

  const queryVariables = React.useMemo(() => {
    if (type === 'SECTION') {
      return {
        section_id: subjectId,
      }
    } else {
      return {
        abstract_id: subjectId,
      }
    }
  }, [type, subjectId])

  const { data, error, loading } = useQuery(query.qa.QUESTIONS, {
    variables: Object.assign({}, queryVariables),
    pollInterval: 10000,
  })

  React.useEffect(() => {
    if (loading) return

    if (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
      captureException(error)
    }

    if (data && Array.isArray(data.questions)) {
      setQuestions(data.questions)
    }
  }, [data, error, loading, enqueueSnackbar])

  const [updateQuestion] = useMutation(mutation.qa.UPDATE_QUESTION)

  const toggleQuestionFlagged = async (questionId, isFlagged, isAnswered) => {
    try {
      await updateQuestion({
        variables: {
          id: questionId,
          flagged: !isFlagged,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateQuestion: {
            id: questionId,
            answered: isAnswered,
            flagged: !isFlagged,
            __typename: 'Qa',
          },
        },
      })
    } catch (error) {
      enqueueSnackbar(
        error.message ? error.message : 'Error: error while saving question!',
        { variant: 'error' }
      )
      captureException(error)
    }
  }

  const toggleQuestionAnswered = async (questionId, isFlagged, isAnswered) => {
    try {
      await updateQuestion({
        variables: {
          id: questionId,
          answered: !isAnswered,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateQuestion: {
            id: questionId,
            answered: !isAnswered,
            flagged: isFlagged,
            __typename: 'Qa',
          },
        },
      })
    } catch (error) {
      enqueueSnackbar(
        error.message ? error.message : 'Error: error while saving question!',
        { variant: 'error' }
      )
      captureException(error)
    }
  }

  return (
    <div className={classes.container}>
      <Questions
        questions={questions}
        toggleFlagged={toggleQuestionFlagged}
        toggleAnswered={toggleQuestionAnswered}
      />
    </div>
  )
}

QA.propTypes = {
  type: PropTypes.oneOf(['SECTION', 'ABSTRACT']).isRequired,
  subjectId: PropTypes.string.isRequired,
}

const styles = makeStyles(() => ({
  container: {
    width: '100%',
  },
}))

export default QA
