import { makeStyles } from '@material-ui/core/styles'
import { captureException } from '@sentry/browser'
import clsx from 'clsx'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import Image from 'react-image'
import { File } from '../../services/openapi'
import { useAssetStore } from './context'

// config
import {
  DOC_SUPPORTED_FORMATS,
  ICON_SUPPORTED_FORMATS,
  IMG_SUPPORTED_FORMATS,
  s3config,
} from '../../config'

// MUI
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Paper,
  Slide,
  SlideProps,
  Toolbar,
  Typography,
} from '@material-ui/core'
import {
  default as SelectIcon,
  default as SelectedIcon,
} from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import UploadIcon from '@material-ui/icons/CloudUpload'
import ImageIcon from '@material-ui/icons/Image'
import DocumentIcon from '@material-ui/icons/InsertDriveFile'

// Cosmetics
import ContentLoading from '../cosmetics/loadings/ContentLoading'

// Queries & Mutations
import { useFileUpload } from '../../hooks/file-upload.mutation'
import { useFiles } from '../../hooks/files.query'

const AssetStore = () => {
  const classes = styles()
  const { enqueueSnackbar } = useSnackbar()
  const {
    assetStoreOpen,
    assetStoreType,
    handleAssetStoreClose,
    assetStoreCallback,
  } = useAssetStore()

  const [selected, setSelected] = useState<File | null>(null)
  const [validationError, setValidationError] = useState<null | string>(null)
  const [inputFieldRef, setInputFieldRef] = useState<HTMLInputElement | null>(
    null
  )

  // TODO: add assetStoreType filter for backend
  const { data: files, isLoading: isFilesDataLoading } =
    useFiles(assetStoreType)

  const { isLoading, mutateAsync } = useFileUpload(assetStoreType)

  /**
   * Handles Asset Store submit.
   * - invokes the cb function (coming from the provider)
   * - closes asset store
   */
  const handleSubmit = () => {
    if (!selected) {
      return
    }

    assetStoreCallback(selected)
    handleClose()
  }
  const handleClose = () => {
    setSelected(null)
    setValidationError(null)
    handleAssetStoreClose()
  }

  /**
   * Uploads target file to S3.
   */
  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files[0] === undefined) {
      enqueueSnackbar('Error: no selected file!', {
        variant: 'error',
      })
      return
    }

    // Validate file size (only ICON & IMG)
    const ONE_MEGABYTE_IN_BYTES = 1048576
    if (
      (assetStoreType === 'ICON' || assetStoreType === 'IMG') &&
      e.target.files[0].size > ONE_MEGABYTE_IN_BYTES
    ) {
      setValidationError(
        'The selected image exceeds the 1MB limit, please upload a smaller one!'
      )
      return
    } else {
      setValidationError(null)
    }

    /* Validate format
     *
     * cases:
     * fileType: ICON -> accept icon formats
     * fileType: IMG -> accept img formats
     * fileType: DOC -> accept doc formats
     */
    if (
      (assetStoreType === 'ICON' &&
        ICON_SUPPORTED_FORMATS.includes(e.target.files[0].type)) ||
      (assetStoreType === 'IMG' &&
        IMG_SUPPORTED_FORMATS.includes(e.target.files[0].type)) ||
      (assetStoreType === 'DOC' &&
        DOC_SUPPORTED_FORMATS.includes(e.target.files[0].type))
    ) {
      setValidationError(null)
    } else {
      setValidationError('The uploaded file has an unsupported format!')
      return
    }

    try {
      const singleUploadResult = await mutateAsync({
        file: e.target.files[0],
      })

      // immediately select uploaded item
      if (singleUploadResult) {
        setSelected(singleUploadResult)
      }
    } catch (error) {
      enqueueSnackbar('Error: error during uploading file!', {
        variant: 'error',
      })
      captureException(error)
    }
  }

  return (
    <Dialog
      open={assetStoreOpen}
      onClose={handleAssetStoreClose}
      fullScreen
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6">{'Asset Store'}</Typography>

          <IconButton className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <DialogContent>
        {isLoading || isFilesDataLoading ? (
          <ContentLoading />
        ) : (
          <Grid container spacing={2}>
            {files &&
              files.map(file => (
                <Grid key={file.id} item xs={6} sm={3} md={2}>
                  <Paper
                    variant="outlined"
                    className={clsx(
                      classes.thumbnail,
                      selected &&
                        selected.id === file.id &&
                        classes.selectedThumbnail
                    )}
                    onClick={() => setSelected(file)}
                  >
                    {(file.type === 'ICON' || file.type === 'IMG') && (
                      <Image
                        src={`https://${s3config.bucket}.s3.${s3config.region}.amazonaws.com/${file.file_name}`}
                        style={{
                          maxHeight: '95%',
                          maxWidth: '95%',
                          borderRadius: '4px',
                        }}
                        loader={<CircularProgress size={35} thickness={5} />}
                        unloader={
                          <ImageIcon className={classes.thumbnailIcon} />
                        }
                      />
                    )}

                    {file.type === 'DOC' && (
                      <DocumentIcon className={classes.thumbnailIcon} />
                    )}

                    {selected && selected.id === file.id && (
                      <div className={classes.selected}>
                        <SelectedIcon fontSize="small" />
                      </div>
                    )}
                  </Paper>

                  <Typography
                    variant="body1"
                    className={classes.fileName}
                    align="center"
                  >
                    {file.file_name}
                  </Typography>
                </Grid>
              ))}
          </Grid>
        )}
      </DialogContent>

      <DialogActions className={classes.actions}>
        <input
          type="file"
          style={{
            display: 'none',
          }}
          ref={ref => setInputFieldRef(ref)}
          onChange={handleUpload}
        />
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            if (inputFieldRef) inputFieldRef.click()
          }}
        >
          <UploadIcon
            style={{
              marginRight: '6px',
            }}
          />

          {'Upload'}
        </Button>

        {validationError && (
          <div className={classes.error}>
            <Typography component="p">{validationError}</Typography>
          </div>
        )}

        <Button
          variant="contained"
          color="primary"
          disabled={!selected}
          onClick={handleSubmit}
        >
          {'Select file'}

          {selected && (
            <SelectIcon
              style={{
                marginLeft: '6px',
              }}
            />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const styles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  closeButton: {
    color: theme.palette.common.white,
  },
  thumbnail: {
    height: 125,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  selectedThumbnail: {
    borderColor: theme.palette.primary.main,
  },
  thumbnailIcon: {
    fontSize: '3em',
  },
  fileName: {
    marginTop: theme.spacing(1),
    wordBreak: 'break-word',
  },
  selected: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 999,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: '50%',
    width: 30,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 0px 15px -5px rgba(0,0,0,0.75)',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  error: {
    color: theme.palette.error.main,
  },
}))

// Slide transition
const Transition = React.forwardRef<HTMLDivElement, SlideProps>(
  function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
  }
)

export default AssetStore
