import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'

// MUI
import { Breadcrumbs, Link } from '@material-ui/core'

// Components
import ContentDrawer from '../../components/drawers/ContentDrawer'
import MenuItemForm from '../../components/forms/MenuItemForm'

// Elements
import DataItem from './DataItem'

// Cosmetics
import FullscreenLoading from '../../components/cosmetics/loadings/FullscreenLoading'

// Queries & Mutations
import { query } from '../../lib/apollo-client'

const MenuItem = props => {
  const { match } = props
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  // Data
  const { data, error, loading } = useQuery(query.form.menu.MENU_ITEM, {
    variables: { id: match.params.menuItemId },
  })

  const [isFormDrawerOpen, setIsFormDrawerOpen] = useState(false)
  const [tabValue, setTabValue] = useState(0)

  useEffect(() => {
    if (loading) {
      return
    }

    if (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      })
      captureException(error)
    }

    if (data && data.menuItem) {
      setIsFormDrawerOpen(true)
    }
  }, [data, error, loading, enqueueSnackbar, setIsFormDrawerOpen])

  const closeDrawer = () => {
    setIsFormDrawerOpen(false)

    setTimeout(() => {
      if (match.params.congressId) {
        history.push(`/congress/${match.params.congressId}`)
      } else {
        history.push('/societyapp')
      }
    }, 500)
  }

  // Breadcrumb subheader
  const navigationComponent =
    data &&
    data.menuItem &&
    data.menuItem.data_item_id &&
    data.menuItem.data ? (
      <Breadcrumbs>
        <Link component="button" variant="body1" onClick={() => setTabValue(0)}>
          {data.menuItem.title}
        </Link>

        <Link component="button" variant="body1" onClick={() => setTabValue(1)}>
          {data.menuItem.data.title}
        </Link>
      </Breadcrumbs>
    ) : null

  if (loading) {
    return <FullscreenLoading variant="white" transparent />
  }

  return (
    <>
      <Helmet>
        <title>{'Edit menu item'}</title>
      </Helmet>

      <ContentDrawer
        open={isFormDrawerOpen}
        onClose={closeDrawer}
        navigationComponent={navigationComponent}
      >
        {data && data.menuItem && tabValue === 0 && (
          <MenuItemForm
            menuItem={data.menuItem}
            congressId={
              match.params.congressId ? match.params.congressId : null
            }
            setTabValue={setTabValue}
          />
        )}

        {data &&
          data.menuItem &&
          data.menuItem.data_item_id &&
          tabValue === 1 && (
            <DataItem
              id={data.menuItem.data_item_id}
              congressId={
                match.params.congressId ? match.params.congressId : null
              }
            />
          )}
      </ContentDrawer>
    </>
  )
}

export default MenuItem
