import { useMutation } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/core/styles'
import { captureException } from '@sentry/browser'
import { FastField, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import * as Yup from 'yup'
import { useAssetStore } from '../../AssetStore'

// config
import { gmtZones, siteConfig } from '../../../config'

// Utils
import FormikTextField from '../../utils/FormikTextField'

// MUI
import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@material-ui/core'
import MigrationIcon from '@material-ui/icons/SystemUpdateAlt'

// Layout
import FormContainer from '../elements/layout/FormContainer'
import FormFooter from '../elements/layout/FormFooter'

// Elements
import ColorDemo from '../elements/ColorDemo'
import ColorPicker from '../elements/inputs/ColorPicker'
import IconPicker from '../elements/inputs/IconPicker'
import TimestampPicker from '../elements/inputs/TimestampPicker'

// Dialogs
import ConfirmationDialog from '../../dialogs/ConfirmationDialog'
import SelectionDialog from '../../dialogs/SelectionDialog'

// Cosmetics
import FullscreenLoading from '../../cosmetics/loadings/FullscreenLoading'

// Queries & Mutations
import { mutation } from '../../../lib/apollo-client'

const schema = Yup.object().shape({
  name: Yup.string().required().max(200),
  from: Yup.number()
    .integer()
    .required()
    .lessThan(
      Yup.ref('to'),
      'congress start date should be earlier than end date'
    ),
  to: Yup.number()
    .integer()
    .required()
    .moreThan(
      Yup.ref('from'),
      'congress end date should be later than start date'
    ),
  hidden: Yup.boolean().required(),
  status: Yup.boolean().required(),
  cover_id: Yup.string().required(),
  color: Yup.string().required(),
  text_color: Yup.string().required(),
  source: Yup.string().nullable(true),
})

const CongressForm = props => {
  const { congress } = props
  const classes = styles()
  const { enqueueSnackbar } = useSnackbar()
  const { handleAssetStoreOpen } = useAssetStore()

  const [timezoneDialogOpen, setTimezoneDialogOpen] = useState(false)
  const handleTimezoneDialogOpen = () => setTimezoneDialogOpen(true)
  const handleTimezoneDialogClose = () => setTimezoneDialogOpen(false)

  const [migrateConfirmOpen, setMigrateConfirmOpen] = useState(false)
  const handleConfirmationOpen = () => setMigrateConfirmOpen(true)
  const handleConfirmationClose = () => setMigrateConfirmOpen(false)

  const [selectedTimezone, setSelectedTimezone] = useState(null)
  const [migrationError, setMigrationError] = useState([])

  // Data
  const [updateCongress, updateCongressMutation] = useMutation(
    mutation.form.congress.UPDATE_CONGRESS
  )
  const [migrateCongressData, migrateCongressDataMutation] = useMutation(
    mutation.form.congress.MIGRATE_CONGRESS_DATA
  )

  const assetStoreHandler = (setFieldValue, type) => {
    switch (type) {
      case 'cover':
        handleAssetStoreOpen('IMG', selected => {
          if (!selected) {
            return
          }

          setFieldValue('cover_id', selected.id)
          setFieldValue('cover.id', selected.id)
          setFieldValue('cover.file_name', selected.file_name)
        })
        break
      default:
        return
    }
  }

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      // change empty strings back to nulls
      const { source, cover, ...rest } = values
      await updateCongress({
        variables: {
          source: source !== '' ? source : null,
          ...rest,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateCongress: {
            source: source !== '' ? source : null,
            cover: cover
              ? {
                  ...cover,
                  __typename: 'File',
                }
              : null,
            ...rest,
            __typename: 'Congress',
          },
        },
      })

      resetForm(values)
    } catch (error) {
      enqueueSnackbar('Error: error during updating congress!', {
        variant: 'error',
      })
      captureException(error)
    }

    setSubmitting(false)
  }

  const handleMigrate = async () => {
    try {
      await migrateCongressData({
        variables: { id: congress.id, originTimezone: selectedTimezone },
      })

      window.location.reload(true)
    } catch (error) {
      const errorList = error.graphQLErrors.map(e =>
        e.message.replaceAll('SequelizeDatabaseError: ', '')
      )
      setMigrationError(errorList)
      enqueueSnackbar('Error: error during migrating congress data!', {
        variant: 'error',
      })
      captureException(error)
    }
  }

  // generate form data
  // change nulls to empty strings
  const { source, ...rest } = congress
  const formData = {
    source: source !== null ? source : '',
    ...rest,
  }

  return (
    <>
      {updateCongressMutation.loading && (
        <FullscreenLoading variant="white" transparent />
      )}

      {migrateCongressDataMutation.loading && (
        <FullscreenLoading
          variant="white"
          transparent
          label="Migrating congress data..."
        />
      )}

      <Formik
        enableReinitialize
        initialValues={formData}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, dirty, values, setFieldValue, errors }) => (
          <>
            <FormContainer>
              <Grid container spacing={4}>
                {/* NAME */}
                <Grid item xs={12} lg={6}>
                  <Typography variant="h6" gutterBottom>
                    {'Congress name'}
                  </Typography>

                  <FastField
                    name="name"
                    type="text"
                    placeholder="Congress name"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                {/* FROM */}
                <Grid item xs={12} sm={6} lg={3}>
                  <TimestampPicker
                    label="Congress starts from"
                    timestamp={values.from}
                    onChange={timestamp => setFieldValue('from', timestamp)}
                    error={Boolean(errors.from)}
                    errorText={Boolean(errors.from) ? errors.from : undefined}
                    fullWidth
                  />
                </Grid>

                {/* TO */}
                <Grid item xs={12} sm={6} lg={3}>
                  <TimestampPicker
                    label="Congress ends at"
                    timestamp={values.to}
                    onChange={timestamp => setFieldValue('to', timestamp)}
                    error={Boolean(errors.to)}
                    errorText={Boolean(errors.to) ? errors.to : undefined}
                    fullWidth
                  />
                </Grid>

                {/* STATUS */}
                <Grid item xs={6} lg={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.status}
                        name="status"
                        onChange={e => {
                          setFieldValue('status', e.target.checked)
                        }}
                        color="primary"
                      />
                    }
                    label="Status"
                  />
                </Grid>

                {/* HIDDEN */}
                <Grid item xs={6} lg={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.hidden}
                        name="hidden"
                        onChange={e => {
                          setFieldValue('hidden', e.target.checked)
                        }}
                        color="primary"
                      />
                    }
                    label="Hidden?"
                  />
                </Grid>

                <Grid item xs={12} className={classes.marginTop}>
                  <Divider />
                </Grid>

                {/* THUMBNAIL */}
                <Grid item xs={12} lg={4}>
                  <IconPicker
                    label="Congress thumbnail"
                    icon={values.cover}
                    onChange={() => assetStoreHandler(setFieldValue, 'cover')}
                    error={Boolean(errors.cover_id) || Boolean(errors.cover)}
                    errorText={
                      Boolean(errors.cover_id) || Boolean(errors.cover)
                        ? 'Thumbnail is required!'
                        : undefined
                    }
                  />
                </Grid>

                {/* COLOR */}
                <Grid item xs={12} md={6} lg={4}>
                  <ColorPicker
                    label="Congress color"
                    color={values.color || ''}
                    onChange={color => setFieldValue('color', color)}
                    error={Boolean(errors.color)}
                    errorText={
                      Boolean(errors.color) ? 'Color is required!' : undefined
                    }
                  />
                </Grid>

                {/* TEXT COLOR */}
                <Grid item xs={12} md={6} lg={4}>
                  <ColorPicker
                    label="Congress text color"
                    color={values.text_color || ''}
                    onChange={color => setFieldValue('text_color', color)}
                    error={Boolean(errors.text_color)}
                    errorText={
                      Boolean(errors.text_color)
                        ? 'Text color is required!'
                        : undefined
                    }
                  />
                </Grid>

                {/* COLOR DEMO */}
                <Grid item xs={12}>
                  <ColorDemo
                    backgroundColor={values.color || '#808080'}
                    textColor={values.text_color || '#000000'}
                  />
                </Grid>

                <Grid item xs={12} className={classes.marginTop}>
                  <Divider />
                </Grid>

                {/* SOURCE */}
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    {`Congress source (${siteConfig.name} API) URL`}
                  </Typography>

                  <FastField
                    name="source"
                    type="text"
                    placeholder="https://your.congress.source.api.url"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                {/* MIGRATE */}
                <Grid item xs={12} className={classes.center}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!congress.source}
                    onClick={handleTimezoneDialogOpen}
                  >
                    {'Migrate congress data'}

                    <MigrationIcon
                      style={{
                        marginLeft: '6px',
                      }}
                    />
                  </Button>
                </Grid>
              </Grid>

              <FormFooter isSubmitting={isSubmitting} dirty={dirty} save />
            </FormContainer>

            <SelectionDialog
              title="Which timezone is used by the incoming dates and times?"
              open={timezoneDialogOpen}
              onClose={handleTimezoneDialogClose}
              options={gmtZones}
              onSubmit={selected => {
                setSelectedTimezone(selected)
                handleTimezoneDialogClose()
                handleConfirmationOpen()
              }}
            />

            <ConfirmationDialog
              title="Migrate congress data"
              open={migrateConfirmOpen}
              description={
                <>
                  {'Are you sure you would like to migrate congress data? '}
                  <b>
                    {
                      'All sections, abstracts, topics and breaks for this congress will be overwritten!'
                    }
                  </b>
                  {Array.isArray(migrationError) && (
                    <ul className={classes.error}>
                      {migrationError.map((error, i) => (
                        <li key={i}>{error}</li>
                      ))}
                    </ul>
                  )}
                </>
              }
              returnLabel="Cancel"
              confirmLabel="Migrate"
              onReturn={handleConfirmationClose}
              onConfirm={handleMigrate}
              variant="general"
            />
          </>
        )}
      </Formik>
    </>
  )
}

CongressForm.propTypes = {
  congress: PropTypes.object.isRequired,
}

const styles = makeStyles(theme => ({
  marginTop: {
    marginTop: theme.spacing(4),
  },
  error: {
    color: theme.palette.error.main,
    fontSize: 14,
    listStyleType: 'none',
    paddingLeft: 0,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

export default CongressForm
