import React from 'react'
import PropTypes from 'prop-types'

// MUI
import {
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Button,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'

const CurrentDataItem = props => {
  const { data, setTabValue } = props

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {'Currently displayed content'}
      </Typography>

      <Paper variant="outlined">
        <List disablePadding>
          <ListItem>
            {data ? (
              <>
                <ListItemText primary={data.title} secondary={data.type} />

                <ListItemSecondaryAction>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => setTabValue(1)}
                  >
                    {'Edit'}

                    <EditIcon
                      fontSize="small"
                      style={{
                        marginLeft: '6px',
                      }}
                    />
                  </Button>
                </ListItemSecondaryAction>
              </>
            ) : (
              <ListItemText primary="No current content." />
            )}
          </ListItem>
        </List>
      </Paper>
    </>
  )
}

CurrentDataItem.propTypes = {
  data: PropTypes.object,
  setTabValue: PropTypes.func.isRequired,
}

export default CurrentDataItem
