import LogoCw from './assets/logo-cw.png'
import Logo from './assets/logo.png'

// Auth0 App Info

export const auth0config = {
  domain: process.env.REACT_APP_AUTH0_CLIENT_DOMAIN || '',
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
  audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
}

// Amazon S3 Info
export const s3config = {
  bucket: process.env.REACT_APP_AMAZON_S3_BUCKET || '',
  region: process.env.REACT_APP_AMAZON_S3_REGION || '',
}

// Asset Store upload supported formats
export const IMG_SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png']
export const ICON_SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png']
export const DOC_SUPPORTED_FORMATS = []

export const MENU_TYPES = [
  {
    label: 'List',
    value: 'LIST',
  },
  {
    label: 'Tiles',
    value: 'TILES',
  },
]

export const DATA_ITEM_TYPES = [
  {
    label: 'News',
    value: 'NEWS',
    enabled: true,
    where: ['society', 'congress'],
  },
  {
    label: 'Content',
    value: 'CONTENT',
    enabled: true,
    where: ['society', 'congress'],
  },
  {
    label: 'Link',
    value: 'LINK',
    enabled: true,
    where: ['society', 'congress'],
  },
  {
    label: 'Search',
    value: 'SEARCH',
    enabled: true,
    where: ['society', 'congress'],
  },
  {
    label: 'My schedule',
    value: 'MY_SCHEDULE',
    enabled: true,
    where: ['society', 'congress'],
  },
  {
    label: 'Schedule',
    value: 'SCHEDULE',
    enabled: true,
    where: ['society', 'congress'],
  },
  {
    label: 'Persons',
    value: 'PERSONS',
    enabled: true,
    where: ['society', 'congress'],
  },
  {
    label: 'Profile',
    value: 'PROFILE',
    enabled: true,
    where: ['society', 'congress'],
  },
  {
    label: `What's now?`,
    value: 'NOW',
    enabled: true,
    where: ['society', 'congress'],
  },
  {
    label: 'Poll',
    value: 'POLL',
    enabled: true,
    where: ['society', 'congress'],
  },
  {
    label: 'Notes',
    value: 'NOTES',
    enabled: true,
    where: ['congress'],
  },
  {
    label: 'Floorplan',
    value: 'FLOORPLAN',
    enabled: true,
    where: ['congress'],
  },
  {
    label: 'Sponsors',
    value: 'SPONSORS',
    enabled: true,
    where: ['congress'],
  },
  {
    label: 'Settings',
    value: 'SETTINGS',
    enabled: true,
    where: ['society', 'congress'],
  },
]
export const EDITABLE_DATA_ITEM_TYPES = ['NEWS', 'CONTENT', 'LINK', 'POLL']

export const POLL_TYPES = [
  {
    label: 'Voting',
    value: 'VOTING',
  },
  {
    label: 'Evaluation',
    value: 'EVALUATION',
  },
  {
    label: 'Exam',
    value: 'EXAM',
  },
]

export const QUESTION_TYPES = [
  {
    label: 'Options',
    value: 'OPTIONS',
  },
  {
    label: 'Text',
    value: 'TEXT',
  },
]

export const DEFAULT_QUESTION_ANSWER_VALUES = {
  right: 1,
  wrong: -1,
  empty: 0,
}

export const SECTION_PROPERTIES = [
  {
    name: 'short_name',
    description: 'Section short ID',
  },
  {
    name: 'name',
    description: 'Section title',
  },
  {
    name: 'name2',
    description: 'Section secondary title',
  },
  {
    name: 'name3',
    description: 'Section additional secondary title',
  },
  {
    name: 'date',
    description: 'Section start date',
  },
  {
    name: 'date_end',
    description: 'Section end date',
  },
  {
    name: 'start_time',
    description: 'Section start time',
  },
  {
    name: 'end_time',
    description: 'Section end time',
  },
  {
    name: 'room',
    description: 'Section room name',
  },
  {
    name: 'chair_free',
    description: 'Section moderator(s)',
  },
  {
    name: 'track',
    description: 'Section track name',
  },
  {
    name: 'section_group',
    description: 'Section group name',
  },
]

export const ABSTRACT_PROPERTIES = [
  {
    name: 'abstract_num',
    description: 'Abstract ID',
  },
  {
    name: 'rank',
    description: 'Abstract rank',
  },
  {
    name: 'title',
    description: 'Abstract title',
  },
  {
    name: 'presenter',
    description: 'Abstract presenter(s)',
  },
  {
    name: 'name_head',
    description: 'Abstract author(s)',
  },
  {
    name: 'inst_head',
    description: 'Abstract author institution(s)',
  },
  {
    name: 'lect_name',
    description: 'Abstract lecture name',
  },
  {
    name: 'lect_number',
    description: 'Abstract lecture number',
  },
  {
    name: 'lect_time',
    description: 'Abstract lecture time',
  },
  {
    name: 'discussion_time',
    description: 'Abstract discussion time',
  },
]

export const gmtZones = [
  'GMT-12',
  'GMT-11',
  'GMT-10',
  'GMT-9',
  'GMT-8',
  'GMT-7',
  'GMT-6',
  'GMT-5',
  'GMT-4',
  'GMT-3',
  'GMT-2',
  'GMT-1',
  'GMT+0',
  'GMT+1',
  'GMT+2',
  'GMT+3',
  'GMT+4',
  'GMT+5',
  'GMT+6',
  'GMT+7',
  'GMT+8',
  'GMT+9',
  'GMT+10',
  'GMT+11',
  'GMT+12',
]

export const ADVERTISEMENT_LOCATIONS = [
  {
    label: 'Splash screen',
    value: 'SPLASH_SCREEN',
  },
  {
    label: 'Header banner',
    value: 'HEADER_BANNER',
  },
  {
    label: 'Footer banner',
    value: 'FOOTER_BANNER',
  },
  {
    label: 'Menu banner',
    value: 'MENU_BANNER',
  },
  {
    label: 'Search page',
    value: 'SEARCH_PAGE',
  },
]

export const siteConfig = {
  name: process.env.REACT_APP_PROJECT === 'fessh' ? 'FESSH' : 'CongressWorks',
  logo: process.env.REACT_APP_PROJECT === 'fessh' ? Logo : LogoCw,
  color: process.env.REACT_APP_PROJECT === 'fessh' ? '#663399' : '#f07f1b',
}
