import gql from 'graphql-tag'

export default {
  SEND_PUSH_NOTIF: gql`
    mutation SEND_PUSH_NOTIF(
      $title: String!
      $description: String!
      $scheduled: Int
      $congress_id: ID
      $menu_item_id: ID
      $menu_item_title: String
      $payload_id: ID
      $payload_type: String
      $payload_content: String
    ) {
      sendPushNotif(
        title: $title
        description: $description
        scheduled: $scheduled
        congress_id: $congress_id
        menu_item_id: $menu_item_id
        menu_item_title: $menu_item_title
        payload_id: $payload_id
        payload_type: $payload_type
        payload_content: $payload_content
      ) {
        id
        recipients
        external_id
        errors
      }
    }
  `,
}
