import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'
import { v4 as uuidv4 } from 'uuid'

// config
import { DATA_ITEM_TYPES } from '../../../config'

// MUI
import {
  Typography,
  TextField,
  MenuItem,
  IconButton,
  Paper,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined'

// Elements
import DataItem from './DataItem'

// Dialogs
import NamingDialog from '../../dialogs/NamingDialog'

// Cosmetics
import FullscreenLoading from '../../cosmetics/loadings/FullscreenLoading'
import ContentLoading from '../../cosmetics/loadings/ContentLoading'

// Queries & Mutations
import { query, mutation } from '../../../lib/apollo-client'

const DataItems = props => {
  const { currentDataItemId, setFieldValue, congressId, saveForm } = props
  const classes = styles()
  const { enqueueSnackbar } = useSnackbar()

  const [dataItems, setDataItems] = useState([])
  const [type, setType] = useState('NEWS')

  const [namingDialogOpen, setNamingDialogOpen] = useState(false)
  const handleNamingDialogOpen = () => setNamingDialogOpen(true)
  const handleNamingDialogClose = () => setNamingDialogOpen(false)

  // Data
  const [loadDataItems, { data, error, loading }] = useLazyQuery(
    query.form.menu.DATA_ITEMS
  )
  const [addDataItem, addDataItemMutation] = useMutation(
    mutation.form.menu.ADD_DATA_ITEM,
    {
      update(cache, { data: { addDataItem } }) {
        const { dataItems } = cache.readQuery({
          query: query.form.menu.DATA_ITEMS,
          variables: { congress_id: congressId, type },
        })

        cache.writeQuery({
          query: query.form.menu.DATA_ITEMS,
          variables: { congress_id: congressId, type },
          data: { dataItems: dataItems.concat([addDataItem]) },
        })
      },
    }
  )

  useEffect(() => {
    if (loading) {
      return
    }

    if (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      })
      captureException(error)
    }

    if (data && data.dataItems) {
      setDataItems(data.dataItems)
    }
  }, [data, error, loading, enqueueSnackbar])

  // Reload data items each time 'type' changes
  // 'congressId' won't change but still part of the
  // query so it is a dependency
  useEffect(() => {
    loadDataItems({
      variables: { congress_id: congressId, type },
    })
  }, [loadDataItems, congressId, type])

  const handleTypeChange = e => {
    setType(e.target.value)
  }

  /**
   * Adds new data item.
   */
  const addNewDataItem = async name => {
    const values = {
      id: uuidv4(),
      congress_id: congressId,
      title: name,
      type: type,
    }

    try {
      await addDataItem({
        variables: values,
        optimisticResponse: {
          __typename: 'Mutation',
          addDataItem: {
            ...values,
            icon: null,
            __typename: 'DataItem',
          },
        },
      })
    } catch (error) {
      enqueueSnackbar('Error: error during adding data item!', {
        variant: 'error',
      })
      captureException(error)
    }
  }

  const availableDataItemTypes = useMemo(() => {
    return DATA_ITEM_TYPES.filter(typeItem => {
      if (congressId) {
        return typeItem.where.includes('congress')
      } else {
        return typeItem.where.includes('society')
      }
    })
  }, [congressId])

  return (
    <>
      {addDataItemMutation.loading && (
        <FullscreenLoading variant="white" transparent />
      )}

      <div className={classes.title}>
        <Typography variant="h6" component="span">
          {'Available contents'}
        </Typography>

        <Tooltip
          placement="top"
          title={`Add new content (with the type '${
            type.charAt(0).toUpperCase() + type.substring(1).toLowerCase()
          }').`}
        >
          <span>
            <IconButton
              color="primary"
              size="small"
              className={classes.addIcon}
              onClick={handleNamingDialogOpen}
              disabled={loading}
            >
              <AddIcon />
            </IconButton>
          </span>
        </Tooltip>
      </div>

      <NamingDialog
        subject="content"
        open={namingDialogOpen}
        onClose={handleNamingDialogClose}
        onSubmit={name => addNewDataItem(name)}
        maxLength={100}
      />

      <TextField
        id="dataItemType"
        type="text"
        label="Type"
        variant="outlined"
        value={type}
        onChange={handleTypeChange}
        fullWidth
        select
        className={classes.dropdown}
      >
        {availableDataItemTypes.map((item, index) => (
          <MenuItem key={index} value={item.value} disabled={!item.enabled}>
            {item.label}
          </MenuItem>
        ))}
      </TextField>

      {loading ? (
        <ContentLoading />
      ) : (
        <Paper variant="outlined" className={classes.items}>
          <List disablePadding>
            {dataItems && dataItems.length > 0 ? (
              dataItems.map((dataItem, index) => (
                <DataItem
                  key={index}
                  dataItem={dataItem}
                  currentDataItemId={currentDataItemId}
                  setFieldValue={setFieldValue}
                  listingType={type}
                  congressId={congressId}
                  saveForm={saveForm}
                />
              ))
            ) : (
              <ListItem>
                <ListItemText primary="No items." />
              </ListItem>
            )}
          </List>
        </Paper>
      )}
    </>
  )
}

DataItems.propTypes = {
  currentDataItemId: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  congressId: PropTypes.string,
  saveForm: PropTypes.func.isRequired,
}

const styles = makeStyles(theme => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  dropdown: {
    marginTop: theme.spacing(1),
  },
  items: {
    marginTop: theme.spacing(1),
  },
  addIcon: {
    marginLeft: theme.spacing(1),
  },
}))

export default DataItems
