import {
  ThemeProvider,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

// MUI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { red } from '@material-ui/core/colors'

const ConfirmationDialog = props => {
  const {
    title,
    description,
    open,
    returnLabel,
    confirmLabel,
    onReturn,
    onConfirm,
    variant,
  } = props
  const classes = styles()

  return (
    <Dialog
      open={open}
      onClose={onReturn}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button variant="contained" color="secondary" onClick={onReturn}>
          {returnLabel}
        </Button>

        {variant === 'general' ? (
          <Button variant="outlined" color="primary" onClick={onConfirm}>
            {confirmLabel}
          </Button>
        ) : (
          <ThemeProvider theme={redTheme}>
            <Button variant="outlined" color="primary" onClick={onConfirm}>
              {confirmLabel}
            </Button>
          </ThemeProvider>
        )}
      </DialogActions>
    </Dialog>
  )
}

ConfirmationDialog.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  returnLabel: PropTypes.string.isRequired,
  confirmLabel: PropTypes.string.isRequired,
  onReturn: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['general', 'delete']),
}

const redTheme = createTheme({
  palette: {
    primary: red,
  },
})
const styles = makeStyles(theme => ({
  dialogActions: {
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
}))

export default ConfirmationDialog
