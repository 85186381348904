import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useQuery } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'
import { Form, Formik, FastField } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import { differenceInCalendarDays } from 'date-fns'

// MUI
import {
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Divider,
  FormControlLabel,
  Switch,
  TextField,
  MenuItem,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core'
import SendIcon from '@material-ui/icons/Send'
import { DateTimePicker } from '@material-ui/pickers'

// Elements
import FormikTextField from '../utils/FormikTextField'

// Cosmetics
import ContentLoading from '../cosmetics/loadings/ContentLoading'
import InformationBlock from '../cosmetics/UI/InformationBlock'

// Queries
import { query } from '../../lib/apollo-client'

const schema = Yup.object().shape({
  title: Yup.string().required(),
  description: Yup.string().required(),
  isScheduled: Yup.boolean().required(),
  scheduled: Yup.number().integer(),
  payload: Yup.object()
    .shape({
      id: Yup.string().required(),
      type: Yup.string().required(),
    })
    .nullable(true),
})

const initialValues = {
  title: '',
  description: '',
  isScheduled: false,
  scheduled: Math.floor(Date.now() / 1000),
  payload: null,
}

const PushNotificationDialog = props => {
  const { congressId, open, onClose, sendHandler } = props
  const classes = styles()
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()

  // State
  const [nodes, setNodes] = useState([])

  // Data
  const { data, error, loading, refetch } = useQuery(
    query.oneSignal.ACTIVE_DATA_NODES,
    {
      variables: {
        congress_id: congressId,
        type: 'NEWS',
      },
      fetchPolicy: 'network-only',
    }
  )
  useEffect(() => {
    if (loading) {
      return
    }

    if (error) {
      enqueueSnackbar(`${error.name}: ${error.message}`, {
        variant: 'error',
      })
      captureException(error)
    }

    if (data && Array.isArray(data.activeDataNodes)) {
      data.activeDataNodes.sort((a, b) => {
        if (Number(a.created_at) < Number(b.created_at)) {
          return 1
        } else if (Number(a.created_at) > Number(b.created_at)) {
          return -1
        } else {
          return 0
        }
      })

      const nodes = data.activeDataNodes.map(node => ({
        ...node,
        content: node.data ? JSON.parse(node.data) : undefined,
      }))

      setNodes(nodes)
    }
  }, [data, error, loading, enqueueSnackbar])

  // fetch active data nodes each time dialog opens
  // necessary to keep items updated
  useEffect(() => {
    if (open) refetch()
  }, [open, refetch])

  const handleSubmit = async (
    values,
    { setErrors, setSubmitting, resetForm }
  ) => {
    if (values.isScheduled) {
      const nowDate = new Date()
      const scheduledDate = new Date(values.scheduled * 1000)

      if (values.scheduled <= Math.floor(Date.now() / 1000)) {
        setErrors({
          scheduled: 'Scheduled date should be newer than current date!',
        })
        setSubmitting(false)
        return
      } else if (differenceInCalendarDays(scheduledDate, nowDate) > 30) {
        setErrors({
          scheduled:
            'Scheduled date cannot be further than +30 days from today!',
        })
        setSubmitting(false)
        return
      }
    }

    await sendHandler(
      values.title,
      values.description,
      values.isScheduled ? values.scheduled : null,
      values.payload &&
        values.payload.parent_menu_item &&
        values.payload.parent_menu_item.id
        ? values.payload.parent_menu_item.id
        : null,
      values.payload &&
        values.payload.parent_menu_item &&
        values.payload.parent_menu_item.title
        ? values.payload.parent_menu_item.title
        : null,
      values.payload ? values.payload.id : null,
      values.payload ? values.payload.type : null,
      values.payload ? values.payload.data : null
    )

    resetForm(values)
    setSubmitting(false)

    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.dialog }}>
      <DialogTitle>
        {congressId
          ? 'Send new push notification (to people who downloaded this congress)'
          : 'Send new push notification (to everyone)'}
      </DialogTitle>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values, setFieldValue, errors }) => (
          <Form noValidate>
            <DialogContent>
              <Grid container spacing={2}>
                {/* TITLE */}
                <Grid item xs={12}>
                  <FastField
                    name="title"
                    type="text"
                    label="Title"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    autoFocus
                  />
                </Grid>

                {/* DESCRIPTION */}
                <Grid item xs={12}>
                  <FastField
                    name="description"
                    type="text"
                    label="Description"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                {/* SCHEDULED */}
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.isScheduled}
                        name="isScheduled"
                        onChange={e => {
                          setFieldValue('isScheduled', e.target.checked)
                        }}
                        color="primary"
                      />
                    }
                    label="Scheduled?"
                  />
                </Grid>
                {values.isScheduled && (
                  <>
                    <Grid item xs={12}>
                      <DateTimePicker
                        value={moment.unix(values.scheduled)}
                        onChange={date => {
                          if (date) {
                            setFieldValue('scheduled', date.unix())
                          }
                        }}
                        error={Boolean(errors.scheduled)}
                        helperText={
                          Boolean(errors.scheduled)
                            ? errors.scheduled
                            : undefined
                        }
                        inputVariant="outlined"
                        fullWidth
                        ampm={false}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{
                        marginBottom: theme.spacing(2),
                      }}
                    >
                      <InformationBlock variant="warning" size="small">
                        <Typography
                          style={{
                            fontWeight: theme.typography.fontWeightBold,
                          }}
                        >
                          {`All dates are displayed in your browser's current timezone!`}
                        </Typography>
                      </InformationBlock>
                    </Grid>
                  </>
                )}

                {/* PAYLOAD */}
                {loading ? (
                  <ContentLoading />
                ) : (
                  <Grid item xs={12}>
                    <TextField
                      name="payload"
                      type="text"
                      label="Assigned data"
                      variant="outlined"
                      fullWidth
                      select
                      value={values.payload || 'unselected'}
                      onChange={e => {
                        if (e.target.value === 'unselected') {
                          setFieldValue('payload', null)
                        } else {
                          setFieldValue('payload', e.target.value)
                        }
                      }}
                    >
                      <MenuItem value={'unselected'}>{'Select...'}</MenuItem>
                      {Array.isArray(nodes) &&
                        nodes.map((node, index) => (
                          <MenuItem key={index} value={node}>
                            {`${
                              node.content && node.content.title
                                ? node.content.title
                                : 'Could not read title'
                            } (${node.type})`}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                )}
              </Grid>
            </DialogContent>

            <DialogActions className={classes.actions}>
              <Button onClick={onClose} variant="outlined" color="secondary">
                {'Cancel'}
              </Button>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={
                  isSubmitting || (!values.title && !values.description)
                }
              >
                {'Send'}
                <SendIcon fontSize="small" className={classes.marginLeft} />
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

PushNotificationDialog.propTypes = {
  congressId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  sendHandler: PropTypes.func.isRequired,
}

const styles = makeStyles(theme => ({
  dialog: {
    [theme.breakpoints.up('xs')]: {
      width: 425,
    },
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
}))

export default PushNotificationDialog
