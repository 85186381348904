import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Form } from 'formik'

// MUI
import { Paper } from '@material-ui/core'

const FormContainer = props => {
  const { elevation } = props
  const classes = styles()

  return (
    <Paper
      className={classes.paper}
      elevation={elevation === undefined ? 2 : elevation}
    >
      <Form noValidate>{props.children}</Form>
    </Paper>
  )
}

FormContainer.propTypes = {
  elevation: PropTypes.number,
}

const styles = makeStyles(theme => ({
  paper: {
    overflow: 'hidden',
    padding: theme.spacing(4),
  },
}))

export default FormContainer
