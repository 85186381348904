import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

// Elements
import BackButton from '../elements/BackButton'
import AdItems from './elements/Ads/AdItems'

const PollsDrawer = props => {
  const { closeDrawer } = props
  const history = useHistory()

  return (
    <>
      <BackButton
        handleBack={() => {
          history.push('/')
        }}
      />

      <AdItems closeDrawer={closeDrawer} />
    </>
  )
}

PollsDrawer.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
}

export default PollsDrawer
