import React, { useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Switch, Route } from 'react-router-dom'
import { useAuth0 } from '../../lib/auth0'

// MUI
import {
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  IconButton,
  Drawer,
  Hidden,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import AccountCircle from '@material-ui/icons/AccountCircle'

// Components
import PageTitle from './PageTitle'
import DrawerRouter from './DrawerRouter'

// Elements
import PublishButton from './elements/PublishButton'

const Header = () => {
  const classes = styles()
  const theme = useTheme()
  const { logout } = useAuth0()

  // Local state
  const [anchorEl, setAnchorEl] = useState(null)
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false)

  const handleMenuClick = e => {
    setAnchorEl(e.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }
  const toggleDrawer = () => {
    setMobileDrawerOpen(!mobileDrawerOpen)
  }
  const closeDrawer = () => {
    setMobileDrawerOpen(false)
  }

  const handleLogout = () => {
    logout()
  }

  return (
    <div className={classes.header}>
      <AppBar
        color="primary"
        position="fixed"
        className={classes.appBar}
        elevation={0}
      >
        <Toolbar className={classes.toolbar}>
          {
            // Toggle drawer button
          }
          <Hidden mdUp>
            <IconButton
              aria-label="Show menu"
              edge="start"
              onClick={toggleDrawer}
              className={classes.toggleButton}
            >
              <MenuIcon className={classes.menuIcon} />
            </IconButton>
          </Hidden>

          {
            // AppBar title
          }
          <div className={classes.pageTitle}>
            <PageTitle />
          </div>

          <div>
            {
              // Publish Button (for congress)
            }
            <Switch>
              <Route path="/congress/:congressId" component={PublishButton} />
            </Switch>

            {
              // Account menu
            }
            <IconButton
              aria-label="Show account menu"
              edge="end"
              onClick={handleMenuClick}
              className={classes.accountMenu}
            >
              <AccountCircle className={classes.accountIcon} />
            </IconButton>
            <Menu
              id="account-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem aria-label="Logout" onClick={handleLogout}>
                {'Logout'}
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>

      <nav className={classes.drawer} aria-label="Menu">
        <Hidden mdUp>
          {
            // Mobile drawer
          }
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileDrawerOpen}
            onClose={toggleDrawer}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // For better performance
            }}
          >
            <DrawerRouter closeDrawer={closeDrawer} />
          </Drawer>
        </Hidden>

        <Hidden smDown>
          {
            // Desktop drawer
          }
          <Drawer
            variant="permanent"
            open
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <DrawerRouter closeDrawer={closeDrawer} />
          </Drawer>
        </Hidden>
      </nav>
    </div>
  )
}

const drawerWidth = 240
const styles = makeStyles(theme => ({
  // Root
  header: {
    display: 'flex',
  },
  // Drawer
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  // AppBar
  appBar: {
    [theme.breakpoints.up('md')]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
  },
  toggleButton: {
    marginRight: theme.spacing(2),
  },
  menuIcon: {
    color: theme.palette.common.white,
  },
  pageTitle: {
    flex: 1,
  },
  accountMenu: {
    marginLeft: theme.spacing(1),
  },
  accountIcon: {
    color: theme.palette.common.white,
  },
}))

export default Header
