import React from 'react'
import Helmet from 'react-helmet'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'

// Components
import ContentDrawer from '../../../components/drawers/ContentDrawer'
import BreakForm from '../../../components/forms/Break/BreakForm'

// Cosmetics
import FullscreenLoading from '../../../components/cosmetics/loadings/FullscreenLoading'

// Queries & Mutations
import { query } from '../../../lib/apollo-client'

const Break = props => {
  const { match } = props
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const [isFormDrawerOpen, setIsFormDrawerOpen] = React.useState(false)

  // Data
  const { data, error, loading } = useQuery(query.form.break.BREAK, {
    variables: { id: match.params.breakId },
  })
  React.useEffect(() => {
    if (loading) return

    if (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      })
      captureException(error)
    }

    if (data && data.break) {
      setIsFormDrawerOpen(true)
    }
  }, [data, error, loading, enqueueSnackbar])

  const closeDrawer = () => {
    setIsFormDrawerOpen(false)

    setTimeout(() => {
      history.push(`/congress/${match.params.congressId}`)
    }, 500)
  }

  if (loading) {
    return <FullscreenLoading variant="white" transparent />
  }

  return (
    <>
      <Helmet>
        <title>{'Edit break'}</title>
      </Helmet>

      <ContentDrawer open={isFormDrawerOpen} onClose={closeDrawer}>
        {data && data.break && (
          <BreakForm
            breakItem={data.break}
            congressId={match.params.congressId}
          />
        )}
      </ContentDrawer>
    </>
  )
}

export default Break
