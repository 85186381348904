import ads from './ads'
import drawer from './drawer'
import form from './form'
import oneSignal from './oneSignal'
import polls from './polls'
import qa from './qa'
import settings from './settings'
import table from './table'

export default {
  drawer,
  form,
  table,
  oneSignal,
  polls,
  ads,
  settings,
  qa,
}
