import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { HttpLink } from 'apollo-link-http'

const createApolloClientFromToken = (token: string) => {
  const link = ApolloLink.from([
    onError(({ graphQLErrors }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ extensions }) => {
          if (extensions && extensions.code) {
            switch (extensions.code) {
              case 'UNAUTHENTICATED':
                window.location.href = '/login'
                break
              default:
                return
            }
          }
        })
      }
    }),
    new HttpLink({
      uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
      credentials: 'include',
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
      fetch: fetch,
    }),
  ])

  return new ApolloClient({
    cache: new InMemoryCache(),
    link,
  })
}

export { createApolloClientFromToken }
