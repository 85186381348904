import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

// MUI
import { Paper, Typography, Divider } from '@material-ui/core'

// Layout
import { ContentWrapper } from '../../../layout'

const PollSettingsEntryCode = props => {
  const { poll } = props
  const classes = styles()

  return (
    <ContentWrapper>
      <Paper className={clsx(classes.padding)}>
        <Typography variant="h4" gutterBottom>
          {'The poll entry code is:'}
        </Typography>
        <Divider />

        <Typography
          variant="h1"
          align="center"
          noWrap
          className={classes.marginTop}
        >
          {poll.entry_code &&
            poll.entry_code.split('').map((char, index) => (
              <span key={index} className={clsx(classes.paddingSm)}>
                {char}
              </span>
            ))}
        </Typography>
      </Paper>
    </ContentWrapper>
  )
}

PollSettingsEntryCode.propTypes = {
  poll: PropTypes.object.isRequired,
}

const styles = makeStyles(theme => ({
  padding: {
    padding: theme.spacing(4),
  },
  paddingSm: {
    padding: theme.spacing(2),
  },
  marginTop: {
    marginTop: theme.spacing(6),
  },
}))

export default PollSettingsEntryCode
