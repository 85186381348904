import React from 'react'
import PropTypes from 'prop-types'
import { Formik, FastField } from 'formik'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'

import { removeNullProps } from '../../../utils/functions'

// Utils
import FormikTextField from '../../utils/FormikTextField'

// MUI
import {
  Grid,
  FormControlLabel,
  Switch,
  Paper,
  Typography,
  Divider,
  TextField,
  MenuItem,
  useTheme,
  Button,
} from '@material-ui/core'

// Layout
import FormContainer from '../elements/layout/FormContainer'
import FormFooter from '../elements/layout/FormFooter'

// Components
import QA from '../../QA'

// Elements
import DrawerTitle from '../../drawers/elements/DrawerTitle'
import TimestampPicker from '../elements/inputs/TimestampPicker'

// Cosmetics
import FullscreenLoading from '../../cosmetics/loadings/FullscreenLoading'
import SelectLoading from '../../cosmetics/loadings/SelectLoading'
import InformationBlock from '../../cosmetics/UI/InformationBlock'

// Dialogs
import ConfirmationDialog from '../../dialogs/ConfirmationDialog'
import ContentDialog from '../../dialogs/ContentDialog'

// Queries & Mutations
import { query, mutation } from '../../../lib/apollo-client'

const FORM_DISABLED = false

const initialValues = {
  section_id: null,
  topic_id: null,
  abstract_num: 0,
  title: '',
  presenter: '',
  name_head: '',
  inst_head: '',
  description: '',
  content: '',
  keyword: '',
  rank: 0,
  from: Math.floor(Date.now() / 1000),
  to: Math.floor(Date.now() / 1000),
  lect_name: '',
  lect_number: '',
  lect_time: 0,
  discussion_time: 0,
  uploaded_file_name: '',
  track: '',
  forum: false,
  info_before: '',
  info_after: '',
  video_id: '',
  room: '',
}

const schema = Yup.object().shape({
  section_id: Yup.string().nullable(true).required(),
  topic_id: Yup.string().nullable(true),
  abstract_num: Yup.number().integer().moreThan(-1).required(),
  title: Yup.string().required(),
  presenter: Yup.string(),
  name_head: Yup.string(),
  inst_head: Yup.string(),
  description: Yup.string(),
  content: Yup.string(),
  keyword: Yup.string(),
  rank: Yup.number().integer().moreThan(-1).required(),
  from: Yup.number()
    .integer()
    .required()
    .lessThan(
      Yup.ref('to'),
      'abstract start date should be earlier than end date'
    ),
  to: Yup.number()
    .integer()
    .required()
    .moreThan(
      Yup.ref('from'),
      'abstract end date should be later than start date'
    ),
  lect_name: Yup.string().max(30),
  lect_number: Yup.string().max(10),
  lect_time: Yup.number().integer().required(),
  discussion_time: Yup.number().integer().required(),
  uploaded_file_name: Yup.string().max(50),
  track: Yup.string().max(10),
  forum: Yup.boolean().required(),
  info_before: Yup.string(),
  info_after: Yup.string(),
  video_id: Yup.string().max(50),
  room: Yup.string().max(50),
})

const AbstractForm = props => {
  const { abstract, congressId } = props
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const isNewAbstract = !abstract

  const [sections, setSections] = React.useState([])
  const [topics, setTopics] = React.useState([])

  const [deleteConfirmOpen, setDeleteConfirmOpen] = React.useState(false)
  const handleConfirmationOpen = () => setDeleteConfirmOpen(true)
  const handleConfirmationClose = () => setDeleteConfirmOpen(false)

  const [qaDialogOpen, setQaDialogOpen] = React.useState(false)
  const handleQaDialogOpen = () => setQaDialogOpen(true)
  const handleQaDialogClose = () => setQaDialogOpen(false)

  // Data
  const sectionsQuery = useQuery(query.polls.SECT_LIST, {
    variables: { congress_id: congressId },
  })
  const topicsQuery = useQuery(query.table.TOPICS, {
    variables: { congress_id: congressId },
  })
  const [updateAbstract, updateAbstractMutation] = useMutation(
    mutation.form.abstract.UPDATE_ABSTRACT
  )
  const [addAbstract, addAbstractMutation] = useMutation(
    mutation.form.abstract.ADD_ABSTRACT
  )
  const [deleteAbstract, deleteAbstractMutation] = useMutation(
    mutation.form.abstract.DELETE_ABSTRACT
  )

  // sectionsQuery
  React.useEffect(() => {
    if (sectionsQuery.loading) return

    if (sectionsQuery.error) {
      enqueueSnackbar(sectionsQuery.error.message, { variant: 'error' })
      captureException(sectionsQuery.error)
    }

    if (sectionsQuery.data && Array.isArray(sectionsQuery.data.sections)) {
      setSections(sectionsQuery.data.sections)
    }
  }, [sectionsQuery, enqueueSnackbar])

  // topicsQuery
  React.useEffect(() => {
    if (topicsQuery.loading) return

    if (topicsQuery.error) {
      enqueueSnackbar(topicsQuery.error.message, { variant: 'error' })
      captureException(topicsQuery.error)
    }

    if (topicsQuery.data && Array.isArray(topicsQuery.data.topics)) {
      setTopics(topicsQuery.data.topics)
    }
  }, [topicsQuery, enqueueSnackbar])

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    values.abstract_num = Number(values.abstract_num)
    values.rank = Number(values.rank)
    values.lect_time = Number(values.lect_time)
    values.discussion_time = Number(values.discussion_time)

    try {
      if (isNewAbstract) {
        const abstractId = uuidv4()

        await addAbstract({
          variables: {
            id: abstractId,
            congress_id: congressId,
            ...values,
          },
        })

        history.push(`/congress/${congressId}/abstract/${abstractId}`)
      } else {
        await updateAbstract({
          variables: {
            ...values,
          },
        })

        resetForm(values)
        setSubmitting(false)
      }
    } catch (error) {
      enqueueSnackbar(
        error.message ? error.message : 'Error: error during saving abstract!',
        { variant: 'error' }
      )
      captureException(error)

      setSubmitting(false)
    }
  }

  const handleDelete = async () => {
    if (!abstract) {
      enqueueSnackbar('Could not delete non-existent abstract!', {
        variant: 'error',
      })
      captureException('Could not delete non-existent abstract!')
      return
    }

    try {
      await deleteAbstract({
        variables: { id: abstract.id },
      })

      history.push(`/congress/${congressId}`)
    } catch (error) {
      enqueueSnackbar(
        error.message
          ? error.message
          : 'Error: error during deleting abstract!',
        { variant: 'error' }
      )
      captureException(error)
    }
  }

  removeNullProps(abstract)
  const formData = {
    ...initialValues,
    ...abstract,
  }

  return (
    <>
      {(updateAbstractMutation.loading ||
        addAbstractMutation.loading ||
        deleteAbstractMutation.loading) && (
        <FullscreenLoading variant="white" transparent />
      )}

      <Formik
        enableReinitialize
        initialValues={formData}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, errors, isSubmitting, dirty }) => (
          <>
            <FormContainer elevation={0}>
              <DrawerTitle
                title={isNewAbstract ? 'Create new abstract' : 'Edit abstract'}
              />

              <Grid container spacing={2}>
                {!isNewAbstract && (
                  <>
                    {/* RATING COUNT */}
                    <Grid item xs={12} sm={6}>
                      <Paper
                        variant="outlined"
                        style={{
                          padding: theme.spacing(2),
                        }}
                      >
                        <Typography align="center" gutterBottom>
                          {'Total ratings'}
                        </Typography>
                        <Typography variant="h4" align="center" noWrap>
                          {values.ratingCount}
                        </Typography>
                      </Paper>
                    </Grid>

                    {/* RATING AVG */}
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        marginBottom: theme.spacing(2),
                      }}
                    >
                      <Paper
                        variant="outlined"
                        style={{
                          padding: theme.spacing(2),
                        }}
                      >
                        <Typography align="center" gutterBottom>
                          {'Rating average'}
                        </Typography>
                        <Typography variant="h4" align="center" noWrap>
                          {values.ratingAvg}
                        </Typography>
                      </Paper>
                    </Grid>
                  </>
                )}

                {!isNewAbstract && (
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginBottom: theme.spacing(2),
                    }}
                  >
                    <Button
                      onClick={handleQaDialogOpen}
                      variant="outlined"
                      color="primary"
                      size="large"
                      fullWidth
                    >
                      {'View Q&A session'}
                    </Button>
                  </Grid>
                )}

                {/* TITLE */}
                <Grid item xs={12}>
                  <FastField
                    name="title"
                    type="text"
                    label="Name"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                {/* ABSTRACT NUM */}
                <Grid item xs={6}>
                  <FastField
                    name="abstract_num"
                    type="text"
                    label="Abstract number"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                {/* RANK */}
                <Grid item xs={6}>
                  <FastField
                    name="rank"
                    type="text"
                    label="Rank"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                {/* SECTION */}
                <Grid item xs={12}>
                  <Typography>{'Section'}</Typography>

                  {sectionsQuery.loading ? (
                    <SelectLoading />
                  ) : Array.isArray(sections) && sections.length > 0 ? (
                    <TextField
                      name="section_id"
                      type="text"
                      variant="outlined"
                      fullWidth
                      select
                      disabled={sectionsQuery.loading}
                      value={values.section_id || 'unselected'}
                      onChange={e => {
                        if (e.target.value === 'unselected') {
                          setFieldValue('section_id', null)
                        } else {
                          setFieldValue('section_id', e.target.value)
                        }
                      }}
                      error={Boolean(errors.section_id)}
                      helperText={
                        Boolean(errors.section_id)
                          ? errors.section_id
                          : undefined
                      }
                    >
                      <MenuItem value={'unselected'}>
                        {'Please select a section...'}
                      </MenuItem>

                      {sections.map((section, index) => (
                        <MenuItem key={index} value={section.id}>
                          {section.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <Typography color="error">
                      {
                        'No available sections. Please add sections before trying to add abstracts!'
                      }
                    </Typography>
                  )}
                </Grid>

                {/* TOPIC */}
                <Grid item xs={12}>
                  <Typography>{'Topic'}</Typography>

                  {topicsQuery.loading ? (
                    <SelectLoading />
                  ) : Array.isArray(topics) && topics.length > 0 ? (
                    <TextField
                      name="topic_id"
                      type="text"
                      variant="outlined"
                      fullWidth
                      select
                      disabled={topicsQuery.loading}
                      value={values.topic_id || 'unselected'}
                      onChange={e => {
                        if (e.target.value === 'unselected') {
                          setFieldValue('topic_id', null)
                        } else {
                          setFieldValue('topic_id', e.target.value)
                        }
                      }}
                      error={Boolean(errors.topic_id)}
                      helperText={
                        Boolean(errors.topic_id) ? errors.topic_id : undefined
                      }
                    >
                      <MenuItem value={'unselected'}>
                        {'Please select a topic...'}
                      </MenuItem>

                      {topics.map((topic, index) => (
                        <MenuItem key={index} value={topic.id}>
                          {topic.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <Typography color="error">
                      {'No available topics.'}
                    </Typography>
                  )}
                </Grid>

                {/* FROM */}
                <Grid item xs={12} md={6}>
                  <TimestampPicker
                    label="Starts from"
                    labelVariant="body1"
                    time
                    timestamp={values.from}
                    onChange={timestamp => setFieldValue('from', timestamp)}
                    error={Boolean(errors.from)}
                    errorText={Boolean(errors.from) ? errors.from : undefined}
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                {/* TO */}
                <Grid item xs={12} md={6}>
                  <TimestampPicker
                    label="Ends at"
                    labelVariant="body1"
                    time
                    timestamp={values.to}
                    onChange={timestamp => setFieldValue('to', timestamp)}
                    error={Boolean(errors.to)}
                    errorText={Boolean(errors.to) ? errors.to : undefined}
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                <Grid item xs={12}>
                  <InformationBlock variant="warning" size="small">
                    <Typography
                      style={{
                        fontWeight: theme.typography.fontWeightBold,
                      }}
                    >
                      {`All dates are displayed in your browser's current timezone!`}
                    </Typography>
                  </InformationBlock>
                </Grid>

                <Grid item xs={12}>
                  <Divider
                    style={{
                      marginTop: theme.spacing(2),
                      marginBottom: theme.spacing(2),
                    }}
                  />
                </Grid>

                {/* PRESENTER */}
                <Grid item xs={12} md={6}>
                  <FastField
                    name="presenter"
                    type="text"
                    label="Presenter"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                {/* NAME HEAD */}
                <Grid item xs={12} md={6}>
                  <FastField
                    name="name_head"
                    type="text"
                    label="Authors"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                {/* INST HEAD */}
                <Grid item xs={12}>
                  <FastField
                    name="inst_head"
                    type="text"
                    label="Author's institutions"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider
                    style={{
                      marginTop: theme.spacing(2),
                      marginBottom: theme.spacing(2),
                    }}
                  />
                </Grid>

                {/* DESCRIPTION */}
                <Grid item xs={12}>
                  <FastField
                    name="description"
                    type="text"
                    label="Description"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                {/* CONTENT */}
                <Grid item xs={12}>
                  <FastField
                    name="content"
                    type="text"
                    label="Content"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                {/* KEYWORD */}
                <Grid item xs={12}>
                  <FastField
                    name="keyword"
                    type="text"
                    label="Keywords"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider
                    style={{
                      marginTop: theme.spacing(2),
                      marginBottom: theme.spacing(2),
                    }}
                  />
                </Grid>

                {/* LECT NAME */}
                <Grid item xs={12} md={6}>
                  <FastField
                    name="lect_name"
                    type="text"
                    label="Lecture name"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                {/* LECT NUMBER */}
                <Grid item xs={12} md={6}>
                  <FastField
                    name="lect_number"
                    type="text"
                    label="Lecture number"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                {/* LECT TIME */}
                <Grid item xs={6}>
                  <FastField
                    name="lect_time"
                    type="text"
                    label="Lecture time"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                {/* DISCUSSION TIME */}
                <Grid item xs={12} md={6}>
                  <FastField
                    name="discussion_time"
                    type="text"
                    label="Discussion time"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider
                    style={{
                      marginTop: theme.spacing(2),
                      marginBottom: theme.spacing(2),
                    }}
                  />
                </Grid>

                {/* ROOM */}
                <Grid item xs={12}>
                  <FastField
                    name="room"
                    type="text"
                    label="Room"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider
                    style={{
                      marginTop: theme.spacing(2),
                      marginBottom: theme.spacing(2),
                    }}
                  />
                </Grid>

                {/* FORUM */}
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.forum}
                        name="forum"
                        onChange={e => {
                          setFieldValue('forum', e.target.checked)
                        }}
                        color="primary"
                      />
                    }
                    label="Forum"
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                {/* INFO BEFORE */}
                <Grid item xs={6}>
                  <FastField
                    name="info_before"
                    type="text"
                    label="Info before"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                {/* INFO AFTER */}
                <Grid item xs={6}>
                  <FastField
                    name="info_after"
                    type="text"
                    label="Info after"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                {/* TRACK */}
                <Grid item xs={12}>
                  <FastField
                    name="track"
                    type="text"
                    label="Track"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                {/* UPLOADED FILE NAME */}
                <Grid item xs={12}>
                  <FastField
                    name="uploaded_file_name"
                    type="text"
                    label="Uploaded file name"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                {/* VIDEO ID */}
                <Grid item xs={12}>
                  <FastField
                    name="video_id"
                    type="text"
                    label="Video ID"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>
              </Grid>

              <FormFooter
                isSubmitting={isSubmitting}
                dirty={dirty}
                save={!isNewAbstract}
                onDelete={!isNewAbstract ? handleConfirmationOpen : null}
              />
            </FormContainer>

            {!isNewAbstract && (
              <ContentDialog
                title={`Abstract Q&A session`}
                open={qaDialogOpen}
                onClose={handleQaDialogClose}
                scroll={false}
              >
                <QA type="ABSTRACT" subjectId={abstract.id} />
              </ContentDialog>
            )}

            <ConfirmationDialog
              title="Delete abstract"
              description="Are you sure you would like to delete this abstract? This action is irreversible."
              returnLabel="Cancel"
              confirmLabel="Delete"
              onReturn={handleConfirmationClose}
              onConfirm={() => {
                handleConfirmationClose()
                handleDelete()
              }}
              open={deleteConfirmOpen}
            />
          </>
        )}
      </Formik>
    </>
  )
}

AbstractForm.propTypes = {
  congressId: PropTypes.string.isRequired,
  abstract: PropTypes.object,
}

export default AbstractForm
