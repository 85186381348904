import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

// MUI
import { AppBar, Tabs } from '@material-ui/core'

const SubHeader = props => {
  const { value, onChange, children } = props
  const classes = styles()

  return (
    <AppBar
      position="static"
      color="inherit"
      component="div"
      className={classes.appBar}
    >
      <Tabs
        value={value}
        onChange={onChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        {children}
      </Tabs>
    </AppBar>
  )
}

SubHeader.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
}

const styles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.appBar - 1, // to make it appear under header's appbar
  },
}))

export default SubHeader
