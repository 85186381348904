import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { Draggable } from 'react-beautiful-dnd'

// MUI
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from '@material-ui/core'

// Cosmetics
import MaterialIcon from '../../../cosmetics/UI/MaterialIcon'

const MenuItem = props => {
  const { index, item, isAdding, congressId, closeDrawer } = props
  const classes = styles()
  const history = useHistory()

  return (
    <Draggable draggableId={item.id} index={index}>
      {provided => (
        <ListItem
          button
          onClick={() => {
            if (!isAdding) {
              if (congressId) {
                history.push(`/congress/${congressId}/menuItem/${item.id}`)
              } else {
                history.push(`/societyapp/menuItem/${item.id}`)
              }
              closeDrawer()
            }
          }}
          innerRef={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <ListItemAvatar className={classes.listItemAvatar}>
            {item.icon ? (
              <MaterialIcon icon={item.icon} color="primary" />
            ) : (
              <Avatar alt="icon" className={classes.icon}>
                {item.title.charAt(0)}
              </Avatar>
            )}
          </ListItemAvatar>

          <ListItemText primary={item.title} />
        </ListItem>
      )}
    </Draggable>
  )
}

MenuItem.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  isAdding: PropTypes.bool.isRequired,
  congressId: PropTypes.string,
  closeDrawer: PropTypes.func.isRequired,
}

const styles = makeStyles(theme => ({
  listItemAvatar: {
    minWidth: '40px',
  },
  icon: {
    height: theme.spacing(3),
    width: theme.spacing(3),
  },
}))

export default MenuItem
