import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

// MUI
import { Button } from '@material-ui/core'
import MUIDataTable from 'mui-datatables'

// Table head
const columns = [
  {
    name: 'id',
    label: 'ID',
    options: {
      display: 'excluded',
      filter: false,
    },
  },
  {
    name: 'congress_id',
    label: 'Congress ID',
    options: {
      display: 'excluded',
      filter: false,
    },
  },
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'from',
    label: 'From',
    options: {
      customBodyRender: value => {
        if (value) {
          const date = moment.unix(Number(value))

          return date.format('YYYY. MM. DD., HH:mm')
        } else {
          return '-'
        }
      },
      sortDirection: 'asc',
    },
  },
  {
    name: 'to',
    label: 'To',
    options: {
      customBodyRender: value => {
        if (value) {
          const date = moment.unix(Number(value))

          return date.format('YYYY. MM. DD., HH:mm')
        } else {
          return '-'
        }
      },
    },
  },
]

const BreaksTable = props => {
  const { breaks, congressId } = props
  const history = useHistory()

  const addNewButton = (
    <Button
      onClick={() => history.push(`/congress/${congressId}/break/new`)}
      variant="contained"
      color="primary"
    >
      {'New break'}
    </Button>
  )

  return (
    <MUIDataTable
      title={addNewButton}
      columns={columns}
      data={breaks}
      options={{
        responsive: 'scrollMaxHeight',
        rowHover: true,
        selectableRows: 'none',
        print: false,
        download: false,
        elevation: 2,
        onRowClick: row => {
          const breakId = row[0]
          const congressId = row[1]

          history.push(`/congress/${congressId}/break/${breakId}`)
        },
      }}
    />
  )
}

BreaksTable.propTypes = {
  breaks: PropTypes.array.isRequired,
  congressId: PropTypes.string.isRequired,
}

export default BreaksTable
