import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'
import { Formik, FastField } from 'formik'
import * as Yup from 'yup'

// config
import { EDITABLE_DATA_ITEM_TYPES } from '../../../config'

// Utils
import FormikTextField from '../../utils/FormikTextField'

// MUI
import { Grid } from '@material-ui/core'

// Layout
import FormContainer from '../elements/layout/FormContainer'
import FormFooter from '../elements/layout/FormFooter'

// Elements
import DrawerTitle from '../../drawers/elements/DrawerTitle'
import News from './elements/News'
import EditNodeButton from './elements/EditNodeButton'

// Cosmetics
import FullscreenLoading from '../../cosmetics/loadings/FullscreenLoading'

// Queries & Mutations
import { mutation } from '../../../lib/apollo-client'

const schema = Yup.object().shape({
  title: Yup.string().required().max(100),
})

const initialValues = {
  title: '',
}

const DataItemForm = props => {
  const { congressId, dataItem } = props
  const classes = styles()
  const { enqueueSnackbar } = useSnackbar()

  // Data
  const [updateDataItem, updateDataItemMutation] = useMutation(
    mutation.form.menu.UPDATE_DATA_ITEM
  )

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await updateDataItem({
        variables: values,
        optimisticResponse: {
          __typename: 'Mutation',
          updateDataItem: {
            ...values,
            __typename: 'DataItem',
          },
        },
      })

      resetForm(values)
    } catch (error) {
      enqueueSnackbar('Error: error during updating data item!', {
        variant: 'error',
      })
      captureException(error)
    }

    setSubmitting(false)
  }

  // generate form data
  const formData = {
    ...initialValues,
    ...dataItem,
  }

  // see if data item's type is editable or not
  const isEditable = EDITABLE_DATA_ITEM_TYPES.some(type => {
    return type === dataItem.type
  })

  return (
    <>
      {updateDataItemMutation.loading && (
        <FullscreenLoading variant="white" transparent />
      )}

      <Formik
        enableReinitialize
        initialValues={formData}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ dirty, isSubmitting }) => (
          <FormContainer elevation={0}>
            <DrawerTitle title="Edit content item" />

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FastField
                  name="title"
                  type="text"
                  label="Title"
                  component={FormikTextField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              {dataItem.type === 'NEWS' ? (
                <Grid item xs={12} className={classes.marginTop}>
                  <News congressId={congressId} dataItem={dataItem} />
                </Grid>
              ) : (
                isEditable && (
                  <Grid
                    item
                    xs={12}
                    className={clsx(classes.marginTop, classes.center)}
                  >
                    <EditNodeButton
                      congressId={congressId}
                      dataItemId={dataItem.id}
                      dataItemType={dataItem.type}
                    />
                  </Grid>
                )
              )}
            </Grid>

            <FormFooter isSubmitting={isSubmitting} dirty={dirty} save />
          </FormContainer>
        )}
      </Formik>
    </>
  )
}

DataItemForm.propTypes = {
  congressId: PropTypes.string,
  dataItem: PropTypes.object.isRequired,
}

const styles = makeStyles(theme => ({
  marginTop: {
    marginTop: theme.spacing(4),
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

export default DataItemForm
