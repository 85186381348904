import React from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

// MUI
import { Grid, TextField, MenuItem } from '@material-ui/core'

// Layout
import FormFooter from '../../../elements/layout/FormFooter'

// Cosmetics
import FullscreenLoading from '../../../../cosmetics/loadings/FullscreenLoading'
import SelectLoading from '../../../../cosmetics/loadings/SelectLoading'

// Queries & Mutations
import { query, mutation } from '../../../../../lib/apollo-client'

const schema = Yup.object().shape({
  entry_code: Yup.string().required().nullable(true),
})

const initialValues = {
  entry_code: null,
}

const PollForm = ({ congressId, node, closeContentDialog }) => {
  const { enqueueSnackbar } = useSnackbar()

  const [polls, setPolls] = React.useState([])

  // Data
  const [updateDataNode, updateDataNodeMutation] = useMutation(
    mutation.form.menu.UPDATE_DATA_NODE
  )

  const pollsQuery = useQuery(query.polls.POLLS, {
    variables: { congress_id: congressId },
  })
  React.useEffect(() => {
    const { loading, error, data } = pollsQuery

    if (loading) return

    if (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
      captureException(error)
    }

    if (data && Array.isArray(data.polls)) {
      setPolls(data.polls)
    }
  }, [pollsQuery, enqueueSnackbar])

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await updateDataNode({
        variables: { id: node.id, data: values.entry_code },
        optimisticResponse: {
          __typename: 'Mutation',
          updateDataNode: {
            id: node.id,
            data: values.entry_code,
            __typename: 'DataNode',
          },
        },
      })

      resetForm(values)
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
      captureException(error)
    }

    setSubmitting(false)
    closeContentDialog()
  }

  // generate form data
  const formData = {
    ...initialValues,
    entry_code: node.data,
  }

  const sortedPolls = React.useMemo(() =>
      polls.sort((a, b) => {
        if (Number(a.created_at) < Number(b.created_at)) {
          return 1
        }
        if (Number(a.created_at) > Number(b.created_at)) {
          return -1
        }
        return 0
      }),
    [polls]
  )

  return (
    <>
      {updateDataNodeMutation.loading && (
        <FullscreenLoading variant="white" transparent />
      )}

      <Formik
        enableReinitialize
        initialValues={formData}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ dirty, isSubmitting, values, setFieldValue }) => (
          <Form noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {pollsQuery.loading ? (
                  <SelectLoading />
                ) : sortedPolls.length > 0 ? (
                  <TextField
                    name="entryCode"
                    type="text"
                    value={values.entry_code || 'unselected'}
                    onChange={e => {
                      if (e.target.value === 'unselected') {
                        setFieldValue('entry_code', null)
                      } else {
                        setFieldValue('entry_code', e.target.value)
                      }
                    }}
                    label="Poll"
                    variant="outlined"
                    fullWidth
                    select
                  >
                    <MenuItem value="unselected">
                      {'Select which poll to display...'}
                    </MenuItem>
                    {sortedPolls.map(poll => (
                      <MenuItem key={poll.id} value={poll.entry_code}>
                        {poll.title}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : null}
              </Grid>
            </Grid>

            <FormFooter isSubmitting={isSubmitting} dirty={dirty} save />
          </Form>
        )}
      </Formik>
    </>
  )
}

PollForm.propTypes = {
  congressId: PropTypes.string,
  node: PropTypes.object.isRequired,
  closeContentDialog: PropTypes.func.isRequired,
}

export default PollForm
