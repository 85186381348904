import React from 'react'
import PropTypes from 'prop-types'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

// Utils
import { indicesToOrders } from '../../../../utils/functions'

// MUI
import { List } from '@material-ui/core'

// Elements
import MenuItem from './MenuItem'

const MenuItems = props => {
  const { items, setItems, isAdding, congressId, closeDrawer } = props

  /**
   * 1. Update indices of items.
   * 2. Update their order based on the updated indices.
   * 3. Update local state.
   *
   * @param result
   */
  const onDragEnd = result => {
    const { source, destination } = result

    if (!destination) {
      return
    }

    if (source.index === destination.index) {
      return
    }

    const newItems = Array.from(items)
    const [moved] = newItems.splice(source.index, 1)
    newItems.splice(destination.index, 0, moved)

    indicesToOrders(newItems)

    setItems(newItems)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="societyAppMenuItems">
        {provided => (
          <List innerRef={provided.innerRef} {...provided.droppableProps}>
            {items &&
              items.map((item, index) => (
                <MenuItem
                  key={item.id}
                  index={index}
                  item={item}
                  isAdding={isAdding}
                  congressId={congressId}
                  closeDrawer={closeDrawer}
                />
              ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  )
}

MenuItems.propTypes = {
  items: PropTypes.array.isRequired,
  setItems: PropTypes.func.isRequired,
  isAdding: PropTypes.bool.isRequired,
  congressId: PropTypes.string,
  closeDrawer: PropTypes.func.isRequired,
}

export default MenuItems
