import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { v4 as uuidv4 } from 'uuid'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { captureException } from '@sentry/browser'

// Utils
import { sortItemsByOrder, indicesToOrders } from '../../../../utils/functions'

// MUI
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListSubheader,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/PlaylistAdd'

// Elements
import Menu from './Menu'

// Cosmetics
import DrawerLoading from '../../../cosmetics/loadings/DrawerLoading'
import FullscreenLoading from '../../../cosmetics/loadings/FullscreenLoading'

// Dialogs
import NamingDialog from '../../../dialogs/NamingDialog'

// Queries & Mutations
import { query, mutation } from '../../../../lib/apollo-client'

const Menus = props => {
  const { congressId, closeDrawer } = props
  const classes = styles()
  const { enqueueSnackbar } = useSnackbar()

  // Local state
  const [sidebars, setSidebars] = useState([])
  const [menus, setMenus] = useState([])

  // New sidebar dialog
  const [sidebarNamingOpen, setSidebarNamingOpen] = useState(false)
  const handleSidebarNamingOpen = () => setSidebarNamingOpen(true)
  const handleSidebarNamingClose = () => setSidebarNamingOpen(false)

  // New menu dialog
  const [menuNamingOpen, setMenuNamingOpen] = useState(false)
  const handleMenuNamingOpen = () => setMenuNamingOpen(true)
  const handleMenuNamingClose = () => setMenuNamingOpen(false)

  // Data
  const { data, error, loading } = useQuery(query.drawer.MENUS, {
    variables: { congress_id: congressId },
  })

  useEffect(() => {
    if (loading) {
      return
    }

    if (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      })
      captureException(error)
    }

    if (data && data.menus) {
      // separate sidebars and menus
      let sidebars = []
      let menus = []

      data.menus.forEach(menu => {
        if (menu.is_sidebar) {
          sidebars.push(menu)
        } else {
          menus.push(menu)
        }
      })

      // sort menus
      sortItemsByOrder(menus)
      indicesToOrders(menus)

      // set sidebars + menus
      setSidebars(sidebars)
      setMenus(menus)
    }
  }, [data, error, loading, enqueueSnackbar])

  const [updateMenuOrder] = useMutation(mutation.drawer.UPDATE_MENU_ORDER)
  const [addMenu, addMenuMutationData] = useMutation(mutation.drawer.ADD_MENU, {
    update(cache, { data: { addMenu } }) {
      const { menus } = cache.readQuery({
        query: query.drawer.MENUS,
        variables: { congress_id: congressId },
      })

      cache.writeQuery({
        query: query.drawer.MENUS,
        variables: { congress_id: congressId },
        data: { menus: menus.concat([addMenu]) },
      })
    },
  })

  /**
   * Sync order of sidebars with DB.
   */
  useEffect(() => {
    ;(async () => {
      await Promise.all(
        sidebars.map(async sidebar => {
          try {
            await updateMenuOrder({
              variables: { id: sidebar.id, order: -1 },
            })
          } catch (error) {
            enqueueSnackbar('Error: error during updating menu order!', {
              variant: 'error',
            })
            captureException(error)
          }
        })
      )
    })()
  }, [sidebars, updateMenuOrder, enqueueSnackbar])

  /**
   * Sync order of menus with DB.
   */
  useEffect(() => {
    ;(async () => {
      await Promise.all(
        menus.map(async menu => {
          try {
            await updateMenuOrder({
              variables: { id: menu.id, order: menu.order },
            })
          } catch (error) {
            enqueueSnackbar('Error: error during updating menu order!', {
              variant: 'error',
            })
            captureException(error)
          }
        })
      )
    })()
  }, [menus, updateMenuOrder, enqueueSnackbar])

  /**
   * Adds new menu.
   *
   * @param {boolean} is_sidebar
   * @param {string} name
   */
  const addNewMenu = async (is_sidebar, name) => {
    const values = {
      id: uuidv4(),
      congress_id: congressId ? congressId : null,
      title: name,
      order: is_sidebar ? -1 : menus.length,
      type: 'LIST',
      is_sidebar,
    }

    try {
      await addMenu({
        variables: values,
        optimisticResponse: {
          __typename: 'Mutation',
          addMenu: {
            ...values,
            items: [],
            __typename: 'Menu',
          },
        },
      })
    } catch (error) {
      enqueueSnackbar('Error: error during adding menu!', { variant: 'error' })
      captureException(error)
    }
  }

  /**
   * 1. Update indices of items.
   * 2. Update their order based on the updated indices.
   * 3. Update local state.
   *
   * @param result
   */
  const onDragEnd = result => {
    const { source, destination } = result

    if (!destination) {
      return
    }

    if (source.index === destination.index) {
      return
    }

    const newMenus = Array.from(menus)
    const [moved] = newMenus.splice(source.index, 1)
    newMenus.splice(destination.index, 0, moved)

    indicesToOrders(newMenus)

    setMenus(newMenus)
  }

  if (loading) {
    return <DrawerLoading />
  }

  return (
    <>
      {addMenuMutationData.loading && (
        <FullscreenLoading variant="white" transparent />
      )}

      {/* SIDEBARS */}
      <List subheader={<ListSubheader>{'Sidebars'}</ListSubheader>}>
        <ListItem button onClick={handleSidebarNamingOpen}>
          <ListItemIcon className={classes.drawerItemLogo}>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary={'New sidebar'} />
        </ListItem>
      </List>

      <NamingDialog
        subject="sidebar"
        open={sidebarNamingOpen}
        onClose={handleSidebarNamingClose}
        onSubmit={name => addNewMenu(true, name)}
        maxLength={100}
      />

      {sidebars.length > 0 && (
        <>
          <List>
            {sidebars.map((menu, index) => (
              <Menu
                key={menu.id}
                index={index}
                menu={menu}
                closeDrawer={closeDrawer}
                congressId={congressId}
                isAdding={addMenuMutationData.loading}
              />
            ))}
          </List>
          <Divider />
        </>
      )}

      {/* MENUS */}
      <List subheader={<ListSubheader>{'Menus'}</ListSubheader>}>
        <ListItem button onClick={handleMenuNamingOpen}>
          <ListItemIcon className={classes.drawerItemLogo}>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary={'New menu'} />
        </ListItem>
      </List>

      <NamingDialog
        subject="menu"
        open={menuNamingOpen}
        onClose={handleMenuNamingClose}
        onSubmit={name => addNewMenu(false, name)}
        maxLength={100}
      />

      {menus.length > 0 && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="menusDnd">
            {provided => (
              <List innerRef={provided.innerRef} {...provided.droppableProps}>
                {menus.map((menu, index) => (
                  <Menu
                    key={menu.id}
                    index={index}
                    menu={menu}
                    closeDrawer={closeDrawer}
                    congressId={congressId}
                    isAdding={addMenuMutationData.loading}
                  />
                ))}

                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  )
}

Menus.propTypes = {
  congressId: PropTypes.string,
  closeDrawer: PropTypes.func.isRequired,
}

const styles = makeStyles(theme => ({
  drawerItemLogo: {
    color: theme.palette.primary.main,
    minWidth: '40px',
  },
}))

export default Menus
