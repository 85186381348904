import gql from 'graphql-tag'

export default {
  UPDATE_QUESTION: gql`
    mutation UPDATE_QUESTION($id: ID!, $answered: Boolean, $flagged: Boolean) {
      updateQuestion(id: $id, answered: $answered, flagged: $flagged) {
        id
        answered
        flagged
        __typename
      }
    }
  `,
}
