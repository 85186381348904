import React from 'react'
import PropTypes from 'prop-types'

// Quill.js
import ReactQuill, { Quill } from 'react-quill'
import ImageUploader from 'quill-image-uploader'
import 'react-quill/dist/quill.snow.css'
import './quill.css'

// configure Quill to use inline styles
const DirectionAttribute = Quill.import('attributors/attribute/direction')
Quill.register(DirectionAttribute, true)
const AlignClass = Quill.import('attributors/class/align')
Quill.register(AlignClass, true)
const BackgroundClass = Quill.import('attributors/class/background')
Quill.register(BackgroundClass, true)
const ColorClass = Quill.import('attributors/class/color')
Quill.register(ColorClass, true)
const DirectionClass = Quill.import('attributors/class/direction')
Quill.register(DirectionClass, true)
const FontClass = Quill.import('attributors/class/font')
Quill.register(FontClass, true)
const SizeClass = Quill.import('attributors/class/size')
Quill.register(SizeClass, true)
const AlignStyle = Quill.import('attributors/style/align')
Quill.register(AlignStyle, true)
const BackgroundStyle = Quill.import('attributors/style/background')
Quill.register(BackgroundStyle, true)
const ColorStyle = Quill.import('attributors/style/color')
Quill.register(ColorStyle, true)
const DirectionStyle = Quill.import('attributors/style/direction')
Quill.register(DirectionStyle, true)
const FontStyle = Quill.import('attributors/style/font')
Quill.register(FontStyle, true)
const SizeStyle = Quill.import('attributors/style/size')
Quill.register(SizeStyle, true)

// Plugins
Quill.register('modules/imageUploader', ImageUploader)

class QuillEditor extends React.Component {
  modules = {
    toolbar: [
      [
        {
          header: [1, 2, 3, 4, 5, 6, false],
        },
      ],
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],

      [{ script: 'sub' }, { script: 'super' }],

      [{ color: [] }, { background: [] }],

      [{ align: [] }],
      [{ indent: '-1' }, { indent: '+1' }],

      [{ list: 'ordered' }, { list: 'bullet' }],

      ['link'],
      ['image'],

      ['clean'],
    ],
    clipboard: {
      matchVisual: false,
    },
    imageUploader: {
      upload: this.props.uploadHandler,
    },
  }

  render() {
    const { value, onChange } = this.props

    return (
      <ReactQuill
        theme="snow"
        placeholder="Start writing..."
        modules={this.modules}
        value={value}
        onChange={onChange}
      />
    )
  }
}

QuillEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  uploadHandler: PropTypes.func.isRequired,
}

export default QuillEditor
