import React from 'react'
import Helmet from 'react-helmet'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'

// Components
import ContentDrawer from '../../../components/drawers/ContentDrawer'
import TopicForm from '../../../components/forms/Topic/TopicForm'

// Cosmetics
import FullscreenLoading from '../../../components/cosmetics/loadings/FullscreenLoading'

// Queries & Mutations
import { query } from '../../../lib/apollo-client'

const Topic = props => {
  const { match } = props
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const [isFormDrawerOpen, setIsFormDrawerOpen] = React.useState(false)

  // Data
  const { data, error, loading } = useQuery(query.form.topic.TOPIC, {
    variables: { id: match.params.topicId },
  })
  React.useEffect(() => {
    if (loading) return

    if (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
      captureException(error)
    }

    if (data && data.topic) {
      setIsFormDrawerOpen(true)
    }
  }, [data, error, loading, enqueueSnackbar])

  const closeDrawer = () => {
    setIsFormDrawerOpen(false)

    setTimeout(() => {
      history.push(`/congress/${match.params.congressId}`)
    }, 500)
  }

  if (loading) {
    return <FullscreenLoading variant="white" transparent />
  }

  return (
    <>
      <Helmet>
        <title>{'Edit section'}</title>
      </Helmet>

      <ContentDrawer open={isFormDrawerOpen} onClose={closeDrawer}>
        {data && data.topic && (
          <TopicForm topic={data.topic} congressId={match.params.congressId} />
        )}
      </ContentDrawer>
    </>
  )
}

export default Topic
