import React from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from 'react-apollo'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'
import { v4 as uuidv4 } from 'uuid'

// Components
import ContentForm from './forms/ContentForm'
import LinkForm from './forms/LinkForm'
import PollForm from './forms/PollForm'

// Cosmetics
import ContentLoading from '../../../cosmetics/loadings/ContentLoading'

// Queries & Mutations
import { query, mutation } from '../../../../lib/apollo-client'

const EditNodeDialog = props => {
  const { congressId, dataItemId, dataItemType, closeContentDialog } = props
  const { enqueueSnackbar } = useSnackbar()

  const [node, setNode] = React.useState(null)

  // Data
  const [addDataNode, addDataNodeMutation] = useMutation(
    mutation.form.menu.ADD_DATA_NODE,
    {
      update(cache, { data: { addDataNode } }) {
        const { dataNodes } = cache.readQuery({
          query: query.form.menu.DATA_NODES,
          variables: { data_item_id: dataItemId },
        })

        cache.writeQuery({
          query: query.form.menu.DATA_NODES,
          variables: { data_item_id: dataItemId },
          data: { dataNodes: dataNodes.concat([addDataNode]) },
        })
      },
    }
  )

  const { data, error, loading } = useQuery(query.form.menu.DATA_NODES, {
    variables: { data_item_id: dataItemId },
  })
  React.useEffect(() => {
    if (loading) return

    if (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      })
      captureException(error)
      return
    }

    if (data) {
      if (Array.isArray(data.dataNodes) && data.dataNodes.length > 0) {
        setNode(data.dataNodes[0])
      } else {
        ;(async function () {
          try {
            await addDataNode({
              variables: {
                id: uuidv4(),
                data_item_id: dataItemId,
                data: null,
              },
            })
          } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' })
            captureException(error)
          }
        })()
      }
    }
  }, [data, error, loading, enqueueSnackbar, addDataNode, dataItemId])

  if (loading || addDataNodeMutation.loading) {
    return <ContentLoading />
  }

  return (
    <>
      {node && (
        <>
          {dataItemType === 'CONTENT' && (
            <ContentForm node={node} closeContentDialog={closeContentDialog} />
          )}

          {dataItemType === 'LINK' && (
            <LinkForm node={node} closeContentDialog={closeContentDialog} />
          )}

          {dataItemType === 'POLL' && (
            <PollForm
              congressId={congressId}
              node={node}
              closeContentDialog={closeContentDialog}
            />
          )}
        </>
      )}
    </>
  )
}

EditNodeDialog.propTypes = {
  congressId: PropTypes.string,
  dataItemId: PropTypes.string.isRequired,
  dataItemType: PropTypes.string.isRequired,
  closeContentDialog: PropTypes.func.isRequired,
}

export default EditNodeDialog
