import React from 'react'
import PropTypes from 'prop-types'
import { Formik, FastField } from 'formik'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'
import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'
import { useHistory } from 'react-router-dom'

import { removeNullProps } from '../../../utils/functions'

// Utils
import FormikTextField from '../../utils/FormikTextField'

// MUI
import {
  Grid,
  FormControlLabel,
  Switch,
  Paper,
  Typography,
  Divider,
  useTheme,
  Button,
} from '@material-ui/core'

// Layout
import FormContainer from '../elements/layout/FormContainer'
import FormFooter from '../elements/layout/FormFooter'

// Components
import QA from '../../QA'

// Elements
import DrawerTitle from '../../drawers/elements/DrawerTitle'
import TimestampPicker from '../elements/inputs/TimestampPicker'

// Cosmetics
import FullscreenLoading from '../../cosmetics/loadings/FullscreenLoading'
import InformationBlock from '../../cosmetics/UI/InformationBlock'

// Dialogs
import ConfirmationDialog from '../../dialogs/ConfirmationDialog'
import ContentDialog from '../../dialogs/ContentDialog'

// Queries & Mutations
import { query, mutation } from '../../../lib/apollo-client'

const FORM_DISABLED = false

const initialValues = {
  name: '',
  name2: '',
  name3: '',
  short_name: '',
  description: '',
  from: Math.floor(Date.now() / 1000),
  to: Math.floor(Date.now() / 1000),
  chair_label: '',
  chair_free: '',
  discussant: '',
  room: '',
  room_rank: 0,
  show_break_after: false,
  break_name: '',
  break_time: 0,
  show_discussion_after: false,
  discussion_name: '',
  discussion_time: 0,
  poster: false,
  section_group: '',
  track: '',
  download_file: '',
  link: '',
  logo: '',
}

const schema = Yup.object().shape({
  name: Yup.string().required().max(600),
  name2: Yup.string().max(300),
  name3: Yup.string(),
  short_name: Yup.string().max(30),
  description: Yup.string(),
  from: Yup.number()
    .integer()
    .required()
    .lessThan(
      Yup.ref('to'),
      'section start date should be earlier than end date'
    ),
  to: Yup.number()
    .integer()
    .required()
    .moreThan(
      Yup.ref('from'),
      'section end date should be later than start date'
    ),
  chair_label: Yup.string().max(20),
  chair_free: Yup.string().max(400),
  discussant: Yup.string().max(100),
  room: Yup.string().max(50),
  room_rank: Yup.number().integer().moreThan(-1).required(),
  show_break_after: Yup.boolean().required(),
  break_name: Yup.string().max(200),
  break_time: Yup.number().integer().moreThan(-1).nullable(true),
  show_discussion_after: Yup.boolean().required(),
  discussion_name: Yup.string().max(500),
  discussion_time: Yup.number().integer().moreThan(-1).nullable(true),
  poster: Yup.boolean().required(),
  section_group: Yup.string().max(20),
  track: Yup.string().max(20),
  download_file: Yup.string(),
  link: Yup.string(),
  logo: Yup.string().max(100),
})

const SectionForm = props => {
  const { section, congressId } = props
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const isNewSection = !section

  const [deleteConfirmOpen, setDeleteConfirmOpen] = React.useState(false)
  const handleConfirmationOpen = () => setDeleteConfirmOpen(true)
  const handleConfirmationClose = () => setDeleteConfirmOpen(false)

  const [qaDialogOpen, setQaDialogOpen] = React.useState(false)
  const handleQaDialogOpen = () => setQaDialogOpen(true)
  const handleQaDialogClose = () => setQaDialogOpen(false)

  // Data
  const [updateSection, updateSectionMutation] = useMutation(
    mutation.form.section.UPDATE_SECTION
  )
  const [addSection, addSectionMutation] = useMutation(
    mutation.form.section.ADD_SECTION,
    {
      refetchQueries: [
        {
          query: query.polls.SECT_LIST,
          variables: { congress_id: congressId },
        },
      ],
    }
  )
  const [deleteSection, deleteSectionMutation] = useMutation(
    mutation.form.section.DELETE_SECTION,
    {
      refetchQueries: [
        {
          query: query.polls.SECT_LIST,
          variables: { congress_id: congressId },
        },
      ],
    }
  )

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    values.room_rank = Number(values.room_rank)
    values.break_time = Number(values.break_time)
    values.discussion_time = Number(values.discussion_time)

    try {
      if (isNewSection) {
        const sectionId = uuidv4()

        await addSection({
          variables: {
            id: sectionId,
            congress_id: congressId,
            ...values,
          },
        })

        history.push(`/congress/${congressId}/section/${sectionId}`)
      } else {
        await updateSection({
          variables: {
            ...values,
          },
        })

        resetForm(values)
        setSubmitting(false)
      }
    } catch (error) {
      enqueueSnackbar(
        error.message ? error.message : 'Error: error during saving section!',
        { variant: 'error' }
      )
      captureException(error)

      setSubmitting(false)
    }
  }

  const handleDelete = async () => {
    if (!section) {
      enqueueSnackbar('Could not delete non-existent section!', {
        variant: 'error',
      })
      captureException('Could not delete non-existent section!')
      return
    }

    try {
      await deleteSection({
        variables: { id: section.id },
      })

      history.push(`/congress/${congressId}`)
    } catch (error) {
      enqueueSnackbar(
        error.message ? error.message : 'Error: error during deleting section!',
        { variant: 'error' }
      )
      captureException(error)
    }
  }

  removeNullProps(section)
  const formData = {
    ...initialValues,
    ...section,
  }

  return (
    <>
      {(updateSectionMutation.loading ||
        deleteSectionMutation.loading ||
        addSectionMutation.loading) && (
        <FullscreenLoading variant="white" transparent />
      )}

      <Formik
        enableReinitialize
        initialValues={formData}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, errors, isSubmitting, dirty }) => (
          <>
            <FormContainer elevation={0}>
              <DrawerTitle
                title={isNewSection ? 'Create new section' : 'Edit section'}
              />

              <Grid container spacing={2}>
                {!isNewSection && (
                  <>
                    {/* RATING COUNT */}
                    <Grid item xs={12} sm={6}>
                      <Paper
                        variant="outlined"
                        style={{
                          padding: theme.spacing(2),
                        }}
                      >
                        <Typography align="center" gutterBottom>
                          {'Total ratings'}
                        </Typography>
                        <Typography variant="h4" align="center" noWrap>
                          {values.ratingCount}
                        </Typography>
                      </Paper>
                    </Grid>

                    {/* RATING AVG */}
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        marginBottom: theme.spacing(2),
                      }}
                    >
                      <Paper
                        variant="outlined"
                        style={{
                          padding: theme.spacing(2),
                        }}
                      >
                        <Typography align="center" gutterBottom>
                          {'Rating average'}
                        </Typography>
                        <Typography variant="h4" align="center" noWrap>
                          {values.ratingAvg}
                        </Typography>
                      </Paper>
                    </Grid>
                  </>
                )}

                {!isNewSection && (
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginBottom: theme.spacing(2),
                    }}
                  >
                    <Button
                      onClick={handleQaDialogOpen}
                      variant="outlined"
                      color="primary"
                      size="large"
                      fullWidth
                    >
                      {'View Q&A session'}
                    </Button>
                  </Grid>
                )}

                {/* NAME */}
                <Grid item xs={12}>
                  <FastField
                    name="name"
                    type="text"
                    label="Name"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                {/* NAME 2 */}
                <Grid item xs={12}>
                  <FastField
                    name="name2"
                    type="text"
                    label="Name 2"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                {/* NAME 3 */}
                <Grid item xs={12}>
                  <FastField
                    name="name3"
                    type="text"
                    label="Name 3"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                {/* SHORT NAME */}
                <Grid item xs={12}>
                  <FastField
                    name="short_name"
                    type="text"
                    label="Short name"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                {/* DESCRIPTION */}
                <Grid item xs={12}>
                  <FastField
                    name="description"
                    type="text"
                    label="Description"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                {/* FROM */}
                <Grid item xs={12} md={6}>
                  <TimestampPicker
                    label="Starts from"
                    labelVariant="body1"
                    time
                    timestamp={values.from}
                    onChange={timestamp => setFieldValue('from', timestamp)}
                    error={Boolean(errors.from)}
                    errorText={Boolean(errors.from) ? errors.from : undefined}
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                {/* TO */}
                <Grid item xs={12} md={6}>
                  <TimestampPicker
                    label="Ends at"
                    labelVariant="body1"
                    time
                    timestamp={values.to}
                    onChange={timestamp => setFieldValue('to', timestamp)}
                    error={Boolean(errors.to)}
                    errorText={Boolean(errors.to) ? errors.to : undefined}
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                <Grid item xs={12}>
                  <InformationBlock variant="warning" size="small">
                    <Typography
                      style={{
                        fontWeight: theme.typography.fontWeightBold,
                      }}
                    >
                      {`All dates are displayed in your browser's current timezone!`}
                    </Typography>
                  </InformationBlock>
                </Grid>

                <Grid item xs={12}>
                  <Divider
                    style={{
                      marginTop: theme.spacing(2),
                      marginBottom: theme.spacing(2),
                    }}
                  />
                </Grid>

                {/* CHAIR LABEL */}
                <Grid item xs={12} md={6}>
                  <FastField
                    name="chair_label"
                    type="text"
                    label="Chair Label"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                {/* CHAIR FREE */}
                <Grid item xs={12} md={6}>
                  <FastField
                    name="chair_free"
                    type="text"
                    label="Chair Free"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                {/* DISCUSSANT */}
                <Grid item xs={12}>
                  <FastField
                    name="discussant"
                    type="text"
                    label="Discussant"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider
                    style={{
                      marginTop: theme.spacing(2),
                      marginBottom: theme.spacing(2),
                    }}
                  />
                </Grid>

                {/* ROOM */}
                <Grid item xs={12} md={6}>
                  <FastField
                    name="room"
                    type="text"
                    label="Room"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                {/* ROOM RANK */}
                <Grid item xs={12} md={6}>
                  <FastField
                    name="room_rank"
                    type="text"
                    label="Room rank"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider
                    style={{
                      marginTop: theme.spacing(2),
                      marginBottom: theme.spacing(2),
                    }}
                  />
                </Grid>

                {/* SHOW BREAK AFTER */}
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.show_break_after}
                        name="show_break_after"
                        onChange={e => {
                          setFieldValue('show_break_after', e.target.checked)
                        }}
                        color="primary"
                      />
                    }
                    label="Show break after?"
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                {/* BREAK NAME */}
                <Grid item xs={12} md={6}>
                  <FastField
                    name="break_name"
                    type="text"
                    label="Break name"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                {/* BREAK TIME */}
                <Grid item xs={12} md={6}>
                  <FastField
                    name="break_time"
                    type="text"
                    label="Break time"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                {/* SHOW DISCUSSION AFTER */}
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.show_discussion_after}
                        name="show_discussion_after"
                        onChange={e => {
                          setFieldValue(
                            'show_discussion_after',
                            e.target.checked
                          )
                        }}
                        color="primary"
                      />
                    }
                    label="Show discussion after?"
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                {/* DISCUSSION NAME */}
                <Grid item xs={12} md={6}>
                  <FastField
                    name="discussion_name"
                    type="text"
                    label="Discussion name"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                {/* DISCUSSION TIME */}
                <Grid item xs={12} md={6}>
                  <FastField
                    name="discussion_time"
                    type="text"
                    label="Discussion time"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider
                    style={{
                      marginTop: theme.spacing(2),
                      marginBottom: theme.spacing(2),
                    }}
                  />
                </Grid>

                {/* POSTER */}
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.poster}
                        name="poster"
                        onChange={e => {
                          setFieldValue('poster', e.target.checked)
                        }}
                        color="primary"
                      />
                    }
                    label="Poster"
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                {/* SECTION GROUP */}
                <Grid item xs={12} md={6}>
                  <FastField
                    name="section_group"
                    type="text"
                    label="Section group"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                {/* TRACK */}
                <Grid item xs={12} md={6}>
                  <FastField
                    name="track"
                    type="text"
                    label="Track"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                {/* DOWNLOAD FILE */}
                <Grid item xs={12}>
                  <FastField
                    name="download_file"
                    type="text"
                    label="Download file"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                {/* LINK */}
                <Grid item xs={12}>
                  <FastField
                    name="link"
                    type="text"
                    label="Link"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>

                {/* LOGO */}
                <Grid item xs={12}>
                  <FastField
                    name="logo"
                    type="text"
                    label="Logo"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                    disabled={FORM_DISABLED}
                  />
                </Grid>
              </Grid>

              <FormFooter
                isSubmitting={isSubmitting}
                dirty={dirty}
                save={!isNewSection}
                onDelete={!isNewSection ? handleConfirmationOpen : null}
              />
            </FormContainer>

            {!isNewSection && (
              <ContentDialog
                title={`Section Q&A session`}
                open={qaDialogOpen}
                onClose={handleQaDialogClose}
                scroll={false}
              >
                <QA type="SECTION" subjectId={section.id} />
              </ContentDialog>
            )}

            <ConfirmationDialog
              title="Delete section"
              description="Are you sure you would like to delete this section? This action is irreversible."
              returnLabel="Cancel"
              confirmLabel="Delete"
              onReturn={handleConfirmationClose}
              onConfirm={() => {
                handleConfirmationClose()
                handleDelete()
              }}
              open={deleteConfirmOpen}
            />
          </>
        )}
      </Formik>
    </>
  )
}

SectionForm.propTypes = {
  congressId: PropTypes.string.isRequired,
  section: PropTypes.object,
}

export default SectionForm
