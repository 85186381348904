import React from 'react'
import ContentLoader from 'react-content-loader'

const SelectLoading = () => {
  return (
    <ContentLoader height={55} style={{ width: '100%' }} speed={2}>
      <rect x="0" y="0" rx="0" ry="0" height="55" style={{ width: '100%' }} />
    </ContentLoader>
  )
}

export default SelectLoading
