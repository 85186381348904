import gql from 'graphql-tag'

export default {
  SETTINGS: gql`
    query SETTINGS {
      settings {
        key
        value
      }
    }
  `,
}
