import React from 'react'
import Helmet from 'react-helmet'
import { useQuery } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'

// MUI
import GeneralIcon from '@material-ui/icons/Settings'
import QuestionIcon from '@material-ui/icons/Help'
import CodeIcon from '@material-ui/icons/DeveloperMode'
import ResultsIcon from '@material-ui/icons/Equalizer'

// Components
import SubHeader from '../../components/SubHeader'
import { ResponsiveTab } from '../../components/SubHeader'

// Tabs
import PollSettingsGeneral from './tabs/PollSettingsGeneral'
import PollSettingsQuestions from './tabs/PollSettingsQuestions'
import PollSettingsEntryCode from './tabs/PollSettingsEntryCode'
import PollSettingsResults from './tabs/PollSettingsResults'

// Cosmetics
import FullscreenLoading from '../../components/cosmetics/loadings/FullscreenLoading'

// Queries & Mutations
import { query } from '../../lib/apollo-client'

const PollSettings = props => {
  const { match } = props
  const { enqueueSnackbar } = useSnackbar()

  const [tabValue, setTabValue] = React.useState(0)

  // Data
  const { data, error, loading, refetch } = useQuery(query.polls.POLL, {
    variables: { id: match.params.pollId },
  })

  React.useEffect(() => {
    if (loading) {
      return
    }

    if (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      })
      captureException(error)
    }
  }, [data, error, loading, enqueueSnackbar])

  const handleTabChange = (_, value) => {
    setTabValue(value)
  }

  if (loading) {
    return <FullscreenLoading variant="white" transparent />
  }

  return (
    <>
      <Helmet>
        <title>{'Edit poll'}</title>
      </Helmet>

      <SubHeader value={tabValue} onChange={handleTabChange}>
        <ResponsiveTab label="General settings" icon={<GeneralIcon />} />
        <ResponsiveTab label="Question settings" icon={<QuestionIcon />} />
        <ResponsiveTab label="Entry code" icon={<CodeIcon />} />
        <ResponsiveTab label="Poll results" icon={<ResultsIcon />} />
      </SubHeader>

      {data && data.poll && (
        <>
          {tabValue === 0 && <PollSettingsGeneral poll={data.poll} />}

          {tabValue === 1 && (
            <PollSettingsQuestions poll={data.poll} refetchPoll={refetch} />
          )}

          {tabValue === 2 && <PollSettingsEntryCode poll={data.poll} />}

          {tabValue === 3 && <PollSettingsResults pollId={data.poll.id} />}
        </>
      )}
    </>
  )
}

export default PollSettings
