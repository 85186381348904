import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

// MUI
import { Drawer, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/ArrowBack'

const ContentDrawer = props => {
  const { open, onClose } = props
  const classes = styles(props)

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={open}
      onClose={onClose}
      classes={{ paper: classes.drawerPaper }}
    >
      <div className={classes.navigation}>
        <IconButton color="secondary" onClick={onClose}>
          <CloseIcon />
        </IconButton>

        {props.navigationComponent}
      </div>

      <div>{props.children}</div>
    </Drawer>
  )
}

ContentDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  navigationComponent: PropTypes.node,
}

const styles = makeStyles(theme => ({
  drawerPaper: {
    width: 850,
    [theme.breakpoints.down('lg')]: {
      width: 650,
    },
    [theme.breakpoints.down('sm')]: {
      width: 450,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  navigation: props => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: props.navigationComponent ? 'space-between' : 'flex-start',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(4),
  }),
}))

export default ContentDrawer
