import gql from 'graphql-tag'

export default {
  ADD_TOPIC: gql`
    mutation ADD_TOPIC($id: ID!, $congress_id: ID!, $name: String!) {
      addTopic(id: $id, congress_id: $congress_id, name: $name) {
        id
        congress_id
        name
        __typename
      }
    }
  `,
  ADD_FLOORPLAN: gql`
    mutation ADD_FLOORPLAN($id: ID!, $congress_id: ID!, $name: String!) {
      addFloorplan(id: $id, congress_id: $congress_id, name: $name) {
        id
        congress_id
        name
        __typename
      }
    }
  `,
  ADD_SPONSOR_CATEGORY: gql`
    mutation ADD_SPONSOR_CATEGORY($id: ID!, $congress_id: ID!, $name: String!) {
      addSponsorCategory(id: $id, congress_id: $congress_id, name: $name) {
        id
        congress_id
        name
        order
        __typename
      }
    }
  `,
  UPDATE_SPONSOR_CATEGORY: gql`
    mutation UPDATE_SPONSOR_CATEGORY($id: ID!, $name: String!) {
      updateSponsorCategory(id: $id, name: $name) {
        id
        name
        __typename
      }
    }
  `,
  DELETE_SPONSOR_CATEGORY: gql`
    mutation DELETE_SPONSOR_CATEGORY($id: ID!) {
      deleteSponsorCategory(id: $id) {
        id
        __typename
      }
    }
  `,
  ADD_SPONSOR: gql`
    mutation ADD_SPONSOR(
      $id: ID!
      $congress_id: ID!
      $name: String!
      $sponsor_category_id: ID!
    ) {
      addSponsor(
        id: $id
        congress_id: $congress_id
        name: $name
        sponsor_category_id: $sponsor_category_id
      ) {
        id
        name
        order
        __typename
      }
    }
  `,
  UPDATE_SPONSOR_CATEGORY_ORDER: gql`
    mutation UPDATE_SPONSOR_CATEGORY_ORDER($id: ID!, $order: Int!) {
      updateSponsorCategoryOrder(id: $id, order: $order) {
        id
        order
        __typename
      }
    }
  `,
  UPDATE_SPONSOR_ORDER: gql`
    mutation UPDATE_SPONSOR_ORDER($id: ID!, $order: Int!) {
      updateSponsorOrder(id: $id, order: $order) {
        id
        order
        __typename
      }
    }
  `,
}
