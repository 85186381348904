import drawer from './drawer'
import form from './form'
import table from './table'
import assetStore from './assetStore'
import oneSignal from './oneSignal'
import polls from './polls'
import ads from './ads'
import settings from './settings'
import qa from './qa'

export default {
  drawer,
  form,
  table,
  assetStore,
  oneSignal,
  polls,
  ads,
  settings,
  qa,
}
