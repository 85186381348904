import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLazyQuery } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { dotify } from '../../utils/functions'

import { Button } from '@material-ui/core'

// Components
import ServerSideTable from '../../components/tables/ServerSideTable'

// Queries & Mutations
import { query } from '../../lib/apollo-client'

const LIMIT = 10
const ORDER_BY = 'from'
const ORDER = 'ASC'

const Abstracts = ({ congressId }) => {
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  // Data
  const [fetchAbstracts, { data, error, loading }] = useLazyQuery(
    query.table.ABSTRACTS_CONNECTION,
    {
      variables: {
        filter: {
          congress_id: congressId,
        },
        offset: 0,
        limit: LIMIT,
        orderBy: ORDER_BY,
        order: ORDER,
      },
    }
  )
  useEffect(() => {
    if (loading) {
      return
    }

    if (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      })
      captureException(error)
    }
  }, [data, error, loading, enqueueSnackbar])

  // Get initial data
  React.useEffect(() => {
    fetchAbstracts()
  }, [fetchAbstracts, congressId])

  /* Table Data */
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'title',
      },
      {
        Header: 'Starts at',
        accessor: 'from',
      },
      {
        Header: 'Ends at',
        accessor: 'to',
      },
      {
        Header: 'Presenter',
        accessor: 'presenter',
      },
      {
        Header: 'Topic',
        accessor: 'topicName',
      },
      {
        Header: 'Rating count',
        accessor: 'ratingCount',
      },
      {
        Header: 'Average rating',
        accessor: 'ratingAvg',
      },
    ],
    []
  )
  const tableData = React.useMemo(() => {
    if (!data || !data.abstractsConnection) {
      return []
    }
    const { edges } = data.abstractsConnection
    if (!Array.isArray(edges)) {
      return []
    }

    return edges.map(abstract => {
      const { title, from, to, topic, ...rest } = abstract

      return {
        title: title ? dotify(title, 20) : '',
        from: from
          ? moment.unix(Number(from)).format('YYYY. MM. DD., HH:mm')
          : '',
        to: to ? moment.unix(Number(to)).format('YYYY. MM. DD., HH:mm') : '',
        topicName: topic ? topic.name : '',
        ...rest,
      }
    })
  }, [data])
  const pageCount = React.useMemo(() => {
    if (!data || !data.abstractsConnection) {
      return 0
    }

    const { count } = data.abstractsConnection
    if (!count) return 0

    return Math.ceil(count / LIMIT)
  }, [data])
  const orderByOptions = React.useMemo(
    () => [
      { label: 'Name', value: 'title' },
      { label: 'Starts at', value: 'from' },
      { label: 'Ends at', value: 'to' },
      { label: 'Presenter', value: 'presenter' },
    ],
    []
  )
  /* Table Data */

  return (
    <ServerSideTable
      title={
        <Button
          onClick={() => history.push(`/congress/${congressId}/abstract/new`)}
          variant="contained"
          color="primary"
        >
          {'New abstract'}
        </Button>
      }
      onRowClick={row => {
        const {
          original: { id, congress_id },
        } = row

        history.push(`/congress/${congress_id}/abstract/${id}`)
      }}
      columns={columns}
      data={tableData}
      loading={loading}
      fetchData={fetchAbstracts}
      pageCount={pageCount}
      limit={LIMIT}
      defaultOrderBy={ORDER_BY}
      orderByOptions={orderByOptions}
      defaultOrder={ORDER}
      elevation={2}
    />
  )
}

Abstracts.propTypes = {
  congressId: PropTypes.string.isRequired,
}

export default Abstracts
