import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

// MUI
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const ContentDialog = props => {
  const { title, open, onClose, scroll } = props
  const classes = styles()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll={scroll ? 'paper' : undefined}
      aria-labelledby="content-dialog-title"
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle id="content-dialog-title">
        {title}

        <IconButton onClick={onClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        style={{
          overflowY: scroll ? 'auto' : 'hidden',
        }}
      >
        {props.children}
      </DialogContent>
    </Dialog>
  )
}

ContentDialog.defaultProps = {
  scroll: true,
}

ContentDialog.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  scroll: PropTypes.bool,
}

const styles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  dialog: {
    width: '900px',
    maxWidth: '900px',
  },
}))

export default ContentDialog
