import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Form, Formik, FastField } from 'formik'
import * as Yup from 'yup'

// MUI
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core'

// Elements
import FormikTextField from '../utils/FormikTextField'

const initialValues = {
  name: '',
}

const NamingDialog = props => {
  const { subject, open, onClose, onSubmit, maxLength } = props
  const classes = styles()

  const schema = Yup.object().shape({
    name: Yup.string().required().max(maxLength),
  })

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    onSubmit(values.name)

    resetForm(values)
    setSubmitting(false)

    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.dialog }}>
      <DialogTitle>{`Name of new ${subject ? subject : 'item'}`}</DialogTitle>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, dirty }) => (
          <Form noValidate>
            <DialogContent>
              <FastField
                name="name"
                type="text"
                label="Name"
                component={FormikTextField}
                variant="standard"
                fullWidth
                autoFocus
              />
            </DialogContent>

            <DialogActions className={classes.actions}>
              <Button onClick={onClose} variant="outlined" color="secondary">
                {'Cancel'}
              </Button>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting || !dirty}
              >
                {'Add'}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

NamingDialog.defaultProps = {
  maxLength: 500,
}

NamingDialog.propTypes = {
  subject: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  maxLength: PropTypes.number,
}

const styles = makeStyles(theme => ({
  dialog: {
    [theme.breakpoints.up('xs')]: {
      width: 425,
    },
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
}))

export default NamingDialog
