import PropTypes from 'prop-types'
import React from 'react'

// MUI
import { OpenAPI } from '../../services/openapi'

function MenuV2Iframe(props) {
  return (
    <iframe
      title="Congress Edit"
      src={`${process.env.REACT_APP_NEW_FRONTEND}/navigation?accessToken=${OpenAPI.TOKEN}&congressId=${props.congressId}`}
      style={{ height: '100vh', width: '100%', border: 0 }}
    />
  )
}

MenuV2Iframe.propTypes = {
  congressId: PropTypes.node.isRequired,
}

export { MenuV2Iframe }
