import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useAssetStore } from '../../AssetStore'

// MUI
import { Grid } from '@material-ui/core'

// Layout
import FormContainer from '../elements/layout/FormContainer'
import StepperFooter from '../elements/layout/StepperFooter'

// Elements
import ColorDemo from '../elements/ColorDemo'
import ColorPicker from '../elements/inputs/ColorPicker'
import IconPicker from '../elements/inputs/IconPicker'

const schema = Yup.object().shape({
  cover_id: Yup.string().required(),
  color: Yup.string().required(),
  text_color: Yup.string().required(),
})

const CosmeticSettings = props => {
  const {
    congress,
    setCongress,
    currentStep,
    maxStep,
    handleBack,
    handleNext,
  } = props
  const { handleAssetStoreOpen } = useAssetStore()

  const saveProgress = values => {
    setCongress(values)
  }

  const assetStoreHandler = (setFieldValue, type) => {
    switch (type) {
      case 'cover':
        handleAssetStoreOpen('IMG', selected => {
          if (!selected) {
            return
          }

          setFieldValue('cover_id', selected.id)
          setFieldValue('cover.id', selected.id)
          setFieldValue('cover.file_name', selected.file_name)
        })
        break
      default:
        return
    }
  }

  /**
   * Handles form submit.
   *
   * If there is an available next step:
   * 1. Sets 'NewCongressGuide' congress state.
   * 2. Resets form to new values.
   * 3. Goes to the next step's view.
   */
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    saveProgress(values)

    resetForm(values)
    setSubmitting(false)

    handleNext()
  }

  // generate form data
  const formData = {
    ...congress,
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, submitForm, values, setFieldValue, errors }) => (
        <>
          <FormContainer>
            <Grid container spacing={4}>
              {/* THUMBNAIL */}
              <Grid item xs={12} lg={4}>
                <IconPicker
                  label="Congress thumbnail"
                  icon={values.cover}
                  onChange={() => assetStoreHandler(setFieldValue, 'cover')}
                  error={Boolean(errors.cover_id) || Boolean(errors.cover)}
                  errorText={
                    Boolean(errors.cover_id) || Boolean(errors.cover)
                      ? 'Thumbnail is required!'
                      : undefined
                  }
                />
              </Grid>

              {/* COLOR */}
              <Grid item xs={12} sm={6} lg={4}>
                <ColorPicker
                  label="Congress color"
                  color={values.color || ''}
                  onChange={color => setFieldValue('color', color)}
                  error={Boolean(errors.color)}
                  errorText={
                    Boolean(errors.color) ? 'Color is required!' : undefined
                  }
                />
              </Grid>

              {/* TEXT COLOR */}
              <Grid item xs={12} sm={6} lg={4}>
                <ColorPicker
                  label="Congress text color"
                  color={values.text_color || ''}
                  onChange={color => setFieldValue('text_color', color)}
                  error={Boolean(errors.text_color)}
                  errorText={
                    Boolean(errors.text_color)
                      ? 'Text color is required!'
                      : undefined
                  }
                />
              </Grid>

              {/* COLOR DEMO */}
              <Grid item xs={12}>
                <ColorDemo
                  backgroundColor={values.color || '#808080'}
                  textColor={values.text_color || '#000000'}
                />
              </Grid>
            </Grid>
          </FormContainer>

          <StepperFooter
            currentStep={currentStep}
            maxStep={maxStep}
            handleBack={() => {
              saveProgress(values)
              handleBack()
            }}
            handleNext={submitForm}
            loading={isSubmitting}
          />
        </>
      )}
    </Formik>
  )
}

CosmeticSettings.propTypes = {
  congress: PropTypes.object,
  setCongress: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  maxStep: PropTypes.number.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
}

export default CosmeticSettings
