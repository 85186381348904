import createAuth0Client from '@auth0/auth0-spa-js'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { OpenAPI } from '../../services/openapi'

import { Auth0Context } from './context'

const Auth0Provider = ({ children, ...initOptions }) => {
  const history = useHistory()

  // A function that routes the user to the right place
  // after login
  const onRedirectCallback = appState => {
    window.history.replaceState(
      {},
      document.title,
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )

    // Redirect to proper route
    if (appState && appState.targetUrl) {
      history.push(appState.targetUrl)
    }
  }

  // Data
  const [isAuthenticated, setIsAuthenticated] = useState()
  const [user, setUser] = useState()
  const [auth0Client, setAuth0] = useState()
  const [loading, setLoading] = useState(true)
  const [token, setToken] = useState('')

  useEffect(() => {
    ;(async () => {
      const auth0FromHook = await createAuth0Client(initOptions)
      setAuth0(auth0FromHook)

      if (window.location.search.includes('code=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback()
        onRedirectCallback(appState)
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated()
      setIsAuthenticated(isAuthenticated)

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser()
        setUser(user)

        const token = await auth0FromHook.getTokenSilently()
        setToken(token)
      }

      setLoading(false)
    })()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (token) {
      OpenAPI.TOKEN = token
    }
  }, [token])

  const handleRedirectCallback = async () => {
    setLoading(true)

    await auth0Client.handleRedirectCallback()
    const user = await auth0Client.getUser()
    const token = await auth0Client.getTokenSilently()

    setLoading(false)
    setIsAuthenticated(true)
    setUser(user)
    setToken(token)
  }

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        token,
        loading,
        handleRedirectCallback,
        getIdTokenClaims: options => auth0Client.getIdTokenClaims(options),
        loginWithRedirect: options => auth0Client.loginWithRedirect(options),
        getTokenSilently: options => auth0Client.getTokenSilently(options),
        getTokenWithPopup: (options, config) =>
          auth0Client.getTokenWithPopup(options, config),
        logout: options => auth0Client.logout(options),
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}

export default Auth0Provider
