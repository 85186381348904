import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import { useAuth0 } from '../../lib/auth0'

// Cosmetics
import FullscreenLoading from '../cosmetics/loadings/FullscreenLoading'

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0()

  useEffect(() => {
    if (loading || isAuthenticated) {
      return
    }

    ;(async () => {
      await loginWithRedirect({
        appState: { targetUrl: path },
      })
    })()
  }, [loading, isAuthenticated, loginWithRedirect, path])

  return (
    <Route
      path={path}
      render={props =>
        isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <FullscreenLoading variant="primary" />
        )
      }
      {...rest}
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
}

export default PrivateRoute
