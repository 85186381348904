import React from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useQuery } from '@apollo/react-hooks'
import { captureException } from '@sentry/browser'
import { useSnackbar } from 'notistack'

// MUI
import {
  useTheme,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import CongressIcon from '@material-ui/icons/EventNote'
import ForwardArrow from '@material-ui/icons/ArrowForwardIos'

// Cosmetics
import DrawerLoading from '../../cosmetics/loadings/DrawerLoading'

// Queries & Mutations
import { query } from '../../../lib/apollo-client'

const CongressList = () => {
  const classes = styles()
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  // Data
  const { data, error, loading } = useQuery(query.drawer.CONGRESSES)

  React.useEffect(() => {
    if (loading) return

    if (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
      captureException(error)
    }
  }, [data, error, loading, enqueueSnackbar])

  return (
    <Paper className={classes.paper}>
      <Typography
        variant="h6"
        style={{
          marginBottom: theme.spacing(3),
        }}
      >
        {'Your congresses'}
      </Typography>

      {loading ? (
        <DrawerLoading />
      ) : (
        <List>
          {data &&
            Array.isArray(data.congresses) &&
            data.congresses.map(congress => (
              <ListItem
                key={congress.id}
                button
                onClick={() => history.push(`/congress/${congress.id}`)}
              >
                <ListItemIcon className={classes.logo}>
                  <CongressIcon />
                </ListItemIcon>

                <ListItemText primary={congress.name} />

                <ForwardArrow
                  style={{
                    color: theme.palette.primary.main,
                  }}
                />
              </ListItem>
            ))}
        </List>
      )}
    </Paper>
  )
}

const styles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3),
  },
  logo: {
    color: theme.palette.primary.main,
    minWidth: 40,
  },
}))

export default CongressList
