import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Formik, FastField } from 'formik'
import * as Yup from 'yup'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'

import { POLL_TYPES } from '../../../config'

// Utils
import FormikTextField from '../../utils/FormikTextField'

// MUI
import {
  useTheme,
  Typography,
  Grid,
  FormControlLabel,
  Switch,
  TextField,
  MenuItem,
} from '@material-ui/core'

// Layout
import FormContainer from '../elements/layout/FormContainer'
import StepperFooter from '../elements/layout/StepperFooter'

// Elements
import TimestampPicker from '../elements/inputs/TimestampPicker'

// Cosmetics
import InformationBlock from '../../cosmetics/UI/InformationBlock'

// Queries & Mutations
import { query } from '../../../lib/apollo-client'

const schema = Yup.object().shape({
  congress_id: Yup.string().nullable(true),
  section_id: Yup.string().nullable(true),
  abstract_id: Yup.string().nullable(true),
  title: Yup.string().required().max(200),
  type: Yup.string().required().max(100),
  are_results_public: Yup.boolean().required(),
  from: Yup.number()
    .integer()
    .required()
    .lessThan(Yup.ref('to'), 'poll start date should be earlier than end date'),
  to: Yup.number()
    .integer()
    .required()
    .moreThan(Yup.ref('from'), 'poll end date should be later than start date'),
})

const NewPollSettings = props => {
  const { poll, setPoll, currentStep, maxStep, handleBack, handleNext } = props
  const classes = styles()
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()

  const [congresses, setCongresses] = React.useState([])
  const [sections, setSections] = React.useState([])
  const [abstracts, setAbstracts] = React.useState([])

  // Data
  // CONGRESSES
  const congressQuery = useQuery(query.polls.CONG_LIST)
  React.useEffect(() => {
    if (congressQuery.loading) {
      return
    }

    if (congressQuery.error) {
      enqueueSnackbar(congressQuery.error.message, { variant: 'error' })
      captureException(congressQuery.error)
    }

    if (congressQuery.data && Array.isArray(congressQuery.data.congresses)) {
      setCongresses(congressQuery.data.congresses)
    }
  }, [congressQuery, enqueueSnackbar])

  // SECTIONS
  const [fetchSections, sectionQuery] = useLazyQuery(query.polls.SECT_LIST)
  React.useEffect(() => {
    if (sectionQuery.loading) {
      return
    }

    if (sectionQuery.error) {
      enqueueSnackbar(sectionQuery.error.message, { variant: 'error' })
      captureException(sectionQuery.error)
    }

    if (sectionQuery.data && Array.isArray(sectionQuery.data.sections)) {
      setSections(sectionQuery.data.sections)
    }
  }, [sectionQuery, enqueueSnackbar])

  // ABSTRACTS
  const [fetchAbstracts, abstractQuery] = useLazyQuery(query.polls.ABS_LIST)
  React.useEffect(() => {
    if (abstractQuery.loading) {
      return
    }

    if (abstractQuery.error) {
      enqueueSnackbar(abstractQuery.error.message, { variant: 'error' })
      captureException(abstractQuery.error)
    }

    if (
      abstractQuery.data &&
      Array.isArray(abstractQuery.data.sectionAbstracts)
    ) {
      setAbstracts(abstractQuery.data.sectionAbstracts)
    }
  }, [abstractQuery, enqueueSnackbar])

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    setPoll(values)

    resetForm(values)
    setSubmitting(false)

    handleNext()
  }

  const formData = {
    ...poll,
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, submitForm, values, setFieldValue, errors }) => (
        <>
          <FormContainer>
            <Grid container spacing={4}>
              {/* TITLE */}
              <Grid item xs={12} lg={6}>
                <Typography variant="h6" gutterBottom>
                  {'Poll title'}
                </Typography>

                <FastField
                  name="title"
                  type="text"
                  placeholder="Poll title"
                  component={FormikTextField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              {/* FROM */}
              <Grid item xs={12} sm={6} lg={3}>
                <TimestampPicker
                  label="Poll starts at"
                  time
                  timestamp={values.from}
                  onChange={timestamp => {
                    setFieldValue('from', timestamp)
                    setFieldValue('to', timestamp + 3600)
                  }}
                  error={Boolean(errors.from)}
                  errorText={Boolean(errors.from) ? errors.from : undefined}
                  fullWidth
                />
              </Grid>

              {/* TO */}
              <Grid item xs={12} sm={6} lg={3}>
                <TimestampPicker
                  label="Poll ends at"
                  time
                  timestamp={values.to}
                  onChange={timestamp => setFieldValue('to', timestamp)}
                  error={Boolean(errors.to)}
                  errorText={Boolean(errors.to) ? errors.to : undefined}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <InformationBlock variant="warning" size="small">
                  <Typography
                    style={{
                      fontWeight: theme.typography.fontWeightBold,
                    }}
                  >
                    {`All dates are displayed in your browser's current timezone!`}
                  </Typography>
                </InformationBlock>
              </Grid>

              {/* TYPE */}
              <Grid item xs={12} md={6}>
                <TextField
                  name="type"
                  type="text"
                  label="Poll type"
                  value={values.type}
                  onChange={e => {
                    setFieldValue('type', e.target.value)
                    if (e.target.value === 'EVALUATION') {
                      setFieldValue('are_results_public', false)
                    }
                  }}
                  variant="outlined"
                  fullWidth
                  select
                >
                  {POLL_TYPES.map(type => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              {/* ARE RESULTS PUBLIC? */}
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  disabled={values.type === 'EVALUATION'}
                  control={
                    <Switch
                      checked={values.are_results_public}
                      name="are_results_public"
                      onChange={e => {
                        setFieldValue('are_results_public', e.target.checked)
                      }}
                      color="primary"
                    />
                  }
                  label="Are results public?"
                />
              </Grid>
            </Grid>

            <Typography
              variant="h6"
              gutterBottom
              className={classes.marginTopLg}
            >
              {'The poll is for...'}
            </Typography>

            <Grid container spacing={2}>
              {/* CONGRESS ID */}
              <Grid item xs={12}>
                <TextField
                  name="congress_id"
                  type="text"
                  variant="outlined"
                  fullWidth
                  select
                  disabled={congressQuery.loading}
                  value={values.congress_id || 'unselected'}
                  onChange={e => {
                    if (e.target.value === 'unselected') {
                      setFieldValue('congress_id', null)
                      setFieldValue('section_id', null)
                      setFieldValue('abstract_id', null)
                    } else {
                      setFieldValue('congress_id', e.target.value)
                      fetchSections({
                        variables: { congress_id: e.target.value },
                      })
                    }
                  }}
                >
                  <MenuItem value={'unselected'}>
                    {'Select congress...'}
                  </MenuItem>
                  {Array.isArray(congresses) &&
                    congresses.length > 0 &&
                    congresses.map((congress, index) => (
                      <MenuItem key={index} value={congress.id}>
                        {congress.name}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>

              {/* SECTION ID */}
              <Grid item xs={12}>
                <TextField
                  name="section_id"
                  type="text"
                  variant="outlined"
                  fullWidth
                  select
                  disabled={!values.congress_id || sectionQuery.loading}
                  value={values.section_id || 'unselected'}
                  onChange={e => {
                    if (e.target.value === 'unselected') {
                      setFieldValue('section_id', null)
                      setFieldValue('abstract_id', null)
                    } else {
                      setFieldValue('section_id', e.target.value)
                      fetchAbstracts({
                        variables: { section_id: e.target.value },
                      })
                    }
                  }}
                >
                  <MenuItem value={'unselected'}>
                    {'Select section...'}
                  </MenuItem>
                  {Array.isArray(sections) &&
                    sections.length > 0 &&
                    sections.map((section, index) => (
                      <MenuItem key={index} value={section.id}>
                        {section.name}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>

              {/* ABSTRACT ID */}
              <Grid item xs={12}>
                <TextField
                  name="abstract_id"
                  type="text"
                  variant="outlined"
                  fullWidth
                  select
                  disabled={!values.section_id || abstractQuery.loading}
                  value={values.abstract_id || 'unselected'}
                  onChange={e => {
                    if (e.target.value === 'unselected') {
                      setFieldValue('abstract_id', null)
                    } else {
                      setFieldValue('abstract_id', e.target.value)
                    }
                  }}
                >
                  <MenuItem value={'unselected'}>
                    {'Select abstract...'}
                  </MenuItem>
                  {Array.isArray(abstracts) &&
                    abstracts.length > 0 &&
                    abstracts.map((abstract, index) => (
                      <MenuItem key={index} value={abstract.id}>
                        {abstract.title}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
            </Grid>
          </FormContainer>

          <StepperFooter
            currentStep={currentStep}
            maxStep={maxStep}
            handleBack={() => {
              setPoll(values)
              handleBack()
            }}
            handleNext={submitForm}
            loading={isSubmitting}
          />
        </>
      )}
    </Formik>
  )
}

NewPollSettings.propTypes = {
  poll: PropTypes.object.isRequired,
  setPoll: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  maxStep: PropTypes.number.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
}

const styles = makeStyles(theme => ({
  marginTop: {
    marginTop: theme.spacing(4),
  },
  marginTopLg: {
    marginTop: theme.spacing(6),
  },
}))

export default NewPollSettings
