import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { getIn } from 'formik'
import InputMask from 'react-input-mask'

// MUI
import { TextField } from '@material-ui/core'

/**
 * Wrapper for: TextField (MUI)
 * Required for: Formik
 *
 * Wrapper components for MUI input fields are required
 * to sync changes to the input field and to render
 * errors correctly.
 */
const FormikTextField = memo(props => {
  const {
    field,
    form: { touched, errors },
    mask,
    disabled,
    ...rest
  } = props

  const hasError =
    (touched[field.name] && Boolean(errors[field.name])) ||
    Boolean(getIn(errors, field.name))
  const errorText = errors[field.name] || getIn(errors, field.name)

  return (
    <>
      {mask && !disabled ? (
        <InputMask mask={mask} maskChar=" " {...field} {...rest}>
          {inputProps => (
            <TextField
              {...inputProps}
              error={hasError}
              helperText={hasError ? errorText : ''}
            />
          )}
        </InputMask>
      ) : (
        <TextField
          {...field}
          {...rest}
          disabled={disabled}
          error={hasError}
          helperText={hasError ? errorText : ''}
        />
      )}
    </>
  )
})

FormikTextField.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  mask: PropTypes.string,
  disabled: PropTypes.bool,
}

export default FormikTextField
