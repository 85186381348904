import gql from 'graphql-tag'

export default {
  ADD_ADVERTISEMENT: gql`
    mutation ADD_ADVERTISEMENT(
      $id: ID!
      $congress_id: ID
      $name: String!
      $location: String!
      $image_file_names: String
      $random: Boolean!
      $timing: Int
    ) {
      addAdvertisement(
        id: $id
        congress_id: $congress_id
        name: $name
        location: $location
        image_file_names: $image_file_names
        random: $random
        timing: $timing
      ) {
        id
        name
        created_at
        __typename
      }
    }
  `,

  UPDATE_ADVERTISEMENT: gql`
    mutation UPDATE_ADVERTISEMENT(
      $id: ID!
      $congress_id: ID
      $name: String
      $location: String
      $image_file_names: String
      $random: Boolean
      $timing: Int
    ) {
      updateAdvertisement(
        id: $id
        congress_id: $congress_id
        name: $name
        location: $location
        image_file_names: $image_file_names
        random: $random
        timing: $timing
      ) {
        id
        congress_id
        name
        location
        image_file_names
        random
        timing
        __typename
      }
    }
  `,

  DELETE_ADVERTISEMENT: gql`
    mutation DELETE_ADVERTISEMENT($id: ID!) {
      deleteAdvertisement(id: $id) {
        id
        __typename
      }
    }
  `,
}
