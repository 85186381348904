import React from 'react'
import PropTypes from 'prop-types'

// Layout
import { ContentWrapper } from '../../../layout'

// Components
import PollSettingsGeneralForm from '../../../components/forms/PollSettings/PollSettingsGeneralForm'

const PollSettingsGeneral = props => {
  const { poll } = props

  return (
    <ContentWrapper>
      <PollSettingsGeneralForm poll={poll} />
    </ContentWrapper>
  )
}

PollSettingsGeneral.propTypes = {
  poll: PropTypes.object.isRequired,
}

export default PollSettingsGeneral
