import React from 'react'
import Helmet from 'react-helmet'
import { makeStyles } from '@material-ui/core/styles'

// MUI
import { Typography, Container } from '@material-ui/core'
import SadFaceIcon from '@material-ui/icons/SentimentVeryDissatisfied'

const NotFound = () => {
  const classes = styles()

  return (
    <>
      <Helmet>
        <title>{'Error 404: Page not found'}</title>
      </Helmet>

      <Container className={classes.wrapper}>
        <Typography variant="h1" align="center">
          <SadFaceIcon fontSize="inherit" />
        </Typography>
        <Typography variant="h2" align="center" gutterBottom>
          {'Oops!'}
        </Typography>
        <Typography variant="h6" align="center">
          {
            'It looks like that the page you are trying to visit does not exist.'
          }
        </Typography>
      </Container>
    </>
  )
}

const styles = makeStyles({
  wrapper: {
    width: '100%',
    height: '80vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
})

export default NotFound
