import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'

// Components
import NewsForm from './forms/NewsForm'

// Cosmetics
import ContentLoading from '../../../cosmetics/loadings/ContentLoading'

// Queries & Mutations
import { query } from '../../../../lib/apollo-client'

const NewsItemDialog = props => {
  const { dataNodeId, closeContentDialog } = props
  const { enqueueSnackbar } = useSnackbar()

  // Data
  const { data, error, loading } = useQuery(query.form.menu.DATA_NODE, {
    variables: { id: dataNodeId },
  })

  React.useEffect(() => {
    if (loading) return

    if (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
      captureException(error)
    }
  }, [data, error, loading, enqueueSnackbar])

  if (loading) return <ContentLoading />

  return (
    <>
      {data && data.dataNode && (
        <NewsForm
          dataNode={data.dataNode}
          closeContentDialog={closeContentDialog}
        />
      )}
    </>
  )
}

NewsItemDialog.propTypes = {
  dataNodeId: PropTypes.string.isRequired,
  closeContentDialog: PropTypes.func.isRequired,
}

export default NewsItemDialog
