import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'
import { Formik, FastField } from 'formik'
import * as Yup from 'yup'

// Utils
import FormikTextField from '../utils/FormikTextField'

// Config
import { MENU_TYPES } from '../../config'

// MUI
import { Grid, MenuItem, FormControlLabel, Switch } from '@material-ui/core'

// Layout
import FormContainer from './elements/layout/FormContainer'
import FormFooter from './elements/layout/FormFooter'

// Elements
import DrawerTitle from '../drawers/elements/DrawerTitle'

// Dialogs
import ConfirmationDialog from '../dialogs/ConfirmationDialog'

// Cosmetics
import FullscreenLoading from '../cosmetics/loadings/FullscreenLoading'

// Queries & Mutations
import { query, mutation } from '../../lib/apollo-client'

const schema = Yup.object().shape({
  title: Yup.string().required().max(100),
  type: Yup.string().required(),
  is_sidebar: Yup.boolean().required(),
})

const initialValues = {
  title: '',
  type: 'LIST',
  is_sidebar: false,
}

const MenuForm = props => {
  const { menu, congressId } = props
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  // Data
  const [updateMenu, updateMenuMutation] = useMutation(
    mutation.form.menu.UPDATE_MENU
  )
  const [deleteMenu, deleteMenuMutation] = useMutation(
    mutation.form.menu.DELETE_MENU,
    {
      update(cache, { data: { deleteMenu } }) {
        const { menus } = cache.readQuery({
          query: query.drawer.MENUS,
          variables: { congress_id: congressId },
        })

        cache.writeQuery({
          query: query.drawer.MENUS,
          variables: { congress_id: congressId },
          data: {
            menus: menus.filter(menu => {
              return menu.id !== deleteMenu.id
            }),
          },
        })
      },
    }
  )

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
  const handleConfirmationOpen = () => setDeleteConfirmOpen(true)
  const handleConfirmationClose = () => setDeleteConfirmOpen(false)

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await updateMenu({
        variables: values,
        optimisticResponse: {
          __typename: 'Mutation',
          updateMenu: {
            ...values,
            __typename: 'Menu',
          },
        },
      })

      resetForm(values)
    } catch (error) {
      enqueueSnackbar('Error: error during updating menu!', {
        variant: 'error',
      })
      captureException(error)
    }

    setSubmitting(false)
  }

  /**
   * Deletes menu and redirects to Society App.
   */
  const handleDelete = async () => {
    try {
      await deleteMenu({
        variables: { id: menu.id },
        optimisticResponse: {
          __typename: 'Mutation',
          deleteMenu: {
            id: menu.id,
            __typename: 'Menu',
          },
        },
      })

      if (congressId) {
        history.push(`/congress/${congressId}`)
      } else {
        history.push('/societyapp')
      }
    } catch (error) {
      enqueueSnackbar('Error: error during deleting menu!', {
        variant: 'error',
      })
      captureException(error)
    }
  }

  const formData = {
    ...initialValues,
    ...menu,
  }

  return (
    <>
      {(updateMenuMutation.loading || deleteMenuMutation.loading) && (
        <FullscreenLoading variant="white" transparent />
      )}

      <Formik
        enableReinitialize
        initialValues={formData}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ values, dirty, isSubmitting, setFieldValue }) => (
          <FormContainer elevation={0}>
            <DrawerTitle title="Edit menu" />

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FastField
                  name="title"
                  type="text"
                  label="Title"
                  component={FormikTextField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <FastField
                  name="type"
                  type="text"
                  label="Type"
                  component={FormikTextField}
                  variant="outlined"
                  fullWidth
                  select
                >
                  {MENU_TYPES &&
                    MENU_TYPES.map((item, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                </FastField>
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.is_sidebar}
                      name="is_sidebar"
                      onChange={e => {
                        setFieldValue('is_sidebar', e.target.checked)
                      }}
                      color="primary"
                    />
                  }
                  label="Is sidebar?"
                />
              </Grid>
            </Grid>

            <FormFooter
              isSubmitting={isSubmitting}
              dirty={dirty}
              save
              onDelete={handleConfirmationOpen}
            />

            <ConfirmationDialog
              title="Delete menu"
              open={deleteConfirmOpen}
              description="Are you sure you would like to delete this menu and all of its menu items? This action is irreversible."
              returnLabel="Cancel"
              confirmLabel="Delete"
              onReturn={handleConfirmationClose}
              onConfirm={() => {
                handleConfirmationClose()
                handleDelete()
              }}
              variant="delete"
            />
          </FormContainer>
        )}
      </Formik>
    </>
  )
}

MenuForm.propTypes = {
  menu: PropTypes.object.isRequired,
  congressId: PropTypes.string,
}

export default MenuForm
