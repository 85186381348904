import { useMutation } from '@apollo/react-hooks'
import { captureException } from '@sentry/browser'
import { FastField, Form, Formik, FormikHelpers } from 'formik'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import React from 'react'
import * as Yup from 'yup'

// config
import { s3config } from '../../../../../config'

// utils
import FormikTextField from '../../../../utils/FormikTextField'

// MUI
import { Grid } from '@material-ui/core'

// Layout
import FormFooter from '../../../elements/layout/FormFooter'

// Inputs
import QuillEditor from '../../../elements/inputs/QuillEditor'

// Cosmetics
import FullscreenLoading from '../../../../cosmetics/loadings/FullscreenLoading'

// Queries & Mutations
import { useFileUpload } from '../../../../../hooks/file-upload.mutation'
import { mutation } from '../../../../../lib/apollo-client'
import { FileType } from '../../../../../services/openapi'

const ONE_MEGABYTE_IN_BYTES = 1048576

const schema = Yup.object().shape({
  title: Yup.string().required(),
  excerpt: Yup.string().required(),
  content: Yup.string().required(),
})

type NewsFormState = {
  title: string
  excerpt: string
  content: string
}
const initialValues: NewsFormState = {
  title: '',
  excerpt: '',
  content: '',
}

type NewsFormProps = {
  closeContentDialog: () => void
  dataNode: any
}

const NewsForm = (props: NewsFormProps) => {
  const { dataNode, closeContentDialog } = props
  const { enqueueSnackbar } = useSnackbar()

  // Data
  const [updateDataNode, updateDataNodeMutation] = useMutation(
    mutation.form.menu.UPDATE_DATA_NODE
  )
  // FOR QUILL IMAGE UPLOAD
  const { isLoading: isImageUploading, mutateAsync } = useFileUpload(
    FileType.QUILL_IMAGE
  )

  const handleSubmit = async (
    values: NewsFormState,
    { setSubmitting, resetForm }: FormikHelpers<NewsFormState>
  ) => {
    try {
      await updateDataNode({
        variables: { id: dataNode.id, data: JSON.stringify(values) },
        optimisticResponse: {
          __typename: 'Mutation',
          updateDataNode: {
            id: dataNode.id,
            data: JSON.stringify(values),
            __typename: 'DataNode',
          },
        },
      })

      resetForm()
    } catch (error) {
      enqueueSnackbar('Error: error during updating data node!', {
        variant: 'error',
      })
      captureException(error)
    }

    setSubmitting(false)
    closeContentDialog()
  }

  const uploadImage = async (file: Blob) => {
    if (file.size > ONE_MEGABYTE_IN_BYTES) {
      enqueueSnackbar('Error: the selected image exceeds the 1MB limit!', {
        variant: 'error',
      })
      return new Error('Selected image exceeds the 1MB limit!')
    }

    try {
      const uploadedFile = await mutateAsync({
        file: file,
      })

      if (uploadedFile) {
        const fileName = uploadedFile.file_name

        return `https://${s3config.bucket}.s3.${s3config.region}.amazonaws.com/${fileName}`
      } else {
        captureException(
          new Error('Could not retrieve filename from response!')
        )
        enqueueSnackbar('Error: could not retrieve image URL from response!', {
          variant: 'error',
        })
      }
    } catch (error) {
      captureException(error)
    }
  }

  // Parse dataNode.data so we can edit it
  const data = dataNode.data ? JSON.parse(dataNode.data) : null

  // generate form data
  const formData = {
    ...initialValues,
    ...data,
  }

  return (
    <>
      {(updateDataNodeMutation.loading || isImageUploading) && (
        <FullscreenLoading variant="white" transparent />
      )}

      <Formik
        enableReinitialize
        initialValues={formData}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ dirty, isSubmitting, values, setFieldValue }) => (
          <Form noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FastField
                  name="title"
                  type="text"
                  label="Title"
                  component={FormikTextField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <FastField
                  name="excerpt"
                  type="text"
                  label="Excerpt"
                  component={FormikTextField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <QuillEditor
                  value={values.content}
                  onChange={(newContent: string) => {
                    setFieldValue('content', newContent)
                  }}
                  uploadHandler={uploadImage}
                />
              </Grid>
            </Grid>

            <FormFooter isSubmitting={isSubmitting} dirty={dirty} save />
          </Form>
        )}
      </Formik>
    </>
  )
}

NewsForm.propTypes = {
  dataNode: PropTypes.object.isRequired,
  closeContentDialog: PropTypes.func.isRequired,
}

export default NewsForm
