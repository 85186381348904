import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { FastField } from 'formik'

// MUI
import { IconButton, Grid } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/DeleteForeverOutlined'
import TickIcon from '@material-ui/icons/Check'
import TickCircleIcon from '@material-ui/icons/CheckCircle'

// Utils
import FormikTextField from '../../../utils/FormikTextField'

const NewPollOptionItem = props => {
  const {
    option,
    questionIndex,
    optionIndex,
    pollType,
    removeOption,
    setFieldValue,
    rightAnswerId,
  } = props
  const classes = styles()

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} sm={10}>
        <FastField
          name={`questions[${questionIndex}].options[${optionIndex}].title`}
          type="text"
          placeholder="Option title"
          component={FormikTextField}
          margin="dense"
          fullWidth
        />
      </Grid>

      <Grid item xs={6} sm={2} className={classes.buttons}>
        {pollType === 'EXAM' && (
          <IconButton
            onClick={() =>
              setFieldValue(
                `questions[${questionIndex}].right_option_id`,
                option.id
              )
            }
            disabled={rightAnswerId === option.id}
            className={classes.tick}
          >
            {rightAnswerId === option.id ? (
              <TickCircleIcon fontSize="small" />
            ) : (
              <TickIcon fontSize="small" />
            )}
          </IconButton>
        )}

        <IconButton
          onClick={() => {
            if (rightAnswerId === option.id) {
              setFieldValue(`questions[${questionIndex}].right_option_id`, null)
            }

            removeOption()
          }}
          className={classes.error}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Grid>
    </Grid>
  )
}

NewPollOptionItem.propTypes = {
  pollType: PropTypes.string.isRequired,
  option: PropTypes.object.isRequired,
  questionIndex: PropTypes.number.isRequired,
  optionIndex: PropTypes.number.isRequired,
  removeOption: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  rightAnswerId: PropTypes.string,
}

const styles = makeStyles(theme => ({
  buttons: {
    display: 'flex',
  },
  error: {
    color: theme.palette.error.main,
  },
  tick: {
    marginRight: theme.spacing(1),
    color: theme.palette.grey[400],
    '&:hover': {
      color: theme.palette.success.main,
    },
    '&:disabled': {
      color: theme.palette.success.main,
    },
  },
}))

export default NewPollOptionItem
