import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'

// MUI
import { Button, Box, Tooltip } from '@material-ui/core'
import AddAlertIcon from '@material-ui/icons/AddAlert'

// Cosmetics
import FullscreenLoading from '../../cosmetics/loadings/FullscreenLoading'

// Dialogs
import PushNotificationDialog from '../../dialogs/PushNotificationDialog'

// Queries & Mutations
import { mutation } from '../../../lib/apollo-client'

const SendNotificationButton = props => {
  const { congressId } = props
  const { enqueueSnackbar } = useSnackbar()

  // Data
  const [sendPushNotif, sendPushNotifMutation] = useMutation(
    mutation.oneSignal.SEND_PUSH_NOTIF
  )

  // State
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const handleDialogOpen = () => setIsDialogOpen(true)
  const handleDialogClose = () => setIsDialogOpen(false)

  const handleSendPushNotif = async (
    title,
    description,
    scheduled,
    menu_item_id,
    menu_item_title,
    payload_id,
    payload_type,
    payload_content
  ) => {
    try {
      const response = await sendPushNotif({
        variables: {
          title,
          description,
          scheduled,
          congress_id: congressId,
          menu_item_id,
          menu_item_title,
          payload_id,
          payload_type,
          payload_content,
        },
      })

      if (response && response.data && response.data.sendPushNotif) {
        const errors = response.data.sendPushNotif.errors
        if (Array.isArray(errors) && errors.length > 0) {
          if (
            response.data.sendPushNotif.errors[0] ===
            'All included players are not subscribed'
          ) {
            enqueueSnackbar('The targeted congress has no subscribed users.', {
              variant: 'warning',
            })
          } else {
            enqueueSnackbar(`Error: ${response.data.sendPushNotif.errors[0]}`, {
              variant: 'error',
            })
          }
        } else {
          enqueueSnackbar(
            scheduled
              ? 'Push notification is on its way!'
              : 'Push notification sent!',
            {
              variant: 'success',
            }
          )
        }
      }
    } catch (error) {
      enqueueSnackbar(
        error.message
          ? error.message
          : 'Error: error sending push notification!',
        {
          variant: 'error',
        }
      )
      captureException(error)
    }
  }

  return (
    <>
      {sendPushNotifMutation.loading && (
        <FullscreenLoading variant="white" transparent />
      )}

      <Box mt={1.5} mb={1.5} p={1} display="flex" justifyContent="center">
        <Tooltip title="Send new push notification" placement="right">
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDialogOpen}
            disabled={sendPushNotifMutation.loading}
            startIcon={<AddAlertIcon />}
          >
            {'New notification'}
          </Button>
        </Tooltip>
      </Box>

      <PushNotificationDialog
        congressId={congressId}
        open={isDialogOpen}
        onClose={handleDialogClose}
        sendHandler={handleSendPushNotif}
      />
    </>
  )
}

SendNotificationButton.propTypes = {
  congressId: PropTypes.string,
}

export default SendNotificationButton
