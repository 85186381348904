import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import { Redirect } from 'react-router-dom'
import { useAuth0 } from '../lib/auth0'

// Cosmetics
import FullscreenLoading from '../components/cosmetics/loadings/FullscreenLoading'

const Login = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0()

  useEffect(() => {
    if (isAuthenticated) {
      return
    }

    ;(async () => {
      await loginWithRedirect({
        appState: { targetUrl: '/' },
      })
    })()
  }, [isAuthenticated, loginWithRedirect])

  return (
    <>
      <Helmet>
        <title>{'Login'}</title>
      </Helmet>

      {isAuthenticated ? (
        <Redirect to="/" />
      ) : (
        <FullscreenLoading variant="primary" />
      )}
    </>
  )
}

export default Login
