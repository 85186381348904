import React from 'react'
import PropTypes from 'prop-types'

// MUI
import { useTheme, Typography, Divider } from '@material-ui/core'

const DrawerTitle = props => {
  const { title } = props
  const theme = useTheme()

  return (
    <>
      <Typography
        variant="h5"
        style={{
          fontWeight: theme.typography.fontWeightBold,
        }}
        gutterBottom
      >
        {title}
      </Typography>

      <Divider
        style={{
          marginBottom: theme.spacing(4),
        }}
      />
    </>
  )
}

DrawerTitle.propTypes = {
  title: PropTypes.string.isRequired,
}

export default DrawerTitle
