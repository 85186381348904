import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import moment from 'moment'

// MUI
import { Typography } from '@material-ui/core'
import { DatePicker, DateTimePicker } from '@material-ui/pickers'

const TimestampPicker = ({
  timestamp,
  onChange,
  label,
  labelVariant,
  error,
  errorText,
  time,
  className,
  ...rest
}) => {
  const classes = styles()

  return (
    <>
      {label ? (
        <Typography variant={labelVariant ? labelVariant : 'h6'} gutterBottom>
          {label}
        </Typography>
      ) : null}

      {time ? (
        <DateTimePicker
          className={clsx(classes.root, className)}
          value={moment.unix(timestamp)}
          onChange={date => {
            if (date) onChange(date.unix())
          }}
          error={error}
          helperText={errorText}
          inputVariant="outlined"
          ampm={false}
          {...rest}
        />
      ) : (
        <DatePicker
          className={clsx(classes.root, className)}
          value={moment.unix(timestamp)}
          onChange={date => {
            if (date) onChange(date.unix())
          }}
          error={error}
          helperText={errorText}
          inputVariant="outlined"
          {...rest}
        />
      )}
    </>
  )
}

TimestampPicker.propTypes = {
  timestamp: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  labelVariant: PropTypes.string,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  time: PropTypes.bool,
  className: PropTypes.string,
}

const styles = makeStyles(() => ({
  root: {},
}))

export default TimestampPicker
