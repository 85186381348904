import React from 'react'
import Helmet from 'react-helmet'
import { useQuery } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'

import { objectify } from '../utils/functions'

// Layout
import { ContentWrapper } from '../layout'

// Cosmetics
import FullscreenLoading from '../components/cosmetics/loadings/FullscreenLoading'

// Componencs
import AppSettingsForm from '../components/forms/AppSettings/AppSettingsForm'

// Queries & Mutations
import { query } from '../lib/apollo-client'

const AppSettings = () => {
  const { enqueueSnackbar } = useSnackbar()

  const [settings, setSettings] = React.useState({})

  // Data
  const { data, error, loading } = useQuery(query.settings.SETTINGS)
  React.useEffect(() => {
    if (loading) return

    if (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
      captureException(error)
    }

    if (data && Array.isArray(data.settings)) {
      setSettings(objectify(data.settings))
    }
  }, [data, error, loading, enqueueSnackbar])

  if (loading) {
    return <FullscreenLoading variant="white" transparent />
  }

  return (
    <>
      <Helmet>
        <title>{'App Settings'}</title>
      </Helmet>

      <ContentWrapper>
        <AppSettingsForm settings={settings} />
      </ContentWrapper>
    </>
  )
}

export default AppSettings
