import gql from 'graphql-tag'

export default {
  ADVERTISEMENTS: gql`
    query ADVERTISEMENTS {
      advertisements {
        id
        name
        created_at
      }
    }
  `,

  ADVERTISEMENT: gql`
    query ADVERTISEMENT($id: ID!) {
      advertisement(id: $id) {
        id
        congress_id
        name
        location
        image_file_names
        random
        timing
      }
    }
  `,
}
