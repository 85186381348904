import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

// config
import { gmtZones, siteConfig } from '../../../config'

// MUI
import { Button, FormControlLabel, Switch, Typography } from '@material-ui/core'

// Layout
import StepperFooter from '../elements/layout/StepperFooter'

// Dialogs
import SelectionDialog from '../../dialogs/SelectionDialog'

const Finish = props => {
  const {
    currentStep,
    maxStep,
    handleBack,
    handleNext,
    onFinish,
    disableMigrate,
  } = props
  const classes = styles()

  const [migrate, setMigrate] = useState(true)

  const [timezoneDialogOpen, setTimezoneDialogOpen] = useState(false)
  const handleTimezoneDialogOpen = () => setTimezoneDialogOpen(true)
  const handleTimezoneDialogClose = () => setTimezoneDialogOpen(false)

  return (
    <>
      <div className={classes.finish}>
        <Typography variant="h3" align="center" gutterBottom>
          {'Your congress is ready to be created!'}
        </Typography>

        <FormControlLabel
          control={
            <Switch
              checked={migrate}
              name="migrate"
              onChange={e => {
                setMigrate(e.target.checked)
              }}
              color="primary"
            />
          }
          label={`Migrate congress data from ${siteConfig.name} API immediately after creation.`}
          disabled={disableMigrate}
        />

        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => {
            if (!disableMigrate && migrate) {
              handleTimezoneDialogOpen()
              return
            } else {
              onFinish(false)
            }
          }}
        >
          {'Create'}
          {!disableMigrate && migrate && ' & migrate'}
        </Button>
      </div>

      <StepperFooter
        currentStep={currentStep}
        maxStep={maxStep}
        handleBack={handleBack}
        handleNext={handleNext}
      />

      <SelectionDialog
        title="Which timezone is used by the incoming dates and times?"
        open={timezoneDialogOpen}
        onClose={handleTimezoneDialogClose}
        options={gmtZones}
        onSubmit={selected => onFinish(true, selected)}
        defaultValue="GMT+1"
      />
    </>
  )
}

Finish.propTypes = {
  currentStep: PropTypes.number.isRequired,
  maxStep: PropTypes.number.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  disableMigrate: PropTypes.bool,
}

const styles = makeStyles(() => ({
  finish: {
    height: '50vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
}))

export default Finish
