import gql from 'graphql-tag'

export default {
  ACTIVE_DATA_NODES: gql`
    query ACTIVE_DATA_NODES($congress_id: ID, $type: String) {
      activeDataNodes(congress_id: $congress_id, type: $type) {
        id
        data_item_id
        type
        parent_menu_item {
          id
          title
        }
        data
        created_at
      }
    }
  `,
}
