import React from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

// MUI
import { useTheme, Paper, Grid, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

// Elements
import QuickAction from './QuickAction'

const QuickActions = () => {
  const classes = styles()
  const theme = useTheme()
  const history = useHistory()

  return (
    <Paper className={classes.paper}>
      <Typography
        variant="h6"
        style={{
          marginBottom: theme.spacing(3),
        }}
      >
        {'Quick actions'}
      </Typography>

      <Grid container spacing={2}>
        <QuickAction
          label="Create new congress"
          icon={<AddIcon />}
          action={() => history.push('/congresses/new')}
        />

        <QuickAction
          label="Create new poll"
          icon={<AddIcon />}
          action={() => history.push('/polls/new')}
        />
      </Grid>
    </Paper>
  )
}

const styles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3),
  },
}))

export default QuickActions
