import React from 'react'
import Helmet from 'react-helmet'
import { useQuery } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'

// Layout
import { ContentWrapper } from '../layout'

// Components
import AdForm from '../components/forms/Ad/AdForm'

// Cosmetics
import FullscreenLoading from '../components/cosmetics/loadings/FullscreenLoading'

// Queries & Mutations
import { query } from '../lib/apollo-client'

const EditAd = ({ match }) => {
  const { enqueueSnackbar } = useSnackbar()

  // Data
  const { data, error, loading } = useQuery(query.ads.ADVERTISEMENT, {
    variables: { id: match.params.adId },
  })
  React.useEffect(() => {
    if (loading) return

    if (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      })
      captureException(error)
    }
  }, [data, error, loading, enqueueSnackbar])

  if (loading) {
    return <FullscreenLoading variant="white" transparent />
  }

  return (
    <>
      <Helmet>
        <title>{'Edit advertisement'}</title>
      </Helmet>

      <ContentWrapper>
        {data && data.advertisement && (
          <AdForm advertisement={data.advertisement} />
        )}
      </ContentWrapper>
    </>
  )
}

export default EditAd
