import { useQuery } from '@apollo/react-hooks'
import { captureException } from '@sentry/browser'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'

// MUI
import CongressIcon from '@material-ui/icons/EventNote'
import BreakIcon from '@material-ui/icons/FreeBreakfast'
import MenuIcon from '@material-ui/icons/Menu'
import SectionIcon from '@material-ui/icons/Schedule'
import SettingsIcon from '@material-ui/icons/Settings'
import AbstractIcon from '@material-ui/icons/Slideshow'
import TopicIcon from '@material-ui/icons/ViewList'

// Layout
import { ContentWrapper } from '../../layout'

// Components
import SubHeader, { ResponsiveTab } from '../../components/SubHeader'

// Elements
import CongressForm from '../../components/forms/Congress/CongressForm'
import Abstracts from './Abstracts'
import Breaks from './Breaks'
import { MenuV2Iframe } from './MenuV2Iframe'
import Miscellaneous from './Miscellaneous'
import { NotificationV2Iframe } from './NotificationV2Iframe'
import Sections from './Sections'
import Topics from './Topics'

// Cosmetics
import FullscreenLoading from '../../components/cosmetics/loadings/FullscreenLoading'

// Queries & Mutations
import { query } from '../../lib/apollo-client'

const Congress = props => {
  const { match } = props
  const { enqueueSnackbar } = useSnackbar()

  // Data
  const { data, error, loading } = useQuery(query.form.congress.CONGRESS, {
    variables: { id: match.params.congressId },
  })

  const [tabValue, setTabValue] = useState(0)

  useEffect(() => {
    if (loading) {
      return
    }

    if (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      })
      captureException(error)
    }
  }, [data, error, loading, enqueueSnackbar])

  const handleTabChange = (_, value) => {
    setTabValue(value)
  }

  if (loading) {
    return <FullscreenLoading variant="white" transparent />
  }

  return (
    <>
      <Helmet>
        <title>{'Edit congress'}</title>
      </Helmet>

      <SubHeader value={tabValue} onChange={handleTabChange}>
        <ResponsiveTab label="Congress" icon={<CongressIcon />} />
        <ResponsiveTab label="Sections" icon={<SectionIcon />} />
        <ResponsiveTab label="Abstracts" icon={<AbstractIcon />} />
        <ResponsiveTab label="Topics" icon={<TopicIcon />} />
        <ResponsiveTab label="Breaks" icon={<BreakIcon />} />
        {/* <ResponsiveTab label="Floorplans" icon={<FloorplanIcon />} /> */}
        {/* <ResponsiveTab label="Sponsors" icon={<SponsorIcon />} /> */}
        <ResponsiveTab label="Misc" icon={<SettingsIcon />} />
        <ResponsiveTab label="Nav v2" icon={<MenuIcon />} />
        <ResponsiveTab label="Notification v2" icon={<MenuIcon />} />
      </SubHeader>

      <ContentWrapper>
        {data && data.congress && (
          <>
            {/* CONGRESS */}
            {tabValue === 0 && <CongressForm congress={data.congress} />}

            {/* SECTIONS */}
            {tabValue === 1 && <Sections congressId={data.congress.id} />}

            {/* ABSTRACTS */}
            {tabValue === 2 && <Abstracts congressId={data.congress.id} />}

            {/* TOPICS */}
            {tabValue === 3 && <Topics congressId={data.congress.id} />}

            {/* BREAKS */}
            {tabValue === 4 && <Breaks congressId={data.congress.id} />}

            {/* FLOORPLANS */}
            {/* {tabValue === 5 && <Floorplans congressId={data.congress.id} />} */}

            {/* SPONSORS */}
            {/* {tabValue === 6 && <Sponsors congressId={data.congress.id} />} */}

            {/* MISCELLANEOUS */}
            {tabValue === 5 && <Miscellaneous congressId={data.congress.id} />}

            {tabValue === 6 && <MenuV2Iframe congressId={data.congress.id} />}
            {tabValue === 7 && (
              <NotificationV2Iframe congressId={data.congress.id} />
            )}
          </>
        )}
      </ContentWrapper>
    </>
  )
}

export default Congress
