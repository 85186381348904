import gql from 'graphql-tag'

export default {
  QUESTIONS: gql`
    query QUESTIONS($section_id: ID, $abstract_id: ID) {
      questions(section_id: $section_id, abstract_id: $abstract_id) {
        id
        author
        question
        answered
        flagged
        created_at
      }
    }
  `,
}
