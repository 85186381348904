import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'
import { Formik, FieldArray } from 'formik'
import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { DEFAULT_QUESTION_ANSWER_VALUES } from '../../../config'

// MUI
import { Typography, Grid, Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddCircleOutline'

// Layout
import FormContainer from '../elements/layout/FormContainer'
import FormFooter from '../elements/layout/FormFooter'

// Elements
import QuestionItem from './elements/QuestionItem'

// Cosmetics
import FullscreenLoading from '../../cosmetics/loadings/FullscreenLoading'

// Queries & Mutations
import { mutation } from '../../../lib/apollo-client'

const initialQuestionValues = {
  title: '',
  type: '',
  allow_multiple: false,
  required: true,
  right_option_id: null,
  exam_answer_values: DEFAULT_QUESTION_ANSWER_VALUES,
  additional_info: '',
  options: [],
}

const schema = Yup.object().shape({
  questions: Yup.array()
    .of(
      Yup.object().shape({
        title: Yup.string()
          .required('Question is required')
          .max(200, 'Question title must be at most 200 characters'),
        type: Yup.string().required('Question type is required'),
        allow_multiple: Yup.boolean().required(),
        required: Yup.boolean().required(),
        exam_answer_values: Yup.object()
          .shape({
            right: Yup.number().integer(),
            wrong: Yup.number().integer(),
            empty: Yup.number().integer(),
          })
          .nullable(true),
        additional_info: Yup.string(),
        options: Yup.array()
          .of(
            Yup.object().shape({
              title: Yup.string()
                .required('Option title is required')
                .max(200, 'Option title must be at most 200 characters'),
            })
          )
          .when('type', {
            is: val => val === 'OPTIONS',
            then: field => field.required(),
            otherwise: field => field.nullable(true),
          }),
      })
    )
    .required(),
})

const sortHandler = (a, b) => {
  if (a.order < b.order) {
    return -1
  }
  if (a.order > b.order) {
    return 1
  }
  return 0
}

const PollSettingsQuestionsForm = props => {
  const { poll, refetchPoll } = props
  const classes = styles()
  const { enqueueSnackbar } = useSnackbar()

  // Stores deletable poll question options
  const [optionCemetery, setOptionCemetery] = React.useState([])
  // Stores deletable poll questions
  const [questionCemetery, setQuestionCemetery] = React.useState([])

  // Data
  const [deletePollQuestion, dpqMutation] = useMutation(
    mutation.polls.DELETE_POLL_QUESTION
  )
  const [deletePollQuestionOption, dpqoMutation] = useMutation(
    mutation.polls.DELETE_POLL_QUESTION_OPTION
  )
  const [addPollQuestion, apqMutation] = useMutation(
    mutation.polls.ADD_POLL_QUESTION
  )
  const [addPollQuestionOption, apqoMutation] = useMutation(
    mutation.polls.ADD_POLL_QUESTION_OPTION
  )
  const [updatePollQuestion, upqMutation] = useMutation(
    mutation.polls.UPDATE_POLL_QUESTION
  )
  const [updatePollQuestionOption, upqoMutation] = useMutation(
    mutation.polls.UPDATE_POLL_QUESTION_OPTION
  )

  const markQuestionForDeletion = question => {
    questionCemetery.push(question)
  }
  const markOptionForDeletion = option => {
    optionCemetery.push(option)
  }

  /**
   * Handles questions form save.
   *
   * @param values
   * @param FormikBag
   */
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      // 1. delete options & questions
      if (Array.isArray(optionCemetery) && optionCemetery.length > 0) {
        await Promise.all(
          optionCemetery.map(async optionId => {
            await deletePollQuestionOption({
              variables: { id: optionId },
            })
          })
        )
      }
      if (Array.isArray(questionCemetery) && questionCemetery.length > 0) {
        await Promise.all(
          questionCemetery.map(async questionId => {
            await deletePollQuestion({
              variables: { id: questionId },
            })
          })
        )
      }

      // 2. create & update options & questions
      const { questions } = values

      if (Array.isArray(questions) && questions.length > 0) {
        for (const question of questions) {
          const { options, exam_answer_values, ...rest } = question

          // Serialize exam answer values
          const examAnswerValues =
            exam_answer_values && typeof exam_answer_values === 'object'
              ? JSON.stringify({
                  right: Number(exam_answer_values.right),
                  wrong: Number(exam_answer_values.wrong),
                  empty: Number(exam_answer_values.empty),
                })
              : null

          if (question.new) {
            // CREATE
            await addPollQuestion({
              variables: {
                poll_id: poll.id,
                exam_answer_values: examAnswerValues,
                ...rest,
              },
            })

            if (
              question.type === 'OPTIONS' &&
              Array.isArray(options) &&
              options.length > 0
            ) {
              for (const option of options) {
                // only need to create options since
                // new questions can only have new options
                await addPollQuestionOption({
                  variables: {
                    poll_question_id: question.id,
                    ...option,
                  },
                })
              }
            }
          } else {
            // UPDATE
            await updatePollQuestion({
              variables: {
                exam_answer_values: examAnswerValues,
                ...rest,
              },
            })

            if (
              question.type === 'OPTIONS' &&
              Array.isArray(options) &&
              options.length > 0
            ) {
              // create & update options
              for (const option of options) {
                if (option.new) {
                  await addPollQuestionOption({
                    variables: {
                      poll_question_id: question.id,
                      ...option,
                    },
                  })
                } else {
                  await updatePollQuestionOption({
                    variables: {
                      ...option,
                    },
                  })
                }
              }
            } else {
              // if type changed from 'OPTIONS'
              // then delete all existing options
              for (const option of options) {
                await deletePollQuestionOption({
                  variables: { id: option.id },
                })
              }
            }
          }
        }
      }

      // Reset form
      setOptionCemetery([])
      setQuestionCemetery([])
      refetchPoll()
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
      captureException(error)
    }

    setSubmitting(false)
  }

  const onDragEnd = (result, setFieldValue, questions) => {
    const { source, destination } = result

    if (!destination) {
      return
    }
    if (source.index === destination.index) {
      return
    }

    // sync orders
    if (Array.isArray(questions)) {
      const [moved] = questions.splice(source.index, 1)
      questions.splice(destination.index, 0, moved)

      questions.forEach((_, index) => {
        setFieldValue(`questions[${index}].order`, index)
      })
    }
  }

  const formData = React.useMemo(() => {
    const { questions, ...rest } = poll

    return {
      questions: Array.isArray(questions)
        ? questions.map(question => {
            const { exam_answer_values, additional_info, ...rest } = question

            return {
              exam_answer_values:
                typeof exam_answer_values === 'string'
                  ? JSON.parse(exam_answer_values)
                  : DEFAULT_QUESTION_ANSWER_VALUES,
              additional_info:
                typeof additional_info === 'string' ? additional_info : '',
              ...rest,
            }
          })
        : [],
      ...rest,
    }
  }, [poll])

  return (
    <>
      {(dpqMutation.loading ||
        dpqoMutation.loading ||
        upqMutation.loading ||
        upqoMutation.loading ||
        apqMutation.loading ||
        apqoMutation.loading) && (
        <FullscreenLoading variant="white" transparent />
      )}

      <Formik
        enableReinitialize
        initialValues={formData}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ dirty, isSubmitting, values, setFieldValue }) => (
          <>
            <FormContainer>
              <div>
                {/* QUESTIONS */}
                <FieldArray
                  name="questions"
                  render={arrayHelpers => (
                    <DragDropContext
                      onDragEnd={result =>
                        onDragEnd(result, setFieldValue, values.questions)
                      }
                    >
                      <Droppable droppableId="questionsDroppable">
                        {provided => (
                          <Grid
                            innerRef={provided.innerRef}
                            {...provided.droppableProps}
                            container
                            spacing={8}
                          >
                            {Array.isArray(values.questions) &&
                            values.questions.length > 0 ? (
                              <>
                                {values.questions
                                  .sort(sortHandler)
                                  .map((question, questionIndex) => (
                                    <Draggable
                                      draggableId={question.id}
                                      index={questionIndex}
                                      key={question.id}
                                    >
                                      {provided => (
                                        <Grid
                                          innerRef={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          item
                                          xs={12}
                                        >
                                          <QuestionItem
                                            pollType={poll.type}
                                            question={question}
                                            questionIndex={questionIndex}
                                            setFieldValue={setFieldValue}
                                            removeQuestion={() => {
                                              // Normalize other questions' order
                                              for (
                                                let i = questionIndex + 1;
                                                i < values.questions.length;
                                                i++
                                              ) {
                                                setFieldValue(
                                                  `questions[${i}].order`,
                                                  values.questions[i].order - 1
                                                )
                                              }

                                              markQuestionForDeletion(
                                                question.id
                                              )
                                              arrayHelpers.remove(questionIndex)
                                            }}
                                            markOptionForDeletion={
                                              markOptionForDeletion
                                            }
                                          />
                                        </Grid>
                                      )}
                                    </Draggable>
                                  ))}

                                {provided.placeholder}
                              </>
                            ) : (
                              <Grid item xs={12}>
                                <Typography align="center" variant="h6" noWrap>
                                  {
                                    'Add questions to the poll by using the button below!'
                                  }
                                </Typography>
                                <Typography
                                  component="p"
                                  align="center"
                                  variant="caption"
                                  color="error"
                                  noWrap
                                >
                                  {'(At least 1 question is required.)'}
                                </Typography>
                              </Grid>
                            )}

                            <Grid item xs={12} className={classes.center}>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  arrayHelpers.push({
                                    id: uuidv4(),
                                    order: values.questions.length,
                                    ...initialQuestionValues,
                                    new: true,
                                  })
                                }}
                              >
                                {'New question'}

                                <AddIcon className={classes.buttonIcon} />
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                      </Droppable>
                    </DragDropContext>
                  )}
                />
              </div>

              <FormFooter isSubmitting={isSubmitting} dirty={dirty} save />
            </FormContainer>
          </>
        )}
      </Formik>
    </>
  )
}

PollSettingsQuestionsForm.propTypes = {
  poll: PropTypes.object.isRequired,
  refetchPoll: PropTypes.func.isRequired,
}

const styles = makeStyles(theme => ({
  marginTop: {
    marginTop: theme.spacing(4),
  },
  marginTopLg: {
    marginTop: theme.spacing(6),
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonIcon: {
    marginLeft: theme.spacing(1),
  },
}))

export default PollSettingsQuestionsForm
