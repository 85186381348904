import React from 'react'
import PropTypes from 'prop-types'

// Layout
import { ContentWrapper } from '../../../layout'

// Components
import PollSettingsQuestionsForm from '../../../components/forms/PollSettings/PollSettingsQuestionsForm'

const PollSettingsQuestions = props => {
  const { poll, refetchPoll } = props

  return (
    <ContentWrapper>
      <PollSettingsQuestionsForm poll={poll} refetchPoll={refetchPoll} />
    </ContentWrapper>
  )
}

PollSettingsQuestions.propTypes = {
  poll: PropTypes.object.isRequired,
  refetchPoll: PropTypes.func.isRequired,
}

export default PollSettingsQuestions
