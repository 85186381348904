import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'

// Components
import CongressMiscellaneousForm from '../../components/forms/Congress/CongressMiscellaneousForm'

// Cosmetics
import FullscreenLoading from '../../components/cosmetics/loadings/FullscreenLoading'

// Queries & Mutations
import { query } from '../../lib/apollo-client'

const Settings = props => {
  const { congressId } = props
  const { enqueueSnackbar } = useSnackbar()

  // Data
  const { data, error, loading } = useQuery(
    query.form.congress.CONGRESS_SETTINS,
    {
      variables: { id: congressId },
    }
  )

  React.useEffect(() => {
    if (loading) return

    if (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      })
      captureException(error)
    }
  }, [data, error, loading, enqueueSnackbar])

  if (loading) {
    return <FullscreenLoading variant="white" transparent />
  }

  return (
    <>
      {data && data.congress && (
        <CongressMiscellaneousForm congress={data.congress} />
      )}
    </>
  )
}

Settings.propTypes = {
  congressId: PropTypes.string.isRequired,
}

export default Settings
