import { useMutation, useQuery } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/core/styles'
import { captureException } from '@sentry/browser'
import { FastField, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import ReactImage from 'react-image'
import { useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import * as Yup from 'yup'
import { useAssetStore } from '../AssetStore'

// Utils
import FormikTextField from '../utils/FormikTextField'

// config
import { s3config } from '../../config'

// MUI
import {
  Button,
  CircularProgress,
  Grid,
  Tooltip,
  Typography,
} from '@material-ui/core'
import MarkerIcon from '@material-ui/icons/Room'

// Layout
import FormContainer from './elements/layout/FormContainer'
import FormFooter from './elements/layout/FormFooter'

// Elements
import DrawerTitle from '../drawers/elements/DrawerTitle'

// Dialogs
import ConfirmationDialog from '../dialogs/ConfirmationDialog'
import SelectionDialog from '../dialogs/SelectionDialog'

// Cosmetics
import FullscreenLoading from '../cosmetics/loadings/FullscreenLoading'

// Queries & Mutations
import { mutation, query } from '../../lib/apollo-client'
import { FileType } from '../../services/openapi'

const schema = Yup.object().shape({
  name: Yup.string().required().max(100),
  file_id: Yup.string().nullable(true),
  width: Yup.number().integer().nullable(true),
  height: Yup.number().integer().nullable(true),
})

const initialValues = {
  name: '',
  file_id: null,
  file: null,
  width: null,
  height: null,
}

const FloorplanForm = (props: any) => {
  const { floorplan, congressId } = props
  const classes = styles()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const { handleAssetStoreOpen } = useAssetStore()

  // Data
  // MARKERS
  const markersQuery = useQuery(query.form.floorplan.MARKERS, {
    variables: { floorplan_id: floorplan.id },
  })
  // LOCATIONS
  const locationsQuery = useQuery(query.form.floorplan.LOCATIONS, {
    variables: { congress_id: congressId },
  })

  useEffect(() => {
    if (markersQuery.loading) {
      return
    }

    if (markersQuery.error) {
      enqueueSnackbar(
        `Error: ${markersQuery.error.name}: ${markersQuery.error.message}`,
        {
          variant: 'error',
        }
      )
      captureException(markersQuery.error)
    }
  }, [
    markersQuery.data,
    markersQuery.error,
    markersQuery.loading,
    enqueueSnackbar,
  ])

  useEffect(() => {
    if (locationsQuery.loading) {
      return
    }

    if (locationsQuery.error) {
      enqueueSnackbar(
        `Error: ${locationsQuery.error.name}: ${locationsQuery.error.message}`,
        {
          variant: 'error',
        }
      )
      captureException(locationsQuery.error)
    }
  }, [
    locationsQuery.data,
    locationsQuery.error,
    locationsQuery.loading,
    enqueueSnackbar,
  ])

  // MUTATIONS
  const [updateFloorplan, updateFloorplanMutation] = useMutation(
    mutation.form.floorplan.UPDATE_FLOORPLAN
  )
  const [deleteFloorplan, deleteFloorplanMutation] = useMutation(
    mutation.form.floorplan.DELETE_FLOORPLAN,
    {
      update(cache: any, { data: { deleteFloorplan } }) {
        const { floorplans } = cache.readQuery({
          query: query.table.FLOORPLANS,
          variables: { congress_id: congressId },
        })

        cache.writeQuery({
          query: query.table.FLOORPLANS,
          variables: { congress_id: congressId },
          data: {
            floorplans: floorplans.filter((floorplan: any) => {
              return floorplan.id !== deleteFloorplan.id
            }),
          },
        })
      },
    }
  )
  const [addMarker, addMarkerMutation] = useMutation(
    mutation.form.floorplan.ADD_MARKER,
    {
      update(cache, { data: { addMarker } }) {
        const { markers } = markersQuery.data

        cache.writeQuery({
          query: query.form.floorplan.MARKERS,
          variables: { floorplan_id: floorplan.id },
          data: {
            markers: markers.concat([addMarker]),
          },
        })
      },
    }
  )
  const [deleteMarker, deleteMarkerMutation] = useMutation(
    mutation.form.floorplan.DELETE_MARKER,
    {
      update(cache, { data: { deleteMarker } }) {
        const { markers } = markersQuery.data

        cache.writeQuery({
          query: query.form.floorplan.MARKERS,
          variables: { floorplan_id: floorplan.id },
          data: {
            markers: markers.filter((marker: any) => {
              return marker.id !== deleteMarker.id
            }),
          },
        })
      },
    }
  )
  const [deleteAllMarkers, deleteAllMarkersMutation] = useMutation(
    mutation.form.floorplan.DELETE_ALL_MARKERS,
    {
      update(cache) {
        cache.writeQuery({
          query: query.form.floorplan.MARKERS,
          variables: { floorplan_id: floorplan.id },
          data: {
            markers: [],
          },
        })
      },
    }
  )

  const [clickX, setClickX] = useState(0)
  const [clickY, setClickY] = useState(0)
  const imageRef = useRef<ReactImage>(null)

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
  const handleConfirmationOpen = () => setDeleteConfirmOpen(true)
  const handleConfirmationClose = () => setDeleteConfirmOpen(false)

  const [selectionDialogOpen, setSelectionDialogOpen] = useState(false)
  const handleSelectionDialogOpen = () => setSelectionDialogOpen(true)
  const handleSelectionDialogClose = () => setSelectionDialogOpen(false)

  const assetStoreHandler = (setFieldValue: any) => {
    handleAssetStoreOpen(FileType.ICON, selected => {
      if (!selected) {
        return
      }

      // if file changes, delete all markers
      if (selected.id !== floorplan.file_id) {
        ;(async () => {
          try {
            await deleteAllMarkers({
              variables: { floorplan_id: floorplan.id },
            })
          } catch (error) {
            enqueueSnackbar('Error: error during removing markers!', {
              variant: 'error',
            })
            captureException(error)
          }
        })()
      }

      setFieldValue('file_id', selected.id)
      setFieldValue('file.id', selected.id)
      setFieldValue('file.file_name', selected.file_name)

      const img = new Image()
      //@ts-ignore
      img.src = imageRef.current?.props.src || ''
      img.onload = function () {
        setFieldValue('width', img.width || null)
        setFieldValue('height', img.height || null)
      }
    })
  }

  const handleSubmit = async (
    values: any,
    { setSubmitting, resetForm }: any
  ) => {
    try {
      // change empty strings back to nulls
      const { file, ...rest } = values

      await updateFloorplan({
        variables: {
          ...rest,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateFloorplan: {
            file: file
              ? {
                  ...file,
                  __typename: 'File',
                }
              : null,
            ...rest,
            __typename: 'Floorplan',
          },
        },
      })

      resetForm(values)
    } catch (error) {
      enqueueSnackbar('Error: error during updating floorplan!', {
        variant: 'error',
      })
      captureException(error)
    }

    setSubmitting(false)
  }

  /**
   * Deletes floorplan and redirects to Congress.
   */
  const handleDelete = async () => {
    try {
      await deleteFloorplan({
        variables: { id: floorplan.id },
        optimisticResponse: {
          __typename: 'Mutation',
          deleteFloorplan: {
            id: floorplan.id,
            __typename: 'Floorplan',
          },
        },
      })

      history.push(`/congress/${congressId}`)
    } catch (error) {
      enqueueSnackbar('Error: error during deleting floorplan!', {
        variant: 'error',
      })
      captureException(error)
    }
  }

  const handleImageClick = (e: any) => {
    setClickX(e.nativeEvent.offsetX)
    setClickY(e.nativeEvent.offsetY)

    handleSelectionDialogOpen()
  }

  /**
   * Adds new marker on image click.
   */
  const addNewMarker = async (room: any) => {
    const values = {
      id: uuidv4(),
      floorplan_id: floorplan.id,
      section_room: room,
      coord_x: clickX,
      coord_y: clickY,
    }

    try {
      await addMarker({
        variables: values,
        optimisticResponse: {
          __typename: 'Mutation',
          addMarker: {
            ...values,
            __typename: 'Marker',
          },
        },
      })
    } catch (error) {
      enqueueSnackbar('Error: error during adding marker!', {
        variant: 'error',
      })
      captureException(error)
    }
  }

  /**
   * Deletes marker.
   */
  const handleMarkerDelete = async (id: string) => {
    try {
      await deleteMarker({
        variables: { id },
        optimisticResponse: {
          __typename: 'Mutation',
          deleteMarker: {
            id: id,
            __typename: 'Marker',
          },
        },
      })
    } catch (error) {
      enqueueSnackbar('Error: error during deleting marker!', {
        variant: 'error',
      })
      captureException(error)
    }
  }

  // generate form data
  // change nulls to empty strings
  const formData = {
    ...initialValues,
    ...floorplan,
  }

  return (
    <>
      {(markersQuery.loading ||
        locationsQuery.loading ||
        updateFloorplanMutation.loading ||
        deleteFloorplanMutation.loading ||
        addMarkerMutation.loading ||
        deleteMarkerMutation.loading ||
        deleteAllMarkersMutation.loading) && (
        <FullscreenLoading variant="white" transparent />
      )}

      <Formik
        enableReinitialize
        initialValues={formData}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ dirty, isSubmitting, setFieldValue, values }) => (
          <FormContainer>
            <DrawerTitle title="Edit floorplan" />

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FastField
                  name="name"
                  type="text"
                  label="Name"
                  component={FormikTextField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              {values.file && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="caption" paragraph align="center">
                      {'Click on the image to place a new marker.'}
                    </Typography>
                    <Typography variant="caption" paragraph align="center">
                      {'Click on a marker to delete it.'}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={classes.planContainer}>
                      <div
                        style={{
                          position: 'relative',
                        }}
                      >
                        <ReactImage
                          src={`https://${s3config.bucket}.s3.${s3config.region}.amazonaws.com/${values.file.file_name}`}
                          loader={<CircularProgress size={35} thickness={5} />}
                          onClick={handleImageClick}
                          ref={imageRef}
                        />

                        {markersQuery.data &&
                          markersQuery.data.markers &&
                          markersQuery.data.markers.length > 0 &&
                          markersQuery.data.markers.map((marker: any) => (
                            <div
                              key={marker.id}
                              style={{
                                position: 'absolute',
                                left: marker.coord_x,
                                top: marker.coord_y,
                              }}
                            >
                              <div
                                style={{
                                  transform: 'translate(-50%, -50%)',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                }}
                              >
                                <Tooltip
                                  title="Click to delete marker"
                                  placement="top"
                                >
                                  <MarkerIcon
                                    fontSize="large"
                                    className={classes.marker}
                                    onClick={() =>
                                      handleMarkerDelete(marker.id)
                                    }
                                  />
                                </Tooltip>

                                <div className={classes.markerLabel}>
                                  <Typography
                                    variant="caption"
                                    style={{ fontWeight: 'bold' }}
                                    align="center"
                                    noWrap
                                  >
                                    {marker.section_room}
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </Grid>
                </>
              )}

              <Grid item xs={12} className={classes.center}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => assetStoreHandler(setFieldValue)}
                >
                  {values.file ? 'Change plan' : 'Add plan'}
                </Button>
              </Grid>
            </Grid>

            {locationsQuery.data && locationsQuery.data.locations && (
              <SelectionDialog
                title="Please select room for marker:"
                open={selectionDialogOpen}
                onClose={handleSelectionDialogClose}
                options={locationsQuery.data.locations}
                onSubmit={selected => addNewMarker(selected)}
              />
            )}

            <FormFooter
              isSubmitting={isSubmitting}
              dirty={dirty}
              save
              onDelete={handleConfirmationOpen}
            />

            <ConfirmationDialog
              title="Delete floorplan"
              open={deleteConfirmOpen}
              description="Are you sure you would like to delete this floorplan and all of its markers? This action is irreversible."
              returnLabel="Cancel"
              confirmLabel="Delete"
              onReturn={handleConfirmationClose}
              onConfirm={() => {
                handleConfirmationClose()
                handleDelete()
              }}
              variant="delete"
            />
          </FormContainer>
        )}
      </Formik>
    </>
  )
}

FloorplanForm.propTypes = {
  floorplan: PropTypes.object.isRequired,
  congressId: PropTypes.string.isRequired,
}

const styles = makeStyles(theme => ({
  planContainer: {
    overflow: 'scroll',
    height: 500,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  marker: {
    color: theme.palette.error.dark,
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
  markerLabel: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid #000000',
    padding: theme.spacing(0.5),
  },
}))

export default FloorplanForm
