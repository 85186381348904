import React from 'react'
import PropTypes from 'prop-types'

// MUI
import { Grid, Button } from '@material-ui/core'

const QuickAction = props => {
  const { label, action, icon } = props

  return (
    <Grid
      item
      xs={12}
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Button
        onClick={action}
        color="primary"
        variant="contained"
        startIcon={icon}
        style={{
          width: 250,
        }}
      >
        {label}
      </Button>
    </Grid>
  )
}

QuickAction.propTypes = {
  label: PropTypes.node.isRequired,
  action: PropTypes.func.isRequired,
  icon: PropTypes.node,
}

export default QuickAction
