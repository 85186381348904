import gql from 'graphql-tag'

export default {
  congress: {
    UPDATE_CONGRESS: gql`
      mutation UPDATE_CONGRESS(
        $id: ID!
        $name: String!
        $from: Int!
        $to: Int!
        $hidden: Boolean!
        $status: Boolean!
        $cover_id: ID
        $color: String
        $text_color: String
        $source: String
      ) {
        updateCongress(
          id: $id
          name: $name
          from: $from
          to: $to
          hidden: $hidden
          status: $status
          cover_id: $cover_id
          color: $color
          text_color: $text_color
          source: $source
        ) {
          id
          name
          from
          to
          hidden
          status
          cover_id
          color
          text_color
          source
          cover {
            id
            file_name
            __typename
          }
          __typename
        }
      }
    `,
    MIGRATE_CONGRESS_DATA: gql`
      mutation MIGRATE_CONGRESS_DATA($id: ID!, $originTimezone: String!) {
        migrateCongressData(id: $id, originTimezone: $originTimezone)
      }
    `,
    PUBLISH_CONGRESS: gql`
      mutation PUBLISH_CONGRESS($id: ID!) {
        publishCongress(id: $id) {
          id
          settings
          __typename
        }
      }
    `,
    UPDATE_CONGRESS_SETTINGS: gql`
      mutation UPDATE_CONGRESS_SETTINGS($id: ID!, $settings: String) {
        updateCongressSettings(id: $id, settings: $settings) {
          id
          settings
          __typename
        }
      }
    `,
  },
  section: {
    ADD_SECTION: gql`
      mutation ADD_SECTION(
        $id: ID!
        $congress_id: ID!
        $name: String
        $name2: String
        $name3: String
        $short_name: String
        $description: String
        $from: Int
        $to: Int
        $chair_label: String
        $chair_free: String
        $discussant: String
        $room: String!
        $room_rank: Int!
        $show_break_after: Boolean!
        $break_name: String!
        $break_time: Int
        $show_discussion_after: Boolean!
        $discussion_name: String!
        $discussion_time: Int
        $poster: Boolean!
        $section_group: String
        $track: String!
        $download_file: String
        $link: String
        $logo: String
      ) {
        addSection(
          id: $id
          congress_id: $congress_id
          name: $name
          name2: $name2
          name3: $name3
          short_name: $short_name
          description: $description
          from: $from
          to: $to
          chair_label: $chair_label
          chair_free: $chair_free
          discussant: $discussant
          room: $room
          room_rank: $room_rank
          show_break_after: $show_break_after
          break_name: $break_name
          break_time: $break_time
          show_discussion_after: $show_discussion_after
          discussion_name: $discussion_name
          discussion_time: $discussion_time
          poster: $poster
          section_group: $section_group
          track: $track
          download_file: $download_file
          link: $link
          logo: $logo
        ) {
          id
          congress_id
          name
          short_name
          from
          to
          chair_free
          room
          ratingCount
          ratingAvg
          __typename
        }
      }
    `,
    UPDATE_SECTION: gql`
      mutation UPDATE_SECTION(
        $id: ID!
        $name: String
        $name2: String
        $name3: String
        $short_name: String
        $description: String
        $from: Int
        $to: Int
        $chair_label: String
        $chair_free: String
        $discussant: String
        $room: String
        $room_rank: Int
        $show_break_after: Boolean
        $break_name: String
        $break_time: Int
        $show_discussion_after: Boolean
        $discussion_name: String
        $discussion_time: Int
        $poster: Boolean
        $section_group: String
        $track: String
        $download_file: String
        $link: String
        $logo: String
      ) {
        updateSection(
          id: $id
          name: $name
          name2: $name2
          name3: $name3
          short_name: $short_name
          description: $description
          from: $from
          to: $to
          chair_label: $chair_label
          chair_free: $chair_free
          discussant: $discussant
          room: $room
          room_rank: $room_rank
          show_break_after: $show_break_after
          break_name: $break_name
          break_time: $break_time
          show_discussion_after: $show_discussion_after
          discussion_name: $discussion_name
          discussion_time: $discussion_time
          poster: $poster
          section_group: $section_group
          track: $track
          download_file: $download_file
          link: $link
          logo: $logo
        ) {
          id
          name
          name2
          name3
          short_name
          description
          from
          to
          chair_label
          chair_free
          discussant
          room
          room_rank
          show_break_after
          break_name
          break_time
          show_discussion_after
          discussion_name
          discussion_time
          poster
          section_group
          track
          download_file
          link
          logo
          ratingCount
          ratingAvg
          __typename
        }
      }
    `,
    DELETE_SECTION: gql`
      mutation DELETE_SECTION($id: ID!) {
        deleteSection(id: $id) {
          id
          __typename
        }
      }
    `,
  },
  abstract: {
    ADD_ABSTRACT: gql`
      mutation ADD_ABSTRACT(
        $id: ID!
        $congress_id: ID!
        $section_id: ID!
        $topic_id: ID
        $abstract_num: Int!
        $title: String!
        $presenter: String
        $name_head: String!
        $inst_head: String!
        $description: String
        $content: String
        $keyword: String
        $rank: Int!
        $from: Int
        $to: Int
        $lect_name: String!
        $lect_number: String!
        $lect_time: Int!
        $discussion_time: Int!
        $uploaded_file_name: String
        $track: String
        $forum: Boolean!
        $info_before: String
        $info_after: String
        $video_id: String
        $room: String!
      ) {
        addAbstract(
          id: $id
          congress_id: $congress_id
          section_id: $section_id
          topic_id: $topic_id
          abstract_num: $abstract_num
          title: $title
          presenter: $presenter
          name_head: $name_head
          inst_head: $inst_head
          description: $description
          content: $content
          keyword: $keyword
          rank: $rank
          from: $from
          to: $to
          lect_name: $lect_name
          lect_number: $lect_number
          lect_time: $lect_time
          discussion_time: $discussion_time
          uploaded_file_name: $uploaded_file_name
          track: $track
          forum: $forum
          info_before: $info_before
          info_after: $info_after
          video_id: $video_id
          room: $room
        ) {
          id
          congress_id
          section_id
          title
          from
          to
          presenter
          topic {
            id
            name
          }
          ratingCount
          ratingAvg
          __typename
        }
      }
    `,
    UPDATE_ABSTRACT: gql`
      mutation UPDATE_ABSTRACT(
        $id: ID!
        $section_id: ID
        $topic_id: ID
        $abstract_num: Int
        $title: String
        $presenter: String
        $name_head: String
        $inst_head: String
        $description: String
        $content: String
        $keyword: String
        $rank: Int
        $from: Int
        $to: Int
        $lect_name: String
        $lect_number: String
        $lect_time: Int
        $discussion_time: Int
        $uploaded_file_name: String
        $track: String
        $forum: Boolean
        $info_before: String
        $info_after: String
        $video_id: String
        $room: String
      ) {
        updateAbstract(
          id: $id
          section_id: $section_id
          topic_id: $topic_id
          abstract_num: $abstract_num
          title: $title
          presenter: $presenter
          name_head: $name_head
          inst_head: $inst_head
          description: $description
          content: $content
          keyword: $keyword
          rank: $rank
          from: $from
          to: $to
          lect_name: $lect_name
          lect_number: $lect_number
          lect_time: $lect_time
          discussion_time: $discussion_time
          uploaded_file_name: $uploaded_file_name
          track: $track
          forum: $forum
          info_before: $info_before
          info_after: $info_after
          video_id: $video_id
          room: $room
        ) {
          id
          section_id
          topic_id
          abstract_num
          title
          presenter
          name_head
          inst_head
          description
          content
          keyword
          rank
          from
          to
          lect_name
          lect_number
          lect_time
          discussion_time
          uploaded_file_name
          track
          forum
          info_before
          info_after
          video_id
          room
          __typename
        }
      }
    `,
    DELETE_ABSTRACT: gql`
      mutation DELETE_ABSTRACT($id: ID!) {
        deleteAbstract(id: $id) {
          id
          __typename
        }
      }
    `,
  },
  topic: {
    UPDATE_TOPIC: gql`
      mutation UPDATE_TOPIC($id: ID!, $name: String) {
        updateTopic(id: $id, name: $name) {
          id
          name
          __typename
        }
      }
    `,
    DELETE_TOPIC: gql`
      mutation DELETE_TOPIC($id: ID!) {
        deleteTopic(id: $id) {
          id
          __typename
        }
      }
    `,
  },
  break: {
    ADD_BREAK: gql`
      mutation ADD_BREAK(
        $id: ID!
        $congress_id: ID!
        $name: String!
        $from: Int!
        $to: Int!
      ) {
        addBreak(
          id: $id
          congress_id: $congress_id
          name: $name
          from: $from
          to: $to
        ) {
          id
          congress_id
          name
          from
          to
          __typename
        }
      }
    `,
    UPDATE_BREAK: gql`
      mutation UPDATE_BREAK($id: ID!, $name: String, $from: Int, $to: Int) {
        updateBreak(id: $id, name: $name, from: $from, to: $to) {
          id
          name
          from
          to
          __typename
        }
      }
    `,
    DELETE_BREAK: gql`
      mutation DELETE_BREAK($id: ID!) {
        deleteBreak(id: $id) {
          id
          __typename
        }
      }
    `,
  },
  floorplan: {
    UPDATE_FLOORPLAN: gql`
      mutation UPDATE_FLOORPLAN(
        $id: ID!
        $name: String!
        $file_id: ID
        $width: Int
        $height: Int
      ) {
        updateFloorplan(
          id: $id
          name: $name
          file_id: $file_id
          width: $width
          height: $height
        ) {
          id
          name
          file_id
          file {
            id
            file_name
            __typename
          }
          width
          height
          __typename
        }
      }
    `,
    DELETE_FLOORPLAN: gql`
      mutation DELETE_FLOORPLAN($id: ID!) {
        deleteFloorplan(id: $id) {
          id
          __typename
        }
      }
    `,
    ADD_MARKER: gql`
      mutation ADD_MARKER(
        $id: ID!
        $floorplan_id: ID!
        $section_room: String!
        $coord_x: Int!
        $coord_y: Int!
      ) {
        addMarker(
          id: $id
          floorplan_id: $floorplan_id
          section_room: $section_room
          coord_x: $coord_x
          coord_y: $coord_y
        ) {
          id
          section_room
          coord_x
          coord_y
          __typename
        }
      }
    `,
    DELETE_MARKER: gql`
      mutation DELETE_MARKER($id: ID!) {
        deleteMarker(id: $id) {
          id
          __typename
        }
      }
    `,
    DELETE_ALL_MARKERS: gql`
      mutation DELETE_ALL_MARKERS($floorplan_id: ID!) {
        deleteAllMarkers(floorplan_id: $floorplan_id) {
          id
          __typename
        }
      }
    `,
  },
  sponsor: {
    UPDATE_SPONSOR: gql`
      mutation UPDATE_SPONSOR(
        $id: ID!
        $name: String
        $sponsor_category_id: ID!
        $logo_id: ID
        $url: String
        $content: String
      ) {
        updateSponsor(
          id: $id
          name: $name
          sponsor_category_id: $sponsor_category_id
          logo_id: $logo_id
          url: $url
          content: $content
        ) {
          id
          name
          sponsor_category_id
          logo_id
          logo {
            id
            file_name
            __typename
          }
          url
          content
          __typename
        }
      }
    `,
    DELETE_SPONSOR: gql`
      mutation DELETE_SPONSOR($id: ID!) {
        deleteSponsor(id: $id) {
          id
          __typename
        }
      }
    `,
  },
  menu: {
    UPDATE_MENU: gql`
      mutation UPDATE_MENU(
        $id: ID!
        $title: String!
        $type: String!
        $is_sidebar: Boolean!
      ) {
        updateMenu(
          id: $id
          title: $title
          type: $type
          is_sidebar: $is_sidebar
        ) {
          id
          title
          type
          is_sidebar
          __typename
        }
      }
    `,
    DELETE_MENU: gql`
      mutation DELETE_MENU($id: ID!) {
        deleteMenu(id: $id) {
          id
          __typename
        }
      }
    `,
    UPDATE_MENU_ITEM: gql`
      mutation UPDATE_MENU_ITEM(
        $id: ID!
        $title: String!
        $icon: String
        $data_item_id: ID
      ) {
        updateMenuItem(
          id: $id
          title: $title
          icon: $icon
          data_item_id: $data_item_id
        ) {
          id
          title
          icon
          data_item_id
          data {
            id
            title
            type
            __typename
          }
          __typename
        }
      }
    `,
    DELETE_MENU_ITEM: gql`
      mutation DELETE_MENU_ITEM($id: ID!) {
        deleteMenuItem(id: $id) {
          id
          __typename
        }
      }
    `,
    ADD_DATA_ITEM: gql`
      mutation ADD_DATA_ITEM(
        $id: ID!
        $congress_id: ID
        $title: String!
        $type: String!
      ) {
        addDataItem(
          id: $id
          congress_id: $congress_id
          title: $title
          type: $type
        ) {
          id
          title
          type
          __typename
        }
      }
    `,
    DELETE_DATA_ITEM: gql`
      mutation DELETE_DATA_ITEM($id: ID!) {
        deleteDataItem(id: $id) {
          id
          __typename
        }
      }
    `,
    UPDATE_DATA_ITEM: gql`
      mutation UPDATE_DATA_ITEM($id: ID!, $title: String!) {
        updateDataItem(id: $id, title: $title) {
          id
          title
          __typename
        }
      }
    `,
    ADD_DATA_NODE: gql`
      mutation ADD_DATA_NODE($id: ID!, $data_item_id: ID!, $data: String) {
        addDataNode(id: $id, data_item_id: $data_item_id, data: $data) {
          id
          data_item_id
          type
          data
          created_at
          __typename
        }
      }
    `,
    DELETE_DATA_NODE: gql`
      mutation DELETE_DATA_NODE($id: ID!) {
        deleteDataNode(id: $id) {
          id
          __typename
        }
      }
    `,
    UPDATE_DATA_NODE: gql`
      mutation UPDATE_DATA_NODE($id: ID!, $data: String) {
        updateDataNode(id: $id, data: $data) {
          id
          data
          __typename
        }
      }
    `,
  },
}
