import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'

// Components
import TopicsTable from '../../components/tables/TopicsTable'

// Cosmetics
import FullscreenLoading from '../../components/cosmetics/loadings/FullscreenLoading'

// Queries & Mutations
import { query } from '../../lib/apollo-client'

const Topics = props => {
  const { congressId } = props
  const { enqueueSnackbar } = useSnackbar()

  // Data
  const { data, error, loading } = useQuery(query.table.TOPICS, {
    variables: { congress_id: congressId },
  })

  useEffect(() => {
    if (loading) {
      return
    }

    if (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      })
      captureException(error)
    }
  }, [data, error, loading, enqueueSnackbar])

  if (loading) {
    return <FullscreenLoading variant="white" transparent />
  }

  return (
    <>
      {data && data.topics && (
        <TopicsTable topics={data.topics} congressId={congressId} />
      )}
    </>
  )
}

Topics.propTypes = {
  congressId: PropTypes.string.isRequired,
}

export default Topics
