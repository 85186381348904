/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum FileType {
    ICON = 'ICON',
    IMG = 'IMG',
    DOC = 'DOC',
    QUILL_IMAGE = 'QUILL_IMAGE',
}
