import React from 'react'
import PropTypes from 'prop-types'

// MUI
import { Button } from '@material-ui/core'
import BackIcon from '@material-ui/icons/ArrowBackIos'

const BackButton = props => {
  const { handleBack } = props

  return (
    <>
      <Button
        color="secondary"
        onClick={handleBack}
        startIcon={<BackIcon />}
        size="large"
      >
        {'Back'}
      </Button>
    </>
  )
}

BackButton.propTypes = {
  handleBack: PropTypes.func.isRequired,
}

export default BackButton
