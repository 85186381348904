import React from 'react'
import { Switch, Route } from 'react-router-dom'

// MUI
import { Typography } from '@material-ui/core'

const PageTitle = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Typography component="h1" variant="h5" noWrap>
          {'Dashboard'}
        </Typography>
      </Route>

      <Route path="/societyapp">
        <Typography component="h1" variant="h5" noWrap>
          {'Society App'}
        </Typography>
      </Route>

      <Route exact path="/congresses">
        <Typography component="h1" variant="h5" noWrap>
          {'Congresses'}
        </Typography>
      </Route>
      <Route exact path="/congresses/new">
        <Typography component="h1" variant="h5" noWrap>
          {'New congress'}
        </Typography>
      </Route>
      <Route path="/congress/:congressId">
        <Typography component="h1" variant="h5" noWrap>
          {'Edit congress'}
        </Typography>
      </Route>

      <Route exact path="/polls">
        <Typography component="h1" variant="h5" noWrap>
          {'Polls'}
        </Typography>
      </Route>
      <Route exact path="/polls/new">
        <Typography component="h1" variant="h5" noWrap>
          {'Create new poll'}
        </Typography>
      </Route>
      <Route exact path="/poll/:pollId">
        <Typography component="h1" variant="h5" noWrap>
          {'Edit poll'}
        </Typography>
      </Route>

      <Route exact path="/ads">
        <Typography component="h1" variant="h5" noWrap>
          {'Advertisements'}
        </Typography>
      </Route>
      <Route exact path="/ads/new">
        <Typography component="h1" variant="h5" noWrap>
          {'Create new advertisement'}
        </Typography>
      </Route>
      <Route exact path="/ad/:adId">
        <Typography component="h1" variant="h5" noWrap>
          {'Edit advertisement'}
        </Typography>
      </Route>

      <Route exact path="/settings">
        <Typography component="h1" variant="h5" noWrap>
          {'App Settings'}
        </Typography>
      </Route>

      <Route exact path="*">
        <Typography component="h1" variant="h5" noWrap>
          {'Error 404: Page not found'}
        </Typography>
      </Route>
    </Switch>
  )
}

export default PageTitle
