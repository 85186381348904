import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/browser'
import MomentUtils from '@date-io/moment'

// External CSS
import 'react-perfect-scrollbar/dist/css/styles.css'

import { auth0config } from './config'
import { theme } from './lib/material-ui'

// Providers
import { Auth0Provider } from './lib/auth0'
import { ApolloWithAuth0 } from './lib/apollo-client'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { SnackbarProvider } from 'notistack'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { AssetStoreProvider } from './components/AssetStore'

// Components
import App from './App'

// Sentry should only report problems found in production
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://c1f24446e46b4deb8b43fdbdf6222677@sentry.io/1874175',
  })
}

ReactDOM.render(
  <BrowserRouter>
    <Auth0Provider
      domain={auth0config.domain}
      client_id={auth0config.clientId}
      redirect_uri={window.location.origin}
      audience={auth0config.audience}
    >
      <ApolloWithAuth0>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <AssetStoreProvider>
                <App />
              </AssetStoreProvider>
            </MuiPickersUtilsProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </ApolloWithAuth0>
    </Auth0Provider>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
