import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'
import { Formik, FastField } from 'formik'
import * as Yup from 'yup'

// Utils
import FormikTextField from '../../utils/FormikTextField'

// MUI
import { Grid } from '@material-ui/core'

// Layout
import FormContainer from '../elements/layout/FormContainer'
import FormFooter from '../elements/layout/FormFooter'

// Elements
import DrawerTitle from '../../drawers/elements/DrawerTitle'

// Cosmetics
import FullscreenLoading from '../../cosmetics/loadings/FullscreenLoading'

// Dialogs
import ConfirmationDialog from '../../dialogs/ConfirmationDialog'

// Queries & Mutations
import { query, mutation } from '../../../lib/apollo-client'

const schema = Yup.object().shape({
  name: Yup.string().required().max(500),
})

const initialValues = {
  name: '',
}

const TopicForm = props => {
  const { topic, congressId } = props
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const [deleteConfirmOpen, setDeleteConfirmOpen] = React.useState(false)
  const handleConfirmationOpen = () => setDeleteConfirmOpen(true)
  const handleConfirmationClose = () => setDeleteConfirmOpen(false)

  // Data
  const [updateTopic, updateTopicMutation] = useMutation(
    mutation.form.topic.UPDATE_TOPIC
  )
  const [deleteTopic, deleteTopicMutation] = useMutation(
    mutation.form.topic.DELETE_TOPIC,
    {
      update(cache, { data: { deleteTopic } }) {
        const topicsData = cache.readQuery({
          query: query.table.TOPICS,
          variables: { congress_id: congressId },
        })

        if (topicsData && Array.isArray(topicsData.topics)) {
          cache.writeQuery({
            query: query.table.TOPICS,
            variables: {
              congress_id: congressId,
            },
            data: {
              topics: topicsData.topics.filter(topic => {
                return topic.id !== deleteTopic.id
              }),
            },
          })
        }
      },
    }
  )

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await updateTopic({
        variables: {
          ...values,
        },
      })

      resetForm(values)
    } catch (error) {
      enqueueSnackbar('Error: error during saving topic!', {
        variant: 'error',
      })
      captureException(error)
    }

    setSubmitting(false)
  }

  const handleDelete = async () => {
    try {
      await deleteTopic({
        variables: { id: topic.id },
      })

      history.push(`/congress/${congressId}`)
    } catch (error) {
      enqueueSnackbar('Error: error during deleting topic!', {
        variant: 'error',
      })
      captureException(error)
    }
  }

  const { name, ...rest } = topic
  const formData = {
    ...initialValues,
    name: name ? name : '',
    ...rest,
  }

  return (
    <>
      {updateTopicMutation.loading ||
        (deleteTopicMutation.loading && (
          <FullscreenLoading variant="white" transparent />
        ))}

      <Formik
        enableReinitialize
        initialValues={formData}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, dirty }) => (
          <>
            <FormContainer elevation={0}>
              <DrawerTitle title="Edit topic" />

              <Grid container spacing={2}>
                {/* NAME */}
                <Grid item xs={12}>
                  <FastField
                    name="name"
                    type="text"
                    label="Name"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              </Grid>

              <FormFooter
                isSubmitting={isSubmitting}
                dirty={dirty}
                save
                onDelete={handleConfirmationOpen}
              />
            </FormContainer>

            <ConfirmationDialog
              title="Delete topic"
              description="Are you sure you would like to delete this topic? This action is irreversible."
              returnLabel="Cancel"
              confirmLabel="Delete"
              onReturn={handleConfirmationClose}
              onConfirm={() => {
                handleConfirmationClose()
                handleDelete()
              }}
              open={deleteConfirmOpen}
            />
          </>
        )}
      </Formik>
    </>
  )
}

TopicForm.propTypes = {
  topic: PropTypes.object.isRequired,
  congressId: PropTypes.string.isRequired,
}

export default TopicForm
