import React from 'react'
import Helmet from 'react-helmet'

// Layout
import { ContentWrapper } from '../layout'

// Components
import AdForm from '../components/forms/Ad/AdForm'

const NewAd = () => {
  return (
    <>
      <Helmet>
        <title>{'Create new advertisement'}</title>
      </Helmet>

      <ContentWrapper>
        <AdForm />
      </ContentWrapper>
    </>
  )
}

export default NewAd
