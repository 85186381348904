import { useMutation, useQuery } from '@apollo/react-hooks'
import { captureException } from '@sentry/browser'
import { FastField, Formik, FormikHelpers } from 'formik'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { useAssetStore } from '../../AssetStore'

import { s3config } from '../../../config'
import { removeNullProps } from '../../../utils/functions'

// Utils
import FormikTextField from '../../utils/FormikTextField'

// MUI
import { Grid, MenuItem, TextField } from '@material-ui/core'

// Layout
import FormContainer from '../elements/layout/FormContainer'
import FormFooter from '../elements/layout/FormFooter'

// Elements
import DrawerTitle from '../../drawers/elements/DrawerTitle'
import IconPicker from '../elements/inputs/IconPicker'
import QuillEditor from '../elements/inputs/QuillEditor'

// Cosmetics
import FullscreenLoading from '../../cosmetics/loadings/FullscreenLoading'
import SelectLoading from '../../cosmetics/loadings/SelectLoading'

// Dialogs
import ConfirmationDialog from '../../dialogs/ConfirmationDialog'

// Queries & Mutations
import { useFileUpload } from '../../../hooks/file-upload.mutation'
import { mutation, query } from '../../../lib/apollo-client'
import { FileType } from '../../../services/openapi'

const ONE_MEGABYTE_IN_BYTES = 1048576

const schema = Yup.object().shape({
  name: Yup.string().required().max(500),
  sponsor_category_id: Yup.string().required(),
  logo_id: Yup.string().nullable(true),
  url: Yup.string().max(1000),
  content: Yup.string(),
  logo: Yup.object({
    id: Yup.string(),
    file_name: Yup.string(),
  }),
})

type SponsorFormState = {
  name: string
  sponsor_category_id: string
  logo_id: null | string
  url: string
  content: string
  logo?: {
    id: string
    file_name: string
  }
}

const initialValues: SponsorFormState = {
  name: '',
  sponsor_category_id: '',
  logo_id: null,
  url: '',
  content: '',
}

type SponsorFormProps = {
  sponsor: any
  congressId: string
}

const SponsorForm = (props: SponsorFormProps) => {
  const { sponsor, congressId } = props
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const { handleAssetStoreOpen } = useAssetStore()

  const [categories, setCategories] = React.useState([])

  const [deleteConfirmOpen, setDeleteConfirmOpen] = React.useState(false)
  const handleConfirmationOpen = () => setDeleteConfirmOpen(true)
  const handleConfirmationClose = () => setDeleteConfirmOpen(false)

  const { isLoading: isImageUploading, mutateAsync } = useFileUpload(
    FileType.QUILL_IMAGE
  )

  const [updateSponsor, updateSponsorMutation] = useMutation(
    mutation.form.sponsor.UPDATE_SPONSOR
  )
  const [deleteSponsor, deleteSponsorMutation] = useMutation(
    mutation.form.sponsor.DELETE_SPONSOR
  )
  const categoriesQuery = useQuery(query.table.SPONSOR_CATEGORIES, {
    variables: { congress_id: congressId },
  })

  React.useEffect(() => {
    const { data, error, loading } = categoriesQuery

    if (loading) return

    if (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
      captureException(error)
    }

    if (data && Array.isArray(data.sponsorCategories)) {
      setCategories(data.sponsorCategories)
    }
  }, [categoriesQuery, enqueueSnackbar])

  const assetStoreHandler = (setFieldValue: (arg0: any, arg1: any) => void) => {
    handleAssetStoreOpen(FileType.IMG, selected => {
      if (!selected) return

      setFieldValue('logo_id', selected.id)
      setFieldValue('logo.id', selected.id)
      setFieldValue('logo.file_name', selected.file_name)
    })
  }
  const handleSubmit = async (
    values: SponsorFormState,
    { setSubmitting, resetForm }: FormikHelpers<SponsorFormState>
  ) => {
    try {
      await updateSponsor({
        variables: {
          ...values,
        },
        refetchQueries: [
          {
            query: query.table.SPONSOR_CATEGORY,
            variables: { id: values.sponsor_category_id },
          },
          {
            query: query.table.SPONSOR_CATEGORY,
            variables: { id: sponsor.sponsor_category_id },
          },
        ],
      })

      resetForm()
    } catch (e) {
      const error = e as any

      enqueueSnackbar(
        error.message ? error.message : 'Error: error during saving sponsor!',
        {
          variant: 'error',
        }
      )
      captureException(error)
    }

    setSubmitting(false)
  }

  const handleDelete = async () => {
    try {
      await deleteSponsor({
        variables: { id: sponsor.id },
        refetchQueries: [
          {
            query: query.table.SPONSOR_CATEGORY,
            variables: {
              id: sponsor.sponsor_category_id,
            },
          },
        ],
      })

      history.push(`/congress/${congressId}`)
    } catch (e) {
      const error = e as any
      enqueueSnackbar(
        error.message ? error.message : 'Error: error during deleting sponsor!',
        {
          variant: 'error',
        }
      )
      captureException(error)
    }
  }

  const uploadImage = async (file: Blob) => {
    if (file.size > ONE_MEGABYTE_IN_BYTES) {
      enqueueSnackbar('Error: the selected image exceeds the 1MB limit!', {
        variant: 'error',
      })
      return new Error('Selected image exceeds the 1MB limit!')
    }

    try {
      const uploadedFile = await mutateAsync({
        file: file,
      })

      if (uploadedFile) {
        const fileName = uploadedFile.file_name

        return `https://${s3config.bucket}.s3.${s3config.region}.amazonaws.com/${fileName}`
      } else {
        captureException(
          new Error('Could not retrieve filename from response!')
        )
        enqueueSnackbar('Error: could not retrieve image URL from response!', {
          variant: 'error',
        })
      }
    } catch (error) {
      captureException(error)
    }
  }

  removeNullProps(sponsor)
  const formData = {
    ...initialValues,
    ...sponsor,
  }

  return (
    <>
      {(updateSponsorMutation.loading ||
        deleteSponsorMutation.loading ||
        isImageUploading) && <FullscreenLoading variant="white" transparent />}

      <Formik
        enableReinitialize
        initialValues={formData}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, dirty, values, setFieldValue, errors }) => (
          <>
            <FormContainer>
              <DrawerTitle title="Edit sponsor" />
              <Grid container spacing={2}>
                {/* LOGO */}
                <Grid item xs={12}>
                  <IconPicker
                    label="Logo"
                    icon={values.logo}
                    onChange={() => assetStoreHandler(setFieldValue)}
                    onRemove={() => {
                      setFieldValue('logo_id', null)
                      setFieldValue('logo', null)
                    }}
                    error={Boolean(errors.logo_id)}
                    errorText={
                      Boolean(errors.logo_id) ? errors.logo_id : undefined
                    }
                  />
                </Grid>

                {/* NAME */}
                <Grid item xs={12}>
                  <FastField
                    name="name"
                    type="text"
                    label="Name"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                {/* CATEGORY */}
                <Grid item xs={12}>
                  {categoriesQuery.loading ? (
                    <SelectLoading />
                  ) : (
                    Array.isArray(categories) &&
                    categories.length > 0 && (
                      <TextField
                        name="sponsor_category_id"
                        type="text"
                        variant="outlined"
                        fullWidth
                        select
                        value={values.sponsor_category_id}
                        onChange={e => {
                          setFieldValue('sponsor_category_id', e.target.value)
                        }}
                        error={Boolean(errors.sponsor_category_id)}
                        helperText={
                          Boolean(errors.sponsor_category_id)
                            ? errors.sponsor_category_id
                            : undefined
                        }
                      >
                        {categories.map((category: any) => (
                          <MenuItem key={category.id} value={category.id}>
                            {category.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  )}
                </Grid>

                {/* URL */}
                <Grid item xs={12}>
                  <FastField
                    name="url"
                    type="text"
                    label="URL"
                    component={FormikTextField}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                {/* CONTENT */}
                <Grid item xs={12}>
                  <QuillEditor
                    value={values.content}
                    onChange={(newContent: string) => {
                      setFieldValue('content', newContent)
                    }}
                    uploadHandler={uploadImage}
                  />
                </Grid>
              </Grid>

              <FormFooter
                isSubmitting={isSubmitting}
                dirty={dirty}
                save
                onDelete={handleConfirmationOpen}
              />
            </FormContainer>

            <ConfirmationDialog
              title="Delete sponsor"
              description="Are you sure you would like to delete this sponsor? This action is irreversible."
              returnLabel="Cancel"
              confirmLabel="Delete"
              onReturn={handleConfirmationClose}
              onConfirm={() => {
                handleConfirmationClose()
                handleDelete()
              }}
              open={deleteConfirmOpen}
            />
          </>
        )}
      </Formik>
    </>
  )
}

SponsorForm.propTypes = {
  sponsor: PropTypes.object.isRequired,
  congressId: PropTypes.string.isRequired,
}

export default SponsorForm
