import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { File, FileType, FileUploadService } from '../services/openapi'
import { HttpError } from '../types/error'

function useFiles(
  type?: FileType,
  queryOptions?: UseQueryOptions<File[], HttpError>
) {
  return useQuery<File[], HttpError>(
    ['files', type],
    () => FileUploadService.fileUploadControllerGetAll(type),
    queryOptions
  )
}

export { useFiles }
