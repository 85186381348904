import { createContext, useContext } from 'react'
import { File, FileType } from '../../services/openapi'

type AssetStoreState = {
  assetStoreOpen: boolean
  assetStoreType: FileType
  handleAssetStoreOpen: (type: FileType, callback: (file: File) => any) => void
  handleAssetStoreClose: () => void
  assetStoreCallback: (file: File) => void
}

const defaultAssetStore: AssetStoreState = {
  assetStoreOpen: false,
  assetStoreType: FileType.IMG,
  handleAssetStoreOpen: () => {},
  handleAssetStoreClose: () => {},
  assetStoreCallback: () => {},
}

export const AssetStoreContext = createContext(defaultAssetStore)
export const useAssetStore = () => useContext(AssetStoreContext)
