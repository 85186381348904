import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useQuery } from '@apollo/react-hooks'
import { captureException } from '@sentry/browser'
import { useSnackbar } from 'notistack'

// MUI
import { Paper, Typography, Divider, Button, Box } from '@material-ui/core'
import EyeIcon from '@material-ui/icons/Visibility'
import SadIcon from '@material-ui/icons/SentimentDissatisfied'

// Layout
import { ContentWrapper } from '../../../layout'

// Cosmetics
import FullscreenLoading from '../../../components/cosmetics/loadings/FullscreenLoading'

// Dialogs
import PollResultsDialog from '../../../components/dialogs/PollResultsDialog'

// Queries & Mutations
import { query } from '../../../lib/apollo-client'

const PollSettingsResults = ({ pollId }) => {
  const classes = styles()
  const { enqueueSnackbar } = useSnackbar()

  const [resultsDialogOpen, setResultsDialogOpen] = React.useState(false)
  const handleResultsDialogOpen = () => setResultsDialogOpen(true)
  const handleResultsDialogClose = () => setResultsDialogOpen(false)

  const { data, error, loading } = useQuery(query.polls.POLL_RESULTS, {
    variables: { id: pollId },
    pollInterval: 5000,
  })

  React.useEffect(() => {
    if (loading) return

    if (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
      captureException(error)
    }
  }, [data, error, loading, enqueueSnackbar])

  const doResultsExist = React.useMemo(() => {
    return (
      data &&
      data.poll &&
      Array.isArray(data.poll.submissions) &&
      data.poll.submissions.length > 0
    )
  }, [data])

  if (loading) {
    return <FullscreenLoading variant="white" transparent />
  }

  return (
    <div className={classes.root}>
      <ContentWrapper>
        <Paper className={classes.paper}>
          <Typography variant="h4" gutterBottom>
            {'Poll results'}
          </Typography>
          <Divider />

          <Box display="flex" justifyContent="center" mt={4}>
            {doResultsExist ? (
              <>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="large"
                  endIcon={<EyeIcon />}
                  onClick={handleResultsDialogOpen}
                >
                  {'Open poll results'}
                </Button>

                <PollResultsDialog
                  open={resultsDialogOpen}
                  onClose={handleResultsDialogClose}
                  poll={data.poll}
                />
              </>
            ) : (
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="h6">{'No results yet.'}</Typography>
                <SadIcon fontSize="large" className={classes.sadIcon} />
              </Box>
            )}
          </Box>
        </Paper>
      </ContentWrapper>
    </div>
  )
}

PollSettingsResults.propTypes = {
  pollId: PropTypes.string.isRequired,
}

const styles = makeStyles(theme => ({
  root: {},
  paper: {
    padding: theme.spacing(4),
  },
  sadIcon: {
    marginTop: theme.spacing(1),
  },
}))

export default PollSettingsResults
