import React from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'
import { Formik, FastField } from 'formik'
import * as Yup from 'yup'

import { removeNullProps } from '../../../utils/functions'
import FormikTextField from '../../utils/FormikTextField'

// MUI
import { useTheme, Grid, Typography, Divider } from '@material-ui/core'

// Layout
import FormContainer from '../elements/layout/FormContainer'
import FormFooter from '../elements/layout/FormFooter'

// Cosmetics
import FullscreenLoading from '../../cosmetics/loadings/FullscreenLoading'
import InformationBlock from '../../cosmetics/UI/InformationBlock'

// Queries & Mutations
import { query, mutation } from '../../../lib/apollo-client'

const initialValues = {
  user_api_url: '',
}

const schema = Yup.object().shape({
  user_api_url: Yup.string(),
})

const AppSettingsForm = props => {
  const { settings } = props
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()

  // Data
  const [updateSettings, updateSettingsMutation] = useMutation(
    mutation.settings.UPDATE_SETTINGS,
    {
      update(cache, { data: { updateSettings } }) {
        cache.writeQuery({
          query: query.settings.SETTINGS,
          data: { settings: updateSettings },
        })
      },
    }
  )

  removeNullProps(settings)
  const formData = {
    ...initialValues,
    ...settings,
  }

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await updateSettings({
        variables: {
          settings: JSON.stringify(values),
        },
      })

      resetForm(values)
    } catch (error) {
      enqueueSnackbar(
        error.message
          ? error.message
          : 'Error: error during updating app settings!',
        {
          variant: 'error',
        }
      )
      captureException(error)
    }

    setSubmitting(false)
  }

  return (
    <>
      {updateSettingsMutation.loading && (
        <FullscreenLoading variant="white" transparent />
      )}

      <Formik
        enableReinitialize
        initialValues={formData}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, dirty }) => (
          <FormContainer>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                style={{
                  marginBottom: theme.spacing(2),
                }}
              >
                <Typography variant="h4" gutterBottom>
                  {'App Settings'}
                </Typography>

                <Divider />
              </Grid>

              {/* USER API URL */}
              <Grid item xs={12}>
                <Typography variant="h6">{'User API URL'}</Typography>
              </Grid>

              <Grid item xs={12}>
                <FastField
                  name="user_api_url"
                  type="text"
                  placeholder="User API URL"
                  component={FormikTextField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <InformationBlock variant="info" size="small">
                  <Typography>
                    {
                      'You can use two placeholders in the URL: $USER$ and $PARTICIPANT$.'
                    }
                  </Typography>
                </InformationBlock>
              </Grid>
            </Grid>

            <FormFooter isSubmitting={isSubmitting} dirty={dirty} save />
          </FormContainer>
        )}
      </Formik>
    </>
  )
}

AppSettingsForm.propTypes = {
  settings: PropTypes.object.isRequired,
}

export default AppSettingsForm
