import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

// MUI
import { Button } from '@material-ui/core'
import PreviousIcon from '@material-ui/icons/ArrowBackIos'
import NextIcon from '@material-ui/icons/ArrowForwardIos'
import CheckIcon from '@material-ui/icons/Check'

const StepperFooter = props => {
  const {
    currentStep,
    maxStep,
    handleBack,
    handleNext,
    loading,
    canLastStepSubmit,
    submitLabel,
  } = props
  const classes = styles()

  return (
    <div className={classes.stepperFooter}>
      <Button
        variant="outlined"
        color="secondary"
        size="large"
        disabled={currentStep === 0 || loading}
        onClick={handleBack}
      >
        <PreviousIcon className={classes.previousIcon} />
        {'Previous'}
      </Button>

      <Button
        variant="contained"
        color="primary"
        size="large"
        disabled={(!canLastStepSubmit && currentStep === maxStep) || loading}
        onClick={handleNext}
      >
        {canLastStepSubmit ? (submitLabel ? submitLabel : 'Submit') : 'Next'}

        {canLastStepSubmit ? (
          <CheckIcon className={classes.nextIcon} />
        ) : (
          <NextIcon className={classes.nextIcon} />
        )}
      </Button>
    </div>
  )
}

StepperFooter.propTypes = {
  currentStep: PropTypes.number.isRequired,
  maxStep: PropTypes.number.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  canLastStepSubmit: PropTypes.bool,
  submitLabel: PropTypes.string,
}

const drawerWidth = 240
const styles = makeStyles(theme => ({
  stepperFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'fixed',
    bottom: 0,
    right: 0,
    left: 0,
    [theme.breakpoints.up('md')]: {
      left: drawerWidth,
    },
    zIndex: theme.zIndex.appBar,
    backgroundColor: theme.palette.common.white,
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
  },
  previousIcon: {
    marginRight: theme.spacing(1),
  },
  nextIcon: {
    marginLeft: theme.spacing(1),
  },
}))

export default StepperFooter
