import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'
import moment from 'moment'

// MUI
import {
  useMediaQuery,
  Button,
  IconButton,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'

// Components
import NewsItemDialog from './NewsItemDialog'

// Dialogs
import ContentDialog from '../../../dialogs/ContentDialog'
import ConfirmationDialog from '../../../dialogs/ConfirmationDialog'

// Queries & Mutations
import { mutation, query } from '../../../../lib/apollo-client'

const NewsItem = props => {
  const { congressId, node } = props
  const classes = styles()
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()
  const smAndUp = useMediaQuery(theme.breakpoints.up('sm'))

  const [contentDialogOpen, setContentDialogOpen] = React.useState(false)
  const handleContentDialogOpen = () => setContentDialogOpen(true)
  const handleContentDialogClose = () => setContentDialogOpen(false)

  const [deleteConfirmOpen, setDeleteConfirmOpen] = React.useState(false)
  const handleConfirmationOpen = () => setDeleteConfirmOpen(true)
  const handleConfirmationClose = () => setDeleteConfirmOpen(false)

  // Data
  const [deleteDataNode] = useMutation(mutation.form.menu.DELETE_DATA_NODE, {
    update(cache, { data: { deleteDataNode } }) {
      const { dataNodes } = cache.readQuery({
        query: query.form.menu.DATA_NODES,
        variables: { data_item_id: node.data_item_id },
      })

      cache.writeQuery({
        query: query.form.menu.DATA_NODES,
        variables: { data_item_id: node.data_item_id },
        data: {
          dataNodes: dataNodes.filter(dataNode => {
            return dataNode.id !== deleteDataNode.id
          }),
        },
      })
    },
    refetchQueries: [
      {
        query: query.oneSignal.ACTIVE_DATA_NODES,
        variables: { congress_id: congressId, type: 'NEWS' },
      },
    ],
  })

  const handleDelete = async () => {
    handleConfirmationClose()

    try {
      await deleteDataNode({
        variables: { id: node.id },
        optimisticResponse: {
          __typename: 'Mutation',
          deleteDataNode: {
            id: node.id,
            __typename: 'DataNode',
          },
        },
      })
    } catch (error) {
      enqueueSnackbar('Error: error during deleting data node!', {
        variant: 'error',
      })
      captureException(error)
    }
  }

  // Parse returned JSON data to object so we can display it
  const data = node.data ? JSON.parse(node.data) : {}
  const createdAt = smAndUp
    ? moment.unix(Number(node.created_at) / 1000).format('YYYY. MMMM Do, HH:mm')
    : moment.unix(Number(node.created_at) / 1000).format('YYYY. MM. DD., HH:mm')

  return (
    <>
      <ListItem>
        <ListItemText primary={data.title || node.id} secondary={createdAt} />

        <ListItemSecondaryAction>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleContentDialogOpen}
          >
            {'Edit'}

            <EditIcon
              fontSize="small"
              style={{ marginLeft: theme.spacing(1) }}
            />
          </Button>

          <IconButton
            onClick={handleConfirmationOpen}
            className={classes.deleteIcon}
            size="small"
            edge="end"
          >
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>

      <ContentDialog
        title="Edit piece of news"
        open={contentDialogOpen}
        onClose={handleContentDialogClose}
      >
        <NewsItemDialog
          dataNodeId={node.id}
          closeContentDialog={handleContentDialogClose}
        />
      </ContentDialog>

      <ConfirmationDialog
        title="Delete piece of news"
        open={deleteConfirmOpen}
        description="Are you sure you would like to delete this item? This action is irreversible."
        returnLabel="Cancel"
        confirmLabel="Delete"
        onReturn={handleConfirmationClose}
        onConfirm={handleDelete}
        variant="delete"
      />
    </>
  )
}

NewsItem.propTypes = {
  congressId: PropTypes.string,
  node: PropTypes.object.isRequired,
}

const styles = makeStyles(theme => ({
  deleteIcon: {
    marginLeft: theme.spacing(1),
    color: theme.palette.error.main,
  },
}))

export default NewsItem
