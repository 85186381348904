import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const MaterialIcon = props => {
  const { icon, size } = props
  const classes = styles(props)

  return (
    <i
      className={clsx(
        'mdi',
        `mdi-${icon}`,
        getSizeClassFromProp(size),
        classes.colored
      )}
    ></i>
  )
}

MaterialIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  color: PropTypes.oneOf(['primary', 'secondary']),
}

const styles = makeStyles(theme => ({
  colored: props => ({
    color: getThemeColorFromProp(props.color, theme),
  }),
}))

const getSizeClassFromProp = size => {
  switch (size) {
    case 'large':
      return 'mdi-48px'
    case 'medium':
      return 'mdi-36px'
    case 'small':
      return 'mdi-24px'
    default:
      return 'mdi-24px'
  }
}

const getThemeColorFromProp = (color, theme) => {
  switch (color) {
    case 'primary':
      return theme.palette.primary.main
    case 'secondary':
      return theme.palette.secondary.main
    default:
      return 'inherit'
  }
}

export default MaterialIcon
