import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useQuery } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'
import { useHistory } from 'react-router-dom'

// MUI
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListSubheader,
} from '@material-ui/core'
import AdIcon from '@material-ui/icons/PermDeviceInformation'
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined'

// Cosmetics
import DrawerLoading from '../../../../cosmetics/loadings/DrawerLoading'

// Queries & Mutations
import { query } from '../../../../../lib/apollo-client'

const PollItems = props => {
  const { closeDrawer } = props
  const classes = styles()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const [ads, setAds] = React.useState([])

  // Data
  const { data, error, loading } = useQuery(query.ads.ADVERTISEMENTS)

  React.useEffect(() => {
    if (loading) {
      return
    }

    if (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      })
      captureException(error)
    }

    if (data && Array.isArray(data.advertisements)) {
      data.advertisements.sort((a, b) => {
        if (Number(a.created_at) < Number(b.created_at)) {
          return 1
        } else if (Number(b.created_at) < Number(a.created_at)) {
          return -1
        } else {
          return 0
        }
      })

      setAds(data.advertisements)
    }
  }, [data, error, loading, enqueueSnackbar])

  return (
    <>
      {loading ? (
        <DrawerLoading />
      ) : (
        <>
          <List subheader={<ListSubheader>{'Advertisements'}</ListSubheader>}>
            <ListItem
              button
              onClick={() => {
                history.push('/ads/new')
                closeDrawer()
              }}
            >
              <ListItemIcon className={classes.drawerItemLogo}>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary={'Create new ad'} />
            </ListItem>
          </List>

          {Array.isArray(ads) && ads.length > 0 && (
            <List>
              {ads.map((ad, index) => (
                <ListItem
                  key={index}
                  button
                  onClick={() => {
                    history.push(`/ad/${ad.id}`)
                    closeDrawer()
                  }}
                >
                  <ListItemIcon className={classes.drawerItemLogo}>
                    <AdIcon />
                  </ListItemIcon>

                  <ListItemText primary={ad.name} />
                </ListItem>
              ))}
            </List>
          )}
        </>
      )}
    </>
  )
}

PollItems.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
}

const styles = makeStyles(theme => ({
  drawerItemLogo: {
    color: theme.palette.primary.main,
    minWidth: '40px',
  },
}))

export default PollItems
