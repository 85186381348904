import gql from 'graphql-tag'

export default {
  UPDATE_SETTINGS: gql`
    mutation UPDATE_SETTINGS($settings: String) {
      updateSettings(settings: $settings) {
        key
        value
        __typename
      }
    }
  `,
}
