import gql from 'graphql-tag'

export default {
  UPDATE_MENU_ORDER: gql`
    mutation UPDATE_MENU_ORDER($id: ID!, $order: Int!) {
      updateMenuOrder(id: $id, order: $order)
    }
  `,
  UPDATE_MENU_ITEM_ORDER: gql`
    mutation UPDATE_MENU_ITEM_ORDER($id: ID!, $order: Int!) {
      updateMenuItemOrder(id: $id, order: $order)
    }
  `,
  ADD_MENU: gql`
    mutation ADD_MENU(
      $id: ID!
      $congress_id: ID
      $title: String!
      $order: Int!
      $type: String!
      $is_sidebar: Boolean!
    ) {
      addMenu(
        id: $id
        congress_id: $congress_id
        title: $title
        order: $order
        type: $type
        is_sidebar: $is_sidebar
      ) {
        id
        title
        order
        is_sidebar
        items {
          id
          title
          order
          __typename
        }
        __typename
      }
    }
  `,
  ADD_MENU_ITEM: gql`
    mutation ADD_MENU_ITEM(
      $id: ID!
      $menu_id: ID!
      $title: String!
      $order: Int!
    ) {
      addMenuItem(id: $id, menu_id: $menu_id, title: $title, order: $order) {
        id
        title
        order
        icon
        __typename
      }
    }
  `,
  ADD_CONGRESS: gql`
    mutation ADD_CONGRESS(
      $id: ID!
      $name: String!
      $from: Int!
      $to: Int!
      $hidden: Boolean!
      $status: Boolean!
      $cover_id: ID
      $color: String
      $text_color: String
      $source: String
    ) {
      addCongress(
        id: $id
        name: $name
        from: $from
        to: $to
        hidden: $hidden
        status: $status
        cover_id: $cover_id
        color: $color
        text_color: $text_color
        source: $source
      ) {
        id
        name
        __typename
      }
    }
  `,
}
