import React from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'
import { Formik, FastField, Form } from 'formik'
import * as Yup from 'yup'

// Utils
import FormikTextField from '../../../../utils/FormikTextField'

// MUI
import { Grid } from '@material-ui/core'

// Layout
import FormFooter from '../../../elements/layout/FormFooter'

// Cosmetics
import FullscreenLoading from '../../../../cosmetics/loadings/FullscreenLoading'

// Queries & Mutations
import { mutation } from '../../../../../lib/apollo-client'

const schema = Yup.object().shape({
  url: Yup.string().required(),
})

const initialValues = {
  url: '',
}

const LinkForm = props => {
  const { node, closeContentDialog } = props
  const { enqueueSnackbar } = useSnackbar()

  // Data
  const [updateDataNode, updateDataNodeMutation] = useMutation(
    mutation.form.menu.UPDATE_DATA_NODE
  )

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await updateDataNode({
        variables: { id: node.id, data: JSON.stringify(values) },
        optimisticResponse: {
          __typename: 'Mutation',
          updateDataNode: {
            id: node.id,
            data: JSON.stringify(values),
            __typename: 'DataNode',
          },
        },
      })

      resetForm(values)
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
      captureException(error)
    }

    setSubmitting(false)
    closeContentDialog()
  }

  // Parse returned JSON data to object so we can edit it
  const data = node.data ? JSON.parse(node.data) : null
  // generate form data
  const formData = {
    ...initialValues,
    ...data,
  }

  return (
    <>
      {updateDataNodeMutation.loading && (
        <FullscreenLoading variant="white" transparent />
      )}

      <Formik
        enableReinitialize
        initialValues={formData}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ dirty, isSubmitting }) => (
          <Form noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FastField
                  name="url"
                  type="text"
                  label="Address (URL)"
                  component={FormikTextField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>

            <FormFooter isSubmitting={isSubmitting} dirty={dirty} save />
          </Form>
        )}
      </Formik>
    </>
  )
}

LinkForm.propTypes = {
  node: PropTypes.object.isRequired,
  closeContentDialog: PropTypes.func.isRequired,
}

export default LinkForm
