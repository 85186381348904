import React, { useMemo } from 'react'
import { useAuth0 } from '../auth0'

import { createApolloClientFromToken } from './client'

// Components
import { ApolloProvider } from '@apollo/react-hooks'

const ApolloWithAuth0 = props => {
  const { token } = useAuth0()
  const client = useMemo(() => createApolloClientFromToken(token), [token])

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>
}

export default ApolloWithAuth0
