import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { captureException } from '@sentry/browser'

// Components
import DataItemForm from '../../components/forms/DataItemForm'

// Cosmetics
import ContentLoading from '../../components/cosmetics/loadings/ContentLoading'

// Queries & Mutations
import { query } from '../../lib/apollo-client'

const DataItem = props => {
  const { id, congressId } = props
  const { enqueueSnackbar } = useSnackbar()

  // Data
  const { data, error, loading } = useQuery(query.form.menu.DATA_ITEM, {
    variables: { id },
  })

  useEffect(() => {
    if (loading) {
      return
    }

    if (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      })
      captureException(error)
    }
  }, [data, error, loading, enqueueSnackbar])

  if (loading) {
    return <ContentLoading />
  }

  return (
    <>
      {data && data.dataItem && (
        <DataItemForm congressId={congressId} dataItem={data.dataItem} />
      )}
    </>
  )
}

DataItem.propTypes = {
  id: PropTypes.string.isRequired,
  congressId: PropTypes.string,
}

export default DataItem
